import React from "react";
import ddcImage from '../../../../assets/images/ddc-logo.png'; 

const ConsentForm6 = () => {
    return (
        <div>
              <div class="form-row">
                <div class="form-group col-md-6">
                <img src={ddcImage} className='DDC-Image' alt="DDC logo" />
                </div>
                <div class="form-group col-md-2">
                    <h2><b>CONSENT FORM FOR</b></h2>
                    <h2><b>ASSIGNMENT OF</b></h2>
                    <h2><b>AUTHORIZED</b></h2>
                    <h2><b>REPRESENTATIVE</b></h2>
                </div>
            </div>

            <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
            <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-validate/1.19.3/jquery.validate.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-validate/1.19.3/additional-methods.js"></script>

            <div>
                <br/>
                <u>Appointment as Legal Authorized Representative</u>
                <br/>
                    I hereby appoint Perfect Patient Solutions as my authorized representative with the power to:
                    <br/>
                    - File medical claims with the health plan
                    <br/>
                    - File appeals and grievances with the health plan on my behalf
                    <br/>
                    - Discuss or divulge any of my personal health information or that of my dependents with any third party including the health plan
                    <br/>
                    - Institute any necessary litigation and/or complaints against my health plan naming me as plaintiff in such lawsuits and actions if necessary (or me as guardian of the patient if the patient is a minor)
                    <br/>
                    - Obtain copies of Plan Documents and Summary Plan Documents
                    <br/>
                    - File Appeals with Employers after appeals are exhausted.
                <br/>
                <br/>
                <u>
                    Authorization to Release Information
                </u>
                <br/>
                    I hereby authorize My Authorized Representatives to: (1) release any information necessary to my health
                    benefit plan (or its administrator) regarding my illness and treatments; (2) process insurance claims
                    generated in the course of examination or treatment; and (3) allow a photocopy of my signature to be
                    used to process insurance claims. This order will remain in effect until revoked by me in writing.
                <br/>
                <br/>
                    <u>Authorization</u>
                <br/>
                    I hereby designate, authorize, and convey to My Authorized Representatives to the full extent permissible under
                    law and under any applicable insurance policy and/or employee health care benefit plan: (1) the right and
                    ability to act as my Authorized Representative in connection with any claim, right, or cause of
                    action including litigation against my health plan (even to name me as a plaintiff in such action) that I may
                    have under such insurance policy and/or benefit plan; and (2) the right and ability to act as my Authorized
                    Representative to pursue such claim, right, or cause of action in connection with said insurance policy and/or
                    benefit plan (including but not limited to, the right and ability to act as my Authorized
                    Representative with respect to a benefit plan governed by the provisions of ERISA as provided in 29
                    C.F.R. §2560.5031(b)(4) with respect to any healthcare expense incurred as a result of the services I
                    received from Provider and, to the extent permissible under the law, to claim on my behalf, such benefits,
                    claims, or reimbursement, and any other applicable remedy, including fines. This constitutes an express
                    and knowing assignment of ERISA breach and /or fiduciary duty claims and other legal and/or
                    administrative claims. I authorize communication with my authorized representatives by email. I understand I can revoke this authorization in
                    writing at any time.
                <br/>
                <br/>
                    A photocopy of this Assignment/Authorization shall be as effective and valid as the original.
            </div>
            <br/>
            <b>YOU CAN CLOSE THIS TAB AFTER VIEWING AND GO BACK TO FORM</b>
        </div>
    );
};

export default ConsentForm6;
