import React, { useState, useEffect } from "react";
import { Form, Input, Row, Col, Tabs, Table, Button, Space ,message} from "antd";
import { formatPhoneNumber } from "../utilities/utility";
import "../../../assets/styles/appointmentType.css"
import { fetchAppointmentCRM ,getAppointmentConfirm ,getAppointmentCancel} from '../../services/appointmentService'
import { FileTextOutlined, MessageOutlined, AlertOutlined, CloseOutlined, CheckOutlined, LeftOutlined } from '@ant-design/icons';
import { screenActionCodes, actionPermission } from '../../utils/moduleScreenCodes';
import { format } from "date-fns";
import { conversationColumns } from '../appointments/handler/crmConversationColumn';
import { escalationColumns } from '../appointments/handler/crmEscalationColumn';



const { TabPane } = Tabs;
const AppointmentCRMForm = (props) => {

    const crmActionCodes = { "actionCodes": props.crmAction.actions };
    const [form] = Form.useForm();
    const [formattedDOB, setFormattedDOB] = useState("");
    const [formattedDateOfAppointment, setFormattedDateOfAppointment] = useState("");
    const [formattedPatientCell, setFormattedPatientCell] = useState("");
    const [formattedPatientLandline, setFormattedPatientLandline] = useState("");
    const [appointmentCRM, setAppointmentCRM] = useState(null);


    const labelWrapperProps = {
        labelAlign: 'left',
        labelCol: { span: 14 },
        wrapperCol: { span: 10 },
    };

    const gridBack = () => {
        props.onBack?.();
    };

    const fetchInitialData = async () => {
        try {
            const appointmentResponse = await fetchAppointmentCRM(props.appointmentData.appointmentId);
            //const appointmentResponse = await fetchAppointmentCRM("500");
            const appointmentData = appointmentResponse.data.appointment[0];

            // Format date and phone numbers
            const formattedDOB = format(new Date(appointmentData.patientDOB), "MM/dd/yyyy");
            const formattedDateOfAppointment = format(new Date(appointmentData.dateOfAppointment), "MM/dd/yyyy");
            const formattedPatientCell = formatPhoneNumber(appointmentData.patientCell);
            const formattedPatientLandline = formatPhoneNumber(appointmentData.patientLandline);

            // setAppointmentCRM ({appointmentData}) ;
            setAppointmentCRM({
                ...appointmentData,
                formattedDOB,
                formattedDateOfAppointment,
                formattedPatientCell,
                formattedPatientLandline,
            });

            // Set form fields
            form.setFieldsValue({
                patientName: appointmentData.patientName || '',
                patientId: appointmentData.patientId || '',
                patientDOB: formattedDOB || '',
                patientGender: appointmentData.patientGender || '',
                patientAddress: appointmentData.patientAddress || '',
                patientLandline: formattedPatientLandline || '',
                patientCell: formattedPatientCell || '',
                consentToReceiveText: appointmentData.consentToReceiveText || '',
                providerName: appointmentData.providerName || '',
                dateOfAppointment: formattedDateOfAppointment || '',
                appointmentType: appointmentData.appointmentType || '',
                appointmentDuration: appointmentData.appointmentDuration || '',
                appointmentLocation: appointmentData.appointmentLocation || '',
                reasonForVisit: appointmentData.reasonForVisit || '',
                appointmentStatus: appointmentData.appointmentStatus || '',
            });
        } catch (error) {
            console.error('Error fetching initial data:', error);
        }
    };


    useEffect(() => {
        fetchInitialData();

    }, []);

   
    const handleConfirm = async () => {
        try {
            const response = await getAppointmentConfirm();
            if (response.success) {
                message.success("Appointment confirmed successfully!");
            } else {
                message.error("Failed to confirm appointment.");
            }
        } catch (error) {
            message.error("An error occurred. Please try again.");
        }
    };

    const handleCancel = async () => {
        try {
            const response = await getAppointmentCancel();
            if (response.success) {
                message.success("Appointment confirmed successfully!");
            } else {
                message.error("Failed to confirm appointment.");
            }
        } catch (error) {
            message.error("An error occurred. Please try again.");
        }
    };

    return (
        <>
            <div className="container-height">
                <Form form={form} layout="horizontal" requiredMark={false} {...labelWrapperProps} colon={false} >
                    <div>
                        <Row className="btnApptm">
                            <Space>

                                {appointmentCRM &&
                                    actionPermission(crmActionCodes, screenActionCodes.APTY_CRM_CANCEL) &&
                                    (appointmentCRM.showConfirmedButton === true && appointmentCRM.showCancelledButton === true) && (
                                        <Button onClick={handleConfirm} icon={<CheckOutlined />} id="Confirm" className="btnCRMConfirm">
                                            Confirm
                                        </Button>
                                    )}
                                {appointmentCRM &&
                                    actionPermission(crmActionCodes, screenActionCodes.APTY_CRM_CONFIRM) &&
                                    appointmentCRM.showCancelledButton === true && (

                                        <Button onClick={handleCancel} icon={<CloseOutlined />} id="Cancel" className="btnCRMCancel">
                                            Cancel
                                        </Button>
                                    )}
                                <Button onClick={gridBack} icon={<LeftOutlined />} id="Back" >
                                    Back
                                </Button>
                            </Space>

                        </Row>
                    </div>
                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            <Form.Item label="Patient Name" name="patientName">
                                <Input readOnly />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Patient Id" name="patientId">
                                <Input readOnly />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="DOB" name="patientDOB">
                                <Input readOnly value={formattedDOB} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Gender" name="patientGender">
                                <Input readOnly />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item label="Patient Address" name="patientAddress">
                                <Input readOnly />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Patient Landline (Preferred)" name="patientLandline">
                                <Input readOnly />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Patient Cell" name="patientCell">
                                <Input readOnly value={formattedPatientCell} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Email" name="email">
                                <Input readOnly value={formattedPatientLandline} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item label="Insurance" name="insurance">
                                <Input readOnly />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Portal" name="portal">
                                <Input readOnly />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Consent To Receive Text" name="consentToReceiveText">
                                <Input readOnly />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Provider Name" name="providerName">
                                <Input readOnly />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item label="Date Of Appointment" name="dateOfAppointment">
                                <Input readOnly />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Appointment Type" name="appointmentType">
                                <Input readOnly />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Appointment Duration" name="appointmentDuration">
                                <Input readOnly />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Appointment Location" name="appointmentLocation">
                                <Input readOnly />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item label="Reason For Visit" name="reasonForVisit">
                                <Input readOnly />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Appointment Status" name="appointmentStatus">
                                <Input readOnly />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>



                <Tabs defaultActiveKey="1" >
                    {appointmentCRM && actionPermission(crmActionCodes, screenActionCodes.APTY_CRM_LOG) && (
                        <TabPane key="1"
                            tab={
                                <span>
                                    <FileTextOutlined className="iconCRM" />
                                    <span><strong>Log</strong></span>
                                </span>
                            }

                        >
                            <div>
                                {appointmentCRM.logs && appointmentCRM.logs.length > 0 ? (
                                    appointmentCRM.logs.map((log, index) => (
                                        <div key={index}>
                                            <p>Status: {log.status}</p>
                                            <p>Created At: {log.createdAt}</p>
                                            <hr />
                                        </div>
                                    ))
                                ) : (
                                    <p>No logs available</p>
                                )}
                            </div>
                        </TabPane>
                    )}
                    {actionPermission(crmActionCodes, screenActionCodes.APTY_CRM_CONVO) && (
                        <TabPane key="2"
                            tab={
                                <span>
                                    <MessageOutlined className="iconCRM" />
                                    <span><strong>Conversation</strong></span>
                                </span>
                            }

                        >
                            {appointmentCRM && appointmentCRM.conversations && appointmentCRM.conversations.length > 0 ? (
                                <Table
                                    dataSource={appointmentCRM.conversations}
                                    columns={conversationColumns}
                                    pagination={false}
                                />
                            ) : (
                                <p>No conversations available</p>
                            )}
                        </TabPane>
                    )}
                    {actionPermission(crmActionCodes, screenActionCodes.APTY_CRM_ESCALATION) &&
                        <TabPane key="3"
                            tab={
                                <span>
                                    <AlertOutlined className="iconCRM" />
                                    <span><strong>Escalation</strong></span>
                                </span>
                            }

                        >
                            {appointmentCRM && appointmentCRM.escalations && appointmentCRM.escalations.length > 0 ? (
                                <Table
                                    dataSource={appointmentCRM.escalations}
                                    columns={escalationColumns}
                                    pagination={false}
                                />
                            ) : (
                                <p>No escalations available</p>
                            )}
                        </TabPane>
                    }
                </Tabs>
            </div>
        </>
    );
};

export default AppointmentCRMForm;
