import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {
    Layout,
    DatePicker,
    Form,
    Input,
    Button,
    Select,
    Table,
    Checkbox,
    Row,
    Col,
    Space,
    Typography,
    Spin,
    Modal
} from "antd";
import { saveHealthInformation } from "../../services/ipadRegistartionService";
import moment from "moment/moment";

const { Option } = Select;


const HealthInformation = (props) => {
    const [form] = Form.useForm();
    // const [dataLoaded, setDataLoaded] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [errorData, setErrorData] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formControls, setFormControls] = useState([]);
    const [healthInfo, setHealthInfo] = useState([
        { Name: '', Relationship: '', Medical: '', Billing: '', Byphone: '', Inperson: '' },
    ]);

    // useEffect(() => {
    //     if (props.patientRegistrationInfo?.patientRegistrationFormData) {
    //         if (healthInfo.length > 0 && props.patientRegistrationInfo?.patientRegistrationFormData?.HealthInformation) {
    //             setHealthInfo(props.patientRegistrationInfo.patientRegistrationFormData.HealthInformation);
    //         }
    //         setDataLoaded(true);
    //     }
    // }, [props.patientRegistrationInfo]);

    // useEffect(() => {
    //     if (!(props.formControls.length === 0)) {
    //         const formControlsForActivePage = props.formControls[props.activeIpadPage];
    //         const formFields = formControlsForActivePage.map(control => control.form_field);
    //         setFormControls(formFields);
    //         setDataLoaded(true);
    //     }
    // }, [props.formControls]);
    const handleInputChange = (e, field, index) => {
        const newData = [...healthInfo];
        newData[index][field] = e.target.value;
        setHealthInfo(newData);
    };
    const handleCheckboxChange = (e, field, index) => {
        const newData = [...healthInfo];
        newData[index][field] = e.target.checked;
        setHealthInfo(newData);
    };
    const handleSelectChange = (e, field, index) => {
        const newData = [...healthInfo];
        newData[index][field] = e;
        setHealthInfo(newData);
    };
    const columns = [
        {
            title: 'Name',
            width: '30%', align: 'center',
            dataIndex: 'Name',
            render: (_, record, index) => (
                <Input
                    value={record.Name}
                    onChange={(e) => handleInputChange(e, 'Name', index)}
                />
            ),
        },
        {
            title: 'Relationship',
            width: '20%', align: 'center',
            dataIndex: 'Relationship',
            render: (_, record, index) => (
                <Select value={record.Relationship} onChange={(e) => handleSelectChange(e, 'Relationship', index)} style={{ width: 'calc(100vh - 527px)' }}>
                    <Option key="1" value="Spouse">
                        Spouse
                    </Option>
                    <Option key="2" value="Mother">
                        Mother
                    </Option>
                    <Option key="3" value="Father">
                        Father
                    </Option>
                    <Option key="4" value="Daughter">
                        Daughter
                    </Option>
                    <Option key="5" value="Son">
                        Son
                    </Option>
                    <Option key="6" value="Grandmother">
                        Grandmother
                    </Option>
                    <Option key="7" value="Grandfather">
                        Grandfather
                    </Option>
                    <Option key="8" value="Other">
                        Other
                    </Option>
                </Select>
            ),
        },
        {
            title: 'Type of Information Allowed to Disclose (Check one or both)',
            children:
                [{
                    title: 'Medical',
                    align: 'center',
                    dataIndex: 'Medical',
                    render: (_, record, index) => (
                        <Checkbox className="custom-table-checkbox" value="Medical"
                            checked={record.Medical}
                            onChange={(e) => handleCheckboxChange(e, 'Medical', index)}></Checkbox>
                    ),
                },
                {
                    title: 'Billing',
                    align: 'center',
                    dataIndex: 'Billing',
                    render: (_, record, index) => (
                        <Checkbox className="custom-table-checkbox" value="Billing" checked={record.Billing}
                            onChange={(e) => handleCheckboxChange(e, 'Billing', index)}></Checkbox>
                    ),
                }],
        }
        ,
        {
            title: 'Method of Disclosure (Check one or both)',
            children:
                [{
                    title: 'By phone',
                    align: 'center',
                    dataIndex: 'Byphone',
                    render: (_, record, index) => (
                        <Checkbox className="custom-table-checkbox" value="Byphone" checked={record.Byphone}
                            onChange={(e) => handleCheckboxChange(e, 'Byphone', index)}></Checkbox>
                    ),
                },
                {
                    title: 'In person',
                    align: 'center',
                    dataIndex: 'Inperson',
                    render: (_, record, index) => (
                        <Checkbox className="custom-table-checkbox" value="Inperson" checked={record.Inperson}
                            onChange={(e) => handleCheckboxChange(e, 'Inperson', index)}></Checkbox>
                    ),
                }],
        },
        {
            dataIndex: 'operation',
            width: 50,
            render:
                (_, __, index) =>
                    // currentMedication.length > 1 ? (
                    <Button type="primary" danger onClick={() => handleRemoveRow(index)}>Remove</Button>
            // ) : null,
        },
    ];
    const handleRemoveRow = (index) => {
        const newData = [...healthInfo];
        newData.splice(index, 1);
        setHealthInfo(newData);
        setErrorMessage(false);
    };
    const handleAddRow = () => {
        setErrorMessage(false);
        if (healthInfo.length < 6) {
            setHealthInfo([...healthInfo, { Name: '', Relationship: '', Medical: '', Billing: '', Byphone: '', Inperson: '' }]);
        }
    };
    const onHealthConsentPreviousClick = () => {
        props.handlePreviousClick(props);
    }
    const onHealthConsentNextClick = async () => {
        setIsSubmitting(true);
        try {
            if (healthInfo.length > 0) {
                let allHealthConsentValid = true;
                healthInfo.forEach(data => {
                    if (data['Name'] === null || data['Relationship'] === null ||
                        data['Name'] === '' || data['Relationship'] === '') {
                        allHealthConsentValid = false;
                    }
                });

                if (allHealthConsentValid) {
                    setErrorMessage(false);
                    let response = await saveHealthInformation(props.uniqueHash, healthInfo);
                    if (response.message === 'Success') {
                        props.handleNextClick(props);
                    } else {
                        Modal.error({
                            title: 'This is an error message',
                            content: 'See Console',
                        });
                    }
                } else {
                    setErrorData('Please enter at least one relationship.')
                    setErrorMessage(true);
                    setIsSubmitting(false);
                }
            } else {
                setErrorData('Please enter at least one relationship.')
                setErrorMessage(true);
                setIsSubmitting(false);
            }
        } finally {
            if (!isSubmitting) {
                return (
                    <div className="spinner-overlay">
                        <div className="spinner">
                            <Spin size="large" tip="Loading..." />
                        </div>
                    </div>
                );
            }
            setIsSubmitting(false);
        }
    };
    function parseDate(dateString) {
        if (dateString) {
            const parsedDate = moment(dateString, 'YYYY-MM-DD');
            return parsedDate.isValid() ? parsedDate : null;
        }
        return null;
    }
    const patientInfo = props.patientRegistrationInfo.patientRegistrationFormData?.PersonalInformation ?? [];
    let patientDetails = {
        PatientFirstName: patientInfo?.PatientFirstName || "",
        DateOfBirth: parseDate(patientInfo?.DateOfBirth) || "",
    };
    return (
        <div>
            <h2>Disclose Health Information</h2>
            <h5>Authorization to Disclose Health Information to Family Members and Friends</h5>
            <Form form={form} layout="vertical" size="large"
                initialValues={{
                    ...patientDetails,
                }}>
                <Row gutter={[14, 10]}>
                    <Col span={12}>
                        <Form.Item label="Patient Name:" name="PatientFirstName">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="DOB:" name="DateOfBirth">
                            <DatePicker disabled format="MM/DD/YYYY" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <br></br>
            <p>I hereby authorize {formControls.includes('MetroPracticeName') ? 'METRO' : 'DDC'} to release my patient health information as described below:</p>
            <Table
                dataSource={healthInfo}
                columns={columns}
                pagination={false}
                bordered
                rowKey={(record, index) => index}
            />
            <Space direction="vertical" className="pad-15">
                {errorMessage && <Typography.Title level={3} type="danger">{errorData}</Typography.Title>}
                <Button type="primary" onClick={handleAddRow}>Add Row</Button>
                {isSubmitting ? (
                    <div className="spinner-overlay">
                        <div className="spinner">
                            <Spin size="large" tip="Loading..." />
                        </div>
                    </div>
                ) : (
                    <Row gutter={12}>
                        <Col>
                            <Button type="primary" onClick={onHealthConsentPreviousClick}>Previous</Button>
                        </Col>
                        <Col>
                            <Button type="primary" onClick={onHealthConsentNextClick}>Next</Button>
                        </Col>
                    </Row>
                )}
            </Space>
        </div>
    )
}

export default HealthInformation;
