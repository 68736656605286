import React, { useEffect, useState } from "react";
import { Form, Select, Row, Col, Checkbox, Input, Space, Button, Dropdown, Tooltip } from "antd";
import { fetchPatientData, fetchLocationData, fetchProviderData, handleSearch } from "../appointments/handler/appointmentHandler";
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
const { Option } = Select;

const AppointmentAdd = () => {

    const [form] = Form.useForm();
    const [patientsData, setPatientsData] = useState([]);
    const [locationData, setLocationData] = useState([]);
    const [providerData, setProviderData] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [searchProviderInput, setSearchProviderInput] = useState('');
    const [message, setMessage] = useState('');

    const [filteredProviderData, setPhysiciansData] = useState(providerData);


    let [value, setValue] = useState(15);
    const labelWrapperProps = {
        labelAlign: 'left',
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    useEffect(() => {

        fetchPatientData(setPatientsData);
        fetchLocationData(setLocationData);
        fetchProviderData(setProviderData);

    }, []);

    const renderDropdown = (menuItems) => {
        if (!searchInput || searchInput.length < 2) {
            return (
                <div className='dropdown-message'>
                    Please enter 2 or more characters
                </div>
            );
        }
        return menuItems;
    };

    const handleDecrease = () => {
        const newValue = value - 5;
        if (newValue >= 15) {
            setValue(newValue);
            form.setFieldsValue({ duration: newValue });
        }
    };
    const handleIncrease = () => {
        setValue((prevValue) => prevValue + 5);
        form.setFieldsValue({ duration: value + 5 });
    };

    const renderDropdownOptions = (data, keyField) => {
        const uniqueValues = Array.from(new Set(data.map(item => item[keyField])));
        return uniqueValues.map((value, index) => (
            <Select.Option key={index} value={value}>
                {value}
            </Select.Option>
        ));
    };
    const renderDropdownProvider = (data) => {
        const uniqueProviderNames = [...new Set(data.map(provider => `${provider.firstName} ${provider.lastName}`))];
        return uniqueProviderNames.map((name, index) => (
            <Select.Option key={index} value={name}>
                {name}
            </Select.Option>
        ));
    };
    const handlePhysicianSearch = (value, setSearchProviderInput, setPhysiciansData, setMessage, allPhysiciansData) => {
        setSearchProviderInput(value);
        if (value.length < 2) {
            setMessage('Please enter 2 or more characters');
            setPhysiciansData([]);
        } else {
            setMessage('');
            const filtered = allPhysiciansData.filter((provider) =>
                `${provider.firstName} ${provider.lastName}`.toLowerCase().includes(value.toLowerCase())
            );
            setPhysiciansData(filtered);
        }
    };
    return (
        <div className="form-container">
            <Form
                form={form}
                layout="horizontal"
                requiredMark={false}
                {...labelWrapperProps}
                colon={false}
            >
                <Row gutter={[24, 24]}>
                    <Col span={6}>

                        <Form.Item label="Patients" name="patients">
                            <Select
                                mode="single"
                                allowClear
                                filterOption={false}
                                defaultActiveFirstOption={false}
                                placeholder="Patients"
                                maxTagCount={2}
                                onSearch={(value) => handleSearch(value, setSearchInput, setPatientsData, setMessage)}
                                value={searchInput}
                                dropdownRender={(menu) => (
                                    <div>
                                        <Input
                                            placeholder="Search patients"
                                            value={searchInput}
                                            onChange={(e) => handleSearch(e.target.value, setSearchInput, setPatientsData, setMessage)}
                                            style={{ width: 200, marginBottom: 8, display: 'block' }}
                                        />
                                        {renderDropdown(menu)}
                                    </div>
                                )}
                            >
                                {patientsData &&
                                    patientsData.length > 0 &&
                                    patientsData.map((patient) => (
                                        <Select.Option key={patient.patientId} value={`${patient.firstName} ${patient.lastName}`}>
                                            {`${patient.firstName} ${patient.lastName}`}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="DOB" name="dob">
                            <Select placeholder="Select a physician">
                                <Option value="option1">Option 1</Option>
                                <Option value="option2">Option 2</Option>
                                <Option value="option3">Option 3</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Phone" name="phone">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Insurance" name="insurance">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col span={6}>
                        <Form.Item label="Date" name="date">
                            <Select placeholder="Select a practice location">
                                <Option value="option1">Option 1</Option>
                                <Option value="option2">Option 2</Option>
                                <Option value="option3">Option 3</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Location" name="location">
                            <Select
                                mode="multiple"
                                allowClear
                                getPopupContainer={(trigger) => trigger.parentNode}
                                placeholder="Location"
                                maxTagCount={2}
                                className='location-dropdown'
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {locationData && locationData.length > 0 && renderDropdownOptions(locationData, 'locationName')}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Physician" name="physician">
                            <Select
                                mode="single"
                                allowClear
                                filterOption={false}
                                defaultActiveFirstOption={false}
                                placeholder="Physician"
                                maxTagCount={2}
                                value={searchProviderInput}
                                dropdownRender={(menu) => (
                                    <div>
                                        <Input
                                            placeholder="Search physician"
                                            value={searchProviderInput}
                                            onChange={(e) => handlePhysicianSearch(e.target.value, setSearchProviderInput, setPhysiciansData, setMessage, providerData)}
                                            style={{ width: 200, marginBottom: 8, display: 'block' }}
                                        />
                                        {renderDropdown(menu)}
                                    </div>
                                )}
                            >
                                {filteredProviderData &&
                                    filteredProviderData.length > 0 &&
                                    filteredProviderData.map((provider) => (
                                        <Select.Option key={provider.providerId} value={`${provider.firstName} ${provider.lastName}`}>
                                            {`${provider.firstName} ${provider.lastName}`}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Resource" name="resource">
                            <Select placeholder="Select a visit type">
                                <Option value="option1">Option 1</Option>
                                <Option value="option2">Option 2</Option>
                                <Option value="option3">Option 3</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col span={6}>
                        <Form.Item label="Department" name="department">
                            <Select disabled>
                                <Option value="option1">Option 1</Option>
                                <Option value="option2">Option 2</Option>
                                <Option value="option3">Option 3</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Visit Type" name="visitType">
                            <Select placeholder="Select a physician">
                                <Option value="option1">Option 1</Option>
                                <Option value="option2">Option 2</Option>
                                <Option value="option3">Option 3</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Reason For Visit" name="reasonForVisit">
                            <Select placeholder="Select a visit type">
                                <Option value="option1">Option 1</Option>
                                <Option value="option2">Option 2</Option>
                                <Option value="option3">Option 3</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Available Slots" name="availableSlots">
                            <Select placeholder="Select a visit type">
                                <Option value="option1">Option 1</Option>
                                <Option value="option2">Option 2</Option>
                                <Option value="option3">Option 3</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col span={6}>
                        <Form.Item label="Interval" name="interval">
                            <Space.Compact compact>
                                <Button onClick={handleDecrease} className="btnMinusAdjust" >-</Button>
                                <Input
                                    readOnly
                                    className="inputTimerAdjust"
                                    value={value}
                                    onChange={(e) => setValue(parseInt(e.target.value) || 5)}
                                />
                                <Button onClick={handleIncrease} className="btnPlusAdjust" >+</Button>
                            </Space.Compact>
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={[24, 24]}>
                    <Col span={6}>
                        <Form.Item name="checkbox" valuePropName="checked">
                            <Checkbox>Provider Double Booking</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Form.Item
                            name="intro"
                            label="Intro"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Intro',
                                },
                            ]}
                        >
                            <Input.TextArea showCount maxLength={100} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default AppointmentAdd;
