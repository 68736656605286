import axiosInterceptor from "../../middleware/axiosInterceptor";

export async function providerList() {
  const url = "/api/providers";
  try {
    const response = await axiosInterceptor.get(url);
    return response.data.provider;
  } catch (error) {
    console.error("Error in providerList service:", error);
    throw error;
  }
}

export async function getProviderInitialData() {
  const url = "/api/provider/initialdata"
  try {
    const response = await axiosInterceptor.get(url);
    return response;
  } catch (error) {
    console.error("Error in getPracticeConfigurations service:", error);
    throw error;
  }
}

export const saveProviderDetail = async (formData) => {
  const url = "/api/provider/add";
  try {
    const response = await axiosInterceptor.post(url, formData);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateProviderDetail = async (formData) => {
  const url = "/api/provider/update";
  try {
    const response = await axiosInterceptor.post(url, formData);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const saveProviderLocationConfig = async (formData) => {
  const url = "/api/provider/location/config";
  try {
    const response = await axiosInterceptor.post(url, formData);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteProviderDetail = async (deleteData) => {
  const url = "/api/provider/delete";
  try {
    const response = await axiosInterceptor.delete(url, { data: deleteData });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export async function getProviderDetail(providerid) {
  const url = "/api/provider/get/" + providerid;
  try {
    const response = await axiosInterceptor.get(url);
    return response;
  } catch (error) {
    console.error("Error in getPracticeConfigurations service:", error);
    throw error;
  }
};

export async function locationConfig(providerId, locationId) {
  const url = "/api/provider/location/config/" + parseInt(providerId) + "/" + parseInt(locationId);
  try {
    const response = await axiosInterceptor.get(url);
    return response;
  } catch (error) {
    console.error("Error in getPracticeConfigurations service:", error);
    throw error;
  }
};

export const deleteTiming = async (deleteData) => {
  const url = "/api/provider/timing/delete";
  try {
    const response = await axiosInterceptor.delete(url, { data: deleteData });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};