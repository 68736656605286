import axiosInterceptor from "../../middleware/axiosInterceptor";

const fetchSecurityQuestions = async () => {
    const url = "/api/security-questions";
    try {
        const response = await axiosInterceptor.get(url);
        return response;
    }
    catch (error) {
        console.error(error);
    }
};

export default fetchSecurityQuestions;


