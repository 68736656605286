import axiosInterceptor from "../../middleware/axiosInterceptor";

async function moduleConfiguration(mCode) {
    const url = "/api/modules/screens";
    const moduleReqData = {
        code: mCode.moduleCode,
        roleid: sessionStorage.getItem('roleId')
    }
    try {        
        const response = await axiosInterceptor.post(url, moduleReqData);        
        return response.data.screens;
    } catch (error) {
        console.error("Error in moduleConfig service:", error);
        throw error;
    }
}

export { moduleConfiguration }