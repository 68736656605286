//#region Packages
import React from 'react';
import { Row, Col, Card, } from 'antd';

//#endregion

//#region Local Imports
import "../../../assets/styles/changePassword.css";
import "../../../assets/styles/common.css";

import FoLogo from "../../../assets/images/fo-logo.png"
import PasswordForm from "./passwordForm";
//#region Local Imports

function changePassword() {
    return (
        <div className="background-container-color">
            <Row justify="center" align="middle" className="row-container">
                <Col className="form-col" xl={7} lg={14} md={16}>
                    <div className="guest-logo">
                        <a className="navbar-brand">
                            <img src={FoLogo} alt="Logo" className="logo" />
                        </a>
                    </div>
                    <div >
                        <Card className="change-password-card">
                            <PasswordForm />
                        </Card>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default changePassword;
