
import axiosInterceptor from "../../middleware/axiosInterceptor";



export const fetchPatientsBookAppointment = async () => {
    const url = `/api/patientName/dob/scheduleAppointment`;
    try {
        const response = await axiosInterceptor.get(url);
        return response.data;
    }
    catch (error) {
        throw error;
    }
};

export const fetchVisitTypeData = async (practiceId) => {
    const url = "/api/scheduleAppointment/visitType/" + practiceId;
    try {
        const response = await axiosInterceptor.get(url, practiceId);
        return response.data.initialData;
    }
    catch (error) {
        throw error;
    }
};

export const fetchPatientInitialData = async (patientId) => {
    const url = "/api/patientInitialData/bookAppointment?patientId=" + patientId;
    try {
        const response = await axiosInterceptor.get(url, patientId);
        return response.data;
    }
    catch (error) {
        throw error;
    }
};
export const fetchProviderInitialData = async (practiceLocationId) => {
    const url = "/api/provider/scheduleAppointment?practiceLocationId=" + practiceLocationId;
    try {
        const response = await axiosInterceptor.get(url, practiceLocationId);
        return response.data;
    }
    catch (error) {
        console.error('Error fetching patients book appointment:', error);
        throw error;
    }
};
export const fetchReasonForVisitDropdownData = async (practiceId) => {
    const url = "/api/reasonForVisit/scheduleAppointment?practiceId=" + practiceId;
    try {
        const response = await axiosInterceptor.get(url, practiceId);
        return response.data.scheduleApptInitialData;
    }
    catch (error) {
        console.error('Error fetching patients book appointment:', error);
        throw error;
    }
};
export const fetchVisitTypeDropdownData = async (providerId) => {
    const url = "/api/visitType/bookAppointment?providerId=" + providerId;
    try {
        const response = await axiosInterceptor.get(url, providerId);
        return response.data.scheduleApptInitialData;
    }
    catch (error) {
        console.error('Error fetching patients book appointment:', error);
        throw error;
    }
};


export const ScheduleAppointmentSave = async (appointmentData) => {
    const url = "/api/scheduleAppointmentSave";
    try {
        const response = await axiosInterceptor.post(url,appointmentData);
        return response.data; 
    } catch (error) {
        throw error;
    }
  };