import React, { useState, useEffect } from "react";
import { Form, Input, Select, Button, Switch, Radio, Modal, Divider } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import { CreateUserValidation } from "../../components/users/handlers/createUserValidation";
import PasswordForm from './passwordForm';
import "../../../assets/styles/updateUser.css";
import "../../../assets/styles/common.css";
import { formatPhoneNumber } from "../utilities/utility";
import { handleUpdateUser, handleCancel, handleCloseDrawerCancel } from "./handlers/updateUserHandle";
import {
    handleRoleChange, fetchPopulateData, handleResetPassword,
    handleResetPasswordCancel, handlePasswordOptionChange
} from "./handlers/fetchPopulateHandler";

import { fetchUserProfile } from "../../services/showProfileService";
import { handleNumKeyDown } from "../utilities/utility";

const labelWrapperProps = {
    labelAlign: 'left',
    labelCol: { span: 9 },
    wrapperCol: { span: 15 },
};


const { Option } = Select;

function UpdateUser(props) {

    const { userUpdateData, setUserUpdateData, keyType, userId, setDrawerVisible } = props;

    const [timeZones, setTimeZone] = useState([]);
    // const [drawerVisible, setDrawerVisible] = useState(false);
    const [practiceLocations, setPracticeLocations] = useState('');
    const [roles, setRoles] = useState([]);
    const [selectedTimezone, setSelectedTimezone] = useState('');
    const [practicelocation, setPracticeLocationOptions] = useState('');
    const [selectedpracticeLocation, setSelectedPracticeLocation] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [showAdditionalFields, setShowAdditionalFields] = useState(false);// Define setShowAdditionalFields with useState
    const [phone, setPhoneNumber] = useState('');
    const [form] = Form.useForm();
    const [resetPasswordModalVisible, setResetPasswordModalVisible] = useState(false);
    const [securitypin, setSecurityPin] = useState(''); // Define setSecurityPin as a state setter function with initial value ''
    const [username, setUsername] = useState(false);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [visible, setVisible] = useState(false); // State to manage modal visibility
    const [passwordOption, setPasswordOption] = useState(null);
    const [profileData, setProfileData] = useState('');
    const [userData, setUserData] = useState([]);
    const [userStatus, setUserStatus] = useState(null);

    const [submitted, setSubmitted] = useState(false);
    const [additionalFields, setAdditionalFields] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [enteredPassword, setManualPassword] = useState('');

    const RESET_PASS_AUTO = "auto";
    const RESET_PASS_MANUAL = "manual";



    const fetchInitialData = async () => {
        try {
            const populateResponse = await fetchPopulateData(setTimeZone, setPracticeLocations, setRoles);
            const userProfileResponse = await fetchUserProfile(userId);
            const user = userProfileResponse.data.users[0];
            const formattedPhone = formatPhoneNumber(user.phone);

            user.timezone = populateResponse.data.timeZones.some(timezone => timezone.timezonename === user.timezone) ? user.timezone : "";
            form.setFieldsValue({
                username: user.username || '',
                firstname: user.firstname || '',
                lastname: user.lastname || '',
                email: user.email || '',
                timezone: user.timezone || '',
                phone: formattedPhone || '',
                role: user.role ? [user.role.roleid] : [],
                securitypin: user.role && user.role.datasecuritytypes ?
                    user.role.datasecuritytypes.find(type => type.code === 'SECPIN')?.userdata[0]?.value || '' : '',
                location: user.role && user.role.datasecuritytypes ?
                    user.role.datasecuritytypes
                        .filter(type => type.type === 'dropdown-multiselect')
                        .flatMap(type => type.userdata.map(data => Number(data.value))) : [],
                provider: user.role && user.role.datasecuritytypes ?
                    user.role.datasecuritytypes
                        .filter(type => type.type === 'dropdown-multiselect')
                        .flatMap(type => type.userdata.map(data => Number(data.value))) : [],
                status: user.status,
            });

            handleRoleChange(user.role.roleid, setShowAdditionalFields, setSelectedRole, populateResponse.data.roles, setAdditionalFields);

            setUserData(user);
            setPhoneNumber(formattedPhone);
            if (user.status === "PENDING") {
                setUserStatus(null); // Pending
            } else if (user.status === "ACTIVE") {
                setUserStatus(true); // Active
            } else {
                setUserStatus(false); // Inactive
            }

            return populateResponse.data.success;
        } catch (error) {
            console.error('Error fetching initial data:', error);
            return false;
        }
    };

    useEffect(() => {
        let success = fetchInitialData();
    }, []);

    const onFinish = async (values) => {
        try {
            setSubmitted(true);
            if (userStatus === null) {
                values.status = "PENDING";
            } else if (userStatus === true) {
                values.status = "ACTIVE";
            } else {
                values.status = "INACTIVE";
            }
            await handleUpdateUser(
                userData, profileData, userId, values.username, values.firstname, values.lastname,
                values.email, values.phone, values.status, values.securitypin, keyType, props,
                values, additionalFields, practicelocation, values.role, selectedpracticeLocation,
                values.timezone, setDrawerVisible, userUpdateData, setUserUpdateData
            );

            // if (keyType === "profile") {
            //     await handleUpdateUser(
            //         userData, profileData, userId, values.username, values.firstname, values.lastname,
            //         values.email, values.phone, values.status, values.securitypin, keyType, props,
            //         values, additionalFields, practicelocation, values.role, selectedpracticeLocation,
            //         values.timezone, setDrawerVisible, userUpdateData, setUserUpdateData
            //     );
            // }
            // else {
            //     await handleUpdateUser(
            //         userData, profileData, userId, values.username, values.firstname, values.lastname,
            //         values.email, values.phone, values.status, values.securitypin, keyType, props,
            //         values, additionalFields, practicelocation, values.role, selectedpracticeLocation,
            //         values.timezone, setDrawerVisible, userUpdateData, setUserUpdateData
            //     );
            // }
            setFirstname(values.firstname);
            setLastname(values.lastname);
            setEmail(values.email);
            setSelectedTimezone(values.timezone);

        } catch (error) {
            console.error("Error updating user data:", error);
        }
    };


    const handleUpdateButtonClick = () => {
        // Manually trigger form validation if the form has not been submitted
        if (!submitted) {
            form.validateFields().then(() => {
                // Form fields are valid, proceed with update
                onFinish(form.getFieldsValue());
                // Reset the submitted state
                setSubmitted(false);
            }).catch(errorInfo => {
                console.error('Validation failed:', errorInfo);
            });
        }
    };

    const handlePasswordChange = (e) => {
        setManualPassword(e.target.value);
    };
    const handleChangeSecurityPin = () => {
        setShowAdditionalFields((prevState) => !prevState);
    };

    return (
        <div className="background-container-color">
            <div className="site-card-border-less-wrapper">
                <div className="container-height">
                    {/* <Form colon={false}  layout="horizontal" className="form-container" form={form} requiredMark={false} > */}
                    <Form form={form} layout="horizontal" requiredMark={false} colon={false} onFinish={onFinish}>
                        {keyType === "user" && (
                            <Form.Item
                                label="Username"
                                name="username"
                                {...labelWrapperProps}
                            >
                                <Input className="big-input" id="username" value={username} disabled />
                            </Form.Item>
                        )}
                        {(keyType === "profile" || keyType === "user") && (
                            <>
                                <Form.Item
                                    label="First Name"
                                    name="firstname"
                                    {...labelWrapperProps}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter the first name"
                                        }
                                    ]}
                                >
                                    <Input id="firstname" className="big-input" value={firstname} />
                                </Form.Item>

                                <Form.Item
                                    label="Last Name"
                                    name="lastname"
                                    {...labelWrapperProps}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter the last name"
                                        }
                                    ]}

                                >
                                    <Input id="lastname" className="big-input" value={lastname} />
                                </Form.Item>

                                <Form.Item
                                    name="email"
                                    label="Email"
                                    {...labelWrapperProps}
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'The input is not valid E-mail',
                                        },
                                        {
                                            required: true,
                                            message: 'Please enter your E-mail Address',
                                        },
                                    ]}
                                >
                                    <Input className="inputtxt big-input" id="email" value={email} />
                                </Form.Item>

                                <Form.Item
                                    name="timezone"
                                    label="Time Zone"
                                    {...labelWrapperProps}
                                >
                                    <Select
                                        placeholder="Select a time zone"
                                        id="timezone"
                                        labelAlign="left"
                                        className="big-input"
                                        value={selectedTimezone}
                                    >
                                        {timeZones && timeZones.map(q => (
                                            <Option key={q.timezoneid} value={q.timezonename}>
                                                {q.timezonedisplay}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="phone"
                                    label="Phone"
                                    {...labelWrapperProps}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your phone number',
                                        },
                                    ]}
                                    className="form-item-margin"
                                >
                                    <Input
                                        type="tel"
                                        maxLength={14}
                                        id="phonenumber"
                                        placeholder='(999) 999-9999'
                                        value={phone}
                                        labelAlign="left"
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                        className="big-input"
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const formattedValue = formatPhoneNumber(value); // Assuming formatPhoneNumber is a function that formats the phone number
                                            setPhoneNumber(formattedValue); // Update the state with the formatted value
                                            form.setFieldsValue({ phone: formattedValue }); // Set the form field value to the formatted value
                                        }}
                                    />
                                </Form.Item>

                                <>
                                    {keyType === "profile" && (
                                        <div>

                                            {additionalFields && additionalFields.length > 0 && (
                                                <a
                                                    href="#"
                                                    className="button"
                                                    onClick={handleChangeSecurityPin}
                                                >
                                                    Change Security Pin
                                                </a>
                                            )}



                                            {showAdditionalFields &&
                                                additionalFields &&
                                                additionalFields.some((field) => field.name === 'securitypin') && (
                                                    additionalFields
                                                        .filter((field) => field.name !== 'location') // Exclude the "location" field
                                                        .map((item) => {
                                                            let inputType = item.type === 'numeric' ? 'number' : 'text';
                                                            let maxLength;

                                                            if (item.config) {
                                                                try {
                                                                    const trimmedConfig = item.config.trim();
                                                                    const config = JSON.parse(trimmedConfig);

                                                                    if (config.type === 'numeric') {
                                                                        inputType = "number";
                                                                        maxLength = parseInt(config.length);
                                                                    }
                                                                } catch (error) {
                                                                    console.error('Error parsing config JSON:', error);
                                                                }
                                                            }

                                                            return (
                                                                <div key={item.id}>
                                                                    <Form.Item
                                                                        name={`${item.name}-new`}
                                                                        label={item.name === 'securitypin' ? "New Security Pin" : null}
                                                                        {...labelWrapperProps}
                                                                        rules={[
                                                                            { required: item.required === 1, message: `New ${item.name} is required` },
                                                                            // Add more rules as needed
                                                                        ]}
                                                                    >
                                                                        {item.type === 'text' && (
                                                                            <Input
                                                                                id={`${item.name}-new`}
                                                                                name={`${item.name}-new`}
                                                                                onKeyDown={handleNumKeyDown}
                                                                                maxLength={maxLength}
                                                                            />
                                                                        )}
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        name={`${item.name}-confirm`}
                                                                        label={item.name === 'securitypin' ? "Confirm Security Pin" : null}
                                                                        {...labelWrapperProps}
                                                                        dependencies={[`${item.name}-new`]}
                                                                        rules={[
                                                                            { required: item.required === 1, message: `Confirm ${item.name} is required` },
                                                                            ({ getFieldValue }) => ({
                                                                                validator(_, value) {
                                                                                    if (!value || getFieldValue(`${item.name}-new`) === value) {
                                                                                        return Promise.resolve();
                                                                                    }
                                                                                    return Promise.reject(new Error('The two security pins do not match'));
                                                                                },
                                                                            }),
                                                                        ]}
                                                                    >
                                                                        {item.type === 'text' && (
                                                                            <Input
                                                                                id={`${item.name}-confirm`}
                                                                                name={`${item.name}-confirm`}
                                                                                onKeyDown={handleNumKeyDown}
                                                                                maxLength={maxLength}
                                                                            />
                                                                        )}
                                                                    </Form.Item>
                                                                </div>
                                                            );
                                                        })
                                                )}


                                        </div>
                                    )}
                                </>




                            </>
                        )}


                        {keyType === "user" && (
                            <div>
                                <Form.Item
                                    name="role"
                                    label="Role"
                                    {...labelWrapperProps}
                                >
                                    <Select
                                        placeholder="Select a role"
                                        labelInValue // Ensure that value is an object with label and key properties
                                        id="role"
                                        className="big-input"
                                        onChange={(value) => handleRoleChange(value, setShowAdditionalFields, setSelectedRole, roles, setAdditionalFields)}
                                        value={selectedRole}
                                    >
                                        {roles && roles.map(q => (
                                            <Option key={q.id} value={q.id}>
                                                {q.rolename}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                {additionalFields && additionalFields.map((item) => {
                                    let inputType = item.type === 'numeric' ? 'number' : 'text';
                                    let maxLength = undefined; // Default max length is undefined
                                    // const initialSelectedValues = ["1", "10"];
                                    if (item.config !== null && item.config !== undefined && item.config !== "") {
                                        try {
                                            const trimmedConfig = item.config.trim(); // Trim whitespace from config string             
                                            const config = JSON.parse(trimmedConfig); // Parse the trimmed config JSON string              

                                            if (config.type === 'numeric') {
                                                inputType = "number";
                                                maxLength = parseInt(config.length);
                                                // if (this.value.length > maxLength) {
                                                //     this.value = this.value.slice(0, maxLength); // Limit the input to maxLength
                                                // }
                                            }
                                        } catch (error) {
                                            console.error('Error parsing config JSON:', error);
                                        }
                                    }
                                    const handleSearch = (inputValue) => {
                                        const filteredOptions = item.value.filter((option) =>
                                            option.name.toLowerCase().includes(inputValue.toLowerCase())
                                        );
                                        setFilteredOptions(filteredOptions);
                                    };



                                    return (

                                        <div key={item.name}>
                                            <Form.Item
                                                name={item.name}
                                                label={item.name}
                                                rules={[
                                                    { required: item.required === 1, message: `${item.name} is required` },
                                                    ...CreateUserValidation.practicelocation
                                                ]}
                                                {...labelWrapperProps}
                                            >
                                                {item.type === 'dropdown-multiselect' ? (
                                                    <Select
                                                        placeholder="Select a practice location"
                                                        id={item.id}
                                                        name={item.name}
                                                        data-security-type-id={item.id}
                                                        mode={item.type === 'dropdown-multiselect' ? 'multiple' : undefined}
                                                        showSearch={item.type === 'dropdown-multiselect'}
                                                        onSearch={(inputValue) => handleSearch(inputValue)}

                                                        filterOption={(inputValue, option) =>
                                                            option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0

                                                        } // Filter options based on search input

                                                    >
                                                        {item.value.map((q) => (
                                                            <Option key={q.id} value={q.id} name={q.name}>
                                                                {q.name}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                ) : <></>
                                                }
                                                {item.type === 'text' ? (
                                                    <Input
                                                        id={item.name}
                                                        name={item.name}
                                                        onKeyDown={handleNumKeyDown}
                                                        maxLength={maxLength} // Set max length dynamically
                                                    />
                                                ) : <></>}
                                            </Form.Item>
                                        </div>
                                    );
                                })}


                                {/* Status field */}
                                <Form.Item label="Status" name="status" {...labelWrapperProps}>
                                    <Switch
                                        disabled={userStatus === null} // Disable if status is "PENDING"
                                        checked={userStatus === true} // Check if status is "ACTIVE"
                                        checkedChildren="Active" // Show "Active" if checked
                                        unCheckedChildren={userStatus === null ? "Pending" : "Inactive"} // Show "Pending" if status is "PENDING", otherwise "Inactive"
                                        onChange={(checked) => {
                                            setUserStatus(checked ? true : false); // Update status based on switch state
                                        }}
                                        id="status"
                                        className="status-switch"
                                    />
                                </Form.Item>
                            </div>
                        )}
                    </Form>
                </div>
                {/* <Button
                type="primary"
                htmlType="submit"
                id="updateuser"
                onClick={handleUpdateUser}>Update User
            </Button> */}


                <div>
                    {/* Rest of your code */}
                    {keyType === "profile" && (
                        <div className="rightAlignButtons">
                            <div>
                                <Form.Item>
                                    <Button onClick={() => handleCloseDrawerCancel(keyType, setUsername,
                                        setFirstname, setLastname, setEmail, setSelectedTimezone,
                                        setPhoneNumber, setSelectedRole, setSelectedPracticeLocation,
                                        setSecurityPin, profileData, userData, setDrawerVisible, form)}
                                        icon={<CloseOutlined />} >Cancel</Button>
                                </Form.Item>
                            </div>
                            <div>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" id="updateuser" onClick={() => handleUpdateButtonClick()}>Update User</Button>
                                </Form.Item>
                            </div>
                        </div>
                    )}
                    {keyType === "user" && (
                        <div className="button-container-user">
                            <Button type="primary" htmlType="button" id="resetpassword" onClick={() => setResetPasswordModalVisible(true)}>Reset Password</Button>
                            <Button type="primary" htmlType="submit" id="updateuser" onClick={() => handleUpdateButtonClick()}>Update User</Button>
                        </div>
                    )}
                </div>

                {/* Change password modal */}
                <Modal
                    id="profilemodal"
                    open={visible}
                    onCancel={() => handleCancel(setVisible)}
                    footer={null}
                    width={350}
                    className="custom-modal"
                >
                    <div className="modal-content">
                        <PasswordForm />
                    </div>
                </Modal>

                {/* Reset password modal */}
                <Modal
                    title="Reset Password"
                    open={resetPasswordModalVisible}
                    onCancel={() => setResetPasswordModalVisible(false)}
                    width={400}
                    footer={[
                        <div className='Buttons'>
                            <div className="btncancel">
                                <Button key="reset" type="primary" id="reset"
                                    onClick={() => handleResetPassword(props.userId, passwordOption, enteredPassword, setResetPasswordModalVisible)}>Reset</Button>
                            </div>
                            <div className="btnreset">
                                <Button key="cancel" id="cancel" onClick={() => handleResetPasswordCancel(setResetPasswordModalVisible)}>Cancel</Button>
                            </div>
                        </div>
                    ]}

                >
                    <Divider />

                    <Radio.Group onChange={(e) => handlePasswordOptionChange(e, setPasswordOption)} value={passwordOption}>
                        <div className="password">
                            <Radio value={RESET_PASS_AUTO}>
                                <h4>Automatically generate a password</h4>
                            </Radio>
                        </div>
                        <div className="password">
                            <Radio value={RESET_PASS_MANUAL}> <h4>Enter Password</h4></Radio>
                        </div>
                    </Radio.Group>
                    {passwordOption === RESET_PASS_MANUAL && (
                        <div class="txtresetpassword">
                            <Input.Password id="password" placeholder="Enter password" value={enteredPassword} onChange={handlePasswordChange} />
                        </div>
                    )}

                </Modal>
            </div>
        </div>
    );
}

export default UpdateUser;
