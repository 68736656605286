import React, { useState } from 'react';
import { Form, Input, Button, Table, DatePicker, Col, Row, Radio, Space, Tooltip } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import "../../assets/styles/common.css";

const Others = () => {
  const [data, setData] = useState([1]);
  const [form] = Form.useForm();

  const handleSubmit = () => {
    // Add row with specified columns A to H
    setData(prevData => [...prevData, {}]);

    // Add row with single textbox for columns A to H
    // setData(prevData => [...prevData, {
    //   fromMM: '',
    //   fromDD: '',
    //   fromYY: '',
    //   toMM: '',
    //   toDD: '',
    //   toYY: '',
    //   placeOfService: '',
    //   emg: '',
    //   cptHcpcs: '',
    //   modifier: '',
    //   diagnosisPointer: '',
    //   charges: '',
    //   daysOrUnits: '',
    //   epsdtFamilyPlan: '',
    // }]);
  };
  const columns = [
    //{
    //   title: (
    //     <div>
    //       <Button
    //         icon={<PlusOutlined />}
    //         onClick={handleSubmit}
    //         type="primary"
    //         style={{ marginLeft: 8 }}
    //       >
    //         Add
    //       </Button>
    //     </div>
    //   ),
    //   width: 100,
    // },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space>
          <Tooltip title="Delete">
            <Button type="primary" className="gridIconDelete" danger icon={<DeleteOutlined />} />
          </Tooltip>
        </Space>
      ),
      width: 100,
    },
    {
      title: 'Date(S) Of Service',
      width: "400px",
      children: [
        {
          title: 'From',
          dataIndex: 'from',
          key: 'from',
          width: "800px",
          render: (text, record, index) => (
            <DatePicker
              className="tblDatepicker"
              // style={{ width: '150px' }} 
              placeholder="From Date"
              value={text}
            />
          ),
        },
        {
          title: 'To',
          dataIndex: 'todate',
          key: 'todate',
          render: (text, record, index) => (
            <DatePicker
              className="tblDatepicker"
              style={{ width: '150px' }}
              placeholder="To Date"
            />
          ),
        },
      ],
    },
    {
      title: 'Place Of Service',
      dataIndex: 'placeOfService',
      key: 'placeOfService',
      render: (text, record, index) => (
        <Input
          style={{ width: "150px" }}
          placeholder="Place of Service"
          value={text}
          onChange={(e) => handleChange(e, index, 'placeOfService')}
        />
      ),
    },
    {
      title: 'EMG',
      dataIndex: 'emg',
      key: 'emg',
      render: (text, record, index) => (
        <Input
          placeholder="EMG"
          style={{ width: "100px" }}
          value={text}
          onChange={(e) => handleChange(e, index, 'emg')}
        />
      ),
    },
    {
      title: 'CPT/HCPCS',
      dataIndex: 'cptHcpcs',
      key: 'cptHcpcs',
      render: (text, record, index) => (
        <Input
          placeholder="CPT/HCPCS"
          value={text}
          onChange={(e) => handleChange(e, index, 'cptHcpcs')}
        />
      ),
    },
    {
      title: 'Modifier',
      dataIndex: 'modifier',
      key: 'modifier',
      render: (text, record, index) => (
        <Input
          placeholder="Modifier"
          value={text}
          onChange={(e) => handleChange(e, index, 'modifier')}
        />
      ),
    },
    {
      title: 'Diagnosis Pointer',
      dataIndex: 'diagnosisPointer',
      key: 'diagnosisPointer',
      render: (text, record, index) => (
        <Input
          placeholder="Diagnosis Pointer"
          value={text}
          onChange={(e) => handleChange(e, index, 'diagnosisPointer')}
        />
      ),
    },
    {
      title: '$ Charges',
      dataIndex: 'charges',
      key: 'charges',
      render: (text, record, index) => (
        <Input
          placeholder="Charges"
          value={text}
          onChange={(e) => handleChange(e, index, 'charges')}
        />
      ),
    },
    {
      title: 'Days Or Units',
      dataIndex: 'daysOrUnits',
      key: 'daysOrUnits',
      render: (text, record, index) => (
        <Input
          placeholder="Days or Units"
          value={text}
          onChange={(e) => handleChange(e, index, 'daysOrUnits')}
        />
      ),
    },
    {
      title: 'EPSDT Family Plan',
      dataIndex: 'epsdtFamilyPlan',
      key: 'epsdtFamilyPlan',
      render: (text, record, index) => (
        <Input
          placeholder="EPSDT Family Plan"
          value={text}
          onChange={(e) => handleChange(e, index, 'epsdtFamilyPlan')}
        />
      ),
    },
    {
      title: 'ID. Qual',
      dataIndex: 'idQual',
      key: 'idQual',
      render: (text, record, index) => (
        <Input
          placeholder="ID Qual"
          value={text}
          onChange={(e) => handleChange(e, index, 'idQual')}
        />
      ),
    },
    {
      title: 'Rendering Provider ID.#',
      dataIndex: 'providerId',
      key: 'providerId',
      render: (text, record, index) => (
        <Input
          placeholder="Provider ID"
          value={text}
          onChange={(e) => handleChange(e, index, 'providerId')}
        />
      ),
    },
  ];

  const handleChange = (e, index, field) => {
    const newData = [...data];
    newData[index] = {
      ...newData[index],
      [field]: e.target.value,
    };
    setData(newData);
  };




  return (
    <div>
      <Form form={form} layout="vertical" style={{ padding: '5px' }}>
        <div className='commonborder'>

          <h3>Physician Or Supplier Information : </h3>
          <Row gutter={[16, 16]}>

            {/* <Col xs={24} md={8}>
              <Form.Item label="24.A. DATE OF SERVICES">
                <Row gutter={8}>
                  <Col xs={12}>
                    <Form.Item label="From Date" name="fromDate">
                      <DatePicker format="MM/DD/YY" style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item label="To Date" name="toDate">
                      <DatePicker format="MM/DD/YY" style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Row gutter={8}>
                <Col xs={24} sm={12}>
                  <Form.Item label="B. PLACE OF SERVICE" name="placeOfService">
                    <Input style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="C. EMG" name="emg">
                    <Input style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item label="D. PROCEDURES, SERVICES, OR SUPPLIES (Explain Unusual circumstances)">
                <Row gutter={8}>
                  <Col xs={8}>
                    <Form.Item label="CPT/HCPCS" name="cptHcpcs">
                      <Input style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col xs={16}>
                    <Form.Item label="MODIFIER" name="modifier">
                      <Row gutter={8}>
                        <Col span={6}><Input style={{ width: '100%' }} /></Col>
                        <Col span={6}><Input style={{ width: '100%' }} /></Col>
                        <Col span={6}><Input style={{ width: '100%' }} /></Col>
                        <Col span={6}><Input style={{ width: '100%' }} /></Col>
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item label="E. DIAGNOSIS POINTER" name="diagnosisPointer">
                <Input style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item label="F. $CHARGES" name="charges">
                <Input style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item label="G. DAYS OR UNITS" name="daysOrUnits">
                <Input style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item label="H. EPSDT FAMILY PLAN" name="epsdtFamilyPlan">
                <Input style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item label="I. ID. QUAL" name="idQual">
                <Input style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item label="J. RENDERING PROVIDER ID.#" name="providerId">
                <Input style={{ width: '100%' }} />
              </Form.Item>
            </Col> */}
            <div style={{ overflowX: 'auto' }}>
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
                title={() => (
                  <div className="tabledesign">
                    <Button
                      id="createbutton"
                      type="primary"
                      onClick={handleSubmit}
                      icon={<PlusOutlined />}
                    >
                      Add New Service
                    </Button>
                  </div>
                )}
              />
            </div>



            {/* <div style={{ textAlign: "right", marginBottom: 16 }}>
              <Button
                icon={<PlusOutlined />}
                onClick={handleSubmit}
                type="primary"
              >
                Add New Service
              </Button>
            </div>
            <div className="tabledesign">
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
              />
            </div> */}

          </Row>

          <Row gutter={16} style={{ marginTop: 20 }}>
            <Col xs={24} md={12}>
              <Form.Item label="Federal Tax ID Number" name="federalta">
                <Row gutter={16}>
                  <Col span={12} xs={12} md={12}>
                    <Input style={{ width: '100%' }} />
                  </Col>
                  <Col span={12} xs={12} md={12}>
                    <Radio.Group>
                      <Radio value="SSN">SSN</Radio>
                      <Radio value="EIN">EIN</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item label="Patient's Account No" name="accountno">
                <Input style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="Accept Assignment? (For Govt Claims, See Back)" name="ASSIGNMENT">
                <Radio.Group>
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item label="Total Charge" name="CHARGE">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="Amount Paid" name="PAID">
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item label="Rsvd. for NUCC Use" name="NUCC">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} md={24}>
              <Form.Item label="SIGNATURE OF PHYSICIAN OR SUPPLIER INCLUDING DEGREES OR 
              CREDENTIALS (I certify that the statement on the reverse apply to this bill and are made a
                part thereof)">
                <Row gutter={8}>
                  <Col xs={14} md={16}>
                    <Form.Item name="physicianSignature" noStyle>
                      <Input placeholder="Signature" style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col xs={10} md={8}>
                    <Form.Item name="signatureDate" noStyle>
                      <DatePicker placeholder="Date" style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>

            <Col xs={24} md={24}>
              <Form.Item label="Service Facility Location Information">
                <Row gutter={8}>
                  <Col xs={14} md={16}>
                    <Form.Item >
                      <Input placeholder='a'/>
                    </Form.Item>
                  </Col>
                  <Col xs={10} md={8}>
                    <Form.Item>
                      <Input  placeholder='b'/>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} md={24}>
              <Form.Item label="Billing Provider Info & Ph#">

                <Row gutter={8}>
                  <Col xs={14} md={16}>
                    <Form.Item>
                      <Input  placeholder='a'/>
                    </Form.Item>
                  </Col>
                  <Col xs={10} md={8}>
                    <Form.Item>
                      <Input  placeholder='b'/>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>

          </Row>
        </div>
      </Form >

    </div >
  );
};

export default Others;
