import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Layout, Radio, Form, Input, Button, Select, DatePicker, Checkbox, Modal, Row, Col, Spin, Divider } from "antd";
import moment from 'moment';
import { savePersonalInformation } from "../../services/ipadRegistartionService";
import InputMask from "react-input-mask";
import { fetchIpadRegistrationData } from '../../services/ipadRegistrationService';

const { Option } = Select;

const PersonalInformation = (props) => {
    const [personalForm] = Form.useForm();
    const [size, setSize] = useState('small');
    const [dropdownValues, setDropdownValues] = useState([]);
    const { patient } = props;
    const [dataLoaded, setDataLoaded] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isNoSSN, setIsNoSSN] = useState(false);
    const [isLanguageTranslate, setIsLanguageTranslate] = useState(false);
    const [ssnValue, setSsnValue] = useState('');
    const [formControls, setFormControls] = useState([]);
    const [advanceDirective, setAdvanceDirective] = useState('No');
    const [ipadRegisterControl, setIpadRegisterControl] = useState(true);

    const [data, setData] = useState({
        title: [],
        gender: [],
        maritalStatus: [],
        ethnicity: [],
        race: []
    });
    const currentPageURL = window.location.href;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    useEffect(() => {
        const fetchIpadRegistrationHandler = async () => {
            try {
                const response = await fetchIpadRegistrationData();
                setData({
                    title: response[0].title || [],
                    gender: response[0].gender || [],
                    maritalStatus: response[0].Status || [],
                    ethnicity: response[0].ethnicity || [],
                    race: response[0].race || []
                });
            } catch (error) {
                console.error("Error fetching Registration Data:", error.message);
            }
        };

        fetchIpadRegistrationHandler();
    }, []);
    // useEffect(() => {
    //     if (props.patientRegistrationInfo?.patientRegistrationFormData) {
    //         let info = props.patientRegistrationInfo.patientRegistrationFormData?.PersonalInformation ?? [];
    //         if (info.hasOwnProperty('doNotHaveSSN')) {
    //             setIsNoSSN(info.doNotHaveSSN);
    //         }
    //         if (info.hasOwnProperty('LanguageTranslate')) {
    //             setIsLanguageTranslate(info.LanguageTranslate);
    //         }
    //         if (info.hasOwnProperty('AdvanceDirectives')) {
    //             setAdvanceDirective(info.AdvanceDirectives);
    //         }
    //         setDropdownValues(props.dropdownList);
    //         setDataLoaded(true);
    //         setSsnValue(props);
    //     }
    // }, [props.patientRegistrationInfo, props.dropdownList]);

    // useEffect(() => {
    //     if (!(props.formControls.length === 0)) {
    //         const formControlsForActivePage = props.formControls[props.activeIpadPage];
    //         const formFields = formControlsForActivePage.map(control => control.form_field);
    //         setFormControls(formFields);
    //         setDataLoaded(true);
    //         if (currentPageURL.includes('pre-registration')) {
    //             setIpadRegisterControl(false);
    //         } else if (currentPageURL.includes('ipad-registration')) {
    //             setIpadRegisterControl(true);
    //         }
    //     }
    // }, [props.formControls]);

    function formatSSN(PatientSSN) {
        const numericSSN = PatientSSN.replace(/\D/g, '');
        return numericSSN.replace(/(\d{3})(\d{2})(\d{4})/, '$1 $2 $3');
    }

    const onPersonalInfoNextClick = async (values) => {
        try {
            setIsSubmitting(true);
            const formattedDateOfBirth = moment(values.DateOfBirth).format('YYYY-MM-DD');
            const updatedValues = { ...values, DateOfBirth: formattedDateOfBirth };
            if (currentPageURL.includes('ipad-registration')) {
                updatedValues.PatientSSN = formatSSN(updatedValues.PatientSSN);
                // updatedValues.doNotHaveSSN = isNoSSN ? isNoSSN : NoSSN;
                updatedValues.LanguageTranslate = isLanguageTranslate ? isLanguageTranslate : false;
            }
            let personalInfoResponse = await savePersonalInformation(props.uniqueHash, updatedValues);
            if (personalInfoResponse.message === 'Success') {
                props.handleNextClick(props);
            } else {
                Modal.error({
                    title: 'This is an error message',
                    content: personalInfoResponse.errors.toString(),
                });
            }
        } finally {
            if (!isSubmitting) {
                return (
                    <div className="spinner-overlay">
                        <div className="spinner">
                            <Spin size="large" tip="Loading..." />
                        </div>
                    </div>
                );
            }
            setIsSubmitting(false);
        }
    }

    function parseDate(dateString) {
        if (dateString) {
            const parsedDate = moment(dateString, 'YYYY-MM-DD');
            return parsedDate.isValid() ? parsedDate : null;
        }
        return null;
    }

    const handleSsnChange = (e) => {
        setSsnValue(e.target.value);
    };

    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setSsnValue('');
        }
        setIsNoSSN(e.target.checked); // Update the isNoSSN state
    };

    const validatePatientSSN = (rule, value) => {
        if (!isNoSSN) {
            if (value && value.replace(/\D/g, '').length !== 9) {
                return Promise.reject('The SSN field must be 9 digits.');
            }
        }
        return Promise.resolve();
    };

    // const patientInfo = props.patientRegistrationInfo.patientRegistrationFormData?.PersonalInformation ?? [];
    // const patientTableInfo = props.patientRegistrationInfo.patientRegistrationInfo;

    // let SSN = patientInfo?.PatientSSN || "";
    // let NoSSN = patientInfo?.doNotHaveSSN || "";

    // let patientDetails = {
    //     Title: patientInfo?.Title || "",
    //     PatientFirstName: patientTableInfo?.first_name || "",
    //     PatientMiddleName: patientTableInfo?.middle_name || "",
    //     PatientLastName: patientTableInfo?.last_name || "",
    //     DateOfBirth: parseDate(patientTableInfo?.date_of_birth) || "",
    //     PatientEmail: patientTableInfo?.email || "",
    //     PatientGender: patientTableInfo?.gender || "",
    //     PatientMaritalStatus: patientInfo?.PatientMaritalStatus || "",
    //     PatientSSN: SSN,
    //     PatientEthnicity: patientInfo?.PatientEthnicity || "",
    //     PatientRace: patientInfo?.PatientRace || "",
    //     RequestedServices: patientInfo?.RequestedServices || "",
    //     AdvanceDirectives: patientInfo?.AdvanceDirectives || "",
    //     AdvanceDirectiveSpecify: patientInfo?.AdvanceDirectiveSpecify || "",
    //     AdditionalInformationMigrant: patientInfo?.AdditionalInformationMigrant || "",
    //     HousingStatus: patientInfo?.HousingStatus || "",
    //     AdditionalInformationHomeLessSpecify: patientInfo?.AdditionalInformationHomeLessSpecify || "",
    // };

    const onPersonalInfoPreviousClick = () => {
        props.handlePreviousClick(props);
    }
    const handleAdvanceDirectiveRadioChange = (e) => {
        const newValue = e.target.value;
        setAdvanceDirective((prevValue) => (prevValue === newValue ? null : newValue));
    };

    // if (!dataLoaded) {
    //     return (
    //         <div className="spinner-overlay">
    //             <div className="spinner">
    //                 <Spin size="large" tip="Loading..."/>
    //             </div>
    //         </div>
    //     );
    // }

    // const selectedPreferredLanguages = patientInfo?.PatientPreferredLanguage || [];
    return (
        <div>
            <h2>Personal Information</h2>
            <Form form={personalForm} layout="vertical" scrollToFirstError={true} size="large"
            // onFinish={onPersonalInfoNextClick}
            // initialValues={{
            //     ...patientDetails,
            //     PatientPreferredLanguage: selectedPreferredLanguages,
            // }}
            >
                {ipadRegisterControl ? <Form.Item label="Title" name="Title"
                    rules={[{ required: true, message: 'The title field is required.' }]}>
                    {/* {dropdownValues.title && dropdownValues.title.length > 0 ?
                        <Select>
                            {dropdownValues.title.map((title, index) => (
                                <Option key={index} value={title}>{title}</Option>
                            ))}
                        </Select>
                        : <Select></Select>
                    } */}
                    <Select>
                        {data.title.map((item) => (
                            <Option key={item.dropdownoptionid} value={item.dropdownoptionid}>
                                {item.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item> : null}
                <Form.Item label="First Name" name="PatientFirstName"
                    rules={[{ required: true, message: 'The patient first name field is required.' },]}>
                    <Input placeholder="First Name" />
                </Form.Item>
                <Form.Item label="Middle Name" name="PatientMiddleName">
                    <Input placeholder="Middle Name" />
                </Form.Item>
                <Form.Item label="Last Name" name="PatientLastName"
                    rules={[{ required: true, message: 'The patient last name field is required.' },]}>
                    <Input placeholder="Last Name" />
                </Form.Item>
                <Form.Item label="Date of Birth" name="DateOfBirth"
                    rules={[{ required: true, message: 'The patient DOB field is required.' },]}>
                    <DatePicker format="MM/DD/YYYY" />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="PatientEmail"
                    rules={[
                        {
                            type: 'email',
                            message: 'Please enter a valid email address or use "noemail@gmail.com" if you do not have an email.',
                        },
                        {
                            required: true,
                            message: 'The patient email field is required.',
                        },
                    ]}
                >
                    <Input />
                    {/* <Input defaultValue={patientDetails.email} /> */}
                </Form.Item>
                <Form.Item label="Gender" name="PatientGender"
                    rules={[
                        {
                            type: 'gender',
                            message: 'The patient gender field is required.',
                        },
                        { required: true, message: 'The patient gender field is required.' },]}>
                    {/* {dropdownValues.gender && dropdownValues.gender.length > 0 ?
                        <Select>
                            {dropdownValues.gender.map((gender, index) => (
                                <Option key={index} value={gender}>{gender}</Option>
                            ))}
                        </Select>
                        : <Select></Select>
                    } */}
                    <Select>
                        {data.gender.map((item) => (
                            <Option key={item.dropdownoptionid} value={item.dropdownoptionid}>
                                {item.value}
                            </Option>
                        ))}

                    </Select>
                </Form.Item>
                {ipadRegisterControl ?
                    <Form.Item>
                        <Form.Item label="Marital Status" name="PatientMaritalStatus">
                            {/* {dropdownValues.marital_status && dropdownValues.marital_status.length > 0 ?
                                <Select>
                                    {dropdownValues.marital_status.map((status, index) => (
                                        <Option key={index} value={status}>{status}</Option>
                                    ))}
                                </Select>
                                : <Select></Select>
                            } */}
                            <Select>
                                {data.maritalStatus.map((item) => (
                                    <Option key={item.dropdownoptionid} value={item.dropdownoptionid}>
                                        {item.value}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Form.Item label="SSN" name="PatientSSN" rules={[{ validator: validatePatientSSN }]}>
                                <InputMask
                                    type="text"
                                    className="form-control"
                                    id="PatientSSN"
                                    mask="999 99 9999"
                                    placeholder="___ __ ____"
                                    // defaultValue={props.patientRegistrationInfo.patientRegistrationFormData?.PersonalInformation?.PatientSSN}
                                    onChange={handleSsnChange}
                                    disabled={isNoSSN} />
                            </Form.Item>
                            <Form.Item>
                                <Checkbox
                                    checked={isNoSSN}
                                    onChange={(e) => setIsNoSSN(e.target.checked)}>
                                    I do not have SSN.
                                </Checkbox>
                            </Form.Item>
                        </Form.Item>

                        <Form.Item label="Ethnicity" name="PatientEthnicity">
                            {/* {dropdownValues.ethnicity && dropdownValues.ethnicity.length > 0 ?
                                <Select>
                                    <Option value={null}></Option>
                                    {dropdownValues.ethnicity.map((ethnicity, index) => (
                                        <Option key={index} value={ethnicity}>{ethnicity}</Option>
                                    ))}
                                </Select>
                                : <Select></Select>
                            } */}
                            <Select>
                                {data.ethnicity.map((item) => (
                                    <Option key={item.dropdownoptionid} value={item.dropdownoptionid}>
                                        {item.value}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Form.Item label="Preferred Language" name="PatientPreferredLanguage">
                                <Select mode="multiple">
                                    <Option key="1" value="English"
                                    // selected={selectedPreferredLanguages.includes('English')}
                                    >
                                        English
                                    </Option>
                                    <Option key="2" value="Spanish"
                                    // selected={selectedPreferredLanguages.includes('Spanish')}
                                    >
                                        Spanish
                                    </Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="LanguageTranslate">
                                <Checkbox
                                    checked={isLanguageTranslate}
                                    onChange={(e) => setIsLanguageTranslate(e.target.checked)}>
                                    I request language translation services.
                                </Checkbox>
                            </Form.Item>
                        </Form.Item>
                        <Form.Item label="Race" name="PatientRace">
                            {/* {dropdownValues.race && dropdownValues.race.length > 0 ?
                                <Select>
                                    <Option value={null}></Option>
                                    {dropdownValues.race.map((race, index) => (
                                        <Option key={index} value={race}>{race}</Option>
                                    ))}
                                </Select>
                                : <Select></Select>
                            } */}
                            <Select>
                                {data.race.map((item) => (
                                    <Option key={item.dropdownoptionid} value={item.dropdownoptionid}>
                                        {item.value}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {formControls.includes('RequestedServices') &&
                            <Form.Item label="Requested Services" name="RequestedServices">
                                {dropdownValues.requested_services && dropdownValues.requested_services.length > 0 ?
                                    <Select>
                                        <Option value={null}></Option>
                                        {dropdownValues.requested_services.map((requestedServices, index) => (
                                            <Option key={index} value={requestedServices}>{requestedServices}</Option>
                                        ))}
                                    </Select>
                                    : <Select></Select>
                                }
                            </Form.Item>
                        }
                        {formControls.includes('Advance Directives') ?
                            <div>
                                <hr />
                                <h3>Advance Directives</h3>
                                <Form.Item label="Do you have an Advance Directive?" name="AdvanceDirectives">
                                    <Radio.Group onChange={handleAdvanceDirectiveRadioChange}>
                                        <Radio value="Yes"> Yes </Radio>
                                        <Radio value="No"> No </Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {advanceDirective === 'Yes' && (
                                    <Form.Item>
                                        <Form.Item label="If yes, please specify" name="AdvanceDirectiveSpecify"
                                            rules={[{ required: true, message: 'This field is required.' }]}>
                                            <Radio.Group>
                                                <Radio value="Do Not Resuscitate"> Do Not Resuscitate </Radio>
                                                <Radio value="Do Not Intubate"> Do Not Intubate</Radio>
                                                <Radio value="Living Will"> Living Will</Radio>
                                                <Radio value="Health care Proxy"> Health care Proxy</Radio>
                                                <Radio
                                                    value="Information About Advanced Directives Given To Patient"> Information
                                                    About Advanced Directives Given To Patient</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Divider />
                                    </Form.Item>
                                )}
                                <h3>Additional Information</h3>
                                <Form.Item label="Seasonal/Migrant Status" name="AdditionalInformationMigrant">
                                    {dropdownValues.migrant_status && dropdownValues.migrant_status.length > 0 ?
                                        <Select>
                                            <Option value={null}></Option>
                                            {dropdownValues.migrant_status.map((status, index) => (
                                                <Option key={index} value={status}>{status}</Option>
                                            ))}
                                        </Select>
                                        : <Select></Select>
                                    }
                                </Form.Item>
                                <Form.Item label="Housing Status" name="HousingStatus">
                                    {dropdownValues.housing_status && dropdownValues.housing_status.length > 0 ?
                                        <Select>
                                            <Option value={null}></Option>
                                            {dropdownValues.housing_status.map((status, index) => (
                                                <Option key={index} value={status}>{status}</Option>
                                            ))}
                                        </Select>
                                        : <Select></Select>
                                    }
                                </Form.Item>
                                <Form.Item label="If Homeless, please specify"
                                    name="AdditionalInformationHomeLessSpecify">
                                    {dropdownValues.homeless_specify && dropdownValues.homeless_specify.length > 0 ?
                                        <Select>
                                            <Option value={null}></Option>
                                            {dropdownValues.homeless_specify.map((status, index) => (
                                                <Option key={index} value={status}>{status}</Option>
                                            ))}
                                        </Select>
                                        : <Select></Select>
                                    }
                                </Form.Item>
                            </div> : null
                        }
                    </Form.Item>
                    : null}
                <Form.Item className="form-footer-button">
                    {isSubmitting ? (
                        <div className="spinner-overlay">
                            <div className="spinner">
                                <Spin size="large" tip="Loading..." />
                            </div>
                        </div>
                    ) : (
                        <Row gutter={12}>
                            <Col>
                                <Button type="primary" onClick={onPersonalInfoPreviousClick}>Previous</Button>
                            </Col>
                            <Col>
                                <Button type="primary" htmlType="submit">Next</Button>
                            </Col>
                        </Row>
                    )}
                </Form.Item>
            </Form>
        </div>
    );
}
export default PersonalInformation;
