import React, { useState, useRef, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Modal } from 'antd';

export default function DoubleBookingCalendar({ onEventSelection, onDeleteEvent, doublebooking }) {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const calendarRef = useRef(null);

    useEffect(() => {
        if (doublebooking != null && doublebooking.length > 0) {
            setValuesincalendar(doublebooking);
        }
        else {
            calendarRef.current.getApi().addEventSource();
        }
    }, []);

    const setValuesincalendar = (inputData) => {
        const outputData = [];

        inputData.forEach(item => {
            const daysOfWeekMap = {
                "Sunday": 0,
                "Monday": 1,
                "Tuesday": 2,
                "Wednesday": 3,
                "Thursday": 4,
                "Friday": 5,
                "Saturday": 6
            };
            const daysOfWeek = [daysOfWeekMap[item.day]];
            const [startTime, endTime] = item.timeRange.split(' - ');
            outputData.push({ daysOfWeek, startTime, endTime,
                title: `${startTime} - ${endTime}` 
            });
            console.log ("123",outputData);
        });

        calendarRef.current.getApi().addEventSource(outputData);

    };

    const handleDateClick = (info) => {
        const clickedDate = info.date;
        const clickedTime = clickedDate.getHours() * 60 + clickedDate.getMinutes();

        if (clickedTime < 480 || clickedTime >= 1080) {
            return false;
        }

        const fifteenMinutesLater = new Date(clickedDate);
        fifteenMinutesLater.setMinutes(clickedDate.getMinutes() + 15);

        const events = calendarRef.current.getApi().getEvents();
        const conflictEvents = events.filter(event => {
            return (clickedDate >= event.start && clickedDate < event.end) ||
                (fifteenMinutesLater > event.start && fifteenMinutesLater <= event.end);
        });

        if (conflictEvents.length > 0) {
            return false;
        }

        const newEvent = {
            start: clickedDate,
            end: fifteenMinutesLater,
            allDay: false,
            title:`${clickedDate.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}-${fifteenMinutesLater.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}`
        };

        calendarRef.current.getApi().addEvent(newEvent);

        const formattedStartTime = clickedDate.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
        const formattedEndTime = fifteenMinutesLater.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
        const formattedTimeRange = `${formattedStartTime} - ${formattedEndTime}`;

        onEventSelection(formattedTimeRange, clickedDate);
        return true;
    };

    const eventClickHandler = (info) => {
        setSelectedEvent(info.event);
        setModalVisible(true);
    };

    const handleModalOk = () => {
        if (selectedEvent) {
            selectedEvent.remove();

            const clickedDate = selectedEvent.start;
            const fifteenMinutesLater = selectedEvent.end;

            const formattedStartTime = clickedDate.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
            const formattedEndTime = fifteenMinutesLater.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });

            const formattedTimeRange = `${formattedStartTime} - ${formattedEndTime}`;
            const clickedDayIndex = clickedDate.getDay();

            const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            const clickedDay = daysOfWeek[clickedDayIndex];

            onDeleteEvent(selectedEvent, formattedTimeRange, clickedDay);

        }

        setModalVisible(false);
    };

    const handleModalCancel = () => {
        setModalVisible(false);
    };

    const customView = {
        titleFormat: { weekday: 'short' },
    };

    const customHeaderContent = (args) => {
        const dayName = args.date.toLocaleString('default', { weekday: 'long' });
        return <span>{dayName}</span>;
    };

    const businessHours = [
        { daysOfWeek: [0, 1, 2, 3, 4, 5, 6], startTime: '08:00', endTime: '20:00' }, // Monday to Friday, 9:00 AM to 5:00 PM        
    ];

    const handleEventResize = (info) => {
        const event = info.event;
        const updatedEvent = {
            ...event,
            start: info.start,
            end: info.end
        };
        // Update the event in your events array or data source
        // Call any necessary functions to update the event

        // Access the FullCalendar instance using the ref
        const calendar = calendarRef.current.getApi();

        // Update the event in FullCalendar
        calendar.updateEvent(updatedEvent);
    };


    const handleEventDrop = (info) => {
        // Perform event drop operation
        handleDateClick(info);
    };

    // const updateEventInAllEvents = (info) => {
    //     // Extract event data from info object
    //     const event = info.event;

    //     // Update the event in the allEvents array
    //     // Implement your logic here
    // };

    return (
        <div>
            <FullCalendar
                scrollTime="08:00:00"
                ref={calendarRef}
                plugins={[timeGridPlugin, interactionPlugin]}
                initialView="timeGridWeek"
                defaultView="dayGridMonth"
                dateClick={handleDateClick}
                eventClick={eventClickHandler}
                allDaySlot={false}
                headerToolbar={false}
                slotDuration="00:15:00"
                slotLabelInterval="00:30"
                dayHeaderContent={customHeaderContent}
                businessHours={businessHours} // Define the business hours
                selectConstraint="businessHours" // Set constraint for event selection
                eventConstraint="businessHours" // Set constraint for events
                selectable={true} // Allow user to select time slots
                eventResize={handleEventResize} // Call handleEventResize function when an event is resized
                eventDrop={handleEventDrop} // Call handleEventDrop function when an event is dropped
                views={{
                    timeGridWeek: {
                        dayHeaderFormat: { weekday: 'long' } // Configure the columnHeaderFormat for 'timeGridWeek'
                    }
                }}
                slotLabelFormat={{ hour: 'numeric', minute: '2-digit', hour12: true }}
                slotClassNames={(arg) => {
                    const clickedTime = arg.date.getHours() * 60 + arg.date.getMinutes();
                    if (clickedTime < 480 || clickedTime >= 1080) {
                        return 'custom-red'; // Apply 'custom-red' class for slots outside the specified range
                    }
                    return ''; // Return empty string for slots within the specified range
                }}
            />

            <Modal
                title="Delete Event"
                open={modalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                okText="Yes"
                cancelText="No"
                okButtonProps={{ danger: true }}
                width={400}
            >
                <p>Are you sure you want to delete this double booking?</p>
            </Modal>
        </div>
    );
}