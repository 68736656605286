// Modify your formatPhoneNumber function to accept an input value and format it dynamically
// export function formatPhoneNumber(value) {
//     // Remove all non-digit characters
//     const cleaned = ('' + value).replace(/\D/g, '');

//     // Apply regex to match the cleaned phone number
//     const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

//     if (match) {
//         // Format the phone number as (xxx) xxx-xxxx
//         let formattedValue = '';
//         if (match[1]) {
//             formattedValue += '(' + match[1];
//         }
//         if (match[2]) {
//             formattedValue += ') ' + match[2];
//         }
//         if (match[3]) {
//             formattedValue += '-' + match[3];
//         }
//         return formattedValue.trim(); // Trim any extra spaces
//     }

//     return value; // If no match, return the original value
// }

export const formatPhoneNumber = (value) => {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return value;
}
export function checkEmpty(value) {
    if (value === null || value === undefined || value === "") {
        return true;
    }
    else {
        return false;
    }
}

export const validatePassword = (_, value) => {
    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!pattern.test(value)) {
        return Promise.reject('Password: 8+ chars with at least 1 lowercase, 1 uppercase, 1 digit, 1 special char.');
    }
    return Promise.resolve();
};

export const validateNumber = (_, value) => {
    const pattern = /^\d+$/;
    if (!pattern.test(value)) {
        return Promise.reject('Please input numbers only.');
    }
    return Promise.resolve();
};

export const validatePhone = (_, value) => {
    const pattern = /^\(\d{3}\) \d{3}-\d{4}$/;
    if (!pattern.test(value)) {
        return Promise.reject('Field format is not correct');
    }
    return Promise.resolve();
};

// Function to validate UUID format
export const isValidUUID = (uuid) => {
    const uuidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return uuidPattern.test(uuid);
}

export function removeFormatting(phone) {
    return phone.replace(/[()\s-]/g, '');
}
// export function handleNumKeyDown(e) {
//     const keyCode = e.keyCode || e.which;
//     if (
//         ((keyCode >= 48 && keyCode <= 57) || // Numbers 0-9
//         (keyCode >= 65 && keyCode <= 90) || // Upper-case letters
//         (keyCode >= 96 && keyCode <= 105)) || // Numpad 0-9
//         e.key === 'Backspace' ||
//         e.key === 'Delete' ||
//         e.key === 'ArrowLeft' ||
//         e.key === 'ArrowRight' ||
//         e.key === 'ArrowUp' ||
//         e.key === 'ArrowDown' ||
//         e.key === 'Tab'
//     ) {
//         return;
//     }
//     e.preventDefault();
// }


export function handleNumKeyDown(e) {
    const keyCode = e.keyCode || e.which;
    // Allow only numeric input, backspace, delete, and arrow keys
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 8 && keyCode !== 46 && keyCode !== 37 && keyCode !== 39 && keyCode !== 38 && keyCode !== 40 && keyCode !== 9) {
        // For side number keypad
        if (!((keyCode >= 96 && keyCode <= 105) || keyCode === 110 || keyCode === 144)) {
            e.preventDefault();
        }
    }
    if (e.key.match(/[!@#$%^&*()]/)) {
        e.preventDefault();
    }
}
