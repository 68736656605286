import axiosInterceptor from "../../middleware/axiosInterceptor";

export async function userList() {
  const url = "/api/users";

  try {
    const response = await axiosInterceptor.get(url);
    return response.data.users; // Returning just the array of users
  } catch (error) {
    console.error("Error in userList service:", error);
    throw error; // Rethrowing the error for the caller to handle
  }
}

export async function deleteUser(deleteData) {
  const url = "/api/user/delete";

  try {
    const response = await axiosInterceptor.delete(url, { data: deleteData });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}