import React, { useEffect, useState } from 'react';
import { DatePicker, Button, Switch, message } from 'antd';
import { Form, Select, Row, Col } from "antd";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { getReportDropDownData, getreportData } from '../../services/reportService';
import Grid from '../grid/newGrid';
import "./../../../assets/styles/report.css";
dayjs.extend(customParseFormat);
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
const Report = () => {
    const [form] = Form.useForm();
    const [selectedReport, setSelectedReport] = useState(null);
    const [selectDropDownReport, setSelectDropDownReport] = useState(null);
    const [reportColumns, setReportColumns] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [showReport, setShowReport] = useState(false);
    const [reportDropDown, setReportDropDown] = useState([]);
    const labelWrapperProps = {
        labelAlign: 'middle',
    };
    const dropdownStyle = {
        backgroundColor: '#f0f0f0',
        border: '1px solid #F5F5F5',
        borderRadius: '4px',
    };
    const selectStyle = {
        width: 200,
    };
    const generateColumns = (arr) => {
        const uniqueKeys = [...new Set(arr.reduce((acc, obj) => {
            return acc.concat(Object.keys(obj));
        }, []))];
        let keys = uniqueKeys.map(key => ({
            title: key,
            dataIndex: key,
            key: key
        }));
        let finalKeys = {
            columns: keys
        }
        return finalKeys;
    };
    const cellRender = (current, info) => {
        const style = {
            border: '1px solid #1890ff',
            borderRadius: '50%',
        };
        if (info.type !== 'date') {
            return info.originNode;
        }
        return (
            <div className="ant-picker-cell-inner" style={current.date() === 1 ? style : {}}>
                {current.date()}
            </div>
        );
    };
    const handleSwitchChange = (checked) => {
    };
    const handleSelectChange = (value, option) => {
        setSelectedReport(value);
        var selectedReport = option.key;
        setSelectDropDownReport(selectedReport);
    };
    const handleExecute = async () => {
        try {
            const values = await form.validateFields();
            const reportDropDown = selectDropDownReport;
            const { startDate, endDate, testData } = values;
            let showTestData = testData ? 1 : 0;
            const formattedStartDate = startDate.format(dateFormat);
            const formattedEndDate = endDate.format(dateFormat);
            const response = await getreportData({
                report: reportDropDown,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                showTestData: showTestData || 0,
            });
            if (response) {
                let columns = generateColumns(response);
                setReportColumns(columns);
                setReportData(response);
                setShowReport(true);
            } else {
                message.error('Failed to fetch report!');
            }
        } catch (error) {
            console.error('Error fetching report:', error);
            message.error('Error fetching report!');
        }
    };
    useEffect(() => {
        fetchreportData();
    }, []);
    const fetchreportData = async () => {
        try {
            const ReportData = await getReportDropDownData();
            setReportDropDown(ReportData);
        } catch (error) {
        }
    };
    return (
        <div className="form-container">
        <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            {...labelWrapperProps}
            colon={false}
            initialValues={{
                startDate: dayjs().startOf('day'),
                endDate: dayjs().startOf('day'),
                testData: false,
            }}
        >
            <h1>Reports</h1>
            <Row align="top" gutter={[8, 5]} className="bold-text">
                <Col>
                    <span className="bold-text">Select the report you want to view</span>
                </Col>
                <Col span={5}>
                    <Form.Item
                        name="report"
                        rules={[{ required: true, message: '' }]}
                    >
                        <Select
                            placeholder=""
                            style={selectStyle}
                            dropdownStyle={dropdownStyle}
                            onChange={handleSelectChange}
                        >
                            {reportDropDown.map((report) => (
                                <Option key={report.reportId} value={report.reportName} name={report.reportName}>
                                    {report.reportName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            {selectedReport && (
                <>
                    <Row className="date-pickers-row" gutter={[15, 6]}>
                        <Col className="date-picker">
                            <span className="bold-text">Start Date</span>
                            <Form.Item
                                name="startDate"
                                rules={[{ required: true, message: 'Please select a start date!' }]}
                                style={{ marginBottom: 0 }}
                            >
                                <DatePicker
                                    placeholder="Select start date"
                                    style={{ width: '100%' }}
                                    format={dateFormat}
                                    cellRender={cellRender}
                                />
                            </Form.Item>
                        </Col>
                        <Col className="date-picker">
                            <span className="bold-text">End Date</span>
                            <Form.Item
                                name="endDate"
                                rules={[{ required: true, message: 'Please select an end date!' }]}
                                style={{ marginBottom: 0 }}
                            >
                                <DatePicker
                                    placeholder="Select end date"
                                    style={{ width: '100%' }}
                                    format={dateFormat}
                                    cellRender={cellRender}
                                />
                            </Form.Item>
                        </Col>
                        <Col className="testdata-switch">
                            <span className="bold-text">Testdata</span>
                            <Form.Item name="testData" valuePropName="checked"   className='switchData'>
                                <Switch size="small" defaultUnchecked onChange={handleSwitchChange} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[8, 5]} className="execute-button">
                        <Col>
                            <Button type="primary" size="middle" onClick={handleExecute}>Execute</Button>
                        </Col>
                    </Row>
                    {showReport && (
                        <div className="report-data-container">
                            <h2>Report Data</h2>
                            <Grid
                                columnData={reportColumns}
                                tableData={reportData}
                                initialTableData={reportData}
                                setTableData={setReportData}
                            />
                        </div>
                    )}
                </>
            )}
        </Form>
    </div>
);
};
export default Report;
