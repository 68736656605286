
export const PracticeLocationGridColumns = {
  columns: [
    {
      title: "Action",
      key: "action",
      render: ["edit", "delete"],
      width: 100,
      hidden: false,
    },
    {
      title: "id",
      dataIndex: "practiceLocationid",
      key: "practiceLocationid",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: true,
    },
    {
      title: "Location Name",
      dataIndex: "locationName",
      key: "locationName",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: false,
    },
    {
      title: "Identification",
      dataIndex: "identification",
      key: "identification",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: false,
    },
    {
      title: "Street",
      dataIndex: "street",
      key: "street",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: false,
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: false,
    },
    {
      title: "Zip",
      dataIndex: "zip",
      key: "zip",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: false,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: false,
    },
    {
      title: "Provider",
      dataIndex: "provider",
      key: "provider",
      render: ["link"],
      feature: ["sorter", "filter"],
      filters: [],
      width: 100,
      hidden: false,
    },
  ],
};

export const daysOfOperationColumns = {
  columns: [
    {
      title: 'Action',
      key: 'action',
      render: ["edit", "delete"],
      fixed: true,
      feature: false,
      hidden: false,
      width: 25,
    },
    {
      title: 'Day',
      dataIndex: 'day',
      key: 'day',
      feature: false,
      hidden: false,
    },
    {
      title: 'From Time',
      dataIndex: 'fromTime',
      key: 'fromTime',
      feature: false,
      hidden: false,
    },
    {
      title: 'To Time',
      dataIndex: 'toTime',
      key: 'toTime',
      feature: false,
      hidden: false,
    },
  ],
};

// export const daysOfOperationColumns = [
//   {
//     title: 'Day',
//     dataIndex: 'day',
//     key: 'day'
//   },
//   {
//     title: 'From Time',
//     dataIndex: 'fromTime',
//     key: 'fromTime'
//   },
//   {
//     title: 'To Time',
//     dataIndex: 'toTime',
//     key: 'toTime'
//   },
// ];