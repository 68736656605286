import React from 'react';
import { Drawer } from 'antd';
import Register from '../users/register';

function CreateUser({ drawerVisible, setDrawerVisible, form, handleCloseDrawer,
    handleRefresh, registerCardProps, tableDataInternal, setTableDataInternal,
}) {
    const handleCardOnClose = () => {
        setDrawerVisible(false);
    };

    return (
        <div>
            <Drawer
                title="Create User"
                onClose={handleCardOnClose}
                open={drawerVisible}
                maskClosable={false}
            >
                {drawerVisible &&
                    <Register
                        setDrawerVisible={setDrawerVisible}
                        onRefresh={handleRefresh}
                        props={registerCardProps}
                        tableDataInternal={tableDataInternal}
                        setTableDataInternal={setTableDataInternal}
                    />
                }
            </Drawer>
        </div>
    );
};

export default CreateUser;
