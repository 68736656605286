import { getConfigurations } from '../../services/configurationService';
import {
  providerList, getProviderInitialData, saveProviderDetail, deleteProviderDetail,
  getProviderDetail, saveProviderLocationConfig, locationConfig, deleteTiming,
  updateProviderDetail
} from '../../services/providerService';
import { cloneDeep } from 'lodash';
import { Modal, message } from 'antd';

import dayjs from 'dayjs';
import { removeFormatting, formatPhoneNumber } from '../utilities/utility';

export const fetchData = async (setProviderData, setLoading) => {
  setLoading(true);
  try {
    const providers = await providerList();
    setProviderData(providers);
  } catch (error) {
    console.error('Error fetching provider data:', error);
  } finally {
    setLoading(false);
  }
};

export const fetchProviderForm = async (setProviderFormData, setvisibleFormData, setLoading,
  setSpecialities, setInsurances, setBots, setPracticeLocations) => {
  setLoading(true);
  try {

    const body = {
      level: "PRACTICE",
      practiceId: 1,
      providerId: null,
      practiceLocationId: null
    };

    const response = await getConfigurations(body);
    if (response[0].value != null) {
      setvisibleFormData(JSON.parse(response[0].value));
    }

    const InitialDataResponse = await getProviderInitialData();

    if (InitialDataResponse && InitialDataResponse.data && InitialDataResponse.data.success) {
      setSpecialities(InitialDataResponse.data.specialities);
      setInsurances(InitialDataResponse.data.insurances);
      setBots(InitialDataResponse.data.bots);
      setPracticeLocations(InitialDataResponse.data.practiceLocations);
    }
  } catch (error) {
    console.error('Error fetching provider data:', error);
  } finally {
    setLoading(false);
  }
};

export const getGridData = (providerData) => {
  const data = cloneDeep(providerData);
  return data.map(provider => ({
    providerId: provider.providerId,
    identification: provider.identification,
    firstName: provider.firstName,
    lastName: provider.lastName,
    email: provider.email,
    phone: provider.phone,
  }));
};

export const showDrawer = (setVisible) => {
  setVisible(true);
};

export const onClose = (setVisible) => {
  setVisible(false);
};

export const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};

export const handleBack = (setVisible) => {
  setVisible(false);
};

export const handleEditClick = (provider, setSelectedProvider, setVisible, setkeytype) => {
  setSelectedProvider(provider);
  setVisible(true);
  setkeytype("update");
};

export const handleCreate = (provider, setSelectedProvider, setVisible, setkeytype) => {
  setSelectedProvider(provider);
  setVisible(true);
  setkeytype("create");
};

export async function onSaveProvider(form, formData, initialState, requiredState, insurances, followUp,
  setFormData, setInsuranceItems, setfollowUp, setErrors, setproviderId) {
  try {
    const newErrors = {};
    formData.insurance = insurances;
    formData.followUp = [followUp];
    formData.practiceId = 1;
    formData.modifiedBy = parseInt(sessionStorage.getItem('userId'))

    formData.workPhone = removeFormatting(formData.workPhone);
    formData.phone = removeFormatting(formData.phone);

    // Object.keys(requiredState).forEach((fieldName) => {
    //   if (!formData[fieldName]) {
    //     newErrors[fieldName] = `Please input your ${fieldName}!`;
    //   }
    // });

    Object.keys(requiredState).forEach((fieldName) => {
      if (Array.isArray(formData[fieldName]) && formData[fieldName].length === 0) {
        newErrors[fieldName] = `Please input at least one ${fieldName}!`;
      } else if (!Array.isArray(formData[fieldName]) && !formData[fieldName]) {
        newErrors[fieldName] = `Please input your ${fieldName}!`;
      }
    });
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      let response = await saveProvider(formData);
      if (response && response.data && response.data.success) {
        setproviderId(response.data.provider[0].providerId);
        Modal.success({ title: "Success", content: "provider save successfully." });

        // setFormData(initialState);
        // setformdatetails(form, initialState, setInsuranceItems, setfollowUp);

        return response.data.provider[0].providerId;
      }
      else {
        if (response && response.data && response.data.businessRules && response.data.businessRules.length > 0) {
          const Errormessage = response.data.businessRules[0].description;
          Modal.error({ title: "Fail", content: Errormessage });
          return "";
        }
      }
    }
  }
  catch (error) {
    console.error("Error save provider exception:", error.message);
    return "";

  }
}

export async function onUpdateProvider(form, providerId, formData, initialState, requiredState, insurances, followUp,
  setFormData, setInsuranceItems, setfollowUp, setErrors) {
  try {
    const newErrors = {};
    formData.insurance = insurances;
    formData.providerId = providerId;
    formData.practiceId = 1;
    formData.followUp = [followUp];
    formData.modifiedBy = parseInt(sessionStorage.getItem('userId'))

    formData.workPhone = removeFormatting(formData.workPhone);
    formData.phone = removeFormatting(formData.phone);

    // Object.keys(requiredState).forEach((fieldName) => {
    //   if (!formData[fieldName]) {
    //     newErrors[fieldName] = `Please input your ${fieldName}!`;
    //   }
    // });
    Object.keys(requiredState).forEach((fieldName) => {
      if (Array.isArray(formData[fieldName]) && formData[fieldName].length === 0) {
        newErrors[fieldName] = `Please input at least one ${fieldName}!`;
      } else if (!Array.isArray(formData[fieldName]) && !formData[fieldName]) {
        newErrors[fieldName] = `Please input your ${fieldName}!`;
      }
    });
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      let response = await updateProvider(formData);
      if (response && response.data && response.data.success) {
        Modal.success({ title: "Success", content: "provider update successfully." });

        // setFormData(initialState);
        // setformdatetails(form, initialState, setInsuranceItems, setfollowUp);

        return response.data.provider[0].providerId;
      }
      else {
        if (response && response.data && response.data.businessRules && response.data.businessRules.length > 0) {
          const Errormessage = response.data.businessRules[0].description;
          Modal.error({ title: "Fail", content: Errormessage });
          return "";
        }
      }
    }
  }
  catch (error) {
    console.error("Error update provider exception:", error.message);
    return "";

  }
}

const setformdatetails = (form, formData, setInsuranceItems, setfollowUp) => {

  if (formData != null) {
    let dayjsDates = []
    if (formData.vacation != null) {
      const dates = formData.vacation;
      dayjsDates = dates.map(date => dayjs(date, 'YYYY-MM-DD'));
    }
    let activeFrom = "";
    let activeTo = "";

    if (formData.activeFrom != null) {
      activeFrom = dayjs(formData.activeFrom, 'YYYY-MM-DD')
    }

    if (formData.activeTo != null) {

      activeTo = dayjs(formData.activeTo, 'YYYY-MM-DD')
    }

    form.setFieldsValue({
      // "practiceId": formData.practiceId,
      "identificationId": formData.identificationId,
      "firstName": formData.firstName,
      "lastName": formData.lastName,
      "practiceName": formData.practiceName,
      "practiceLocation": formData.practiceLocation,
      "clientName": formData.clientName,
      "title": formData.title,
      "npi": formData.npi,
      "pediatricAgeFrom": formData.pediatricAgeFrom,
      "pediatricAgeTo": formData.pediatricAgeTo,

      "activeFrom": activeFrom,
      "activeTo": activeTo,

      "allowSameDayAppointment": formData.allowSameDayAppointment,
      "botProfession": formData.botProfession,

      "botPhoneticName": formData.botPhoneticName,
      "email": formData.email,
      "phone": formData.phone,
      "workPhone": formData.workPhone,
      "speciality": formData.speciality,

      "doubleBooking": formData.doubleBooking,
      "insurance": formData.insurance,
      "insuranceActionEscalate": formData.insuranceActionEscalate,
      "insuranceActionBookAppointment": formData.insuranceActionBookAppointment,
      "vacation": dayjsDates,
      "messaging": formData.messaging,

      "followUp": formData.followUp,
      "hl7": formData.hl7,
      "zoomId": formData.zoomId,
      "bots": formData.bots,

      // "startTime": formData.startTime,
      // "endTime": formData.endTime
    });

    if (formData.insurance != null) {
      setInsuranceItems(formData.insurance);
    }

    if (formData.followUp != null) {
      setfollowUp(formData.followUp);
    }

  }
};

export async function saveProvider(formData) {
  try {
    const response = await saveProviderDetail(formData);
    return response;
  } catch (error) {
    console.error("Error save practice location exception:", error.message);
    return false;
  }
};

export async function updateProvider(formData) {
  try {
    const response = await updateProviderDetail(formData);
    return response;
  } catch (error) {
    console.error("Error update practice location exception:", error.message);
    return false;
  }
};

export async function handleModalConfirm(modalData, fetchData, setProviderData, setLoading, setModalData) {
  if (modalData.type === 'delete') {
    try {
      const deleteData = {
        providerid: modalData.record.providerId,
        updatedby: sessionStorage.getItem('userId')
      };

      if (!deleteData.providerid) {
        throw new Error('Provider id cannot be empty');
      }
      const response = await deleteProviderDetail(deleteData);
      if (response && response.data && response.data.success) {
        Modal.success({ title: "Success", content: "Provider detail deleted successfully." });
        fetchData(setProviderData, setLoading);
      } else {
        message.error("Failed to delete provider detail");
      }
    } catch (error) {
      message.error(error.message || "Failed to delete provider detail");
    }
    finally {
      setModalData({ ...modalData, visible: false });
    }
  }
}

export async function getProviderbyId(form, providerid, setFormData, setDaysOfOperation, setInsurances,
  setfollowUp, setLoading) {
  try {
    setLoading(true);
    const response = await getProviderDetail(providerid);
    if (response && response.data && response.data.success) {
      setFormData(response.data.provider[0]);
      setformdatetails(form, response.data.provider[0], setInsurances, setfollowUp);
    }
    setLoading(false);
    return response;
  } catch (error) {
    console.error("Error delete practice location exception:", error);
    return null;
  }
};

export async function onSaveLocationDetails(providerId, locationId, locationFormData, initialLocationState, daysOfOperation,
  setLocationFormData, setDaysOfOperation, setOperations, operationjson, jsonFormattedEvents, formattedEvents) {
  try {
    locationFormData.providerId = providerId;
    locationFormData.locationId = locationId;
    locationFormData.modifiedBy = parseInt(sessionStorage.getItem('userId'));
    locationFormData.dayOfOperation = daysOfOperation;
    locationFormData.doubleBooking = timeFormatCalendar(formattedEvents);

    let response = await savelocationdetail(locationFormData);
    if (response) {
      setLocationFormData(initialLocationState);
      setDaysOfOperation([]);
      setOperations(operationjson);

      Modal.success({ title: "Success", content: "Practice location Configuration save successfully." });
      return true;
    }
    else {
      return false;
    }
  }
  catch (error) {
    console.error("Error save practice location exception:", error.message);
    return false;
  }
}

export const timeFormatCalendar = (formattedEvents) => {
  try {
    const doubleBooking = formattedEvents.map(event => ({
      day: event.day,
      timings: event.timeRange.split(',').map(range => {
        const [start, end] = range.trim().split(' - ');
        return { from: start, to: end };
      })
    }));

    // jsonFormattedEvents = JSON.stringify({ doubleBooking });
    return doubleBooking;
  }
  catch (error) {
    console.error("Error save practice location exception:", error.message);
    return [];
  }
}

export async function savelocationdetail(locationFormData) {
  try {
    const response = await saveProviderLocationConfig(locationFormData);
    if (response && response.data && response.data.success) {
      return true;
    }
    else {
      if (response && response.data && response.data.businessRules && response.data.businessRules.length > 0) {
        const Errormessage = response.data.businessRules[0].description;
        Modal.error({ title: "Fail", content: Errormessage });
        return false;
      }
    }
  } catch (error) {
    console.error("Error save practice location details exception:", error.message);
    return false;
  }
};

export async function getLocationConfig(form, providerid, locationid, setLocationFormData, setDaysOfOperation,
  setOperations, operationjson, setSelectedEvent) {
  try {
    const response = await locationConfig(providerid, locationid);
    if (response && response.data && response.data.success) {
      setLocationFormData(response.data.provider[0]);
      if (response.data.provider[0].dayOfOperation != null) {
        setDaysOfOperation(response.data.provider[0].dayOfOperation);
      }
      else {
        setDaysOfOperation("");
      }

      if (response.data.provider[0].doubleBooking != null) {
        let converdata = convertDoubleBooking(response.data.provider[0].doubleBooking);
        setSelectedEvent(converdata);
      }
      else {
        setSelectedEvent([]);
      }
    }
    return response;
  } catch (error) {
    console.error("Error delete practice location exception:", error);
    return null;
  }
};

export async function deleteDayofOperation(locationid, timingId) {
  try {
    const deleteData = {
      timingId: timingId,
      locationId: locationid,
      modifiedBy: parseInt(sessionStorage.getItem('userId')),
    };

    const response = await deleteTiming(deleteData);
    if (response && response.data && response.data.success) {
      Modal.success({ title: "Success", content: "deleted successfully" });
    }
    return response;
  } catch (error) {
    console.error("Error delete practice location timing exception:", error);
    return null;
  }
};

export const convertDoubleBooking = (doubleBookingdata) => {
  try {
    const newData = [];

    doubleBookingdata.forEach(item => {
      const { day, from, to } = item;
      const fromTime = new Date(`1970-01-01T${from}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      const toTime = new Date(`1970-01-01T${to}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      const timeRange = `${fromTime} - ${toTime}`;
      newData.push({ day: day.charAt(0).toUpperCase() + day.slice(1), timeRange });
    });
    return newData;

  }
  catch (error) {
    console.error("Error save practice location exception:", error.message);
    return [];
  }
}


// export const handleEventSelection = (formattedTimeRange, clickedDate, setSelectedEvent) => {
//   const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
//   const selectedDay = daysOfWeek[clickedDate.getDay()];

//   const selectedEvent = {
//     day: selectedDay,
//     timeRange: formattedTimeRange
//   };

//   setSelectedEvent(prevSelectedEvents => [...prevSelectedEvents, selectedEvent]); // Add the new event to the existing array
// };


// export const handleDeleteEvent = (selectedEventrange, formattedTimeRange, selectedDay, selectedEvent, setSelectedEvent) => {
//   const selectedDayEvents = selectedEvent.filter(event => event.day === selectedDay);
//   const updatedEvents = selectedEvent.filter(event => {
//     return !(event.day === selectedDay && event.timeRange === formattedTimeRange);
//   });
//   setSelectedEvent(updatedEvents);
// };


export const handleDeleteEvent = (selectedEventrange, formattedTimeRange, selectedDay, selectedEvent, setSelectedEvent) => {
  const selectedDayEvents = selectedEvent.filter(event => event.day === selectedDay);
  const updatedEvents = selectedEvent.filter(event => {
    return !(event.day === selectedDay && event.timeRange === formattedTimeRange);
  });
  setSelectedEvent(updatedEvents);
};
export const handleEventSelection = (formattedTimeRange, clickedDate, setSelectedEvent) => {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const selectedDay = daysOfWeek[clickedDate.getDay()];

  const selectedEvent = {
    day: selectedDay,
    timeRange: formattedTimeRange
  };

  setSelectedEvent(prevSelectedEvents => [...prevSelectedEvents, selectedEvent]); // Add the new event to the existing array
};


export const initialState = {
  practiceId: "1", identificationId: "", firstName: "", lastName: "", practiceName: "", practiceLocation: [], clientName: "",
  title: "", npi: "", pediatricAgeFrom: "", pediatricAgeTo: "", activeFrom: "", activeTo: "", allowSameDayAppointment: 0,
  botProfession: "", botPhoneticName: "", email: "", phone: "", workPhone: "", speciality: "",
  insurance: [], insuranceActionEscalate: "0", insuranceActionBookAppointment: "1", vacation: [],
  messaging: "", bots: [], followUp: [], hl7: "", zoomId: "",
  gender: "", modifiedBy: parseInt(sessionStorage.getItem('userId')),
};

export const initialLocationState = {
  practiceId: 1, locationId: 0, providerId: 0, modifiedBy: parseInt(sessionStorage.getItem('userId')),
  dayOfOperation: [], doubleBooking: [],
};

export const requiredState = {
  firstName: "", lastName: "", practiceName: "", practiceLocation: [], identificationId: "",
  speciality: "", bots: [],
};

export const operationjson = {
  weekId: "", week1: 0, week2: 0, week3: 0, week4: 0, week5: 0, weekLast: 0, day: "", from: "", to: "",
};

export const insurancejson = {
  insuranceId: "", insuranceType: "", from: "", to: "", referral: "0",
};

export const followupjson = {
  reminder: "", time: "",
};

export async function getLocationField(setvaluelocation, practiceLocations, setLoading, setfollowUp, setInsuranceItems, setDaysOfOperation, setFormData, form, providerData) {
  if (providerData && providerData.providerId) {
    const response = await getProviderbyId(form, providerData.providerId,
      setFormData, setDaysOfOperation, setInsuranceItems, setfollowUp, setLoading);
    if (response && response.data && response.data.success) {
      if (practiceLocations != null && practiceLocations.length > 0) {
        setvaluelocation(response.data.provider[0].practiceLocation);
      }
    }
  }
};

// export const handleAddAppointment = (operations, setOperations, form, setDaysOfOperation, daysOfOperation, setEndTime, setStartTime
// ) => {
//   if (operations.day && operations.from && operations.to) {

//     const isDuplicate = daysOfOperation.some((day) => {
//       return (
//         day.day === operations.day &&
//         day.from === operations.from &&
//         day.to === operations.to
//       );
//     });


//     if (isDuplicate) {
//       message.error('This day and time range is already added.');
//       return;
//     }
//     // setStartTime(null);
//     // setEndTime(null);

//     let weekid = '0';
//     let ids = 0;
//     if (daysOfOperation != null) {
//       ids = daysOfOperation.length;
//       weekid = ids + 1;
//       operations.weekId = 'timing_' + weekid;
//     }

//     setDaysOfOperation([...daysOfOperation, operations]);
//     setOperations(operationjson);

//     form.setFieldsValue({
//       day: '',
//       fromTime: '',
//       to: '',
//       week1: false,
//       week2: false,
//       week3: false,
//       week4: false,
//       week5: false,
//       weekLast: false,
//     });
//      setStartTime(null);
//      setEndTime(null);
//     // form.resetFields();
//   }
// };
export const handleAddAppointment = (
  operations,
  setOperations,
  form,
  setDaysOfOperation,
  daysOfOperation,
  setEndTime,
  setStartTime
) => {
  // Initialize daysOfOperation as an empty array if it's not already defined
  if (!Array.isArray(daysOfOperation)) {
    setDaysOfOperation([]);
  }

  if (operations.day && operations.from && operations.to) {
    const isDuplicate = daysOfOperation.some((day) => {
      return (
        day.day === operations.day &&
        day.from === operations.from &&
        day.to === operations.to
      );
    });

    if (isDuplicate) {
      message.error('This day and time range is already added.');
      return;
    }

    let weekid = '0';
    let ids = 0;
    if (daysOfOperation != null) {
      ids = daysOfOperation.length;
      weekid = ids + 1;
      operations.weekId = 'timing_' + weekid;
    }

    setDaysOfOperation([...daysOfOperation, operations]);
    setOperations(operationjson);

    form.setFieldsValue({
      day: '',
      fromTime: '',
      to: '',
      week1: false,
      week2: false,
      week3: false,
      week4: false,
      week5: false,
      weekLast: false,
    });
    setStartTime(null);
    setEndTime(null);
  }
};


// export const handleAddInsurance = (form, setInsuranceItem, setInsuranceItems, insuranceItem, insuranceItems) => {
//   let tempinsuranceItem = [...insuranceItems];
//   tempinsuranceItem.push(insuranceItem);

//   setInsuranceItems(tempinsuranceItem);
//   setInsuranceItem(insurancejson);

//   form.setFieldsValue({

//     insurances: "",
//     from: "",
//     to: "",
//     referralRequired: false,

//   });


// };

// export const handleTimeChange = (time, timeString, name, formData1, setFormData1, operations, setOperations) => {
//   setOperations({ ...operations, [name]: timeString });
//   setFormData1({ ...formData1, [name]: time });
// };

export const handleWeekChange = (name, value, operations, setOperations) => {
  let dayweek = 0;
  if (value === true) {
    dayweek = 1;
  }
  setOperations({ ...operations, [name]: dayweek });
};

// export const handlecheckChange = (name, value,formData,setFormData) => {
//   let checkChange = 0;
//   if (value === true) {
//       checkChange = 1;
//   }
//   setFormData({ ...formData, [name]: checkChange });
// };
export const handlecheckChange = (name, checked, formData, setFormData) => {
  const checkChange = checked ? 1 : 0;
  setFormData({ ...formData, [name]: checkChange });
};

export const handlReferalChange = (name, value, insuranceItem, setInsuranceItem) => {
  let referalChange = 0;
  if (value === true) {
    referalChange = 1;
  }
  setInsuranceItem({ ...insuranceItem, [name]: referalChange });
};

// export const handleInsuranceChange = (value, valueString, name, insuranceItem, setInsuranceItem) => {
//   setInsuranceItem({ ...insuranceItem, [name]: valueString });
// };


export const handleDateTimeChange = (value, valueString, formData, setFormData, name) => {
  setFormData({ ...formData, [name]: valueString });
};



export const handleDeleteDOP = async (record, daysOfOperation, setDaysOfOperation, locationId, ExclamationCircleOutlined, confirm) => {
  let timingId = record.weekId;
  confirm({
    title: 'Are you sure you want to delete this Days of Operation?',
    icon: <ExclamationCircleOutlined />,
    async onOk() {
      if (Number.isInteger(timingId)) {
        const response = await deleteDayofOperation(locationId, timingId);
        if (response && response.data && response.data.success) {
          const updatedTableData = daysOfOperation.filter(item => item.weekId !== record.weekId);
          setDaysOfOperation(updatedTableData);
        }
      }
      else {
        const updatedTableData = daysOfOperation.filter(item => item.weekId !== record.weekId);
        setDaysOfOperation(updatedTableData);
        Modal.success({ title: "Success", content: "Deleted successfully" });
      }
    },
    onCancel() {
    },
  });
};

// export const handleDeleteInsurance = (record, insuranceItems, setInsuranceItems, ExclamationCircleOutlined, confirm) => {
//   confirm({
//     title: 'Are you sure you want to delete this Insurance?',
//     icon: <ExclamationCircleOutlined />,
//     onOk() {
//       const updatedTableData = insuranceItems.filter(item => item.insuranceId !== record.insuranceId);
//       setInsuranceItems(updatedTableData);
//     },
//     onCancel() {
//     },
//   });
// };
export const handleDeleteInsurance = (index, insuranceItems, setInsuranceItems, ExclamationCircleOutlined, confirm) => {
  confirm({
    title: 'Are you sure you want to delete this Insurance?',
    icon: <ExclamationCircleOutlined />,
    onOk() {
      const updatedInsuranceItems = insuranceItems.filter((item, i) => i !== index);
      setInsuranceItems(updatedInsuranceItems);
    },
    onCancel() {
      // Handle cancel action if needed
    },
  });
};

export const handlSameChange = (name, value, formData, setFormData) => {
  let sameday = 0;
  if (value === true) {
    sameday = 1;
  }
  setFormData({ ...formData, [name]: sameday });
};

export const handleChange = (value, name, formData, setFormData) => {
  setFormData({ ...formData, [name]: value });
};

export const handleChangeInteger = (value, name, formData, setFormData) => {
  setFormData({ ...formData, [name]: parseInt(value) });
};

export const findObjectsByPracticeLocationIds = (data, ids) => {
  return data.filter(item => ids.includes(item.practiceLocationid));
};

export const handleLocationChange = (value, name, formData, setFormData) => {
  setFormData({ ...formData, [name]: value });
  // setvaluelocation(value);
};

export const setvaluelocation = (value, setLocationList, practiceLocations) => {
  const loclist = findObjectsByPracticeLocationIds(practiceLocations, value);
  if (loclist.length > 0) {
    setLocationList(loclist);
  }
}

export const handleInputChange = (e, name, formData, setFormData) => {
  const { value } = e.target;
  setFormData({ ...formData, [name]: value });
};

// export const handleSelectChange = (value, text, setInsuranceItem, insuranceItem) => {
//   setInsuranceItem({ ...insuranceItem, insuranceType: text, });
//   setInsuranceItem({ ...insuranceItem, insuranceId: value });
// };

export const handlefollowup = (value, name, followUp, setfollowUp) => {
  setfollowUp({ ...followUp, [name]: value });
};

export const showModal = (setIsModalVisible) => {
  setIsModalVisible(true);
};

export const handleCancel = (setRefreshCalendar, setIsModalVisible) => {
  setIsModalVisible(false);
  setRefreshCalendar(prev => !prev);
};

export const handleOk = (setIsModalVisible) => {
  setIsModalVisible(false);
};

export const renderFormItems = (visibleFormData, component) => {
  if (visibleFormData && visibleFormData.fields && visibleFormData.fields.some(item => item.value === 'true' && item.code === component)) {
    return true
  }
  return false;
};

export const handlePhoneChange = (e, errors, setErrors, form, formData, setFormData) => {
  const { name, value } = e.target;
  const formattedValue = formatPhoneNumber(value);
  setFormData({ ...formData, [name]: formattedValue });
  form.setFieldsValue({ [name]: formattedValue });
  setErrors({ ...errors, [name]: '' });
};


export const validateEmail = (_, value) => {
  if (!value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('Invalid email format'));
};

export const handleLocation = (value, name, setSelectedEvent, setOperations, setDaysOfOperation, setLocationFormData, providerId, form, setlocationId, locationFormData) => {
  setLocationFormData({ ...locationFormData, [name]: value });
  setlocationId(value);
  getLocationConfig(form, providerId, value, setLocationFormData,
    setDaysOfOperation, setOperations, operationjson, setSelectedEvent);
};

// export const handleStartDateChange = (date, setEndDate, endDate, setStartDate) => {
//   setStartDate(date);
//   if (endDate && date > endDate) {
//     setEndDate(null);
//   }
// };


// export const handleEndDateChange = (date, startDate, setEndDate) => {
//   if (!startDate || date < startDate) {
//     return;
//   }
//   setEndDate(date);
// };


// functions.js
export const handleSelectChange = (value, text, setInsuranceItem, insuranceItem) => {
  setInsuranceItem({ ...insuranceItem, insuranceType: text, insuranceId: value });
};

export const handleStartDateChange = (date, setEndDate, endDate, setStartDate) => {
  setStartDate(date);
  if (endDate && date > endDate) {
    setEndDate(null);
  }
};

export const handleEndDateChange = (date, startDate, setEndDate) => {
  if (!startDate || date < startDate) {
    return;
  }
  setEndDate(date);
};

export const handleInsuranceChange = (date, dateString, field, insuranceItem, setInsuranceItem) => {
  setInsuranceItem({ ...insuranceItem, [field]: dateString });
};

export const handleReferralChange = (checked, insuranceItem, setInsuranceItem) => {
  setInsuranceItem({ ...insuranceItem, referral: checked });
};

export const handleAddInsurance = (form, setInsuranceItem, setInsuranceItems, insuranceItem, insuranceItems, setStartDate, setEndDate) => {
  const updatedInsuranceItems = [...insuranceItems, insuranceItem];
  setInsuranceItems(updatedInsuranceItems);
  setInsuranceItem({
    insuranceId: null,
    insuranceType: "",
    from: "",
    to: "",
    referral: false, // Ensuring checkbox is unchecked when adding a new entry
  });

  form.resetFields();
  setStartDate(null);
  setEndDate(null);
};

