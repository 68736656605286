import DefaultModule from "../../utils/defaultModule";

function defaultReport(props) {
  return (
    <DefaultModule moduleCode={props} />
    // <h1>reports content</h1>
  
  );
}


export default defaultReport;
