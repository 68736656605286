import axiosInterceptor from "../../middleware/axiosInterceptor";

export async function AppointmentType() {
  const url = "/api/appointmentTypes";

  try {
    const response = await axiosInterceptor.get(url);
    return response.data.appointmentTypes;
  } catch (error) {
    console.error("Error in AppointmentType service:", error);
    throw error;
  }
}


export const appointmentTypeByid = async (appointmentsTypeByid) => {
  const url = "/api/appointmentTypes" + appointmentsTypeByid;
  try {
    const response = await axiosInterceptor.get(url);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateAppointmentType = async (appointmentTypeData) => {
  const url = "api/appointmentTypes/update";
  try {
    const response = await axiosInterceptor.put(url, appointmentTypeData);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const saveAppointmentType = async (appointmentTypeData) => {
  const url = "/api/appointmentType/save";
  try {
    const response = await axiosInterceptor.post(url, appointmentTypeData);
    return response;
  } catch (error) {
    console.error(error);
  }
};
export async function deleteAppointmentType(deleteData) {
  const url = "/api/appointmentTypes/delete";
  try {
    const response = await axiosInterceptor.delete(url, { data: deleteData });
    return response;
  } catch (error) {
    console.error("Error deleting appointment type:", error.response?.data || error.message);
    throw new Error(error.response?.data?.message || error.message);
  }
}
