import { updateAppointmentType, saveAppointmentType, deleteAppointmentType } from '../../../services/appointmentTypeService';
import { Modal, message } from "antd";
import { MAPPING_CONST, MESSAGES } from '../../utilities/mappingData';

// export async function handleCloseDrawer(setDrawerVisible, form) {
//     setDrawerVisible(false);
//     form.resetFields();
// }


export const handleSubmit = async (values, setError, setDataSource, tableData, setDrawerVisible, form) => {
    setError("");
    const successMessage = MESSAGES.MESSAGE_APPTY_SAVE;
    const userId = sessionStorage.getItem('userId');
    try {

        let formData = {
            appointment_type_name: values.name,
            practice_id: MAPPING_CONST.APPTY_PRACTICE_ID,
            description: values.description,
            duration: values.duration,
            is_blocked: values.isBlocked ? 1 : 0,
            allow_make: values.allowMake ? 1 : 0,
            allow_confirm: values.allowConfirm ? 1 : 0,
            allow_cancel: values.allowCancel ? 1 : 0,
            allow_reschedule: values.allowReschedule ? 1 : 0,
            allow_48_hour: values.allow48Hour ? 1 : 0,
            created_by: userId,

        }
        const response = await saveAppointmentType(formData);

        if (response && response.data && response.data.success) {
            Modal.success({ title: 'Success', content: successMessage });
            handleCloseDrawer(setDrawerVisible, form);

            //Grid Refresh data
            let appointmentType = tableData;
            appointmentType.push(response.data.appointmentTypes[0]);
            setDataSource(appointmentType);
        }

        else {
            if (response && response.data && response.data.businessRules && response.data.businessRules.length > 0) {
                const firstError = response.data.businessRules[0].description;
                setError(firstError);
            } else {
                setError("Error Register");
            }
        }

    } catch (error) {
        throw error;
    }
};

function isAppointmentType(appointmentType, appointmentTypeId) {
    return appointmentType.appointmentTypeId === appointmentTypeId;
}

export const handleUpdate = async (values, appointmentTypeId, setError, setDataSource, tableData, setDrawerVisible, form) => {
    try {
        let formData = {
            appointment_typeid: appointmentTypeId,
            appointment_type_name: values.name,
            description: values.description,
            duration: values.duration,
            is_blocked: values.isBlocked ? 1 : 0,
            allow_make: values.allowMake ? 1 : 0,
            allow_confirm: values.allowConfirm ? 1 : 0,
            allow_cancel: values.allowCancel ? 1 : 0,
            allow_reschedule: values.allowReschedule ? 1 : 0,
            allow_48_hour: values.allow48Hour ? 1 : 0,
            updated_by: sessionStorage.getItem('userId'),
        }
        const response = await updateAppointmentType(formData);

        if (response && response.data && response.data.success) {
            Modal.success({ title: "Success", content: "Appointment Type Updated Successfully." });
            handleCloseDrawer(setDrawerVisible, form);
            if (tableData && Array.isArray(tableData)) {
                let arrayIndex = tableData.findIndex(item => isAppointmentType(item, appointmentTypeId));
                if (arrayIndex !== -1) {
                    let updatedItem = {
                        ...tableData[arrayIndex],
                        appointmentName: formData.appointment_type_name,
                        description: formData.description,
                        duration: formData.duration,
                        isBlocked: formData.is_blocked,
                        allowMake: formData.allow_make,
                        allowConfirm: formData.allow_confirm,
                        allowCancel: formData.allow_cancel,
                        allowReschedule: formData.allow_reschedule,
                        allow48Hour: formData.allow_48_hour,
                    };

                    let updatedDataSource = [...tableData];
                    updatedDataSource[arrayIndex] = updatedItem;
                    setDataSource(updatedDataSource);
                }
            }
        } else {
            if (response && response.data && response.data.businessRules && response.data.businessRules.length > 0) {
                const firstError = response.data.businessRules[0].description;
                setError(firstError);
            } else {
                setError("Error Register");
            }
        }
    } catch (error) {
        throw error;
    }
};


export async function handleModalConfirm(modalData, fetchAppointmentTypes, setModalData) {
    if (modalData.type === 'delete') {
        try {
            const deleteData = {
                appointmenttypeId: modalData.record.appointmentTypeId,
                updatedby: sessionStorage.getItem('userName')
            };

            if (!deleteData.appointmenttypeId) {
                throw new Error('Appointment Type id cannot be empty');
            }

            const response = await deleteAppointmentType(deleteData);

            if (response && response.data && response.data.success) {
                Modal.success({ title: "Success", content: "Appointment type deleted successfully." });
                fetchAppointmentTypes();
            } else {
                message.error("Failed to delete appointment type");
            }
        } catch (error) {
            message.error(error.message || "Failed to delete appointment type");
        }
        finally {
            setModalData({ ...modalData, visible: false });
        }
    }
}

export function handleCloseDrawer(setDrawerVisible, form) {
    // if (typeof setDrawerVisible === 'function') {
    setDrawerVisible(false);
    // } else {
    //     console.error('setDrawerVisible is not a function');
    // }
};