import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import {
    Layout,
    Form,
    Input,
    Button,
    Select,
    Row,
    Col,
    Space,
    Upload,
    message,
    Typography,
    Modal,
    Radio,
    Spin, Checkbox, DatePicker
} from "antd";
import { saveLicenseUploadForm, verifyDateOfBirth, verifyLicensePassword } from "../../services/ipadRegistartionService";
import axios from 'axios';
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";

const { Option } = Select;
const LicenseUpload = (props) => {
    const [form] = Form.useForm();
    const [manualRefForm] = Form.useForm();
    // const [dataLoaded, setDataLoaded] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const fileInputFrontRef = useRef(null);
    const backgroundDivFrontRef = useRef(null);
    const backgroundDivBackRef = useRef(null);
    const fileInputBackRef = useRef(null);
    const [errorMessage, setErrorMessage] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [frontInsuranceValue, setFrontInsuranceValue] = useState(null);
    const [backInsuranceValue, setBackInsuranceValue] = useState(null);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [backgroundBackImage, setBackgroundBackImage] = useState(null);
    const [modalManualPopup, setModalManualPopup] = useState(false);
    const [photoIdError, setPhotoIdError] = useState(false);
    const [photoIdValue, setPhotoIdValue] = useState('');
    const [formControls, setFormControls] = useState([]);
    const currentPageURL = window.location.href;
    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, []);

    // useEffect(() => {
    //     if (backgroundDivFrontRef.current && backgroundImage !== null) {
    //         const url = new URL(window.location.href.split('#')[0]);
    //         if (url.searchParams.has('prId')) {
    //             url.searchParams.delete('prId'); // Remove the 'prId' query parameter
    //         }
    //         const modifiedURL = url.toString();
    //         backgroundDivFrontRef.current.style.backgroundImage = `url(${modifiedURL}/storage/images?path=${backgroundImage})`;
    //         const frontLabel = document.querySelector('.front');
    //         if (frontLabel) {
    //             frontLabel.innerHTML = '';
    //         }
    //     }
    // }, [backgroundImage, photoIdValue]);
    // useEffect(() => {
    //     if (backgroundDivBackRef.current && backgroundBackImage !== null) {
    //         const url = new URL(window.location.href.split('#')[0]);
    //         if (url.searchParams.has('prId')) {
    //             url.searchParams.delete('prId');
    //         }
    //         const modifiedURL = url.toString();
    //         backgroundDivBackRef.current.style.backgroundImage = `url(${modifiedURL}/storage/images?path=${backgroundBackImage})`;
    //         const backLabel = document.querySelector('.back');
    //         if (backLabel) {
    //             backLabel.innerHTML = '';
    //         }
    //     }
    // }, [backgroundBackImage, photoIdValue]);

    // useEffect(() => {
    //     if (!(props.patientRegistrationInfo.length === 0)) {
    //         const licenseImageFront = props.patientRegistrationInfo.UploadedFilePath.licenseImageFront;
    //         const licenseImageBack = props.patientRegistrationInfo.UploadedFilePath.licenseImageBack;
    //         if (props.patientRegistrationInfo.patientRegistrationFormData?.photo_identity_type) {
    //             const photoIdentityType = props.patientRegistrationInfo.patientRegistrationFormData?.photo_identity_type;
    //             setPhotoIdValue(photoIdentityType);
    //         }

    //         if (licenseImageFront != null) {
    //             setBackgroundImage(licenseImageFront.path);
    //             setShowConfirmation(true);
    //         }
    //         if (licenseImageBack != null) {
    //             setBackgroundBackImage(licenseImageBack.path);
    //             setShowConfirmation(true);
    //         }
    //         setDataLoaded(true);
    //     }
    // }, [props.patientRegistrationInfo]);

    // useEffect(() => {
    //     if (!(props.formControls.length === 0)) {
    //         const formControlsForActivePage = props.formControls[props.activeIpadPage];
    //         const formFields = formControlsForActivePage.map(control => control.form_field);
    //         setFormControls(formFields);
    //         setDataLoaded(true);
    //     }
    // }, [props.formControls]);
    const handleDivFrontClick = () => {
        fileInputFrontRef.current.click();
    };
    const handleDivBackClick = () => {
        fileInputBackRef.current.click();
    };
    const handleLicenseFrontChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFrontInsuranceValue(e.target.files[0]);
            const imageUrl = URL.createObjectURL(selectedFile);
            backgroundDivFrontRef.current.style.backgroundImage = `url(${imageUrl})`;
            const frontLabel = document.querySelector('.front');
            if (frontLabel) {
                frontLabel.innerHTML = '';
            }
        }
    };
    const handleLicenseBackChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setBackInsuranceValue(e.target.files[0]);
            const imageUrl = URL.createObjectURL(selectedFile);
            backgroundDivBackRef.current.style.backgroundImage = `url(${imageUrl})`;
            const backLabel = document.querySelector('.back');
            if (backLabel) {
                backLabel.innerHTML = '';
            }
        }
    };
    const handleFormSubmit = async () => {
        if ((frontInsuranceValue && backInsuranceValue) || (backgroundImage && backgroundBackImage)) {
            setErrorMessage(false);
            setShowConfirmation(true);
        } else {
            setErrorMessage(true);
        }
    };
    const onMetroLicenseFormSubmit = async () => {
        let photoId = form.getFieldValue('photoId');
        if (photoId) {
            if (photoId != '' && photoId === 'NoPhotoId') {
                onMetroLicenseNextClick();
            }
            else {
                setPhotoIdError(false);
                if ((frontInsuranceValue && backInsuranceValue) || (backgroundImage && backgroundBackImage)) {
                    setErrorMessage(false);
                    setShowConfirmation(true);
                } else {
                    setErrorMessage(true);
                }
            }
        } else {
            setPhotoIdError(true);
        }
    };
    const ManualVerifyPopup = () => {
        setModalManualPopup(true);
    };
    const onLicenseNextClick = async () => {
        setIsSubmitting(true);
        try {
            if (frontInsuranceValue || backInsuranceValue || (backgroundImage && backgroundBackImage)) {
                setErrorMessage(false);
                const formDatas = new FormData();
                if (frontInsuranceValue) {
                    formDatas.append('licenseImageFrontSide', frontInsuranceValue);
                }
                if (backInsuranceValue) {
                    formDatas.append('licenseImageBackSide', backInsuranceValue);
                }
                formDatas.append('uniqueHash', props.uniqueHash);
                const url = new URL(currentPageURL);
                if (url.searchParams.has('prId')) {
                    formDatas.append('prId', true);
                }
                try {
                    let response = await saveLicenseUploadForm(formDatas);
                    if (response.message === 'Success') {
                        props.handleNextClick(props);
                    }
                    // else{
                    //     const url = new URL(currentPageURL);
                    //     if (url.searchParams.has('prId')) {
                    //         Modal.info({
                    //             content: 'We were unable to process your License information. To ensure a smooth visit, please bring your License card to the front desk during your appointment.',
                    //         });
                    //         props.handleNextClick(props);
                    //     }
                    // }
                } catch (error) {
                    console.error('File upload failed', error);
                }
            } else {
                setErrorMessage(true);
            }
        } finally {
            if (!isSubmitting) {
                return (
                    <div className="spinner-overlay">
                        <div className="spinner">
                            <Spin size="large" tip="Loading..." />
                        </div>
                    </div>
                );
            }
            setIsSubmitting(false);
        }
    };
    const onBackUploadPageClick = () => {
        setShowConfirmation(false);
    };
    const onCancelManualVerify = () => {
        setModalManualPopup(true);
    };
    const handleManualVerifySubmit = async (values) => {
        const response = await manualRefForm.validateFields();
        if (response) {
            if (response.message === 'Success') {
                // setModalManualPopup(false);
                props.handleNextClick(props);
            } else {
                Modal.error({
                    title: 'This is an error message',
                    content: 'Invalid Pin',
                });
            }
        }
    };
    // if (!dataLoaded) {
    //     return (
    //         <div className="spinner-overlay">
    //             <div className="spinner">
    //                 <Spin size="large" tip="Loading..." />
    //             </div>
    //         </div>
    //     );
    // }
    const handlePhotoTypeChange = (value) => {
        if (value !== '' && value !== undefined) {
            setPhotoIdError(false);
            setPhotoIdValue(value);
        } else {
            setPhotoIdError(true);
            setPhotoIdValue('');
        }
    };
    const onMetroLicenseNextClick = async () => {
        try {
            if (photoIdValue === '') {
                setPhotoIdError(true);
            } else {
                setPhotoIdError(false);
                if (photoIdValue != '' && photoIdValue != 'NoPhotoId') {
                    if (frontInsuranceValue && backInsuranceValue || (backgroundImage && backgroundBackImage)) {
                        setIsSubmitting(true);
                        setErrorMessage(false);
                        const formDatas = new FormData();
                        formDatas.append('Photo_Identity_Type', photoIdValue);
                        if (frontInsuranceValue) {
                            formDatas.append('licenseImageFrontSide', frontInsuranceValue);
                        }
                        if (backInsuranceValue) {
                            formDatas.append('licenseImageBackSide', backInsuranceValue);
                        }
                        formDatas.append('uniqueHash', props.uniqueHash);
                        const url = new URL(currentPageURL);
                        if (url.searchParams.has('prId')) {
                            formDatas.append('prId', true);
                        }
                        try {
                            let response = await saveLicenseUploadForm(formDatas);
                            if (response.message === 'Success') {
                                props.handleNextClick(props);
                            } else {
                                const url = new URL(currentPageURL);
                                if (url.searchParams.has('prId')) {
                                    Modal.info({
                                        content: 'We were unable to process your License information. To ensure a smooth visit, please bring your License card to the front desk during your appointment.',
                                    });
                                    props.handleNextClick(props);
                                }
                            }
                        } catch (error) {
                            console.error('File upload failed', error);
                        }
                    } else {
                        setErrorMessage(true);
                    }
                } else {
                    setIsSubmitting(true);
                    const formDatas = new FormData();
                    formDatas.append('uniqueHash', props.uniqueHash);
                    formDatas.append('Photo_Identity_Type', photoIdValue);
                    const url = new URL(currentPageURL);
                    if (url.searchParams.has('prId')) {
                        formDatas.append('prId', true);
                    }
                    const response = await saveLicenseUploadForm(formDatas);

                    if (response.message === 'Success') {
                        props.handleNextClick(props);
                    } else {
                        const url = new URL(currentPageURL);
                        if (url.searchParams.has('prId')) {
                            Modal.info({
                                content: 'We were unable to process your License information.'
                            });
                            props.handleNextClick(props);
                        }
                    }
                }
            }
        } finally {
            if (!isSubmitting) {
                return (
                    <div className="spinner-overlay">
                        <div className="spinner">
                            <Spin size="large" tip="Loading..." />
                        </div>
                    </div>
                );
            }
            setIsSubmitting(false);
        }
    };
    return (
        <div>
            <Form form={form} layout="vertical" size="large"
                initialValues={{
                    photoId: photoIdValue
                }}>
                {showConfirmation ? (
                    <h6>{formControls.includes('Metro Photo Identity') ? `Do You See Your Photo Identity ?` : `Do You See Your Driving License ?`}</h6>
                ) : (
                    <div>
                        <h2>Photo identity</h2>
                        <label style={{ fontSize: 16 }}>{formControls.includes('MetroPracticeName') ? 'METRO' : 'DDC'} now
                            requires the following information.
                            {formControls.includes('License Picture') ? `Please take a picture of your Driver's License here (Camera is at the back of your phone or iPad).` : ''}</label>
                        <br></br>
                        {errorMessage && <Typography.Title level={3} type="danger">Both image fields are
                            required.</Typography.Title>}
                    </div>
                )}
                <div>
                    {!showConfirmation && formControls.includes('Dropdown') ?
                        <Form.Item style={{ marginTop: 20 }} name="photoId" label="Choose the type of Photo ID"
                            validateStatus={photoIdError ? "error" : "success"}
                            help={photoIdError ? "Please select Photo ID" : null}>
                            <Select onChange={(value) => handlePhotoTypeChange(value)} value={photoIdValue}
                                style={{ width: '100%', maxWidth: 380 }}>
                                <Option key='0' value=''></Option>
                                <Option key='1' value='DriverLicense'>Driver's License</Option>
                                <Option key='2' value='Other'>Other</Option>
                                <Option key='3' value='NoPhotoId'>No Photo ID</Option>
                            </Select>
                        </Form.Item>
                        : null}
                    {photoIdValue !== 'NoPhotoId' ?
                        <Form.Item>
                            <Form.Item style={{ marginTop: 20 }}>
                                <div id="testingdivImage" style={{ backgroundImage }}></div>
                                <div id="licenseFrontSide" ref={backgroundDivFrontRef}
                                    className="licenseFrontImageDiv"
                                    onClick={!showConfirmation ? handleDivFrontClick : null}>
                                    <label className="text-secondary front">Front side</label>
                                </div>
                                <input type="file" id="licenseFrontFile" name="licenseImageFrontSide"
                                    style={{ display: 'none' }}
                                    accept="image/*"
                                    onChange={handleLicenseFrontChange} ref={fileInputFrontRef} />
                                {!showConfirmation ? <button className="btn btn-primary" id="takePhoto" style={{
                                    width: ' 100%',
                                    maxWidth: '380px',
                                    borderRadius: '7px',
                                    marginTop: '10px'
                                }}
                                    onClick={handleDivFrontClick}>Take photo</button>
                                    : null}
                            </Form.Item>
                            <Form.Item style={{ marginTop: 20 }}>
                                <div id="licenseFrontSide" ref={backgroundDivBackRef} className="licenseBackImageDiv"
                                    onClick={!showConfirmation ? handleDivBackClick : null}>
                                    <label className="text-secondary back">Back side</label>
                                </div>
                                <input type="file" id="licenseBackFile" name="licenseImageBackSide"
                                    style={{ display: 'none' }}
                                    accept="image/*"
                                    onChange={handleLicenseBackChange} ref={fileInputBackRef} />
                                {!showConfirmation ?
                                    <button className="btn btn-primary" id="takeLicensePhotoBack" style={{
                                        width: ' 100%',
                                        maxWidth: '380px',
                                        borderRadius: '7px',
                                        marginTop: '10px'
                                    }}
                                        onClick={handleDivBackClick}>Take photo</button>
                                    : null}
                            </Form.Item>
                        </Form.Item> : null}
                </div>
            </Form>
            {!showConfirmation ? (
                <Form.Item className="form-footer-button">
                    {isSubmitting ? (
                        <div className="spinner-overlay">
                            <div className="spinner">
                                <Spin size="large" tip="Loading..." />
                            </div>
                        </div>
                    ) : (
                        <Row gutter={12}>
                            <Col>
                                {/*<Button type="primary" onClick={onContactInfoPreviousClick}>Previous</Button>*/}
                            </Col>
                            <Col>
                                <Button type="primary" htmlType="submit"
                                    onClick={formControls.includes('MetroPracticeName') ? onMetroLicenseFormSubmit : handleFormSubmit}>Next</Button>
                            </Col>
                        </Row>
                    )}
                </Form.Item>
            ) : (
                <div className="form-footer-button">
                    {isSubmitting ? (
                        <div className="spinner-overlay">
                            <div className="spinner">
                                <Spin size="large" tip="Loading..." />
                            </div>
                        </div>
                    ) : (
                        <Radio.Group value="InsuranceConfirmationButton">
                            <Space>
                                <Radio.Button value="yes" className="btn btn-success"
                                    onClick={formControls.includes('MetroPracticeName') ? onMetroLicenseNextClick : onLicenseNextClick}>Yes</Radio.Button>
                                <Radio.Button value="no" className="btn btn-secondary"
                                    onClick={onBackUploadPageClick}>No</Radio.Button>
                            </Space>
                        </Radio.Group>
                    )}
                </div>
            )}
            <Modal className="manual-validate-modal"
                open={modalManualPopup}
                onOk={() => {
                    if (!manualRefForm.getFieldValue('manualCheck') && !manualRefForm.getFieldValue('pin')) {
                        manualRefForm.setFields([
                            { name: 'manualCheck', errors: ['Please check the checkbox.'] },
                            { name: 'pin', errors: ['This field is required.'] },
                        ]);
                    } else if (!manualRefForm.getFieldValue('pin')) {
                        manualRefForm.setFields([
                            {
                                name: 'pin',
                                errors: ['This field is required.'],
                            },
                        ]);
                    } else if (!manualRefForm.getFieldValue('manualCheck')) {
                        manualRefForm.setFields([{ name: 'manualCheck', errors: ['Please check the checkbox.'] },]);
                    } else {
                        manualRefForm.submit();
                    }
                }}
                maskClosable={false}
                cancelText={null}
                onCancel={onCancelManualVerify} width={500}
            >
                <Form form={manualRefForm} layout="vertical" onFinish={handleManualVerifySubmit}>
                    <Form.Item label="Enter your pin" name="pin"
                        rules={[{ required: true, message: 'This field is required.' }]}>
                        <Input.Password placeholder="Enter your pin" />
                    </Form.Item>
                    <Form.Item name="manualCheck" valuePropName="checked"
                        rules={[{ required: true, message: 'Please check the checkbox.' }]}>
                        <Checkbox>Manually Verified</Checkbox>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default LicenseUpload;
