import React, {useState, useEffect, useRef} from 'react';
import {getPdfFiles} from "../../services/ipadRegistartionService";

const FinalPage = (props) => {
    const [documents, setDocuments] = useState([]);
    const [modifiedUrl, setModifiedUrl] = useState('');
    const currentPageURL = window.location.href;

    // useEffect(() => {
    //     const url = new URL(currentPageURL);
    //     if (url.searchParams.has('prId')) {
    //         // url.searchParams.delete('prId');
    //         // setModifiedUrl(url.toString());
    //     }
    //     else{
    //         setTimeout(() => {
    //             window.close();
    //         }, 3000);
    //     }
    // }, []);

    // useEffect(() => {
    //     const url = new URL(window.location.href.split('#')[0]);
    //     if (url.searchParams.has('prId')) {
    //         url.searchParams.delete('prId'); // Remove the 'prId' query parameter
    //     }
    //     setModifiedUrl(url.toString());
    //     getPdfImages();
    // }, [props]);
    // const getPdfImages = async () => {
    //     let response = await getPdfFiles(props.uniqueHash);
    //     setDocuments(response.documents);
    // }

    return (
        <div id="page7">
            <p className="text-muted font-weight-bold mt-5">Thank you. We have received your information.</p>
            <p className="text-muted mt-5">YOU CAN NOW CLOSE THIS TAB</p>
            <div className="dropdown">
                <button className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
                    Download Pdf
                    <span className="caret"></span>
                </button>
                <ul className="dropdown-menu">
                    {documents.Profile && documents.Profile.length > 0 ? (
                        <li className="p-3" key="1">
                            <a href={modifiedUrl + '/storage/images?path=saved_registration_forms/' + documents.Profile[0]} target="_blank"
                               id="downloadPdf1">
                                Profile
                            </a>
                        </li>) : null}
                    {documents.Insurance && documents.Insurance.length > 0 ? (
                        <li className="p-3" key="2">
                            <a href={modifiedUrl + '/storage/images?path=saved_registration_forms/' + documents.Insurance[0]} target="_blank"
                               id="downloadPdf2">
                                Insurance
                            </a>
                        </li>) : null}
                    {documents.License && documents.License.length > 0 ? (
                        <li className="p-3" key="3">
                            <a href={modifiedUrl + '/storage/images?path=saved_registration_forms/' + documents.License[0]} target="_blank"
                               id="downloadPdf3">
                                License
                            </a>
                        </li>) : null}
                    {documents.Consent && documents.Consent.length > 0 ? (
                        <li className="p-3" key="3">
                            <a href={modifiedUrl + '/storage/images?path=saved_registration_forms/' + documents.Consent[0]} target="_blank"
                               id="downloadPdf3">
                                Consent
                            </a>
                        </li>) : null}
                </ul>
            </div>
        </div>
    );
}

export default FinalPage;

