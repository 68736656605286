
export const conversationColumns = [
    {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Type (Text/Voice)',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'Bot',
        dataIndex: 'bot',
        key: 'bot',
    },
    {
        title: 'Initiated Time',
        dataIndex: 'initiatedTime',
        key: 'initiatedTime',
    },
];
