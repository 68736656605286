
export const escalationColumns = [
    {
        title: 'Source',
        dataIndex: 'source',
        key: 'source',
    },
    {
        title: 'Message',
        dataIndex: 'message',
        key: 'message',
    },
    {
        title: 'Created Time',
        dataIndex: 'createdTime',
        key: 'createdTime',
    },
];
