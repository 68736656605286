import changePasswordService from '../../../services/changePasswordService';
import { message } from 'antd';

export const onSubmit = async (values, tempUserId, form, navigate,setModalVisible) => {
    try {
        const formData = {
            userId: tempUserId,
            password: values.password,
            oldpassword: values.oldpassword
        };
        const response = await changePasswordService(formData);
        if (response && response.data && response.data.success) {
            message.success("Submitted successfully");
            navigate("/dashboard");
            setModalVisible(false);
            form.resetFields();
        } else {
            if (response && response.data && response.data.businessRules && response.data.businessRules.length > 0) {
                const firstError = response.data.businessRules[0].description;
                // setError(firstError); // Make sure setError is defined or passed as a parameter
                message.error("Old Password does not match");

            } else {
                // setError("Update failed"); // Make sure setError is defined or passed as a parameter
                message.error("Old Password does not match")
            }
        }
    } catch (error) {
        message.error("Error: Failed to submit");
    }
};
