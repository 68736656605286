import axiosInterceptor from "../../middleware/axiosInterceptor";

export async function fetchIpadRegistrationData() {
    const url = "/api/patient/register/initialData";
    try {
        const response = await axiosInterceptor.get(url);
        return response.data.initialData;
    }
    catch (error) {
        console.error(error);
    }
};


export async function registrationComponent(formType) {
    const url = "/api/patient/registration/" + formType;
    let response = null;

    try {
        response = await axiosInterceptor.post(url, formType);
    }
    catch (error) {
        console.error(error);
    }

    return response;
};



