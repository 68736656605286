import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Space, Col, Row, DatePicker, Checkbox, Typography, Modal, Select } from "antd";
import moment from "moment/moment";
import { saveAllergies, saveConsentForms, saveConsent7Form, verifyConsentsPassword } from "../../services/ipadRegistartionService";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ConsentsForm1 from "./consentsForm/consentsForm1";
import ConsentsForm2 from "./consentsForm/consentsForm2";
import ConsentsForm3 from "./consentsForm/consentsForm2";
import ConsentsForm4 from "./consentsForm/consentsForm4";
import ConsentsForm5 from "./consentsForm/consentsForm5";
import ConsentsForm6 from "./consentsForm/consentsForm6";

const { Text } = Typography;

const Consents = (props) => {
    const [manualRefConsentForm] = Form.useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [form] = Form.useForm();
    const [healthRefForm] = Form.useForm();
    const [touched, setTouched] = useState(false);
    const [modalManualPopup, setModalManualPopup] = useState(false);
    const [isModalConsentOpen, setIsModalConsentOpen] = useState(false);
    const [consentFormIframeUrl, setConsentFormIframeUrl] = useState('');
    const currentPageURL = window.location.href;
    const [consentFormComponent, setConsentFormComponent] = useState(null);

    const consent1 = 'pr/c1';
    const consent2 = 'pr/c2';
    const consent3 = 'pr/c3';
    const consent4 = 'pr/c4';
    const consent5 = 'pr/c5';
    const consent6 = 'pr/c6';

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const validateCheckbox = (rule, value) => {
        if (value) {
            return Promise.resolve();
        }
        return Promise.reject(rule.message);
    };
    const uniqueHash = props.uniqueHash;
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const onConsentsPreviousClick = () => {
        props.handlePreviousClick(props);
    }

    const handleHealthInfoSubmit = async (values) => {
        try {
            setIsSubmitting(true);
            const modalFormValues = await healthRefForm.validateFields();
            if (modalFormValues) {
                const formattedRepSignDate = moment(values.repSignDate).format('YYYY-MM-DD');
                const updatedValues = { ...values, repSignDate: formattedRepSignDate };
                let response = await saveConsent7Form(uniqueHash, updatedValues);
                if (response.message === 'Success') {
                    setIsModalOpen(false);
                } else {
                    Modal.error({
                        title: 'This is an error message',
                        content: 'Error occured',
                    });
                }
            }
        } finally {
            if (!isSubmitting) {
                return (
                    <div className="spinner-overlay">
                        <div className="spinner">
                            {/* <Spin size="large" tip="Loading..." /> */}
                        </div>
                    </div>
                );
            }
            setIsSubmitting(false);
        }
    }

    const resetCheckbox = () => {
        form.setFieldsValue({ consent_7_signed_at: false });
    };

    const handleCancel = () => {
        resetModalState();
        setIsModalOpen(false);
        resetCheckbox();
    };

    const resetModalState = () => {
        form.setFieldsValue({
            consent7: false,
        });
        healthRefForm.resetFields();
    };

    const handleConsent7Click = () => {
        form.setFieldsValue({ consent_7_signed_at: true });
    };

    const ManualVerifyPopup = () => {
        setModalManualPopup(true);
    };
    const onCancelManualVerify = () => {
        setModalManualPopup(true);
    };

    const openModalWithUrl = (Component) => {
        setConsentFormComponent(<Component />);
        setIsModalConsentOpen(true);
    };

    const closeConsentModal = () => {
        setIsModalConsentOpen(false);
        setConsentFormComponent(null);
    };

    return (
        <div>
            <Form form={form} layout="vertical">
                <h3>Consents</h3>
                <Space>
                    <p className="text-muted">Accept each consent by clicking on each box</p>
                </Space>
                <Form.Item
                    name="consent_1_signed_at"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: validateCheckbox,
                            message: 'Consent form for use or disclosure of health information is required.'
                        }
                    ]}
                >
                    <Checkbox className="custom-checkbox">
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            openModalWithUrl(ConsentsForm1);
                        }} className={`custom-checkbox ${touched && !form.getFieldValue('consent1') ? 'error' : ''}`}>
                            View consent form for use or disclosure of health information:
                        </a>
                        <br />
                        <Text className="text-muted">
                            I understand that I am giving Digestive Disease Care my consent
                            to use and disclose my healthcare
                            information to carry out treatment, payment, and healthcare operations.
                        </Text>
                    </Checkbox>
                </Form.Item>
                <Form.Item
                    name="consent_2_signed_at"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: validateCheckbox,
                            message: 'Consent form for email release is required.'
                        }
                    ]}
                >
                    <Checkbox className="custom-checkbox">
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            openModalWithUrl(ConsentsForm2);
                        }} className="hover-underline">
                            View consent form for email release:
                        </a>
                        <br />
                        <Text className="text-muted">
                            I understand that I am giving Digestive Disease Care my consent
                            to communicate via email on matters related to my health and/or my medical treatment.
                        </Text>
                    </Checkbox>
                </Form.Item>
                <Form.Item
                    name="consent_3_signed_at"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: validateCheckbox,
                            message: 'Consent form for authorization for designated representative is required.'
                        }
                    ]}
                >
                    <Checkbox className="custom-checkbox">
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            openModalWithUrl(ConsentsForm3);
                        }} className="hover-underline">View consent form for
                            authorization for designated representative:</a>
                        <br />
                        <Text className="text-muted">I hereby authorize Digestive Disease Care, or their agent to
                            dispute
                            any unpaid claim, file appeals, and initiate a complaint for any reason with my health
                            insurance
                            carrier on my behalf until all disputes are resolved.
                        </Text>
                    </Checkbox>
                </Form.Item>
                <Form.Item
                    name="consent_4_signed_at"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: validateCheckbox,
                            message: 'Consent form for assignment of benefits is required.'
                        }
                    ]}
                >
                    <Checkbox className="custom-checkbox">
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            openModalWithUrl(ConsentsForm4);
                        }} className="hover-underline">View consent form for
                            assignment of benefits:</a>
                        <br />
                        <Text className="text-muted">I hereby assign my insurance benefits to be paid directly to
                            Digestive
                            Disease Care for services rendered, by check made out to Digestive Disease Care. I
                            authorize
                            Digestive Disease Care to deposit checks in my name.
                        </Text>
                    </Checkbox>
                </Form.Item>
                <Form.Item name="consent_5_signed_at" valuePropName="checked" rules={[{
                    validator: validateCheckbox,
                    message: 'consent form for No Show/ Referral/ Deductible Policy/ Prepayment policy is required.'
                }]}>
                    <Checkbox className="custom-checkbox">
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            openModalWithUrl(ConsentsForm5);
                        }} className="hover-underline">View consent form for No
                            Show/
                            Referral/ Deductible Policy/ Prepayment policy:</a>
                        <br />
                        <Text className="text-muted">I have read and understood the Medical Appointment No Show/
                            Referral/
                            Prepayment policy and agree to its terms.
                        </Text>
                    </Checkbox>
                </Form.Item>
                <Form.Item name="consent_6_signed_at" valuePropName="checked" rules={[{
                    validator: validateCheckbox,
                    message: 'consent form for Assignment of Authorized Representative is required.'
                }]}>
                    <Checkbox className="custom-checkbox">
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            openModalWithUrl(ConsentsForm6);
                        }} className="hover-underline">View consent form for
                            Assignment of
                            Authorized Representative:</a>
                        <br />
                        <Text className="text-muted">I hereby appoint Perfect Patient Solutions as my representative
                            with
                            the power to file medical claims, appeals, and grievances with the health plan on my
                            behalf.
                        </Text>
                    </Checkbox>
                </Form.Item>
                <Form.Item name="consent_7_signed_at" valuePropName="checked" rules={[{
                    validator: validateCheckbox,
                    message: 'consent form for Assignment to Disclose Health Information to Family Members and Friends is ' +
                        'required.'
                }]} onClick={showModal}>
                    <Checkbox className="custom-checkbox">
                        <a href="#" onClick={handleConsent7Click} className="hover-underline">View consent form for
                            Assignment to Disclose Health Information to Family Members and Friends</a>
                        <br />
                        <Text className="text-muted">I hereby authorize Digestive Disease Care(DDC) to release my
                            patient health information as described in the form.
                        </Text>
                    </Checkbox>
                </Form.Item>
                <Space>
                    <p className="text-muted font-weight-bold mt-3">I confirm that I have read and agreed to the
                        checked consent forms above.</p>
                </Space>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item label="Signature"
                            name="signature"
                            rules={[{
                                required: true,
                                message: 'Patient or Caregiver full name is required.'
                            },]}>

                            <Input placeholder="First Name" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Date"
                            name="signed_date"
                            rules={[{ required: true, message: 'This field is required.' }]}
                        >
                            <DatePicker format="MM/DD/YYYY" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item className="form-footer-button">
                    <Space>
                        <Button type="primary" size="large" onClick={onConsentsPreviousClick}>Previous</Button>
                        <Button type="primary" size="large" htmlType="submit">Submit</Button>
                    </Space>
                </Form.Item>
            </Form>

            <div className="spinner-overlay">
                <div className="spinner">
                    {/* <Spin size="large" tip="Loading..." /> */}
                </div>
            </div>
            {/* )} */}
            <Modal title={<div style={{ textAlign: 'center' }}>
                AUTHORIZATION TO DISCLOSE HEALTH INFORMATION TO FAMILY
                MEMBERS AND FRIENDS</div>}
                open={isModalOpen}
                style={{ top: 20 }}
                onOk={healthRefForm.submit}
                maskClosable={false}
                onCancel={handleCancel} width={1300}
            >
                <p>PROTECTED HEALTH INFORMATION (“PHI”) MAY INCLUDE INFORMATION/DOCUMENTS REGARDING MEDICAL TREATMENT OF
                    THE PATIENT INCLUDING, BUT NOT LIMITED TO, DIAGNOSIS, PROCEDURES, TREATMENT PLANS, APPOINTMENTS AND
                    TEST RESULTS; ACCOUNT AND BILLING INFORMATION INCLUDING, BUT NOT LIMITED TO, ACCOUNT BALANCES,
                    PAYMENTS AND PAYMENT ARRANGEMENTS, INSURANCE CLAIM STATUS, AND THIRD-PARTY FINANCING
                </p>

                <p>
                    I UNDERSTAND
                    THAT THE HEALTH INSURANCE PORTABILITY ACCOUNTABILITY ACT OF 1996, AND ITS IMPLEMENTING REGULATIONS
                    (“HIPAA”) GOVERN THE TERMS OF THIS AUTHORIZATION. I UNDERSTAND THAT I HAVE THE RIGHT TO REVOKE THIS
                    AUTHORIZATION, AT ANY TIME PRIOR TO THE PRACTICE'S COMPLIANCE WITH THE REQUEST SET FORTH HEREIN,
                    PROVIDED THAT THE REVOCATION IS IN WRITING. I FURTHER UNDERSTAND THAT ADDITIONAL INFORMATION IS SET
                    FORTH ON DDC'S NOTICE OF PRIVACY PRACTICES. I UNDERSTAND THAT ANY REVOCATION MUST INCLUDE BY NAME,
                    ADDRESS, TELEPHONE NUMBER, DATE OF THIS AUTHORIZATION AND MY SIGNATURE; AND THAT I SHOULD SEND IT TO
                    THE ATTENTION OF THE “HIPAA COMPLIANCE OFFICER"
                </p>
                <p>
                    I UNDERSTAND THAT I AM NOT REQUIRED TO SIGN THIS
                    AUTHORIZATION AND THAT DDC MAY NOT CONDITION TREATMENT ON MY EXECUTION OF THIS AUTHORIZATION.
                </p>
                <p> I UNDERSTAND THAT THE INFORMATION USED OR DISCLOSED PURSUANT TO THIS AUTHORIZATION MAY BE SUBJECT TO
                    RE-DISCLOSURE BY THE RECIPIENT LISTED ABOVE AND, IN THAT CASE, WILL NO LONGER BE PROTECTED BY HIPAA.
                </p>
                <p>
                    THIS AUTHORIZATION EXPIRES WHEN I AM NO LONGER A PATIENT OR HAVE REVOKED THIS AUTHORIZATION.
                </p>
                {/* {dataLoaded ? ( */}
                <Form form={healthRefForm} layout="vertical" onFinish={handleHealthInfoSubmit}
                // initialValues={{
                //     repSign: props.patientRegistrationInfo.patientRegistrationFormData?.HealthDiscloseConsent?.repSign,
                //     repRelationship: props.patientRegistrationInfo.patientRegistrationFormData?.HealthDiscloseConsent?.repRelationship, // Set to the actual selected value
                //     repSignDate:
                //         props.patientRegistrationInfo.patientRegistrationFormData?.HealthDiscloseConsent?.repSignDate
                //             ? moment(props.patientRegistrationInfo.patientRegistrationFormData?.HealthDiscloseConsent?.repSignDate, 'YYYY-MM-DD')
                //             : moment(), // Set to current date if null or empty string
                // }}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item label="SIGNATURE OF PATIENT OF PERSONAL REPRESENTATIVE(I.E. GUARDIAN)"
                                name="repSign"
                                rules={[{ required: true, message: 'This field is required.' }]}>
                                <Input placeholder="Enter your full name to sign" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="RELATIONSHIP OF PERSONAL REPRESENTATIVE TO PATIENT"
                                name="repRelationship" rules={[{
                                    required: true,
                                    message: 'This Field is required.'
                                }]}>
                                <Select
                                    showSearch
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={[
                                        {
                                            value: 'Self',
                                            label: 'Self',
                                        },
                                        {
                                            value: 'Spouse',
                                            label: 'Spouse',
                                        },
                                        {
                                            value: 'Mother',
                                            label: 'Mother',
                                        },
                                        {
                                            value: 'Father',
                                            label: 'Father',
                                        },
                                        {
                                            value: 'Daughter',
                                            label: 'Daughter',
                                        },
                                        {
                                            value: 'Son',
                                            label: 'Son',
                                        },
                                        {
                                            value: 'GrandMother',
                                            label: 'GrandMother',
                                        },
                                        {
                                            value: 'GrandFather',
                                            label: 'GrandFather',
                                        },
                                        {
                                            value: 'Other',
                                            label: 'Other',
                                        }
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Date"
                                name="repSignDate"
                                rules={[{ required: true, message: 'This field is required.' }]}
                            >
                                <DatePicker format="MM/DD/YYYY" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {/* ) : ( */}
                {/* <div className="spinner-overlay">
                        <div className="spinner">
                            <Spin size="large" tip="Loading..." />
                        </div>
                    </div> */}
                {/* // )} */}
            </Modal>
            <Modal className="manual-validate-modal"
                open={modalManualPopup}
                onOk={() => {
                    if (!manualRefConsentForm.getFieldValue('manualCheck') && !manualRefConsentForm.getFieldValue('pin')) {
                        manualRefConsentForm.setFields([
                            { name: 'manualCheck', errors: ['Please check the checkbox.'] },
                            { name: 'pin', errors: ['This field is required.'] },
                        ]);
                    } else if (!manualRefConsentForm.getFieldValue('pin')) {
                        manualRefConsentForm.setFields([
                            {
                                name: 'pin',
                                errors: ['This field is required.'],
                            },
                        ]);
                    } else if (!manualRefConsentForm.getFieldValue('manualCheck')) {
                        manualRefConsentForm.setFields([{
                            name: 'manualCheck',
                            errors: ['Please check the checkbox.']
                        },]);
                    } else {
                        manualRefConsentForm.submit();
                    }
                }}
                maskClosable={false}
                cancelText={null}
                onCancel={onCancelManualVerify} width={500}
            >
                <Form form={manualRefConsentForm} layout="vertical"
                //  onFinish={handleManualVerifySubmit}
                >
                    <Form.Item label="Enter your pin" name="pin"
                        rules={[{ required: true, message: 'This field is required.' }]}>
                        <Input.Password placeholder="Enter your pin" />
                    </Form.Item>
                    <Form.Item name="manualCheck" valuePropName="checked"
                        rules={[{ required: true, message: 'Please check the checkbox.' }]}>
                        <Checkbox>Manually Verified</Checkbox>
                    </Form.Item>
                </Form>
            </Modal>
            {/* <Modal open={isModalConsentOpen} style={{ top: 20 }} width={1300}
                    onCancel={closeConsentModal} footer={null}>
                    <iframe
                        title="Modal Content"
                        src={consentFormIframeUrl}
                        style={{ width: '100%', height: '650px', border: 'none' }}
                    />
                </Modal> */}
            <Modal
                open={isModalConsentOpen}
                style={{ top: 20 }}
                width={1500}
                onCancel={closeConsentModal}
                footer={null}
            >
                {consentFormComponent}
            </Modal>
        </div>
    );
};


export default Consents;
