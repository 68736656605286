import React, { useState, useEffect } from 'react';
import { Select, Input, Button, Table, Space, Tooltip, Modal, message } from 'antd';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { getAppointmentTypes, saveConfigurations, getLocation } from '../../services/configurationService';
import { validateNumber } from '../utilities/utility'; // Import validateNumber function

const { Option } = Select;
const { confirm } = Modal;

const AppointmentType = (props) => {
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [duration, setDuration] = useState('');
  const [defaultValue] = useState(15); 
  const [tableData, setTableData] = useState([]);
  const [editRecord, setEditRecord] = useState(null);
  const [jsonData, setJsonData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedLocationvalue, setSelectedLocationValue] = useState('');

  useEffect(() => {
    fetchAppointmentTypes();
    fetchLocation();
    if (JSON.parse(props.onconfigData[0].value) != null) {
      setTableData(JSON.parse(props.onconfigData[0].value));
    }
  }, [props]);

  const fetchAppointmentTypes = async () => {
    try {
      const data = await getAppointmentTypes();
      if (Array.isArray(data.appointmentType)) {
        setAppointmentTypes(data.appointmentType);
      } else {
        console.error('Appointment types data is not an array:', data);
      }
    } catch (error) {
      console.error('Error fetching appointment types:', error);
    }
  };

  const handleTypeSelect = (value) => {
    setSelectedType(value);
    const selectedAppointment = appointmentTypes.find(appointment => appointment.description === value);
    setDuration(selectedAppointment ? selectedAppointment.duration : '');
  };

  const handleAddAppointment = () => {
    if (selectedType && duration) {
      const selectedAppointment = appointmentTypes.find(appointment => appointment.description === selectedType);
      if (selectedAppointment) {
        const newAppointment = {
          appointmentid: selectedAppointment.appointmentid,
          practiceid: selectedAppointment.practiceid,
          description: selectedType,
          duration: duration,
          locationid: selectedLocationvalue,
          Location: selectedLocation
        };

        if (editRecord) {
          const updatedTableData = tableData.map(item => {
            if (item.appointmentid === editRecord.appointmentid) {
              return newAppointment;
            }
            return item;
          });
          setTableData(updatedTableData);
          setEditRecord(null);
        } else {
          setTableData([...tableData, newAppointment]);
        }

        setSelectedType('');
        setDuration('');
        setSelectedLocation('');
        setSelectedLocationValue('');

      } else {
        console.error('Selected appointment type not found');
      }
    } else {
      console.error('Please select appointment type and duration');
    }
  };
  
  const handleEditClick = (record) => {
    setEditRecord(record);
    setSelectedType(record.description);
    setDuration(record.duration);
    setSelectedLocation(record.Location);
    setSelectedLocationValue(record.locationid);
  };

  const handleDeleteClick = (record) => {
    confirm({
      title: 'Are you sure you want to delete this appointment?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        const updatedTableData = tableData.filter(item => item.appointmentid !== record.appointmentid);
        setTableData(updatedTableData);
      },
      onCancel() {
      },
    });
  };

  const handleLocationSelect = (value, option) => {
    setSelectedLocation(option.children);
    setSelectedLocationValue(value);
  };

  const columns = [
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space>
          <Tooltip title="Edit">
            <Button type="primary" id="providerEdit" className="gridIconEdit" icon={<EditOutlined />} onClick={() => handleEditClick(record)} />
          </Tooltip>
          <Tooltip title="Delete">
            <Button type="primary" className="gridIconDelete" danger icon={<DeleteOutlined />} onClick={() => handleDeleteClick(record)} />
          </Tooltip>
        </Space>
      ),
      width: 100,
    },
    {
      title: 'Appointment ID',
      dataIndex: 'appointmentid',
      key: 'appointmentid',
      hidden: true
    },
    {
      title: 'Practice ID',
      dataIndex: 'practiceid',
      key: 'practiceid',
      hidden: true
    },

    {
      title: 'Location ID',
      dataIndex: 'locationid',
      key: 'locationid',
      hidden: true
    },

    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },

    {
      title: 'Location',
      dataIndex: 'Location',
      key: 'Location',
    },

    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
    },
  ];

  const paginationConfig = {
    pageSize: 5,
    showSizeChanger: false,
  };

  const constructJsonData = () => {
    const newJsonData = tableData.map(item => ({
      appointmentid: item.appointmentid,
      locationid: item.locationid,
      Location: item.Location,
      duration: item.duration,
      description: item.description
    }));
    return newJsonData;
  };

  const handleClick = async () => {
    if (tableData.length === 0) {
      message.error('Cannot save empty table');
      return;
    }
    let datasave = constructJsonData();
    let configData = props.onconfigData;
    configData[0].value = JSON.stringify(datasave);
    setJsonData(configData)

    try {
      await saveConfigurations(configData);
      message.success('Advanced configurations saved successfully');
    } catch (error) {
      message.error('Error saving advanced configurations');
    }
  };

  const handleCancel = () => {
    setSelectedType('');
    setDuration('');
    setSelectedLocation('');
    setSelectedLocationValue('');
    setEditRecord(null);
    setTableData([]);
  };

  const fetchLocation = async () => {
    try {
      setLocations({ ...locations, loading: true });
      const data = await getLocation();
      if (Array.isArray(data.practiceLocations)) {
        setLocations({ practiceLocations: data.practiceLocations, loading: false });
      } else {
        console.error('Locations data is not an array:', data);
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  return (
    <div>
      <div className='advance-config-comp-body'>
        <span >Appointment Type </span>
        <Select
          style={{ width: 250 }}
          placeholder="Select Appointment Type"
          onChange={handleTypeSelect}
          value={selectedType}
        >
          {appointmentTypes.map(type => (
            <Option key={type.appointmentid} value={type.description}>
              {type.description}
            </Option>
          ))}
        </Select>
        <span style={{ marginLeft: 50 }}>Duration</span>
        <Input
          style={{ width: 150, marginLeft: 41 }}
          placeholder="Duration"
          value={duration}
          onChange={(e) => {
            const value = e.target.value;
            if (!isNaN(value)) { 
              setDuration(value);
            }
          }}
        />

        <Button type="primary" onClick={handleAddAppointment} style={{ marginLeft: 16 }}>
          {editRecord ? 'Update' : 'Add'}
        </Button>


        <br /><br />
        <span >Location </span>
        <Select
          style={{ width: 250, marginLeft: 60 }}
          placeholder="Select Location"
          onChange={handleLocationSelect}
          value={selectedLocationvalue}
        >
          {locations.practiceLocations ? locations.practiceLocations.map(location => (
            <Option key={location.locationid} value={location.locationid}>
              {location.locationname}
            </Option>
          )) : (
            <Option value="" disabled> </Option>
          )}
        </Select>
        <span style={{ marginLeft: 50 }}>Default Value </span>

        <Input
          style={{ width: 150, marginLeft: 10 }}
          value={defaultValue}
          disabled // This makes the input box not editable
        />

        <Table
          columns={columns}
          dataSource={tableData}
          rowKey="appointmentid"
          pagination={paginationConfig}
          style={{ marginTop: 16 }}
        />

      </div>

      <div className="button-footer">
        <Button icon={<CheckOutlined />} htmlType="submit" onClick={handleClick} type="primary">
          Save
        </Button>
        <Button icon={<CloseOutlined />} onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AppointmentType;
