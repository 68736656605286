import React, { useEffect, useState } from 'react';
import AppointmentFilter from './appointmentFilter';
import Grid from '../grid/newGrid';
import { columnData } from './handler/appointmentsGridColumn';
import { getAppointmentSecurityPin, getAppointmentData } from "../../services/appointmentService"; // Import the security pin service function
import { FilterFilled } from '@ant-design/icons';
import { screenActionCodes, actionPermission } from '../../utils/moduleScreenCodes';
import { handleViewClick, handleBack } from '../appointments/handler/appointmentHandler';
import AppointmentCRMForm from '../../components/appointments/appointmentCrmForm';
import { Input, Modal, Skeleton } from 'antd';
import shieldImage from '../../../assets/images/shield.png';
import Invalid from '../../../assets/images/Invalid.png'; // Import the red image symbol
import "../../../assets/styles/appointment.css";

const AppointmentDefault = (props) => {
    const [appointmentData, setAppointmentData] = useState([]);
    const [filterValues, setFilterValues] = useState(null);
    const [filterVisible, setFilterVisible] = useState(false);
    const [isIpadAppt, setisIpadAppt] = useState(false);
    const [selectedAppointmentData, setSelectedAppointmentData] = useState(null);
    const [crmVisible, setCRMVisible] = useState(false);
    const [visible, setVisible] = useState(false);
    const [pin, setPin] = useState('');
    const [pinError, setPinError] = useState('');
    const [errorModalVisible, setErrorModalVisible] = useState(false);
    const [loading, setLoading] = useState(true); // Added loading state

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Set loading to true when fetching data
            try {
                const currentDate = new Date().toISOString().split('T')[0];
                const { date_range, status, location, patients, providers, appointment_type } = filterValues || {};
                const startDate = date_range ? date_range[0] : currentDate;
                const endDate = date_range ? date_range[1] : currentDate;
                const appointmentLocation = Array.isArray(location) ? location.join(',') : location;
                const appointmentStatus = Array.isArray(status) ? status.join(',') : status;
                const appointmentType = Array.isArray(appointment_type) ? appointment_type.join(',') : appointment_type;
                const patientName = Array.isArray(patients) ? patients.join(',') : patients;
                const providerName = Array.isArray(providers) ? providers.join(',') : providers;
                // console.log("userId",  sessionStorage.getItem("roleId"));
                // console.log("roleId", sessionStorage.getItem('userId'));
                const appointmentTypes = await getAppointmentData({
                    roleId: sessionStorage.getItem("roleId"),
                    userId: sessionStorage.getItem('userId'),
                    code: "IPAD-APPTS",
                    startDate,
                    endDate,
                    appointmentType,
                    appointmentStatus,
                    appointmentLocation,
                    patientName,
                    providerName
                    
                });
              
                setisIpadAppt(appointmentTypes && appointmentTypes.isIpadAppt);

                if (appointmentTypes && appointmentTypes.appointment) {
                    setAppointmentData(appointmentTypes.appointment[0]);
                } else {
                    setAppointmentData([]);
                }
            } catch (error) {
                console.error('Error fetching appointment data:', error);
            } finally {
                setLoading(false); // Set loading to false after fetching data
            }
        };

        fetchData();
    }, [filterValues]);

    const handleCreateButtonClick = () => {
        setFilterVisible(!filterVisible);
    };

    const handleListButtonClick = () => {
        setVisible(true);
        window.open('/ipad-registration', '_blank');
        // setTimeout(() => {
        //     window.open('/security-success', '_blank'); 
        // }, 500); 
    };

    const handleModalOk = async () => {
        try {
            const roleId = sessionStorage.getItem('roleId');
            const userId = sessionStorage.getItem('userId');

            const pinData = {

                dataSecurityCode: "SECPIN",
                userId: userId,
                roleId: roleId,
                securityPin: pin
            };

            const response = await getAppointmentSecurityPin(pinData); 
            if (response && response.success) {
                setVisible(false);
            } else {
                setVisible(false);
                setErrorModalVisible(true); 
            }
        } catch (error) {
            console.error('Error validating pin:', error);
            setVisible(false);
            setErrorModalVisible(true); // Show the error modal
        }
    };

    const handleModalCancel = () => {
        setVisible(false);
    };

    const handleErrorModalOk = () => {
        setErrorModalVisible(false);
        setVisible(true); // Redirect to the "Enter PIN" modal
    };

    return (
        <>
            <div>
                <AppointmentFilter onSubmit={setFilterValues} visible={filterVisible} setVisible={setFilterVisible} />
            </div>
            {(visible || errorModalVisible) && (
                <div className="skeleton-container">
                    <Skeleton active />
                </div>
            )}
            <div>
                <Modal
                    open={visible}
                    onOk={handleModalOk}
                    okText="Submit"
                    onCancel={handleModalCancel}
                    closable={false}
                    maskClosable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    centered
                    width={400}
                    className="centered-footer"
                >
                    <div className="security-appointment"><br />
                        <img src={shieldImage} alt="Custom image" className='image-size' />
                    </div>
                    <h3 className='secutity-header'>Enter your pin to continue</h3>
                    <Input.Password
                        placeholder="Enter your pin"
                        value={pin}
                        // onChange={(e) => setPin(e.target.value)}
                        onChange={(e) => {
                            setPin(e.target.value);
                            setPinError('');
                        }}
                    />
                </Modal>
                <Modal
                    open={errorModalVisible}
                    onOk={handleErrorModalOk}
                    okText="OK"
                    closable={false}
                    maskClosable={false}
                    centered
                    width={400}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    className="centered-footer"
                >
                    <div className="security-appointment"><br />
                        <img src={Invalid} alt="Invalid PIN" className='image-size' />
                    </div>
                    <h3 className='secutity-header'>Invalid PIN. Please try again.</h3>
                </Modal>
                <div>
                    <div className="">
                        {crmVisible ? (
                            <AppointmentCRMForm
                                crmAction={props.screenProps[1]}
                                appointmentData={selectedAppointmentData}
                                onBack={() => handleBack(setCRMVisible)}
                            />
                        ) : (
                            <Grid
                                columnData={columnData(isIpadAppt)}
                                tableData={appointmentData}
                                setTableData={setAppointmentData}
                                createButton={true}
                                initialTableData={appointmentData}
                                csvFileName="AppointmentList"
                                searchButton={true}
                                downloadCSV={true}
                                showEdit={false}
                                showDelete={false}
                                showView={actionPermission(props, screenActionCodes.APTY_VIEW)}
                                handleEdit={false}
                                handleView={(appointment) => handleViewClick(appointment, setSelectedAppointmentData, setCRMVisible)}
                                handleDelete={false}
                                createButtonIcon={<FilterFilled />}
                                createButtonName={"Appointment Filter"}
                                handleCreate={handleCreateButtonClick}
                                handleLinkEdit={handleListButtonClick}
                            />
                        )}
                    </div>
                </div>
            </div>

        </>
    );
};

export default AppointmentDefault;
