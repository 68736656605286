

export const ProviderGridColumns = {
  columns: [
    {
      title: "Action",
      key: "action",
      render: ["edit", "delete"],
      fixed:"left",
      width: 100,
      hidden: false,
    },
    {
      title: "Provider Id",
      dataIndex: "providerId",
      key: "providerId",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: true,
    },
    {
      title: "Identification",
      dataIndex: "identificationId",
      key: "identificationId",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: false,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: false,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: false,
    },
    {
      title: "Practice Name",
      dataIndex: "practiceName",
      key: "practiceName",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: false,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      feature: ["sorter", "filter"],
      filters: [],
      width: 200,
      hidden: false,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: false,
    },
    {
      title: "Work Phone",
      dataIndex: "workPhone",
      key: "workPhone",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: false,
    },
    {
      title: "Zoom Id",
      dataIndex: "zoomId",
      key: "zoomId",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: false,
    },
    {
      title: "Specialities",
      dataIndex: "speciality",
      key: "speciality",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: false,
    },
    {
      title: "HL7",
      dataIndex: "hl7",
      key: "hl7",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: true,
    },
  ],
};
