//#region Packages
import { Modal } from 'antd';
import { cloneDeep } from 'lodash';
import { practiceList } from '../../services/practiceService';
import { updatePractice} from "../../services/practiceService";

export const fetchData = async (setPracticeData, setLoading) => {
  setLoading(true);
  try {
    const practices = await practiceList();
    setPracticeData(practices);
  } catch (error) {
    console.error('Error fetching practice data:', error);
  } finally {
    setLoading(false);
  }
};

export const getGridData = (practiceData) => {
  const data = cloneDeep(practiceData);
  return data.map(practice => ({
    customer_id: practice.customer_id,
    identification: practice.identification,
    name: practice.name,
    hours_of_operation_from: practice.hours_of_operation_from,
    hours_of_operation_to: practice.hours_of_operation_to,
    email: practice.email,
    phone: practice.phone,
    status: practice.status
  }));

};

//PracticeGridColumn
export const handleEditClick = (setEditMode) => {
  setEditMode(true);
};

export const handleUpdateClick = (setEditMode) => {
  setEditMode(false);
};

export const handleCancelClick = (setPracticeData, setEditMode, initialPracticeData,setTimePickerValues,initialTimePickerValues) => {
  setPracticeData(initialPracticeData);
  setTimePickerValues(initialTimePickerValues);
  setEditMode(false);
};

export const handleInputChange = (name, value, setPracticeData) => {
  setPracticeData(prevPracticeData => ({
    ...prevPracticeData,
    [name]: value,
  }));
};

export const handleTimePickerChange = (name, value, setTimePickerValues) => {
  setTimePickerValues(prevTimePickerValues => ({
    ...prevTimePickerValues,
    [name]: value,
  }));
};



export const updatePracticeData = async (practiceData,timePickerValues,setError,setPracticeData) => {
  try {
      const formData = {
          practice_id: practiceData.practiceId,
          practice_name: practiceData.practiceName,
          identification: practiceData.identification,
          work_hour_from: timePickerValues.workhourfrom.format('HH:mm:ss'),
          work_hour_to: timePickerValues.workhourto.format('HH:mm:ss'),
          off_hour_from: timePickerValues.offhourfrom.format('HH:mm:ss'),
          off_hour_to: timePickerValues.offhourto.format('HH:mm:ss'),
          verify_insurance: practiceData.verifyinsurance,
          updated_by: sessionStorage.getItem('userId'),
      };

      const response = await updatePractice(formData);
      setPracticeData(response);
      if (response && response.data && response.data.success) {
          Modal.success({ title: "Success", content: "Practice Updated Successfully." });
          
      } else {
          if (response && response.data && response.data.businessRules && response.data.businessRules.length > 0) {
              const firstError = response.data.businessRules[0].description;
             setError(firstError);
          } else {
              setError("Error updating practice");
          }
      }
  } catch (error) {
      console.error("Error updating practice data:", error);
  }
};