import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Card, Image, Row, Col, Spin } from "antd";
import { InputOTP } from "antd-input-otp";
import "../../../../src/assets/styles/mfa.css";
import logoOTP from "../../../assets/images/fo-OTP.png";
import MobileViewlogo from "../../../assets/images/MoblieViewLogo.png"
import { useNavigate } from 'react-router-dom';
import { handlePaste, handleVerification, fetchData, getData, formatTime, handleResendOTP, countdown } from "../../components/userNotifyVerification/handlers/userNotifyVerificationHandler";

const UserNotifyVerification = () => {
  const [displayForm, setDisplayForm] = useState({ displaySMS: false, displayEmail: false });
  const [mobileOTP, setMobileOTP] = useState("");
  const [emailOTP, setEmailOTP] = useState("");
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [error, setError] = useState("");
  const [smsType, setSMSType] = useState("");
  const [emailType, setEmailType] = useState("");
  const [loading, setLoading] = useState(true); // State to track loading
  const [isResendDisabled, setResendDisabled] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  // const [timeRemaining, setTimeRemaining] = useState(120);
  const [timer, setTimer] = useState(300); // Get the time remaining from the custom hook
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const fetchData = async () => {
    try {
      await getData(navigate, setError, setSMSType, setEmailType, setLoading, setTimer, setIsVisible, timer, setResendDisabled);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleResendClick = async () => {
    try {
      setMobileOTP("");
      setEmailOTP("");
      setTimer(300); // Set the initial timer value
      setIsVisible(true); // Show the timer
      setResendDisabled(false); // Enable the resend button
      await getData(navigate, setError, setSMSType, setEmailType, setLoading, setTimer, setIsVisible, timer, setResendDisabled);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const handleResendClick = async () => {
  //   setMobileOTP("");
  //   setEmailOTP("");
  //   fetchData(); // Call the fetchData function here
  // };



  return (
    <Spin spinning={loading}>
      <div className="divOtp">
        <div className="wrapper">
          <div className="middle-column">
            <div className="divOtpCard">

              <div className="otpContent">
                {/* <Row justify="center"> 
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Image preview={false} src={logoOTP} width="100%" />
                  </Col>
                </Row> */}
                <div className="guest-logo">
                  <a className="navbar-brand">
                    <img src={MobileViewlogo} alt="Logo" className="logo" />
                  </a>
                </div>
                <Row justify="center">
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Card className="mfa-card">
                      <div gutter={[16, 16]}>
                        {emailType && (

                          <div className="otpRightdiv">
                            <div className="mfa-info ">
                              <h3>Please check Your Email</h3>
                              <p>We've sent the code to the registered email address.</p>
                            </div>
                            <InputOTP
                              className="mfa-input no-spinner"
                              type="number"
                              value={emailOTP}
                              onChange={setEmailOTP}
                              separator={<span style={{ margin: "0 5px" }}></span>}
                              onPaste={(e) => handlePaste(e, setEmailOTP)}
                              autoFocus
                            />
                          </div>

                        )}
                        {smsType && (
                          <div className="otpLeftdiv">
                            <div className="mfa-info">
                              <h3>Please check Your Mobile</h3>
                              <p>We've sent the code to the registered mobile number.</p>
                            </div>
                            <InputOTP
                              className="mfa-input no-spinner"
                              type="number"
                              value={mobileOTP}
                              onChange={setMobileOTP}
                              separator={<span style={{ margin: "0 5px" }}></span>}
                              onPaste={(e) => handlePaste(e, setMobileOTP)}
                              style={{ WebkitAppearance: 'none', MozAppearance: 'textfield', appearance: 'none', width: '100%' }}
                              autoFocus
                            />
                          </div>
                        )}
                      </div>
                      {isVisible && (
                        <div>
                          <p>Time Remaining: {formatTime(timer)}</p>
                          {/* Your OTP input field */}
                        </div>
                      )}
                      <div className="otpVerifyButton">
                        <Button
                          type="primary"
                          className="mfa-button"
                          onClick={() => handleVerification(mobileOTP, emailOTP, setMobileOTP, setEmailOTP, navigate, setResendDisabled, getData, setError, setSMSType, setEmailType, setLoading)}
                          disabled={(smsType && mobileOTP.length !== 6) || (emailType && emailOTP.length !== 6)}
                        >
                          Confirm OTP
                        </Button>
                        <Button
                          type="text"
                          className="mfa-button"
                          onClick={handleResendClick}
                          disabled={isResendDisabled} // Disable the button initially based on isResendDisabled state
                        >
                          Resend OTP
                        </Button>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default UserNotifyVerification;
