import axiosInterceptor from "../../middleware/axiosInterceptor";

export default async function fetchData() {
  const roleId = sessionStorage.getItem('roleId');
  const url = "api/modules/" + roleId;
  try {
    const response = await axiosInterceptor.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
