import React, {useEffect, useRef, useState} from 'react';
import {
    Form,
    Input,
    Button,
    DatePicker,
    Divider,
    Row,
    Col,
    Spin,
    Checkbox,
    Modal, Space
} from "antd";
import {saveImmunizationHistory} from "../../services/ipadRegistartionService";
import moment from "moment/moment";

const ImmunizationHistory = (props) => {
    const [form] = Form.useForm();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const fileInputImmunizationFrontRef = useRef(null);
    const backgroundDivImmunizationFrontRef = useRef(null);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [frontInsuranceValue, setFrontInsuranceValue] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showTetanusDate, setShowTetanusDate] = useState(false);
    const [showPolioDate, setShowPolioDate] = useState(false);
    const [showPPDDate, setShowPPDDate] = useState(false);
    const [showMeaslesDate, setShowMeaslesDate] = useState(false);
    const [showHepatitisBDate, setShowHepatitisBDate] = useState(false);
    const [showBCGDate, setShowBCGDate] = useState(false);
    const [showFluDate, setShowFluDate] = useState(false);
    const [showH1N1FluDate, setShowH1N1FluDate] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (backgroundDivImmunizationFrontRef.current && (backgroundImage !== null && backgroundImage !== undefined)) {
            const url = new URL(window.location.href.split('#')[0]);
            if (url.searchParams.has('prId')) {
                url.searchParams.delete('prId'); // Remove the 'prId' query parameter
            }
            const modifiedURL = url.toString();
            backgroundDivImmunizationFrontRef.current.style.backgroundImage = `url(${modifiedURL}/storage/images?path=${backgroundImage})`;
            const frontLabel = document.querySelector('.front');
            if (frontLabel) {
                frontLabel.innerHTML = '';
            }
        }
    }, [backgroundImage]);

    useEffect(() => {
        if (props.patientRegistrationInfo?.patientRegistrationFormData) {
            let info = props.patientRegistrationInfo.patientRegistrationFormData?.ImmunizationHistory ?? [];
            const conditions = ['Tetanus', 'Polio', 'PPD', 'Measles', 'HepatitisB', 'BCG', 'Flu', 'H1N1Flu'];
            conditions.forEach(condition => {
                if (info.hasOwnProperty(condition)) {
                    const setItemFunction = `setShow${condition}Date`;
                    const correspondingStateSetter = eval(setItemFunction);
                    if (typeof correspondingStateSetter === 'function') {
                        correspondingStateSetter(info[condition]);
                    }
                }
            });
            const ImmunizationImageFront = info?.ImmunizationImage ?? [];
            if (ImmunizationImageFront != null || ImmunizationImageFront != []) {
                setBackgroundImage(ImmunizationImageFront.path);
            }
            setDataLoaded(true);
        }
    }, [props.patientRegistrationInfo]);
    const immunizationHistoryInfo = props.patientRegistrationInfo.patientRegistrationFormData?.ImmunizationHistory ?? [];
    function parseDate(dateString) {
        if (dateString) {
            const parsedDate = moment(dateString, 'YYYY-MM-DD');
            return parsedDate.isValid() ? parsedDate : null;
        }
        return null;
    }
    let immunizationHistoryDetails = {
        Tetanus: immunizationHistoryInfo?.Tetanus || "",
        Polio: immunizationHistoryInfo?.Polio || "",
        PPD: immunizationHistoryInfo?.PPD || "",
        Measles: immunizationHistoryInfo?.Measles || "",
        HepatitisB: immunizationHistoryInfo?.HepatitisB || "",
        BCG: immunizationHistoryInfo?.BCG || "",
        Flu: immunizationHistoryInfo?.Flu || "",
        H1N1Flu: immunizationHistoryInfo?.H1N1Flu || "",
        TetanusDate : parseDate(immunizationHistoryInfo?.TetanusDate) || "",
        PolioDate : parseDate(immunizationHistoryInfo?.PolioDate) || "",
        PPDDate : parseDate(immunizationHistoryInfo?.PPDDate) || "",
        MeaslesDate : parseDate(immunizationHistoryInfo?.MeaslesDate) || "",
        HepatitisBDate : parseDate(immunizationHistoryInfo?.HepatitisBDate) || "",
        BCGDate : parseDate(immunizationHistoryInfo?.BCGDate) || "",
        FluDate : parseDate(immunizationHistoryInfo?.FluDate) || "",
        H1N1FluDate : parseDate(immunizationHistoryInfo?.H1N1FluDate) || ""
    }
    const onImmunizationPreviousClick = () => {
        props.handlePreviousClick(props);
    }
    const handleLicenseFrontChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFrontInsuranceValue(e.target.files[0]);
            const imageUrl = URL.createObjectURL(selectedFile);
            backgroundDivImmunizationFrontRef.current.style.backgroundImage = `url(${imageUrl})`;
            const frontLabel = document.querySelector('.front');
            if (frontLabel) {
                frontLabel.innerHTML = '';
            }
        }
    };
    const handleCheckboxChange = (condition, checked) => {
        if (condition === 'Tetanus') {
            setShowTetanusDate(checked);
        } else if (condition === 'Polio') {
            setShowPolioDate(checked);
        } else if (condition === 'PPD') {
            setShowPPDDate(checked);
        } else if (condition === 'Measles') {
            setShowMeaslesDate(checked);
        } else if (condition === 'HepatitisB') {
            setShowHepatitisBDate(checked);
        } else if (condition === 'BCG') {
            setShowBCGDate(checked);
        } else if (condition === 'Flu') {
            setShowFluDate(checked);
        } else if (condition === 'H1N1Flu') {
            setShowH1N1FluDate(checked);
        }
    };

    const handleFormSubmit = async (values) => {
        try {
            setIsSubmitting(true);
            const dateFields = ['TetanusDate', 'PolioDate', 'PPDDate','MeaslesDate', 'HepatitisBDate', 'BCGDate', 'FluDate', 'H1N1FluDate'];
            const updatedValues = {
                ...values,
                ...dateFields.reduce((acc, field) => {
                    const originalValue = values[field];
                    if (originalValue !== undefined && originalValue !== null) {
                        acc[field] = moment(originalValue).format('YYYY-MM-DD');
                    }
                    return acc;
                }, {}),
            };
            const formDatas = new FormData();
            formDatas.append('ImmunizationHistory', JSON.stringify(updatedValues));
            if (frontInsuranceValue) {
               formDatas.append('immunizationImageFrontSide', frontInsuranceValue);
            }
            formDatas.append('uniqueHash', props.uniqueHash);

            let immunizationResponse = await saveImmunizationHistory(formDatas);
            if (immunizationResponse.message === 'Success') {
                props.handleNextClick(props);
            } else {
                Modal.error({
                    title: 'This is an error message',
                    content: 'See Console',
                });
            }
        } finally {
            if (!isSubmitting) {
                return (
                    <div className="spinner-overlay">
                        <div className="spinner">
                            <Spin size="large" tip="Loading..."/>
                        </div>
                    </div>
                );
            }
            setIsSubmitting(false);
        }
    }

    const handleDivFrontClick = () => {
        fileInputImmunizationFrontRef.current.click();
    };

    if (!dataLoaded) {
        return (
            <div className="spinner-overlay">
                <div className="spinner">
                    <Spin size="large" tip="Loading..."/>
                </div>
            </div>
        );
    }
    return (
        <div>
            <h3 className="mb-3">Immunization History</h3>
            <Divider/>
            <label className="ctl">Immunization History (Include Date of Immunization and Boosters):</label>
            <div>
                <label className="ctl">To take a picture of your Immunization card. Click here <i
                    className="fa fa-camera"></i></label>
            </div>
            <Form form={form} layout="vertical" size="large" onFinish={handleFormSubmit}
                  initialValues={{
                      ...immunizationHistoryDetails,
                  }}>
                <Form.Item>
                    <div id="testingdivImage" style={{backgroundImage}}></div>
                    <div id="licenseFrontSide" ref={backgroundDivImmunizationFrontRef}
                         className="licenseFrontImageDiv"
                         onClick={handleDivFrontClick}>
                        <label className="text-secondary front">Front side</label>
                    </div>
                    <input type="file" id="licenseFrontFile" name="licenseImageFrontSide"
                           style={{display: 'none'}}
                           accept="image/*"
                           onChange={handleLicenseFrontChange} ref={fileInputImmunizationFrontRef}/>
                    <button className="btn btn-primary"  type="button" id="takePhoto" style={{
                            width: ' 100%',
                            maxWidth: '380px',
                            borderRadius: '7px',
                            marginTop: '10px'
                        }}
                        onClick={handleDivFrontClick}>Take photo</button>
                </Form.Item>
                <Form.Item className="pad-2px">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Form.Item name="Tetanus" valuePropName="checked">
                                <Checkbox value="Tetanus / Diptheria"
                                          onChange={(e) => handleCheckboxChange('Tetanus', e.target.checked)}>
                                    Tetanus / Diptheria</Checkbox>
                            </Form.Item>
                        </Col>
                        {showTetanusDate && (
                            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                                <Form.Item name="TetanusDate"
                                           rules={[{required: true, message: 'This field is required'}]}>
                                    <DatePicker
                                        style={{width: '100%'}}
                                        placeholder="Select date"
                                        format="MM/DD/YYYY"
                                        // disabled={!form.getFieldValue('ImmunizationHistory')}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </Form.Item>
                <Form.Item className="pad-2px">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Form.Item name="Polio" valuePropName="checked">
                                <Checkbox value="Polio"
                                          onChange={(e) => handleCheckboxChange('Polio', e.target.checked)}>
                                    Polio</Checkbox>
                            </Form.Item>
                        </Col>
                        {showPolioDate && (
                            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                                <Form.Item name="PolioDate"
                                           rules={[{required: true, message: 'This field is required'}]}>
                                    <DatePicker
                                        style={{width: '100%'}}
                                        placeholder="Select date"
                                        format="MM/DD/YYYY"
                                        // disabled={!form.getFieldValue('ImmunizationHistory')}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </Form.Item>
                <Form.Item className="pad-2px">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Form.Item name="PPD" valuePropName="checked">
                                <Checkbox value="PPD / Mantoux"
                                          onChange={(e) => handleCheckboxChange('PPD', e.target.checked)}>
                                    PPD / Mantoux</Checkbox>
                            </Form.Item>
                        </Col>
                        {showPPDDate && (
                            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                                <Form.Item name="PPDDate" rules={[{required: true, message: 'This field is required'}]}>
                                    <DatePicker
                                        style={{width: '100%'}}
                                        placeholder="Select date"
                                        format="MM/DD/YYYY"
                                        // disabled={!form.getFieldValue('ImmunizationHistory')}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </Form.Item>
                <Form.Item className="pad-2px">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Form.Item name="Measles" valuePropName="checked">
                                <Checkbox value="Measles / Mumps / Ru"
                                          onChange={(e) => handleCheckboxChange('Measles', e.target.checked)}>
                                    Measles / Mumps / Ru</Checkbox>
                            </Form.Item>
                        </Col>
                        {showMeaslesDate && (
                            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                                <Form.Item name="MeaslesDate"
                                           rules={[{required: true, message: 'This field is required'}]}>
                                    <DatePicker
                                        style={{width: '100%'}}
                                        placeholder="Select date"
                                        format="MM/DD/YYYY"
                                        // disabled={!form.getFieldValue('ImmunizationHistory')}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </Form.Item>
                <Form.Item className="pad-2px">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Form.Item name="HepatitisB" valuePropName="checked">
                                <Checkbox value="Hepatitis B"
                                          onChange={(e) => handleCheckboxChange('HepatitisB', e.target.checked)}>
                                    Hepatitis B</Checkbox>
                            </Form.Item>
                        </Col>
                        {showHepatitisBDate && (
                            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                                <Form.Item name="HepatitisBDate"
                                           rules={[{required: true, message: 'This field is required'}]}>
                                    <DatePicker
                                        style={{width: '100%'}}
                                        placeholder="Select date"
                                        format="MM/DD/YYYY"
                                        // disabled={!form.getFieldValue('ImmunizationHistory')}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </Form.Item>
                <Form.Item className="pad-2px">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Form.Item name="BCG" valuePropName="checked">
                                <Checkbox value="BCG"
                                          onChange={(e) => handleCheckboxChange('BCG', e.target.checked)}>
                                    BCG</Checkbox>
                            </Form.Item>
                        </Col>
                        {showBCGDate && (
                            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                                <Form.Item name="BCGDate" rules={[{required: true, message: 'This field is required'}]}>
                                    <DatePicker
                                        style={{width: '100%'}}
                                        placeholder="Select date"
                                        format="MM/DD/YYYY"
                                        // disabled={!form.getFieldValue('ImmunizationHistory')}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </Form.Item>
                <Form.Item className="pad-2px">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Form.Item name="Flu" valuePropName="checked">
                                <Checkbox value="Flu"
                                          onChange={(e) => handleCheckboxChange('Flu', e.target.checked)}>
                                    Flu</Checkbox>
                            </Form.Item>
                        </Col>
                        {showFluDate && (
                            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                                <Form.Item name="FluDate" rules={[{required: true, message: 'This field is required'}]}>
                                    <DatePicker
                                        style={{width: '100%'}}
                                        placeholder="Select date"
                                        format="MM/DD/YYYY"
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </Form.Item>
                <Form.Item className="pad-2px">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Form.Item name="H1N1Flu" valuePropName="checked">
                                <Checkbox value="H1N1Flu"
                                          onChange={(e) => handleCheckboxChange('H1N1Flu', e.target.checked)}>
                                    H1N1Flu</Checkbox>
                            </Form.Item>
                        </Col>
                        {showH1N1FluDate && (
                            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                                <Form.Item name="H1N1FluDate"
                                           rules={[{required: true, message: 'This field is required'}]}>
                                    <DatePicker
                                        style={{width: '100%'}}
                                        placeholder="Select date"
                                        format="MM/DD/YYYY"
                                        // disabled={!form.getFieldValue('ImmunizationHistory')}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </Form.Item>
                <Form.Item className="form-footer-button">
                    {isSubmitting ? (
                        <div className="spinner-overlay">
                            <div className="spinner">
                                <Spin size="large" tip="Loading..."/>
                            </div>
                        </div>
                    ) : (
                        <Space>
                            <Button type="primary" onClick={onImmunizationPreviousClick}>Previous</Button>
                            <Button type="primary" htmlType="submit">Next</Button>
                        </Space>
                    )}
                </Form.Item>
            </Form>
        </div>
    )
}

export default ImmunizationHistory;
