import React, { useState, useEffect } from 'react';
import { fetchProviderForm, onSaveProvider, onSaveLocationDetails, getLocationConfig, onUpdateProvider } from "../../components/provider/providerHandler";
import {
    Form, Input, Select, DatePicker, Switch, Button, Row, Col, Tabs, Skeleton,
    Collapse, TimePicker, Table, Tooltip, Space, Modal, Checkbox, Tag, message
} from 'antd';
import { CloseOutlined, CheckOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Configuration from '../practice/configuration';
import { MAPPING_CONST } from '../utilities/mappingData';
import { CreateUserValidation } from '../users/handlers/createUserValidation';
import DoubleBookingCalendar from './doubleBookingCalendar';
import {
    handleDeleteEvent, requiredState, initialLocationState, initialState, operationjson, handleEventSelection, followupjson, insurancejson, getLocationField,
    handleAddAppointment, handleAddInsurance, handleEndTimeChange, handleStartTimeChange, disabledTime, handleWeekChange, handlecheckChange, handlReferalChange, handleInsuranceChange,
    handleDateTimeChange, handleEndDateChange, handleStartDateChange, handlePhoneChange, renderFormItems, handleCancel,
    showModal, handlefollowup, handleSelectChange, handleInputChange, findObjectsByPracticeLocationIds
    , handleChangeInteger, handleChange, handlSameChange, handleDeleteInsurance, handleDeleteDOP,
    validateEmail, handleReferralChange
} from './providerHandler';


const ProviderForm = ({ providerData, onBack, opType }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [providerFormData, setProviderFormData] = useState([]);
    const [visibleFormData, setvisibleFormData] = useState([]);
    const [daysOfOperation, setDaysOfOperation] = useState([]);
    const [jsonFormattedEvents, setJsonFormattedEvents] = useState("");
    const [insuranceItems, setInsuranceItems] = useState([]);

    const [specialities, setSpecialities] = useState('');
    const [insurances, setInsurances] = useState('');
    const [bots, setBots] = useState('');
    const [practiceLocations, setPracticeLocations] = useState('');
    const [locationList, setLocationList] = useState([]);
    const [visible, setVisible] = useState(false);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [providerId, setproviderId] = useState('');
    const [locationId, setlocationId] = useState('');
    const [selectedEvent, setSelectedEvent] = useState([]);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [refreshCalendar, setRefreshCalendar] = useState(false); // State variable to trigger refresh

    const { Panel } = Collapse;
    const { Option } = Select;
    const { TabPane } = Tabs;
    const { confirm } = Modal;

    const eventsByDay = selectedEvent.reduce((acc, event) => {
        if (!acc[event.day]) {
            acc[event.day] = [];
        }
        acc[event.day].push(event.timeRange);
        return acc;
    }, {});

    const formattedEvents = Object.entries(eventsByDay).map(([day, timeRanges]) => {
        const sortedTimeRanges = timeRanges.sort();
        const formattedTimeRanges = sortedTimeRanges.join(', ');
        return { day, timeRange: formattedTimeRanges };
    });

    const doublebookingcolumns = [
        {
            header: false,
            dataIndex: 'day',
            key: 'day',
        },
        {
            header: false,
            dataIndex: 'timeRange',
            key: 'timeRange',
            render: (timeRange) => (
                <span>
                    {timeRange.split(',').map((range, index) => (
                        <Tag color="blue" key={index}>{range}</Tag>
                    ))}
                </span>
            ),
        },
    ];

    const errMessage = "Field should not empty";
    const [formData, setFormData] = useState(initialState);

    const [locationFormData, setLocationFormData] = useState(initialLocationState);
    const [operations, setOperations] = useState(operationjson);
    const [insuranceItem, setInsuranceItem] = useState(insurancejson);
    const [followUp, setfollowUp] = useState(followupjson);
    const [errors, setErrors] = useState([]);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [error, setError] = useState(false);

    const [formData1, setFormData1] = useState({
        from: null,
        to: null,
    });
    const handleStartTimeChange = (time, timeString) => {
        setOperations(prevOperations => ({
            ...prevOperations,
            from: timeString
        }));
        if (endTime && time.isAfter(endTime)) {
            setError(true);
            message.error('Start time should be before end time');
        } else {
            setError(false);
            setStartTime(time);
        }
    };

    const handleEndTimeChange = (time, timeString) => {
        setOperations(prevOperations => ({
            ...prevOperations,
            to: timeString
        }));
        if (startTime && time.isBefore(startTime)) {
            setError(true);
            message.error('End time should be after start time');
        } else {
            setError(false);
            setEndTime(time);
        }
    };


    const disabledTime = (current) => {
        if (!startTime || !endTime) {
            return null;
        }
        if (current && (current.isBefore(startTime) || current.isAfter(endTime))) {
            return {
                disabled: true,
            };
        }
        return null;
    };

    useEffect(() => {
        fetchProviderForm(setProviderFormData, setvisibleFormData, setLoading,
            setSpecialities, setInsurances, setBots, setPracticeLocations);
    }, []);

    useEffect(() => {
        if (providerData != null && providerData.providerId != undefined) {
            getLocationField(setvaluelocation, practiceLocations, setLoading, setfollowUp, setInsuranceItems, setDaysOfOperation, setFormData, form, providerData);
            setVisible(true);
            setproviderId(providerData.providerId);
        };
    }, [practiceLocations]);

    const dayofOperationcolumn = [
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space>
                    <Tooltip title="Delete">
                        <Button type="primary" className="gridIconDelete" danger icon={<DeleteOutlined />}
                            onClick={() => handleDeleteDOP(record, daysOfOperation, setDaysOfOperation, locationId, ExclamationCircleOutlined, confirm)} />
                    </Tooltip>
                </Space>
            ),
            width: 100,
        },
        {
            title: 'WeekId',
            dataIndex: 'weekId',
            key: 'weekId',
            feature: false,
            hidden: true,
        },
        {
            title: 'Week1',
            dataIndex: 'week1',
            key: 'week1',
            feature: false,
            hidden: false,
            render: (text) => <Checkbox checked={text === 1} disabled />,
        },
        {
            title: 'Week2',
            dataIndex: 'week2',
            key: 'week2',
            feature: false,
            hidden: false,
            render: (text) => <Checkbox checked={text === 1} disabled />,
        },
        {
            title: 'Week3',
            dataIndex: 'week3',
            key: 'week3',
            feature: false,
            hidden: false,
            render: (text) => <Checkbox checked={text === 1} disabled />,
        },
        {
            title: 'Week4',
            dataIndex: 'week4',
            key: 'week4',
            feature: false,
            hidden: false,
            render: (text) => <Checkbox checked={text === 1} disabled />,
        },
        {
            title: 'Week5',
            dataIndex: 'week5',
            key: 'week5',
            feature: false,
            hidden: false,
            render: (text) => <Checkbox checked={text === 1} disabled />,
        },
        {
            title: 'Week Last',
            dataIndex: 'weekLast',
            key: 'weekLast',
            feature: false,
            hidden: false,
            render: (text) => <Checkbox checked={text === 1} disabled />,
        },
        {
            title: 'Day',
            dataIndex: 'day',
            key: 'day',
            feature: false,
            hidden: false,
        },
        {
            title: 'From Time',
            dataIndex: 'from',
            key: 'from',
            feature: false,
            hidden: false,
        },
        {
            title: 'To Time',
            dataIndex: 'to',
            key: 'to',
            feature: false,
            hidden: false,
        },
    ];

    const insurancecolumn = [
        {
            title: 'Action',
            key: 'action',
            render: (text, record, index) => (
                <Space>
                    <Tooltip title="Delete">
                        <Button type="primary" className="gridIconDelete" danger icon={<DeleteOutlined />}
                            // onClick={() => handleDeleteInsurance(record, insuranceItems, setInsuranceItems, ExclamationCircleOutlined, confirm)} />
                            onClick={() => handleDeleteInsurance(index, insuranceItems, setInsuranceItems, ExclamationCircleOutlined, confirm)}
                        />
                    </Tooltip>
                </Space>
            ),
            width: 100,
        },
        {
            title: 'Type',
            dataIndex: 'insuranceId',
            key: 'insuranceId',
            feature: false,
            hidden: false,
        },
        {
            title: 'Type name',
            dataIndex: 'insuranceType',
            key: 'insuranceType',
            feature: false,
            hidden: false,
        },
        {
            title: 'From',
            dataIndex: 'from',
            key: 'from',
            feature: false,
            hidden: false,
        },
        {
            title: 'To',
            dataIndex: 'to',
            key: 'to',
            feature: false,
            hidden: false,
        },
        // {
        //     title: 'Referral Required',
        //     dataIndex: 'referral',
        //     key: 'referral',
        //     feature: false,
        //     hidden: false,
        //     render: (text) => <Checkbox checked={text === 1} disabled />,
        // }
        {
            title: 'Referral Required',
            dataIndex: 'referral',
            key: 'referral',
            feature: false,
            hidden: false,
            render: (text) => <Checkbox checked={text} disabled />,
        }

    ];

    let showLocationTab = false;
    let showLocationTabAdd = false;
    // Check if either double booking or days of operation form item exists
    if (renderFormItems(visibleFormData, MAPPING_CONST.PROV_PAGE_DAYS_OF_OPER) || renderFormItems(visibleFormData, MAPPING_CONST.PROV_PAGE_DOUBLE_BOOK)) {
        showLocationTab = true;
    }
    // Check if any practice locations are selected
    if (formData.practiceLocation && formData.practiceLocation.length > 0) {
        showLocationTabAdd = true;
    }

    const handleDeleteEvent = (selectedEventrange, formattedTimeRange, selectedDay) => {
        const selectedDayEvents = selectedEvent.filter(event => event.day === selectedDay);
        const updatedEvents = selectedEvent.filter(event => {
            return !(event.day === selectedDay && event.timeRange === formattedTimeRange);
        });
        setSelectedEvent(updatedEvents);
    };
    const handleEventSelection = (formattedTimeRange, clickedDate) => {
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const selectedDay = daysOfWeek[clickedDate.getDay()];

        const selectedEvent = {
            day: selectedDay,
            timeRange: formattedTimeRange
        };

        setSelectedEvent(prevSelectedEvents => [...prevSelectedEvents, selectedEvent]); // Add the new event to the existing array
    };

    const handleLocation = (value, name) => {
        setLocationFormData({ ...locationFormData, [name]: value });
        setlocationId(value);
        getLocationConfig(form, providerId, value, setLocationFormData,
            setDaysOfOperation, setOperations, operationjson, setSelectedEvent);
    };

    const setvaluelocation = (value) => {
        const loclist = findObjectsByPracticeLocationIds(practiceLocations, value);
        if (loclist.length > 0) {
            setLocationList(loclist);
        }
    }
    const handleLocationChange = (value, name) => {
        setFormData({ ...formData, [name]: value });
        // setvaluelocation(value);
    };
    return (
        <div className="update-provider-container">
            <Skeleton active loading={loading} paragraph={{ rows: 10 }}>
                <Tabs type="card">

                    <TabPane tab="Provider" key="1">
                        <Form form={form} colon={false} layout="vertical" requiredMark="required" initialValues={formData ? formData : []} >
                            <>
                                <div className="update-provider-content">
                                    <Row gutter={[20, 1]}>
                                        {renderFormItems(visibleFormData, MAPPING_CONST.PROD_PAGE_CLIENT_NAME) && (
                                            <Col span={8} className='form-group'>
                                                <Form.Item name="clientName" label="Client Name" className="form-item-margin">

                                                    <Input name="clientName" id="clientName" className="input"
                                                        onChange={(e) => handleInputChange(e, 'clientName', formData, setFormData)} autoFocus />
                                                </Form.Item>
                                            </Col>
                                        )}

                                        <Col span={8} className='form-group'>
                                            <Form.Item label="First Name" name="firstName" className="form-item-margin"
                                                rules={[{ required: true, message: errMessage }]}>

                                                <Input name="firstName" id="firstName" className="input"
                                                    onChange={(e) => handleInputChange(e, 'firstName', formData, setFormData)} />
                                            </Form.Item>
                                        </Col>

                                        <Col span={8} className='form-group' >
                                            <Form.Item label="Last Name" name="lastName" className="form-item-margin"
                                                rules={[{ required: true, message: errMessage }]}>

                                                <Input name="lastName" id="lastName"
                                                    onChange={(e) => handleInputChange(e, 'lastName', formData, setFormData)} className="input" />
                                            </Form.Item>
                                        </Col>

                                        <Col span={8} className='form-group'>
                                            <Form.Item label="Practice Name" name="practiceName"
                                                rules={[{ required: true, message: errMessage }]} className="form-item-margin">

                                                <Input name="practiceName" id="practiceName" className="input"
                                                    onChange={(e) => handleInputChange(e, 'practiceName', formData, setFormData)} />
                                            </Form.Item>
                                        </Col>

                                        <Col span={8} className='form-group'>
                                            <Form.Item label="Identification" name="identificationId"
                                                rules={[{ required: true, message: errMessage }]} className="form-item-margin">

                                                <Input name="identificationId" id="identificationId" className="input"
                                                    onChange={(e) => handleInputChange(e, 'identificationId', formData, setFormData)} />
                                            </Form.Item>
                                        </Col>

                                        {renderFormItems(visibleFormData, MAPPING_CONST.PROD_PAGE_TITLE) && (
                                            <Col span={8} className='form-group'>
                                                <Form.Item label="Title" name="title" className="form-item-margin">
                                                    <Input name="title" id="identificationId"
                                                        onChange={(e) => handleInputChange(e, 'identificationId', formData, setFormData)} className="input" />
                                                </Form.Item>
                                            </Col>
                                        )}

                                        {renderFormItems(visibleFormData, MAPPING_CONST.PROD_PAGE_NPI) && (
                                            <Col span={8} className='form-group'>
                                                <Form.Item label="NPI" name="npi" className="form-item-margin">
                                                    <Input name="npi" id="identificationId"
                                                        onChange={(e) => handleInputChange(e, 'npi', formData, setFormData)} className="input" />
                                                </Form.Item>

                                            </Col>
                                        )}

                                        {renderFormItems(visibleFormData, MAPPING_CONST.PROD_PAGE_SAMEDAY_APP) && (
                                            <Col span={8} className='form-group'>
                                                <Form.Item label="Do Not Allow Same Day Appointment" name="allowSameDayAppointment">
                                                    <Checkbox id="allowSameDayAppointment" value={formData.allowSameDayAppointment}
                                                        checked={formData.allowSameDayAppointment === 1}
                                                        onChange={(e) => handlSameChange("allowSameDayAppointment", e.target.checked, formData, setFormData)} />
                                                </Form.Item>
                                            </Col>
                                        )}

                                        <Col span={8} className='form-group'>
                                            <Form.Item label="Specialities" name="speciality" rules={[{ required: true, message: errMessage }]}
                                                className="form-item-margin">

                                                <Select mode="single" allowClear style={{ width: '100%' }} placeholder="Select a speciality"
                                                    name="speciality" id="speciality" defaultValue={[]}
                                                    onChange={(value) => handleChangeInteger(value, "speciality", formData, setFormData)}
                                                    rules={CreateUserValidation.speciality} value={formData.holiday}>

                                                    {specialities && specialities.map(q => (
                                                        <Option key={q.specialityid} value={q.specialityid} name={q.speciality}>
                                                            {q.speciality}
                                                        </Option>
                                                    ))}

                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={8} className='form-group'>
                                            <Form.Item label="Practice Location" name="practiceLocation"
                                                rules={[{ required: true, message: errMessage }]} className="form-item-margin">

                                                <Select mode="multiple" allowClear style={{ width: '100%' }} placeholder="Select a Practice Location"
                                                    name="practiceLocation" id="practiceLocation" defaultValue={[]}
                                                    onChange={(value) => handleLocationChange(value, "practiceLocation")}
                                                    rules={CreateUserValidation.practicelocation}
                                                    value={formData.practiceLocation}>

                                                    {practiceLocations && practiceLocations.map(q => (
                                                        <Option key={q.practiceLocationid} value={q.practiceLocationid} name={q.locationName}>
                                                            {q.locationName}
                                                        </Option>
                                                    ))}

                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        {renderFormItems(visibleFormData, MAPPING_CONST.PROV_PAGE_ACTION) && (
                                            <Col span={8} className='form-group'>
                                                <Form.Item label="Action" name="insuranceActionEscalate">
                                                    <Checkbox id="escalate" name='insuranceActionEscalate' value={formData.insuranceActionEscalate}
                                                        onChange={(e) => handlecheckChange("insuranceActionEscalate", e.target.checked, formData, setFormData)}
                                                        checked={formData.insuranceActionEscalate === 1}>Escalate</Checkbox>
                                                    <Checkbox id="bookAppointment" name='insuranceActionBookAppointment' value={formData.insuranceActionBookAppointment}
                                                        onChange={(e) => handlecheckChange("insuranceActionBookAppointment", e.target.checked, formData, setFormData)}
                                                        checked={formData.insuranceActionBookAppointment === 1}>Book Appointment</Checkbox>
                                                </Form.Item>
                                            </Col>
                                        )}

                                        {renderFormItems(visibleFormData, MAPPING_CONST.PROV_PAGE_VAC_SCHE) && (
                                            <Col span={8} className='form-group'>
                                                <Form.Item label="Vacation Schedule" name="vacation" className="form-item-margin">
                                                    <DatePicker multiple name="vacation" id="vacation" className="input" maxTagCount="responsive" size="large"
                                                        onChange={(date, dateString) => handleDateTimeChange(date, dateString, formData, setFormData, "vacation")}>
                                                    </DatePicker>
                                                </Form.Item>
                                            </Col>
                                        )}

                                        {renderFormItems(visibleFormData, MAPPING_CONST.PROV_PAGE_CONTACT) && (
                                            <Col span={8} className='form-group'>
                                                <Form.Item label="Email" name="email" rules={[
                                                    { validator: validateEmail }
                                                ]} className="form-item-margin">
                                                    <Input name="email" id="email" value={formData.email}
                                                        onChange={(e) => handleInputChange(e, 'email', formData, setFormData)} className="input" />
                                                </Form.Item></Col>
                                        )}

                                        {renderFormItems(visibleFormData, MAPPING_CONST.PROV_PAGE_CONTACT) && (
                                            <Col span={8} className='form-group'>
                                                <Form.Item label="Cell Phone" name="phone" className="form-item-margin">
                                                    <Input name="phone" id="phone" value={formData.phone} maxLength={14} code="PHCODE" placeholder='(999) 999-9999'
                                                        onChange={(e) => handlePhoneChange(e, errors, setErrors, form, formData, setFormData)} className="input" />
                                                </Form.Item></Col>
                                        )}

                                        {renderFormItems(visibleFormData, MAPPING_CONST.PROV_PAGE_CONTACT) && (
                                            <Col span={8} className='form-group'>
                                                <Form.Item label="Work Phone" name="workPhone" className="form-item-margin">
                                                    <Input name="workPhone" id="workPhone" value={formData.workPhone} maxLength={14} placeholder='(999) 999-9999'
                                                        onChange={(e) => handlePhoneChange(e, errors, setErrors, form, formData, setFormData)} className="input" />
                                                </Form.Item></Col>
                                        )}

                                        {renderFormItems(visibleFormData, MAPPING_CONST.PROV_PAGE_MESSAGING) && (
                                            <Col span={8} className='form-group'>
                                                <Form.Item label="Messaging" name="messaging" className="form-item-margin">
                                                    <Select defaultValue={formData.messaging} className="select-input input w-100" name="messaging"
                                                        placeholder="Select a messaging" value={formData.messaging}
                                                        onChange={(value) => setFormData({ ...formData, messaging: value })}>
                                                        <Option value="1">None</Option>
                                                        <Option value="2">Text</Option>
                                                        <Option value="3">Vice</Option>
                                                        <Option value="4">Patient Phone Type</Option>
                                                    </Select>
                                                </Form.Item></Col>
                                        )}

                                        {renderFormItems(visibleFormData, MAPPING_CONST.PROV_PAGE_BOTS) && (
                                            <Col span={8} className='form-group'>
                                                <Form.Item label="Bots" name="bots" className="form-item-margin"
                                                    rules={[{ required: true, message: errMessage }]}>
                                                    <Select name="bots" mode="multiple" placeholder="Select a bots" id="bots" className="select-input input w-100"
                                                        onChange={(value) => handleChange(value, "bots", formData, setFormData)}
                                                        value={formData.bots} defaultValue={[]}>

                                                        {bots && bots.map(q => (
                                                            <Option key={q.schedulerId} value={q.schedulerId} name={q.name}>
                                                                {q.name}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item></Col>
                                        )}

                                        <Col span={8} className='form-group'>
                                            <Row key={1} gutter={[16, 16]} align="middle">
                                                <Col span={12} className='form-group'>
                                                    {renderFormItems(visibleFormData, MAPPING_CONST.PROV_PAGE_INIT_FILLOW) && (
                                                        <Form.Item label="Initiate Follow Up" name="reminder">
                                                            <Select mode="single" allowClear style={{ width: '100%' }}
                                                                name="reminder" id="reminder" defaultValue={[]}
                                                                onChange={(value) => handlefollowup(value, "reminder", followUp, setfollowUp)}
                                                                value={followUp.reminder}  >
                                                                <Option value="after end">After</Option>
                                                                <Option value="before end">Before</Option>
                                                            </Select>
                                                        </Form.Item>
                                                    )}
                                                </Col>
                                                <Col span={12} className='form-group'>
                                                    {renderFormItems(visibleFormData, MAPPING_CONST.PROV_PAGE_INIT_FILLOW) && (
                                                        <Form.Item name="time" label="Time">
                                                            <Select mode="single" allowClear style={{ width: '100%', paddingTop: '30' }}
                                                                name="time" id="time" defaultValue={[]}
                                                                onChange={(value) => handlefollowup(value, "time", followUp, setfollowUp)}
                                                                value={followUp.time}  >
                                                                <Option value="5">5 mins</Option>
                                                                <Option value="10">10 mins</Option>
                                                            </Select>
                                                        </Form.Item>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>

                                        {renderFormItems(visibleFormData, MAPPING_CONST.PROV_PAGE_ZOOM) && (
                                            <Col span={8} className='form-group'>
                                                <Form.Item label="Zoom ID" name="zoomId" className="form-item-margin">
                                                    <Input name="zoomId" id="zoomId" value={formData.zoomId}
                                                        onChange={(e) => handleInputChange(e, 'zoomId', formData, setFormData)} className="input" />
                                                </Form.Item></Col>
                                        )}

                                        {renderFormItems(visibleFormData, MAPPING_CONST.PROV_PAGE_HL7) && (
                                            <Col span={8} className='form-group'>
                                                <Form.Item label="HL7" name="hl7" valuePropName="checked">
                                                    <Switch unCheckedChildren="Disabled" checkedChildren="Enabled" id="hl7" name="hl7"
                                                        onChange={(value) => handleChange(value, "hl7", formData, setFormData)} />
                                                </Form.Item></Col>
                                        )}

                                        {renderFormItems(visibleFormData, MAPPING_CONST.PROD_PAGE_PEDI_AGE) && (
                                            <Col span={8} className='form-group'>
                                                <Form.Item label="Pediatric Age" name="pediatricAge" className="form-item-margin">
                                                    <Row key={1} gutter={[16, 16]} align="middle">
                                                        <Col span={12} className='form-group'>
                                                            <Form.Item label="From" name="pediatricAgeFrom" className="form-item-margin"
                                                                rules={[{ required: false, message: errMessage }]}>
                                                                <Input name="pediatricAgeFrom" id="pediatricAgeFrom" className="input"
                                                                    onChange={(e) => handleInputChange(e, 'pediatricAgeFrom', formData, setFormData)} value={formData.pediatricAgeFrom}>
                                                                </Input>
                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={12} className='form-group'>
                                                            <Form.Item label="To" name="pediatricAgeTo" className="form-item-margin">
                                                                <Input name="pediatricAgeTo" id="pediatricAgeTo" className="input"
                                                                    onChange={(e) => handleInputChange(e, 'pediatricAgeTo', formData, setFormData)} value={formData.pediatricAgeTo}>
                                                                </Input>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Form.Item>
                                            </Col>
                                        )}

                                        {renderFormItems(visibleFormData, MAPPING_CONST.PROD_PAGE_ACTIVE) && (
                                            <Col span={8} className='form-group'>
                                                <Form.Item label="Active:" name="active" className="form-item-margin" >
                                                    <Row key={1} gutter={[16, 16]} align="middle">
                                                        <Col span={12} className='form-group'>
                                                            <Form.Item label="From Date" name="activeFrom">
                                                                <DatePicker name="activeFrom" id="activeFrom" className="input"
                                                                    // onChange={(date, dateString) => handleDateTimeChange(date, dateString, "activeFrom")} inputReadOnly={true}>
                                                                    onChange={(date, dateString) => {
                                                                        handleDateTimeChange(date, dateString, formData, setFormData, "activeFrom");
                                                                        handleStartDateChange(date, setEndDate, endDate, setStartDate);
                                                                    }} inputReadOnly={true}>
                                                                </DatePicker>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12} className='form-group'>
                                                            <Form.Item label="To Date" name="activeTo" className="form-item-margin">
                                                                <DatePicker name="activeTo" id="activeTo" className="input"
                                                                    value={(formData.activeTo)}
                                                                    // onChange={(date, dateString) => handleDateTimeChange(date, dateString, "activeTo")} inputReadOnly={true}>
                                                                    onChange={(date, dateString) => {
                                                                        handleDateTimeChange(date, dateString, formData, setFormData, "activeTo");
                                                                        handleEndDateChange(date, startDate, setEndDate)
                                                                    }}
                                                                    disabledDate={(current) => current && current < startDate} inputReadOnly={true}>
                                                                </DatePicker>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Form.Item>
                                            </Col>
                                        )}
                                    </Row>

                                    <Collapse>
                                        {renderFormItems(visibleFormData, MAPPING_CONST.PROV_PAGE_INSURANCE) && (
                                            <Panel header="Insurance" key="1" className=' w-100'>
                                                <Row key={1} gutter={[24]} align="middle">
                                                    <Col span={5} className='form-group'>
                                                        <Form.Item label="Insurances" name="insurances" className="form-item-margin">
                                                            <Select
                                                                placeholder="Select an insurance"
                                                                id="insuranceType"
                                                                className="select-input input w-100"
                                                                onChange={(value, option) => handleSelectChange(value, option.children, setInsuranceItem, insuranceItem)}
                                                                value={insuranceItem.insuranceType}
                                                            >
                                                                {insurances && insurances.map(q => (
                                                                    <Option key={q.insuranceId} value={q.insuranceId}>
                                                                        {q.insuranceName}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={5} className='form-group'>
                                                        <Form.Item label="From Date" name="from" className="form-item-margin" 
                                                        // rules={[{ required: true, message: 'Please select the from date.' }]}
                                                        >
                                                            <DatePicker
                                                                name="from"
                                                                id="from"
                                                                className="input"
                                                                onChange={(date, dateString) => {
                                                                    handleStartDateChange(date, setEndDate, endDate, setStartDate);
                                                                    handleInsuranceChange(date, dateString, "from", insuranceItem, setInsuranceItem);
                                                                }}
                                                                inputReadOnly={true}
                                                                value={startDate}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={5} className='form-group'>
                                                        <Form.Item label="To Date" name="to" className="form-item-margin" 
                                                        // rules={[{ required: true, message: 'Please select the to date.' }]}]
                                                        >
                                                            <DatePicker
                                                                name="to"
                                                                id="to"
                                                                className="input"
                                                                onChange={(date, dateString) => {
                                                                    handleEndDateChange(date, startDate, setEndDate);
                                                                    handleInsuranceChange(date, dateString, "to", insuranceItem, setInsuranceItem);
                                                                }}
                                                                inputReadOnly={true}
                                                                disabledDate={(current) => current && current < startDate}
                                                                value={endDate}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={5} className='form-group'>
                                                        <Form.Item label="Referral Required" name="referralRequired">
                                                            <Checkbox
                                                                id="referralRequired"
                                                                onChange={(e) => handleReferralChange(e.target.checked, insuranceItem, setInsuranceItem)}
                                                                defaultChecked={false} // Initially unchecked
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4} className='form-group'>
                                                        <Button
                                                            type="primary"
                                                            onClick={() => handleAddInsurance(form, setInsuranceItem, setInsuranceItems, insuranceItem, insuranceItems, setStartDate, setEndDate)}
                                                            disabled={!insuranceItem.insuranceType || !insuranceItem.from
                                                                || !insuranceItem.to}
                                                            style={{ marginLeft: 16, marginTop: 21 }}
                                                        >
                                                            Add
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Row gutter={[16, 16]}>
                                                    <Table className='Table w-100' columns={insurancecolumn} dataSource={insuranceItems} rowKey="day" pagination={false} />
                                                </Row>
                                            </Panel>
                                        )}

                                        {renderFormItems(visibleFormData, MAPPING_CONST.PROV_PAGE_VOICE_BOT) && (
                                            <Panel header="Voice Bot" key="2" className=' w-100'>
                                                <Row key={2} gutter={[24, 24]}>
                                                    <Col span={12} className='form-group'>
                                                        <Form.Item label="Profession" name="botProfession" className="form-item-margin">
                                                            <Select defaultValue={formData.botProfession} className="select-input input w-100" name="messaging"
                                                                placeholder="Select a messaging" value={formData.botProfession}
                                                                onChange={(value) => setFormData({ ...formData, botProfession: value })}>
                                                                <Option value="Doctor">Dr</Option>
                                                                <Option value="Nurse practitioner">Nurse practitioner</Option>
                                                                <Option value="Physician's Assistant">Physician Assistant</Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12} className='form-group'>
                                                        <Form.Item label="Phonetic Name" name="botPhoneticName" className="form-item-margin">
                                                            <Input name="botPhoneticName" id="botPhoneticName" value={formData.botPhoneticName}
                                                                onChange={(e) => handleInputChange(e, 'botPhoneticName', formData, setFormData)} className="input" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Panel>
                                        )}
                                    </Collapse>
                                </div>
                                <div className="button-footer" style={{ paddingBottom: 10 }}>
                                    {(opType === "add" || opType === "create") && (
                                        <Button htmlType='submit' icon={<CheckOutlined />} type="primary" id="Add"
                                            onClick={async () => {
                                                const retproviderId = await onSaveProvider(
                                                    form, formData, initialState, requiredState, insuranceItems, followUp,
                                                    setFormData, setDaysOfOperation, setInsuranceItems, setfollowUp,
                                                    setOperations, setErrors
                                                );

                                                if (retproviderId != "") {
                                                    setVisible(true);
                                                    setproviderId(retproviderId);
                                                    setvaluelocation(formData.practiceLocation);
                                                    locationFormData.providerId = retproviderId;
                                                }
                                            }}> Add Providers </Button>
                                    )}
                                    {opType === "update" && (
                                        <Button htmlType='submit' icon={<CheckOutlined />} type="primary" id="Update"
                                            onClick={async () => {
                                                const response = await onUpdateProvider(
                                                    form, providerId, formData, initialState, requiredState, insuranceItems, followUp,
                                                    setFormData, setDaysOfOperation, setInsuranceItems, setfollowUp,
                                                    setOperations, setErrors
                                                );
                                                if (response != "") {
                                                    setvaluelocation(formData.practiceLocation);
                                                    locationFormData.providerId = providerId;
                                                }
                                            }}> Update </Button>
                                    )}
                                    <Button onClick={onBack} icon={<CloseOutlined />} id="Cancel">Cancel</Button>
                                </div>
                            </>
                        </Form>
                    </TabPane>
                    {visible && showLocationTab && showLocationTabAdd && (
                        <TabPane tab="Location" key="2">
                            <Form form={form} requiredMark={false} layout='vertical' initialValues={locationFormData ? locationFormData : []}>
                                {/* <Form.Item label="Practice Location" name="locationId" rules={[{ required: true, message: errMessage }]}
                                    className="form-item-margin">
                                    <Select style={{ width: '100%' }} placeholder="Select a Practice Location"
                                        name="locationId" id="locationId" defaultValue={[]}
                                        onChange={(value) => handleLocation(value, "locationId",setSelectedEvent,setOperations,setDaysOfOperation,setLocationFormData,providerId,form,setlocationId,locationFormData)}>
                                        {locationList && locationList.map(q => (
                                            <Option key={q.practiceLocationid} value={q.practiceLocationid} name={q.locationName}>
                                                {q.locationName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item> */}
                                <Form.Item label="Practice Location" rules={[{ required: true, message: errMessage }]}
                                    className="form-item-margin">
                                    <Select style={{ width: '100%' }} placeholder="Select a Practice Location"
                                        id="locationId" defaultValue={[]}
                                        onChange={(value) => handleLocation(value, "locationId")}>
                                        {locationList && locationList.map(q => (
                                            <Option key={q.practiceLocationid} value={q.practiceLocationid} name={q.locationName}>
                                                {q.locationName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Collapse>
                                    {renderFormItems(visibleFormData, MAPPING_CONST.PROV_PAGE_DAYS_OF_OPER) && (
                                        <Panel header="Days of Operation" key="3" className=' w-100'>
                                            <Row gutter={[24]}>
                                                {/* <Col span={1} className='form-group'><Form.Item label="1" name="week1"> <Checkbox id="week1" value={operations.week1} onChange={(e) => handleWeekChange("week1", e.target.checked, operations, setOperations)} /></Form.Item></Col>
                                                <Col span={1} className='form-group'><Form.Item label="2" name="week2"><Checkbox id="week2" value={operations.week2} onChange={(e) => handleWeekChange("week2", e.target.checked, operations, setOperations)} /></Form.Item></Col>
                                                <Col span={1} className='form-group'><Form.Item label="3" name="week3"><Checkbox id="week3" value={operations.week3} onChange={(e) => handleWeekChange("week3", e.target.checked, operations, setOperations)} /></Form.Item></Col>
                                                <Col span={1} className='form-group'><Form.Item label="4" name="week4"><Checkbox id="week4" value={operations.week4} onChange={(e) => handleWeekChange("week4", e.target.checked, operations, setOperations)} /></Form.Item></Col>
                                                <Col span={1} className='form-group'><Form.Item label="5" name="week5"><Checkbox id="week5" value={operations.week5} onChange={(e) => handleWeekChange("week5", e.target.checked, operations, setOperations)} /></Form.Item></Col>
                                                <Col span={1} className='form-group'><Form.Item label="L" name="weekLast"><Checkbox id="weekLast" value={operations.weekLast} onChange={(e) => handleWeekChange("weekLast", e.target.checked, operations, setOperations)} /></Form.Item></Col> */}


                                                <Col span={1} className='form-group'><Form.Item label="1" name="week1"> <Checkbox id="week1" value={operations.week1} checked={operations.week1 === 1} onChange={(e) => handleWeekChange("week1", e.target.checked, operations, setOperations)} /></Form.Item></Col>
                                                <Col span={1} className='form-group'><Form.Item label="2" name="week2"><Checkbox id="week2" value={operations.week2} checked={operations.week2 === 1} onChange={(e) => handleWeekChange("week2", e.target.checked, operations, setOperations)} /></Form.Item></Col>
                                                <Col span={1} className='form-group'><Form.Item label="3" name="week3"><Checkbox id="week3" value={operations.week3} checked={operations.week3 === 1} onChange={(e) => handleWeekChange("week3", e.target.checked, operations, setOperations)} /></Form.Item></Col>
                                                <Col span={1} className='form-group'><Form.Item label="4" name="week4"><Checkbox id="week4" value={operations.week4} checked={operations.week4 === 1} onChange={(e) => handleWeekChange("week4", e.target.checked, operations, setOperations)} /></Form.Item></Col>
                                                <Col span={1} className='form-group'><Form.Item label="5" name="week5"><Checkbox id="week5" value={operations.week5} checked={operations.week5 === 1} onChange={(e) => handleWeekChange("week5", e.target.checked, operations, setOperations)} /></Form.Item></Col>
                                                <Col span={1} className='form-group'><Form.Item label="L" name="weekLast"><Checkbox id="weekLast" value={operations.weekLast} checked={operations.weekLast === 1} onChange={(e) => handleWeekChange("weekLast", e.target.checked, operations, setOperations)} /></Form.Item></Col>

                                                <Col span={4} className='form-group'>
                                                    <Form.Item label="Day" name="day" className="form-item-margin">
                                                        <Select placeholder="Please select a Day" className="select-input input w-100" value={operations.day}
                                                            onChange={(value) => setOperations({ ...operations, day: value })}>
                                                            <Option value="Sunday">Sunday</Option>
                                                            <Option value="Monday">Monday</Option>
                                                            <Option value="Tuesday">Tuesday</Option>
                                                            <Option value="Wednesday">Wednesday</Option>
                                                            <Option value="Thursday">Thursday</Option>
                                                            <Option value="Friday">Friday</Option>
                                                            <Option value="Saturday">Saturday</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={5} className='form-group'>
                                                    <Form.Item label="From Time" name="fromTime" className="form-item-margin" rules={[{ required: false, message: errMessage }]}>
                                                        <TimePicker use12Hours format="h:mm a" name="fromTime" id="from" className="input" value={startTime} status={error ? 'error' : ''}
                                                            onChange={(time, timeString) => handleStartTimeChange(time, timeString, "from", formData1, setFormData1, operations, setOperations)} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={5} className='form-group'>
                                                    <Form.Item label="To Time" name="to" className="form-item-margin" rules={[{ required: false, message: errMessage }]}>
                                                        <TimePicker use12Hours format="h:mm a" name="to" id="to" className="input" value={endTime} status={error ? 'error' : ''} disabledTime={disabledTime}
                                                            onChange={(time, timeString) => handleEndTimeChange(time, timeString, "to", formData1, setFormData1, operations, setOperations)} />

                                                    </Form.Item>
                                                    {error && <div style={{ color: 'red' }}>Please select valid start and end times.</div>}
                                                </Col>
                                                <Col span={4} className='form-group'>
                                                    <Button type="primary" onClick={() => handleAddAppointment(operations, setOperations, form, setDaysOfOperation, daysOfOperation, setStartTime, setEndTime)} style={{ marginLeft: 16, marginTop: 21 }}
                                                        disabled={!operations.day || !operations.from || !operations.to}>
                                                        Add
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Table className='Table w-100' columns={dayofOperationcolumn} dataSource={daysOfOperation} rowKey="day" pagination={false} />
                                            </Row>
                                        </Panel>
                                    )}

                                    {renderFormItems(visibleFormData, MAPPING_CONST.PROV_PAGE_DOUBLE_BOOK) && (
                                        <Panel header="Double Booking" key="4" className=' w-100'>
                                            <Row gutter={[16, 16]}>
                                                <Col span={24} className='form-group'>
                                                    <Form.Item
                                                        label="Double Booking:"
                                                        name="doubleBooking"
                                                        className="form-item-margin"
                                                        rules={[{ required: false, message: 'Error message' }]}
                                                    >
                                                        <Button type="primary" onClick={() => showModal(setIsModalVisible)}>
                                                            Manage
                                                        </Button>
                                                        <br />
                                                        <Modal
                                                            title="Double Booking"
                                                            open={isModalVisible} // Changed prop name from open to visible
                                                            onCancel={() => handleCancel(setRefreshCalendar, setIsModalVisible)}
                                                            // onOk={handleOk}
                                                            footer={null}
                                                            width={1000}
                                                            maskClosable={false}
                                                        >
                                                            {/* <DoubleBookingCalendar onEventSelection={handleEventSelection} onDeleteEvent={handleDeleteEvent} doublebooking={selectedEvent} /> */}
                                                            {/* <DoubleBookingCalendar
                                                                key={refreshCalendar ? 'refresh' : 'normal'} // Add key prop for remounting
                                                                onEventSelection={(formattedTimeRange, clickedDate) => handleEventSelection(formattedTimeRange, clickedDate, setSelectedEvent)}
                                                                onDeleteEvent={(selectedEventrange, formattedTimeRange, selectedDay) => handleDeleteEvent(selectedEvent, setSelectedEvent)}
                                                                doublebooking={selectedEvent}
                                                            /> */}
                                                            <DoubleBookingCalendar
                                                                key={refreshCalendar ? 'refresh' : 'normal'} // Add key prop for remounting
                                                                onEventSelection={handleEventSelection}
                                                                onDeleteEvent={handleDeleteEvent}
                                                                doublebooking={selectedEvent}
                                                            />
                                                        </Modal>
                                                        {formattedEvents.length > 0 && <Table dataSource={formattedEvents} columns={doublebookingcolumns}
                                                            showHeader={false} className='table-container' bordered />}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Panel>
                                    )}
                                </Collapse>
                            </Form>

                            <div className="button-footer" style={{ paddingBottom: 10 }}>
                                {(opType === "add" || opType === "create") && (
                                    <Button htmlType='submit' icon={<CheckOutlined />} type="primary" id="Add"
                                        onClick={async () => {
                                            const success = await onSaveLocationDetails(
                                                providerId, locationId, locationFormData, initialLocationState, daysOfOperation,
                                                setLocationFormData, setDaysOfOperation, setOperations, operationjson, jsonFormattedEvents, formattedEvents);
                                        }}> Save </Button>
                                )}
                                {opType === "update" && (
                                    <Button icon={<CheckOutlined />} type="primary" id="Update"
                                        onClick={async () => {
                                            const success = await onSaveLocationDetails(
                                                providerId, locationId, locationFormData, initialLocationState, daysOfOperation,
                                                setLocationFormData, setDaysOfOperation, setOperations, operationjson, jsonFormattedEvents, formattedEvents);
                                        }}
                                    // onClick={handleSaveButtonClick}
                                    >
                                        Update</Button>
                                )}
                                <Button onClick={onBack} icon={<CloseOutlined />} id="Cancel">Cancel</Button>
                            </div>
                        </TabPane>
                    )}

                    {opType === "update" && (
                        <TabPane tab="Configuration" key="3">
                            <Configuration providerid={providerData.providerid} praticeid={1} level={"PROVIDER"} />
                        </TabPane>
                    )}
                </Tabs>
            </Skeleton>
        </div >
    );
};

export { ProviderForm };