import React, { useState } from 'react';
import { Input, Modal, Form } from 'antd';

const SecuritySuccess = () => {

    return (
        <div>

            <Form>
                <Form.Item label="Enter your pin to continue" colon={false}>
                    <Input placeholder='Enter your pin' />
                </Form.Item>
            </Form>
        </div>
    );
};

export default SecuritySuccess;
