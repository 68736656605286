import React, { useState, useEffect } from 'react';
import { Checkbox, Form, Button, Row, Col, message } from 'antd';
import { getConfigurations, saveConfigurations } from '../../services/configurationService';

const { Item } = Form;

const ProviderPage = (props) => {
  const [fields, setFields] = useState([]);

  const initialFields = [
    { name: "Show Voice Bot", code: "PROV_PAGE_VOICE_BOT", value: "false" },
    { name: "Contact details", code: "PROV_PAGE_CONTACT", value: "false" },
    { name: "Days of Operation", code: "PROV_PAGE_DAYS_OF_OPER", value: "false" },
    { name: "Double Booking", code: "PROV_PAGE_DOUBLE_BOOK", value: "false" },
    { name: "Insurance", code: "PROV_PAGE_INSURANCE", value: "false" },
    { name: "Action", code: "PROV_PAGE_ACTION", value: "false" },
    { name: "Vacation Schedule", code: "PROV_PAGE_VAC_SCHE", value: "false" },
    { name: "Messaging", code: "PROV_PAGE_MESSAGING", value: "false" },
    { name: "Bots", code: "PROV_PAGE_BOTS", value: "false" },
    { name: "Initiate Follow Up", code: "PROV_PAGE_INIT_FILLOW", value: "false" },
    { name: "HL7", code: "PROV_PAGE_HL7", value: "false" },
    { name: "Zoom ID", code: "PROV_PAGE_ZOOM", value: "false" },
    { name: "Client Name", code: "PROD_PAGE_CLIENT_NAME", value: "false" },
    { name: "Title", code: "PROD_PAGE_TITLE", value: "false" },
    { name: "NPI", code: "PROD_PAGE_NPI", value: "false" },
    { name: "Pediatric Age", code: "PROD_PAGE_PEDI_AGE", value: "false" },
    { name: "Do Not Allow SameDay Appointment", code: "PROD_PAGE_SAMEDAY_APP", value: "false" },
    { name: "Active", code: "PROD_PAGE_ACTIVE", value: "false" }
  ];

  useEffect(() => {
    fetchConfigurations();
  }, []);

  const fetchConfigurations = async () => {
    try {
      const body = {
        level: "PRACTICE",
        practiceId: 1,
        providerId: null,
        practiceLocationId: null
      };

      const response = await getConfigurations(body);
      const parsedResponse = JSON.parse(response[0].value);

      const updatedFields = initialFields.map(field => {
        const configField = parsedResponse.fields.find(f => f.code === field.code);
        return configField ? { ...field, value: configField.value } : field;
      });

      setFields(updatedFields);
    } catch (error) {
      console.error('Error fetching configurations:', error);
    }
  };

  const handleCheckboxChange = (index) => {
    const newFields = [...fields];
    newFields[index].value = newFields[index].value === "true" ? "false" : "true";
    setFields(newFields);
  };

  const handleSave = async () => {
    const jsonData = {
      fields: fields.map(field => ({
        name: field.name,
        code: field.code,
        value: field.value,
        option: field.option,
        type: field.type
      }))
    };

    let configData = props.onconfigData;
    configData[0].value = JSON.stringify(jsonData);

    try {
      await saveConfigurations(configData);
      message.success('Advanced configurations saved successfully');
    } catch (error) {
      message.error('Error saving advanced configurations');
    }
  };

  return (
    <div>
      <Row gutter={16}>
        {fields.map((field, index) => (
          <Col key={field.code} xs={24} sm={12} md={8} lg={6}>
            <div key={index}>
              <Form.Item>
                <Checkbox
                  checked={field.value === "true"}
                  onChange={() => handleCheckboxChange(index)}
                >
                  <span className="custom-label">{field.name}</span>
                </Checkbox>
              </Form.Item>
            </div>
          </Col>
        ))}
      </Row>
      <Button type='primary' onClick={handleSave}>Save</Button>
    </div>
  );
};

export default ProviderPage;
