//#region Packages
import React, { useRef, useState, useEffect } from "react";
import { Form, Input, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { onSubmit } from "./handlers/passwordFormHandler";
import "../../../assets/styles/changePassword.css"
import { validatePassword } from '../utilities/utility';

function PasswordForm ({setModalVisible}) {
    const tempUserId = sessionStorage.getItem('userId');
    const [form] = Form.useForm();
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const passwordRef = useRef(null);

    useEffect(() => {
        passwordRef.current.focus();
    }, []);

    const handleSubmit = async (values) => {
        try {
            await onSubmit(values, tempUserId, form, navigate,setModalVisible);
            //  onClose(); // Close the modal after submission
            // form.resetFields(); // Reset form fields
            // window.location.reload(); // Reload the page
            
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    return (
        <div>
            <div className="custom-card-content">
                <Form colon={false} form={form} layout="vertical" onFinish={handleSubmit} requiredMark="optional">
                    <div className="guest-box-msg">
                        {/* <p>Change your password to login</p> */}
                    </div>
                    <Form.Item label="Old password" name="oldpassword"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the Old password',
                            }
                        ]} className="form-item-margin">
                        <Input.Password ref={passwordRef} />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        label="New password" className="form-item-margin"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your password!',
                            },
                            {
                                validator: validatePassword,
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="confirmpassword"
                        label="Confirm password" className="form-item-margin"
                        dependencies={['password']}
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The new password that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <div className="btnfosubmit">
                        <Button type="primary" htmlType="submit">Submit</Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default PasswordForm;
