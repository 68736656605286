 import axios from "axios";

export async function getDropdowns(practiceId) {
    let config = [];
    await axios
        .post('get-dropdowns',
            {
                practiceId: practiceId
            })
        .then(res => {
            config = res.data
        })
        .catch(err => {
            console.log(err)
        });

    return config;
}
export async function getRegistrationPages(formName) {
    let dropDownList = [];
    await axios
        .post('get-registration-pages',
            {
                formId: formName
            })
        .then(res => {
            dropDownList = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return dropDownList;
}
export async function savePersonalInformation(uniqueHash, formValues) {
    let formResponse = [];
    await axios
        .post('save-personal-information',
            {
                uniqueHash: uniqueHash,
                formValues: formValues

            })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function saveContactInformationForm(uniqueHash, formValues) {
    let formResponse = [];
    await axios
        .post('save-contact-information',
            {
                uniqueHash: uniqueHash,
                formValues: formValues
            })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function savePharmacyInformationForm(uniqueHash, formValues) {
    let formResponse = [];
    await axios
        .post('save-pharmacy-information',
            {
                uniqueHash: uniqueHash,
                formValues: formValues

            })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function savePrimaryCarePhysicianInformationForm(uniqueHash, formValues) {
    let formResponse = [];
    await axios
        .post('save-primary-care-physician-information',
            {
                uniqueHash: uniqueHash,
                formValues: formValues

            })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function saveInsuranceInformationForm(uniqueHash, formValues) {
    let formResponse = [];
    await axios
        .post('save-insurance-information',
            {
                uniqueHash: uniqueHash,
                formValues: formValues

            })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function getPatientRegistrationInformation(uniqueHash) {
    let config = [];
    await axios
        .post('get-patient-registration-info',
            {
                uniqueHash: uniqueHash
            })
        .then(res => {
            config = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return config;
}

export async function saveLicenseUploadForm(formDatas) {
    let formResponse = [];
    await axios
        .post('save-license-upload', formDatas, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function saveConsent7Form(uniqueHash, formValues) {
    let formResponse = [];
    await axios
        .post('save-consent-form-7',
            {
                uniqueHash: uniqueHash,
                formValues: formValues

            })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function saveConsentForms(uniqueHash, formValues) {
    let formResponse = [];
    await axios
        .post('save-consent-forms',
            {
                uniqueHash: uniqueHash,
                formValues: formValues

            })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function saveInsuranceUploadForm(formDatas) {
    let formResponse = [];
    await axios
        .post('save-insurance-upload', formDatas, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}

export async function getPdfFiles(uniqueHash) {
    let formResponse = [];
    await axios
        .post('get-pdf-files',
            {
                uniqueHash: uniqueHash
            })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function verifyLicensePassword(uniqueHash, formValues) {
    let formResponse = [];
    await axios
        .post('verify-license-password',
            {
                uniqueHash: uniqueHash,
                formValues: formValues

            })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function verifyInsurancePassword(uniqueHash, formValues) {
    let formResponse = [];
    await axios
        .post('verify-insurance-password',
            {
                uniqueHash: uniqueHash,
                formValues: formValues

            })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function verifyConsentsPassword(uniqueHash, formValues) {
    let formResponse = [];
    await axios
        .post('verify-consent-password',
            {
                uniqueHash: uniqueHash,
                formValues: formValues

            })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function verifyDateOfBirth(uniqueHash, dateOfBirth) {
    let formResponse = [];
    await axios
        .post('verify-date-of-birth',
            {
                uniqueHash: uniqueHash,
                dateOfBirth: dateOfBirth
            })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function saveCurrentMedications(uniqueHash, currentMedication) {
    let formResponse = [];
    await axios
        .post('save-current-medications',
            {
                uniqueHash: uniqueHash,
                currentMedication: currentMedication

            })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function saveAllergies(uniqueHash, allergies) {
    let formResponse = [];
    await axios
        .post('save-allergies',
            {
                uniqueHash: uniqueHash,
                allergies: allergies
            })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function saveImmunizationHistory(formDatas) {
    let formResponse = [];
    await axios
        .post('save-immunization-history', formDatas, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function savePatientMedicalHistory(uniqueHash, data) {
    let formResponse = [];
    await axios
        .post('save-patient-medical-history',
            {
                uniqueHash: uniqueHash,
                PatientMedicalHistory: data
            })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function savePatientWithSpecialHealthcare(uniqueHash, data) {
    let formResponse = [];
    await axios
        .post('save-patient-special-healthcare',
            {
                uniqueHash: uniqueHash,
                PatientWithSpecialHealthcare: data
            })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function saveSlidingFeeDiscount(uniqueHash, data) {
    let formResponse = [];
    await axios
        .post('save-sliding-fee-discount',
            {
                uniqueHash: uniqueHash,
                SlidingFeeDiscount: data
            })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function saveHealthInformation(uniqueHash, data) {
    let formResponse = [];
    await axios
        .post('save-health-information',
            {
                uniqueHash: uniqueHash,
                HealthInformation: data

            })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function saveMetroConsent8Form(uniqueHash, formValues) {
    let formResponse = [];
    await axios
        .post('save-share-info-text-message-consent-form',
            {
                uniqueHash: uniqueHash,
                formValues: formValues
            })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function savePatientSignature(url) {
    let formResponse = [];
    await axios
        .post('save-patient-signature',
            {
                url: url
            })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function saveHIVConsent(uniqueHash, formValues) {
    let formResponse = [];
    await axios
        .post('save-hiv-consents',
            {
                uniqueHash: uniqueHash,
                formValues: formValues
            })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function saveAcknowledgementConsent(uniqueHash, formValues) {
    let formResponse = [];
    await axios
        .post('save-acknowledgement-consents',
            {
                uniqueHash: uniqueHash,
                formValues: formValues
            })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function saveAuthorizationConsent(uniqueHash, formValues) {
    let formResponse = [];
    await axios
        .post('save-authorization-consents',
            {
                uniqueHash: uniqueHash,
                formValues: formValues
            })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
export async function saveAccessPatientConsent(uniqueHash, formValues) {
    let formResponse = [];
    await axios
        .post('save-access-patient-info-consents',
            {
                uniqueHash: uniqueHash,
                formValues: formValues
            })
        .then(res => {
            formResponse = res.data
        })
        .catch(err => {
            console.log(err)
        });
    return formResponse;
}
