import React from 'react';
import { Form, Input, Button, Radio, DatePicker, Row, Col } from 'antd';
import "../../assets/styles/CRM.css";

const { TextArea } = Input;

const PatientForm = () => {
  const [form] = Form.useForm();

  return (
    <>
      <Form form={form} layout="vertical" style={{ padding: '5px' }}>

        <div className='commonborder'>
          <h3>Patient Info :</h3>
          <Row gutter={[16, 16]}>

            <Col xs={24} md={12}>
              <Form.Item label="Patient's Name (Last Name, First Name, Middle Initial)" name="patientname">
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item label="Patient's Birth Date" name="dob">
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>


          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item label="Address (No., Street)" name="address">
                <TextArea rows={1} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="City" name="patientcity">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item label="State" name="patientstate">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Zip Code" name="patientzipcode">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item label="Telephone (Include Area Code)" name="patienttelephone">
                <Input addonBefore="+91" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item label="Patient Relationship To Insured" name="patientRelationship">
                <Radio.Group>
                  <Radio value="self">Self</Radio>
                  <Radio value="spouse">Spouse</Radio>
                  <Radio value="child">Child</Radio>
                  <Radio value="other">Other</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

        </div>
        <hr></hr>
        <div className='commonborder'>
          <h3>Insurance Info : </h3>
          <Row>
            <Col span={16} xs={24} md={24}>
              <Form.Item>
                <Radio.Group label="1." className="radio-group-vertical">
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={8}>
                      <Radio value="medicare"> MEDICARE (MEDICARE)</Radio></Col>
                    <Col xs={24} md={8}>
                      <Radio value="medicaid">MEDICAID (MEDICAID#)</Radio></Col>
                    <Col xs={24} md={8}>
                      <Radio value="tricare">TRICARE (id#/dod#)</Radio>
                    </Col>
                    <Col xs={24} md={8}>
                      <Radio value="champva">CHAMPVA (MEMBER ID#)</Radio>
                    </Col>
                    <Col xs={24} md={8}>
                      <Radio value="group health">GROUP HEALTH PLAN (ID#)</Radio>
                    </Col>
                    <Col xs={24} md={8}>
                      <Radio value="feca">FECA BLK LUNG (ID#)</Radio>
                    </Col>
                    <Col xs={24} md={8}>
                      <Radio value="other">OTHER (ID#)</Radio>
                    </Col>
                  </Row>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <br></br>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item label="Insured's I.d Number (For Program In Item 1)" name="insured">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Insured's Name (Last Name, First Name, Middle Initial)" name="insuredName">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item label="Address (No., Street)" name="insuredAddress">
                <TextArea rows={1} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="City" name="insuredCity">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item label="State" name="insuredState">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Zip Code" name="insuredZipcode">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item label="Telephone (Include Area Code)" name="insuredTelephone">
                <Input addonBefore="+91" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item label="Reserved For Nucc Use" name="NUCC">
                {/* <Input style={{ fontSize: '24px', fontWeight: 'bold', lineHeight: '0.5' }} /> */}
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <>
            <h4>Other Insured Info : </h4>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Form.Item label="Other Insured's Name (Last Name, First Name, Middle Initial)" name="otherInsuredName">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="A. Other Insured's Policy Or Group Number" name="otherInsuredPolicyNumber">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Form.Item label="B. Reserved For Nucc Use" name="reservedForNUCCUseB">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="C. Reserved For Nucc Use" name="reservedForNUCCUseC">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Form.Item label="D. Insurance Plan Name Or Program Name" name="insurancePlanName">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </>

          <>
            <h4>Is Patient's Condition Related To : </h4>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Form.Item label="A. Employment? (Current Or Previous)" name="employment" style={{ marginBottom: 0 }}>
                  <Radio.Group>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="B. Auto Accident?" name="autoAccident" style={{ marginBottom: 0 }}>
                  <Radio.Group>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={8}>
                <Form.Item label="C. Other Accident?" name="otherAccident">
                  <Radio.Group>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

            </Row>
          </>


          <>
            <h4>Insured's Policy Group : </h4>

            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Form.Item label="Insured's Policy Group Or Feca Number" name="insuredPolicyNumber">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="A. Insured's Date Of Birth" name="insuredDOB">
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Form.Item label="B. Other Claim Id (Designated By Nucc)" name="otherClaimID">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="C. Insurance Plan Name Or Program Name" name="insurancePlanNameOrProgram">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Form.Item label="D. Is There Another Health Benefit Plan?" name="anotherHealthBenefitPlan">
                  <Radio.Group>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Radio.Group>
                  <label>( <b>If yes,</b> complete items 9, 9a and 9d )</label>
                </Form.Item>
              </Col>
            </Row>
          </>

          <>
            <h4>Read Back Of Form Before Completing & Signing This Form : </h4>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item label="PATIENT'S OR AUTHORIZED PERSON'S SIGNATURE. I authorize the release of any medical or other information necessary to process this claim.
                 I also request payment of government benefits either to myself or to the party who accepts assignment below" name="form" style={{ marginBottom: 0 }}>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item label="Signed" name="signed" style={{ marginBottom: 0 }}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Date" name="date" style={{ marginBottom: 0 }}>
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item label="INSURED'S OR AUTHORIZED PERSON'S SIGNATURE. I authorize payment
                 of medical benefits to the undersigned physician or supplier for services 
                 described below.">
                </Form.Item>
                <Form.Item label="Signed" name="insuredSignature" style={{ marginTop: 20 }}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </>
        </div>

      </Form >
    </>
  );
};

export default PatientForm;
