import React, { useState, useEffect } from "react";
import {
    Input, Button, Form, DatePicker, Select, Radio, Row, Col, Layout, TimePicker,
    Modal, ColorPicker,message
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { fetchProviderLoadData } from '../scheduler/handlers/scheduleAppointmentHandler';
import { fetchPracticeLocation, saveAppointmentBlock } from '../../services/appointmentBlockService';
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
const { Option } = Select;

const AppointmentBlock = ({ onBack }) => {
    const [form] = Form.useForm();
    const [blockColor, setBlockColor] = useState('#100F0F');
    const [pickerType, setPickerType] = useState('range');
    const [rangePickerValue, setRangePickerValue] = useState(null);
    const [multipleDatePickerValue, setMultipleDatePickerValue] = useState([]);
    const [radioValue, setRadioValue] = useState('range');
    const [providerData, setProviderData] = useState([]);
    const [locationData, setLocationData] = useState([]);
    const [error, setError] = useState("");
    const [endTime, setEndTime] = useState(null);
    const [operations, setOperations] = useState({
        day: '',
        startTime: null,
        endTimeTime: null
    });
    const [startTime, setStartTime] = useState(null);
    const errMessage = "Field should not empty";

    useEffect(() => {
        fetchProviderLoadData(setProviderData);
    }, []);

    const handleProviderChange = async (selectedProviderIds) => {
        form.resetFields(['practiceLocationId']);
        try {
            const locationsData = await fetchPracticeLocation(selectedProviderIds);
            setLocationData(locationsData.data.providerLocation[0]);
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
    };

    const handleFormSubmit = async (values) => {
        try {
            let dates = [];

            if (pickerType === 'range' && rangePickerValue) {
                const startDate = rangePickerValue[0].format('YYYY-MM-DD');
                const endDate = rangePickerValue[1].format('YYYY-MM-DD');

                let currentDate = dayjs(startDate);
                while (currentDate <= dayjs(endDate)) {
                    dates.push(currentDate.format('YYYY-MM-DD'));
                    currentDate = currentDate.clone().add(1, 'days');
                }

            } else if (pickerType === 'multiple' && multipleDatePickerValue.length > 0) {
                dates = multipleDatePickerValue.map(date => date.format('YYYY-MM-DD'));
            }

            if (dates.length === 0) {
                setError("Please select dates.");
                Modal.error({
                    title: "Error",
                    content: "Please select dates.",
                });
                return;
            }

            const currentDate = new Date();
            const formattedCurrentDate = currentDate.toISOString().slice(0, 19).replace('T', ' ');

            let appointmentBlockData = {
                providerId: values.providerId,
                practiceLocationId: values.practiceLocationId,
                practiceId: 1,
                dateType: pickerType,
                dates: dates,
                startTime: values.startTime.format('HH:mm:ss'),
                endTime: values.endTime.format('HH:mm:ss'),
                description: values.description,
                comments: values.comments || '',
                color: blockColor,
                bookedAt: formattedCurrentDate,
                transportation: 1,
            };
            const response = await saveAppointmentBlock(appointmentBlockData);

            if (response && response.data && response.data.success) {
                Modal.success({ title: "Success", content: "Appointment block saved successfully." });
                form.resetFields();
                setRangePickerValue(null);
                setMultipleDatePickerValue([]);
                setBlockColor('#100F0F');
            }
            else {
                if (response && response.data && response.data.businessRules && response.data.businessRules.length > 0) {
                    const firstError = response.data.businessRules[0].description;
                    setError(firstError);
                    Modal.error({
                        title: "Error",
                        content: firstError,
                    });
                } else {
                    setError("Error saving data");
                }
            }
        } catch (error) {
            console.error("Error saving appointment block data:", error);
        }
    };


    const handleColorChange = (value) => {
        setBlockColor(value.toHexString());
    };

    const handleRadioChange = (e) => {
        const value = e.target.value;
        setRadioValue(value);
        setPickerType(value);
        if (value === 'range') {
            setMultipleDatePickerValue([]);
        } else if (value === 'multiple') {
            setRangePickerValue(null);
        }
    };

    const handleRangePickerChange = (dates) => {
        setRangePickerValue(dates);
        setRadioValue('range');
        setPickerType('range');
        if (dates && dates.length) {
            setMultipleDatePickerValue([]);
        }
    };

    const handleMultipleDatePickerChange = (dates) => {
        setMultipleDatePickerValue(dates);
        setRadioValue('multiple');
        setPickerType('multiple');
        if (dates && dates.length) {
            setRangePickerValue(null);
        }
    };
    const disabledTime = (current) => {
        if (!startTime || !endTime) {
            return null;
        }
        if (current && (current.isBefore(startTime) || current.isAfter(endTime))) {
            return {
                disabled: true,
            };
        }
        return null;
    };
    const handleStartTimeChange = (time, timeString) => {

        if (endTime && time.isAfter(endTime)) {
            setError(true);
            setStartTime(null);
            // message.error('Start time should be before end time');
        } else {
            setError(false);
            setStartTime(time);
        }
        setOperations(prevOperations => ({
            ...prevOperations,
            fromTime: timeString
        }));
    };

    const handleEndTimeChange = (time, timeString) => {
        if (startTime && time.isBefore(startTime)) {
            setError(true);
            setEndTime(null);
        } else {
            setError(false);
            setEndTime(time);
        }
        setOperations(prevOperations => ({
            ...prevOperations,
            toTime: timeString
        }));
    };

    const disabledDate = (current) => {
        return current && current < dayjs().startOf('day');
    };

    return (
        <div className="form-container">
            <Form form={form} colon={false} layout="vertical" onFinish={handleFormSubmit}>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <Form.Item label="Providers & Resources" name="providerId" rules={[{ required: true, message: 'Please select a provider' }]}>
                            <Select
                                mode="single"
                                allowClear
                                defaultActiveFirstOption={false}
                                maxTagCount={2}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={handleProviderChange}
                            >
                                {providerData && providerData.length > 0 &&
                                    [...new Set(providerData.map(provider => `${provider.firstName} ${provider.lastName}`))].map((provider, index) => (
                                        <Select.Option key={index} value={provider.providerId}>
                                            {provider}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Locations" name="practiceLocationId" rules={[{ required: true, message: 'Please select at least one location' }]}>
                            <Select
                                mode="multiple"
                                showSearch
                                placeholder="Select a Location"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {Array.isArray(locationData) && locationData.map(location => (
                                    <Option key={location.id} value={location.practiceLocationId}>
                                        {location.locationName}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label={<><Radio value="range" checked={radioValue === 'range'} onChange={handleRadioChange} /> Date Range</>}
                            rules={[{ required: pickerType === 'range', message: 'Please select a date range' }]}
                        >
                            <RangePicker
                                value={pickerType === 'range' ? rangePickerValue : null}
                                onChange={handleRangePickerChange}
                                onCalendarChange={() => setRadioValue('range')}
                                disabledDate={disabledDate}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label={<><Radio value="multiple" checked={radioValue === 'multiple'} onChange={handleRadioChange} /> Select date(s)</>}
                            rules={[{ required: pickerType === 'multiple', message: 'Please select dates' }]}
                        >
                            <DatePicker
                                value={pickerType === 'multiple' ? multipleDatePickerValue : null}
                                onChange={handleMultipleDatePickerChange}
                                picker="date"
                                onOpenChange={() => setRadioValue('multiple')}
                                multiple
                                disabledDate={disabledDate}
                            />
                        </Form.Item>
                    </Col>

                    {/* <Col span={8}>
                        <Form.Item
                            label="Start Time"
                            name="startTime"
                            rules={[
                                { required: true, message: 'Please enter a start time' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || !endTime || value.isBefore(endTime)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Start time should be before end time'));
                                    },
                                }),
                            ]}
                        >
                            <TimePicker onChange={handleStartTimeChange} />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="End Time"
                            name="endTime"
                            rules={[
                                { required: true, message: 'Please enter an end time' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || !startTime || value.isAfter(startTime)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('End time should be after start time'));
                                    },
                                }),
                            ]}
                        >
                            <TimePicker onChange={handleEndTimeChange} />
                        </Form.Item>
                    </Col> */}
                    <Col span={8}>
                        <Form.Item label="Start Time" name="startTime" className="form-item-margin"
                            rules={[{ required: false, message: errMessage }]}>
                            <TimePicker use12Hours format="h:mm a" name="startTime" id="startTime" className="input" status={error ? 'error' : ''}
                                placeholder="Select Start Time"
                                disabledTime={disabledTime}
                                onChange={(time, timeString) => handleStartTimeChange(time, timeString, "startTime")} inputReadOnly={true} ></TimePicker>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="End Time" name="endTime" className="form-item-margin"
                            rules={[{ required: false, message: errMessage }]}>
                            <TimePicker use12Hours format="h:mm a" name="endTime" id="endTime" className="input" status={error ? 'error' : ''}
                                placeholder="Select End Time"
                                disabledTime={disabledTime}
                                onChange={(time, timeString) => handleEndTimeChange(time, timeString, "endTime")} inputReadOnly={true}></TimePicker>
                        </Form.Item>
                        {error && <div style={{ color: 'red' }}>Please select valid start and end times.</div>}

                    </Col>

                    <Col span={8}>
                        <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Please enter a description' }]}>
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Comments" name="comments">
                            <TextArea className="text-area" rows={1} />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Block Color" name="blockColor" initialValue={blockColor}>
                            <ColorPicker
                                className="colorPcker"
                                defaultValue="#1677ff"
                                onChange={handleColorChange}
                                showText={(color) => <span>Custom Text ({color.toHexString()})</span>}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>

                    </Col>
                </Row>

                <Layout.Footer >
                    <Row justify="end" >
                        <Col  className="buttongap">
                            <Button type="primary" htmlType="submit" >
                                Save
                            </Button>
                        </Col>
                        <Col  className="buttongap">
                            <Button onClick={onBack}>
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Layout.Footer>

            </Form>
        </div>
    );
};

export default AppointmentBlock;
