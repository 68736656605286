// formValidationRules.js
const AppointmentTypeValidation = {
    name: [
        { required: true, message: "Name is required" },
    ],
    description: [
        { required: true, message: "Description is required" }
    ],
    duration: [
        { required: true, message: "Duration is required" }
    ],

};

export { AppointmentTypeValidation };