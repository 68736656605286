import React from 'react';
import { Result } from 'antd';


export default function ExpirePage() {

    // function handleButtonClick() {
    //     navigate('/login');
    // }

    return (
        <Result
            status="error"
            title="Link Expired"
            subTitle="The link you are trying to access has expired. Please contact the administrator for further assistance."
        // extra={<Button type="primary" onClick={() => handleButtonClick()}>Login</Button>}
        />
    );
};
