import DefaultModule from "../../utils/defaultModule";

function Appointment(props) {
  return (
    <DefaultModule moduleCode={props} />
  );
}


export default Appointment;
