export const MAPPING_CONST = {
    //ADD CONST VALUES HERE AND USE
    RESET_PASS_INCORRECT_DATA: 1,
    APPTY_PRACTICE_ID: 1,
    PROD_PAGE_CLIENT_NAME: "PROD_PAGE_CLIENT_NAME",
    PROD_PAGE_TITLE: "PROD_PAGE_TITLE",
    PROD_PAGE_NPI: "PROD_PAGE_NPI",
    PROD_PAGE_SAMEDAY_APP: "PROD_PAGE_SAMEDAY_APP",
    PROV_PAGE_ACTION: "PROV_PAGE_ACTION",
    PROV_PAGE_VAC_SCHE: "PROV_PAGE_VAC_SCHE",
    PROV_PAGE_CONTACT: "PROV_PAGE_CONTACT",
    PROV_PAGE_MESSAGING: "PROV_PAGE_MESSAGING",
    PROV_PAGE_BOTS: "PROV_PAGE_BOTS",
    PROV_PAGE_INIT_FILLOW: "PROV_PAGE_INIT_FILLOW",
    PROV_PAGE_ZOOM: "PROV_PAGE_ZOOM",
    PROV_PAGE_HL7: "PROV_PAGE_HL7",
    PROD_PAGE_PEDI_AGE: "PROD_PAGE_PEDI_AGE",
    PROD_PAGE_ACTIVE: "PROD_PAGE_ACTIVE",
    PROV_PAGE_DOUBLE_BOOK: "PROV_PAGE_DOUBLE_BOOK",
    PROV_PAGE_INSURANCE: "PROV_PAGE_INSURANCE",
    PROV_PAGE_DAYS_OF_OPER: "PROV_PAGE_DAYS_OF_OPER",
    PROV_PAGE_VOICE_BOT: "PROV_PAGE_VOICE_BOT",
}

export const MESSAGES = {
    //ADD MESSAGES HERE AND USE
    MESSAGE_RESET_PASS_INCORRECT: "The security answer or phone number entered is incorrect",
    MESSAGE_RESET_PASS_SUCCESS: "Successfully Updated.",
    MESSAGE_RESET_PASS_SENT: "Successfully mail sent.",
    RESPONSE_ERROR: "Something went wrong. Re-login and try again.",
    MESSAGE_APPTY_SAVE: "Appointment Type Created Successfully.",
}
