import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Result } from 'antd';


export default function AppError() {
    const navigate = useNavigate();

    function handleButtonClick() {
        navigate('/login');
    }

    return (
        <Result
            status="500"
            title="500"
            subTitle="Sorry, something went wrong."
            extra={<Button type="primary" onClick={handleButtonClick}>Login</Button>}
        />
    );
};
