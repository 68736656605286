import { fetchPatientsBookAppointment, fetchVisitTypeData, fetchReasonForVisitDropdownData } from "../../../services/scheduleAppointmentService";
import { fetchRegisterDetails, } from "../../../services/registerService";
import { providerList, } from "../../../services/providerService";

const PracticeId = 1;

// export const fetchPatientBookAppointmentData = async (setPatientNameData) => {
//     try {
//         const response = await fetchPatientsBookAppointment();
//         console.log(response, "book APPT");
//         setPatientNameData(response.patients);
//     } catch (error) {
//         console.error('Error fetching patients data:', error);
//     }
// };
// export const handleNameSearch = async (value, setSearchInput, setPatientNameData, setMessage) => {
//     setSearchInput(value);
//     if (!value || value.length < 2) {
//         setMessage('Please enter 2 or more characters');
//         setPatientNameData([]);
//     } else {
//         setMessage('');
//         try {
//             const patientName = await fetchPatientsBookAppointment(value);
//             console.log("vb<>", patientName.scheduleApptInitialData );
//             setPatientNameData(patientName.patients);
//         } catch (error) {
//             console.error('Error fetching patients data:', error);
//         }
//     }
// };


// export const handlePatientNameDOMSearch = async (value, setSearchInput, setPatientNameData, setPatientDOBData, setMessage) => {
//     setSearchInput(value);
//     if (!value || value.length < 2) {
//         setMessage('Please enter 2 or more characters');
//         setPatientNameData([]);
//     } else {
//         setMessage('');
//         try {
//             console.log('Fetching patients for search value:', value);
//             const patientData = await fetchPatientsBookAppointment(value);
//             if (!patientData || !patientData.scheduleApptInitialData ) {
//                 throw new Error('Invalid response structure');
//             }
//             console.log('Fetched patient data:', patientData);

//             const filteredPatientsName = patientData.scheduleApptInitialData 
//                 .filter(patient => patient.patientName && patient.patientName.toLowerCase().includes(value.toLowerCase()))
//                 .map(patient => ({ patientName: patient.patientName }));
//             const filteredPatientsDob = patientData.scheduleApptInitialData 
//                 .filter(patient => patient.dob && patient.dob.toLowerCase().includes(value.toLowerCase()))
//                 .map(patient => ({ dob: patient.dob }));
//             console.log('Filtered patient names:', filteredPatientsName);
//             console.log('Filtered patient DOB:', filteredPatientsDob);
//             setPatientNameData(filteredPatientsName);
//             setPatientDOBData(filteredPatientsDob);
//         } catch (error) {
//             console.error('Error fetching patients data:', error);
//             setMessage('Error fetching patients data');
//             setPatientNameData([]);
//         }
//     }
// };
export const handlePatientNameDOMSearch = async (
    value,
    type,
    setSearchNameInput,
    setSearchDOBInput,
    setPatientNameData,
    setPatientDOBData,
    setMessage
) => {

    if (type === 'name') {
        setSearchNameInput(value);
        if (!value || value.length < 2) {
            setMessage('Please enter 2 or more characters');
            setPatientNameData([]);
        } else {
            setMessage('');
            try {
                const patientData = await fetchPatientsBookAppointment(value);
                if (!patientData || !patientData.scheduleApptInitialData) {
                    throw new Error('Invalid response structure');
                }
                const filteredPatientsName = patientData.scheduleApptInitialData
                    .filter(patient => patient.patientName && patient.patientName.toLowerCase().includes(value.toLowerCase()))
                    .map(patient => ({
                        patientName: patient.patientName,
                        patientId: patient.patientId
                    }));
                setPatientNameData(filteredPatientsName);

            } catch (error) {
                console.error('Error fetching patients data:', error);
                setMessage('Error fetching patients data');
                setPatientNameData([]);
            }
        }
    } else if (type === 'dob') {
        setSearchDOBInput(value);
        if (!value || value.length < 2) {
            setMessage('Please enter 2 or more characters');
            setPatientDOBData([]);
        } else {
            setMessage('');
            try {
                const patientData = await fetchPatientsBookAppointment(value);
                if (!patientData || !patientData.scheduleApptInitialData) {
                    throw new Error('Invalid response structure');
                }


                const filteredPatientsDob = patientData.scheduleApptInitialData
                    // .filter(patient => patient.dob && patient.dob.toLowerCase().includes(value.toLowerCase()))
                    // .map(patient => ({ dob: patient.dob }));
                    .filter(patient => patient.dob && patient.dob.toLowerCase().includes(value.toLowerCase()))
                    .map(patient => ({
                        dob: patient.dob,
                        patientId: patient.patientId
                    }));
                setPatientDOBData(filteredPatientsDob);
            } catch (error) {
                console.error('Error fetching patients data:', error);
                setMessage('Error fetching patients data');
                setPatientDOBData([]);
            }
        }
    }
};



export async function fetchPracticeLocationsData(setPracticeLocations) {
    try {
        const response = await fetchRegisterDetails();
        setPracticeLocations(response.data.practiceLocations);
        return response;
    } catch (error) {
        console.error("Error fetching security questions:", error.message);
        return null;
    }
};
// export async function fetchReasonForVisitData(setReasonForVisitData) {
//     try {
//         const response = await fetchReasonForVisitDropdownData(1);
//         setReasonForVisitData(response.data.scheduleApptInitialData);
//         console.log("yueg", response);
//         return response;
//     } catch (error) {
//         console.error("Error fetching security questions:", error.message);
//         return null;
//     }
// };


export async function fetchProviderLoadData(setProviderData) {

    try {
        const response = await providerList();
        setProviderData(response);
        return response;
    } catch (error) {
        console.error("Error fetching Provider Data:", error.message);
        return null;
    }
}
export async function fetchVisitTypeLoadData(setVisitTypeData) {

    try {

        const response = await fetchVisitTypeData(PracticeId);
        setVisitTypeData(response);
        return response;
    } catch (error) {
        console.error("Error fetching Provider Data:", error.message);
        return null;
    }
}
// export async function fetchPatientDetail(patientId) {

//     try {
//         const response = await fetchPatientInitialData(patientId);
//         console.log("ggdssuiugh", response);
//         return response;
//     } catch (error) {
//         console.error("Error fetching security questions:", error.message);
//         return null;
//     }
// };
// export async function fetchAndSetPatientDetails(patientId, form) {
//     try {
//         const response = await fetchPatientDetail(patientId);
//         console.log("Bharathi VB", response);
//         if (!response) {
//             throw new Error('No response from fetchPatientDetail');
//         }

//         // Assuming the response structure is { patientId, patientName, dob, phone, insuranceName }
//         const { patientName, dob, phone, insuranceName } = response;

//         // Update form fields
//         form.setFieldsValue({
//             patientName: response.patientName,
//             dob: response.dob,
//             phone: response.phone,
//             insuranceName: response.insuranceName
//         });
//     } catch (error) {
//         console.error('Error fetching patient details:', error);
//     }
// };