import React, { useEffect, useRef } from "react";
import { Form, Select, Row, Col, Checkbox, Input } from "antd";


const { Option } = Select;

const BookAppointment = () => {

    const [form] = Form.useForm();

    const labelWrapperProps = {
        labelAlign: 'left',
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <div className="form-container">
            <Form
                form={form}
                layout="horizontal"
                requiredMark={false}
                {...labelWrapperProps}
                colon={false}
            >
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="Patient Name" name="patientName">
                            <Select placeholder="Select a practice location">
                                <Option value="option1">Option 1</Option>
                                <Option value="option2">Option 2</Option>
                                <Option value="option3">Option 3</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="DOB" name="dob">
                            <Select placeholder="Select a physician">
                                <Option value="option1">Option 1</Option>
                                <Option value="option2">Option 2</Option>
                                <Option value="option3">Option 3</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Phone" name="phone">
                            <Select placeholder="Select a visit type">
                                <Option value="option1">Option 1</Option>
                                <Option value="option2">Option 2</Option>
                                <Option value="option3">Option 3</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Insurance" name="insurance">
                            <Select placeholder="Select a visit type">
                                <Option value="option1">Option 1</Option>
                                <Option value="option2">Option 2</Option>
                                <Option value="option3">Option 3</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="Date" name="date">
                            <Select placeholder="Select a practice location">
                                <Option value="option1">Option 1</Option>
                                <Option value="option2">Option 2</Option>
                                <Option value="option3">Option 3</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Location" name="location">
                            <Select placeholder="Select a physician">
                                <Option value="option1">Option 1</Option>
                                <Option value="option2">Option 2</Option>
                                <Option value="option3">Option 3</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Physician" name="physician">
                            <Select placeholder="Select a visit type">
                                <Option value="option1">Option 1</Option>
                                <Option value="option2">Option 2</Option>
                                <Option value="option3">Option 3</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Resource" name="resource">
                            <Select placeholder="Select a visit type">
                                <Option value="option1">Option 1</Option>
                                <Option value="option2">Option 2</Option>
                                <Option value="option3">Option 3</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="Department" name="department">
                            <Select placeholder="Select a practice location">
                                <Option value="option1">Option 1</Option>
                                <Option value="option2">Option 2</Option>
                                <Option value="option3">Option 3</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Visit Type" name="visitType">
                            <Select placeholder="Select a physician">
                                <Option value="option1">Option 1</Option>
                                <Option value="option2">Option 2</Option>
                                <Option value="option3">Option 3</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Reason For Visit" name="reasonForVisit">
                            <Select placeholder="Select a visit type">
                                <Option value="option1">Option 1</Option>
                                <Option value="option2">Option 2</Option>
                                <Option value="option3">Option 3</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Available Slots" name="availableSlots">
                            <Select placeholder="Select a visit type">
                                <Option value="option1">Option 1</Option>
                                <Option value="option2">Option 2</Option>
                                <Option value="option3">Option 3</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="Interval" name="interval">
                            <Select placeholder="Select a practice location">
                                <Option value="option1">Option 1</Option>
                                <Option value="option2">Option 2</Option>
                                <Option value="option3">Option 3</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item name="checkbox" valuePropName="checked">
                            <Checkbox>Provider Double Booking</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="intro"
                            label="Intro"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Intro',
                                },
                            ]}
                        >
                            <Input.TextArea showCount maxLength={100} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default BookAppointment;
