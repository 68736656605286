import React from "react";
import { Modal } from 'antd'; // Import Modal from Ant Design

const TermsOfService = ({ visible, onClose }) => {
    return (
        <Modal
        title="Terms of Service"
        open={visible}
        onCancel={onClose} // Call onClose when Cancel button or close icon is clicked
        footer={null}
        maskClosable={false} // Prevent closing the modal by clicking outside
        style={{ top: 20 }}
        styles={{ overflowY: 'auto', maxHeight: '80vh' }}
        width={'80%'}
      >
           <div style={{padding:"2%"}}>
            <p>The following Standard Terms of Service (the “TOS”) are made part of and incorporated into your Service Agreement
                with First Outcomes Health Inc, a Delaware corporation company (together with its subsidiaries and affiliates,
                “First Outcomes,” “we” or “us”) and govern your and all users’ rights and responsibilities in connection with
                use and access the Service provided by First Outcomes thereunder.
                THESE TOS ARE LEGALLY BINDING ON FIRST OUTCOMES AND YOU AS A “USER” OF THE SERVICE IN ANY CAPACITY FOR ANY
                REASON WHATSOEVER. EACH TIME YOU LOG IN AND/OR CLICK “I AGREE,” OR BY OTHERWISE ACCESSING OR USING THE SERVICE
                FOR ANY REASON, YOU ARE ENTERING INTO THESE TOS AND YOU AGREE TO BE BOUND BY THEM, BOTH PERSONALLY AS AN
                INDIVIDUAL AND AS AN AGENT OF YOUR EMPLOYER, ORGANIZATION, OR OTHER ENTITY ON BEHALF OF WHICH YOU ARE ACCESSING
                THE SERVICE, REGARDLESS OF WHETHER SUCH PRINCIPAL HAS BEEN DISLOSED. SUCH EMPLOYER, ORGANIZATION, OR OTHER
                ENTITY IS LIKEWISE BOUND TO ALL THESE TERMS TO WHICH YOU ARE ASSENTING.
                Please read these TOS carefully, and do not access or use the Service if you are unwilling or unable to be bound
                by these TOS. You and we are collectively referred to as the “Parties.”
            </p>
            <ol>
                <li><h4>Definitions</h4>
                    For the purposes of these TOS, certain terms used in these TOS (whether or not capitalized) shall have the
                    meanings assigned to them in Section 1 below. Terms not defined below or in the body of these TOS (whether
                    or not capitalized) have the definitions given to them in the Service Agreement or HIPAA, as applicable.
                    “Administrative Rights” means the rights to administer and direct the use of a Client’s account, including
                    the authority to provide, request, issue, administer and limit the access rights to other User accounts
                    issued to such Client’s Authorized Workforce, as well as the rights to integrate, connect, or otherwise
                    share Your Information with, or receive Protected Health Information from, third parties through the
                    Service.
                    “Ancillary Documentation” means any online knowledge base, training materials, documentation, presentations,
                    instructions, online or offline manuals, reference documents, screenshots, videos, recorded webinars, and
                    like information, as updated from time to time, provided or made accessible via login to the Service.
                    “Authorized Workforce” means those natural persons who are members of your Workforce who you have identified
                    (by their legal names, and the legal names of their employers) in your account as authorized to access the
                    Service on your behalf.
                    “BAA” means the First Outcomes Business Associate Agreement or the Smart Source Business Associate
                    Agreement, signed between a Client and First Outcomes or Smart Source and First Outcomes.
                    “Beta Features” means any component of the Service not generally available to all Clients and clearly
                    designated as beta, pilot, limited release, developer preview, non-production or by a notification or to
                    you, whether contained in the Service or not, or description of similar import.
                    “Client” means the legal organization that executed the Service Agreement.
                    “Clinical Data Exchange” means the exchange, with your Consent, of Protected Health Information (and Your
                    Personal Information as necessary) between You and covered entities (and their business associates) for any
                    permitted purpose, including, to the extent applicable, care coordination, performance or quality
                    measurement programs, and risk adjustment, and other treatment, payment or health care operations purposes.
                    “Confidential Information” means any information relating to our business, financial affairs, current or
                    future products or technology, trade secrets, workforce, customers, or any other information, including
                    Ancillary Documentation, that is treated or designated by us as confidential or proprietary, or would
                    reasonably be viewed as confidential or as having value to our competitors. “Confidential Information” does
                    not include information that we make publicly available or that becomes known to the general public other
                    than as a result of a breach of an obligation by you. “Confidential Information” does not include
                    individuals’ health information.
                    “Consent” means consent or authorization by a user of the Service allowing us to take actions described
                    under these TOS, which the user of the Service may give in an electronic communication to us or by use of
                    the features of the Service (such as “share,” “transmit,” “refer,” “authorize,” “opt-in,” “agree” or
                    toggling or selecting an action through a settings or activation page located within the Service, and the
                    like).  Such Consent may apply to an individual case or situation or may apply globally or programmatically
                    based on variables that apply to an overall situation or circumstance (whether through a settings or
                    preference page, a global “opt-in” or otherwise).
                    “Credentials” means any unique identifier, password, token, credential, any combination thereof, or other
                    means we may utilize from time to time for authorizing access to all, or any portion of, the Service.
                    “De-Identified Health Information” means health information that has been de-identified in accordance with
                    the provisions of the Privacy Rule.
                    “De-Identified Information” means De-Identified Health Information and De-Identified Personal Information.
                    “De-Identified Personal Information” means Personal Information from which all identifiers that could
                    reasonably be anticipated to identify an individual by an anticipated recipient – such an individual’s name,
                    contact information, or government identifiers – have been removed.
                    “De-Identify,” means (i) with respect to Personal Information, to make such information into De-Identified
                    Personal Information, and (ii) with respect to health information, means to make such health information
                    into De-Identified Health Information.
                    “HIPAA” means the administrative simplification provisions of the Health Insurance Portability and
                    Accountability Act of 1996, and the regulations promulgated thereunder, including the Privacy Rule and the
                    Security Rule, as amended.
                    “HITECH Act” means the Health Information Technology for Economic and Clinical Health Act of 2009, and
                    regulations promulgated thereunder.
                    “Personal Information” means information that includes an individual’s name, contact information, government
                    identifiers, or includes identifiers that could reasonably be anticipated to identify an individual
                    personally by an anticipated recipient.
                    “Policies and Procedures” means our rules, regulations, policies and procedures for access to and use of the
                    Service, as changed from time to time.
                    “Privacy Rule” means the Standards for Privacy of Individually Identifiable Health Information at 45 CFR
                    Part 160 and Part 164, Subparts A and E, as amended.
                    “Protected Health Information” has the meaning given it in the Privacy Rule.
                    “Security Rule” means the Security Standards for the Protection of electronic Protected Health Information
                    at 45 CFR Part 160 and Part 164, Subparts A and C, as amended.
                    “Service” means our electronic health record and related services, including our electronic medical record
                    services, HIPAA-secure messaging services, revenue cycle management services, practice management services,
                    other operations workflow solutions, and other services provided by us to you.
                    “Service Agreement” means these TOS together with (and as part of) your Service Agreement with First
                    Outcomes with all exhibits and any signed
                    “Term” means the length of time the Service Agreement is in effect until otherwise terminated as provided in
                    any exhibit or addendum to the Service Agreement.
                    “User” (capitalized) means a natural person who has been authorized, pursuant to these TOS, to access the
                    Service on your behalf; a “user” (un-capitalized) shall mean any user of the Service. As a “user” you are
                    bound by these TOS, along with any employer, organization, or other entity for which you are acting as an
                    agent.
                    “Workforce” means a Client’s employees, agents, principals, volunteers, trainees, contractors, and other
                    persons whose conduct, in the performance of work for Client, is under the direct control of such Client,
                    whether or not they are paid by the Client. This includes third-party companies with which you may contract
                    for services, including but not limited to third-party billers, information technology professionals, and
                    any other service provider that performs services on your behalf.
                    “Your Health Information” means Protected Health Information that you or your Workforce input or upload onto
                    the Service, or that we otherwise receive from you via interfaces to your EMR, telemetry from Remote Patient
                    Monitoring devices, Insurance Companies or on your behalf from your patients, authorized service providers,
                    or our third-party partners pursuant to these TOS.
                    “Your Information” means information that you or your Workforce input or upload onto the Service, including
                    Your Personal Information and Your Health Information.
                    “Your Personal Information” means Personal Information that you or your Workforce enter or upload onto the
                    Service.
                    In addition, the words “include,” “includes” and “including” shall be deemed to be followed by the phrase
                    “without limitation.” The word “will” shall be construed to have the same meaning and effect as the word
                    “shall.” The word “or” shall be construed to have the same meaning and effect as “and/or.” The words
                    “herein,” “hereof” and “hereunder,” and words of similar import, shall be construed to refer to these TOS.
                    The headings used in these TOS are used for convenience only and are not to be considered in construing or
                    interpreting these TOS.
                </li>
                <li>
                    <h4>Grant of Right to Use the Service</h4>
                </li>
                <ul style={{listStyleType:"none"}}>
                    <li><p>2.1 We grant to you and you accept a non-exclusive, personal, non-transferable (except as
                        expressly
                        permitted in Section 18.2), limited right to access and use the Service during the Term, subject
                        to
                        your full compliance with the terms and conditions set forth in these TOS and with our Policies
                        and
                        Procedures. You will not: (a) use the Service for time-sharing, rental or service bureau
                        purposes;
                        (b) make the Service, in whole or in part, available to any other person, entity or business;
                        (c)
                        copy, reverse engineer, decompile or disassemble the Service, in whole or in part, or otherwise
                        attempt to discover the source code to the software used by the Service; or (d) modify, combine,
                        integrate, render interoperable, or otherwise access for purposes of automating data conversion
                        or
                        transfer, the Service or associated software with any other software or services not provided or
                        approved by us. You will obtain no rights to the Service except for the limited rights to use
                        the
                        Service expressly granted by the Service Agreement and these TOS. You are bound by these TOS,
                        along
                        with any employer, organization, or other entity for which you are acting as an agent or
                        accessing
                        Service.</p>
                    </li>
                    <li><p>2.2 The Service may include access to certain third-party software, services,
                        data or applications that require that you enter into separate agreements with third parties.
                        Your
                        access to such third-party software services shall be subject to the TPTOS at all times. You
                        consent
                        to the TPTOS and will comply with and, upon request, execute, any agreements or acknowledgments
                        that
                        may be required for the use of such software or services, and hereby agree to comply with the
                        terms
                        of any terms of use, license or other agreement relating to third-party products included in the
                        Service or made accessible to you through the Service. Additionally, your use of the Service or
                        of
                        such third-party products or services will constitute your agreement to be bound by the terms of
                        all
                        licensing, subscription and similar agreements relating to such use.</p></li>
                </ul>

                                        <li>
                                                <h4>Access to the Service</h4>
                                                <ul style={{ listStyleType: "none" }}>
                                                        <li>3.1 Access Rights of Clients and their Authorized Workforce
                                                                <ul style={{ listStyleType: "none" }}>
                                                                        <li><p>3.1.1 Client. We offer the Service to the Client and to natural persons who are members
                                                                                of the Client’s
                                                                                Authorized Workforce, as more fully described in this Section 3.1. We treat the Client
                                                                                in whose name the
                                                                                Service Agreement was executed, and Instance established as the owner of all User
                                                                                accounts associated
                                                                                with such Client. The Client is a party to these TOS for all purposes and shall be
                                                                                subject to all of the
                                                                                provisions that are applicable to the person addressed as “you” in these TOS. Although a
                                                                                member of a
                                                                                Client’s Authorized Workforce may have logged into your Instance and accessed the
                                                                                Service and
                                                                                electronically consented to these TOS, or may continue to administer Administrative
                                                                                Rights on the
                                                                                Client’s behalf, only the Client is entitled to any of the rights, remedies or benefits
                                                                                under these TOS
                                                                                and control over the Administrative Rights. The Client is likewise subject to, and we
                                                                                may enforce
                                                                                against it, all of the covenants, obligations, restrictions, limitations,
                                                                                acknowledgements, Consents,
                                                                                representations, warranties, waivers and releases included in these TOS. The Client may
                                                                                delegate
                                                                                Administrative Rights to one or more members of the Client’s Authorized Workforce, but
                                                                                the Client
                                                                                remains responsible for all activity occurring thereunder.</p></li>
                                                                        <li><p>3.1.2 Authorized Representatives. An authorized representative of a Client may have
                                                                                administrative
                                                                                privileges on a Client’s Instance. We call the person(s) authorized to act on behalf of
                                                                                a Client the
                                                                                “Authorized Representative(s)” of such Client. The Client and Authorized Representative
                                                                                may be the same
                                                                                person. If you are taking any action with respect to a Client’s Instance, you represent
                                                                                and warrant that
                                                                                (a) you have the authority to act on such Client’s behalf either as owner/principal or
                                                                                as a member of
                                                                                such Client’s Authorized Workforce, (b) the information you submit to us is complete and
                                                                                accurate, and
                                                                                (c) you have the authority to enter into these TOS on behalf of such Client and bind
                                                                                such Client to the
                                                                                covenants, obligations, restrictions, limitations, acknowledgements, Consents,
                                                                                representations,
                                                                                warranties, grants, waivers and releases contained in these TOS. If you are an
                                                                                Authorized
                                                                                Representative, you recognize that you have no personal rights with respect to such
                                                                                Client’s Instance,
                                                                                and that such Client may change the Authorized Representative at any time, for any or no
                                                                                reason, with or
                                                                                without notice. You are bound by these TOS, along with any employer, organization, or
                                                                                other entity for
                                                                                which you are acting as an agent.</p></li>
                                                                        <li><p>3.1.3 Authorized Workforce. If you are a member of a Client’s Authorized Workforce, and
                                                                                such Client has
                                                                                authorized you to access the Service on its behalf by authorizing a Credential for you,
                                                                                then you are
                                                                                authorized under these TOS to access the Service solely on behalf and at the direction
                                                                                of such Client.
                                                                                As such, you may log in in and use the functionality of the Service solely on behalf and
                                                                                at the
                                                                                direction of such Client. You consent to and authorize the disclosure to such Client any
                                                                                content related
                                                                                to, or otherwise generated by your use of the Service, including secure messages. You
                                                                                hereby agree and
                                                                                acknowledge that you are subject to, and we may enforce against you, all of the
                                                                                covenants, obligations,
                                                                                restrictions, limitations, acknowledgements, Consents, representations and warranties
                                                                                set forth in these
                                                                                TOS that are applicable to the person addressed as “you” in these TOS, and you hereby
                                                                                grant and make all
                                                                                rights, waivers and releases set forth in these TOS that are granted and made by the
                                                                                person addressed as
                                                                                “you” in these TOS, but you are entitled to none of, and hereby waive and agree not to
                                                                                exercise or
                                                                                assert any of, the rights, remedies or benefits under these TOS other than the limited,
                                                                                non-exclusive,
                                                                                non-transferable, personal right under this Section 3.1.3 to sign in and use the
                                                                                functionality of the
                                                                                Service solely on behalf and at the direction of such Client.
                                                                                Notwithstanding the applicable provisions at Section 14, you acknowledge that your
                                                                                access to the Service
                                                                                may be terminated by the Client or us at any time, for any reason or no reason at all,
                                                                                with or without
                                                                                notice. By (i) accessing any of the Service under a Client’s account(s), or (ii)
                                                                                contacting us by any
                                                                                means and requesting or directing us to take any action with respect to any Client’s
                                                                                account(s) or data
                                                                                held by such account(s), or (iii) asserting any right or authority with respect to such
                                                                                account(s) or
                                                                                data, you represent and warrant that you have the authority to act on such Client’s
                                                                                behalf and that you
                                                                                are not using the Service, or otherwise engaging in the activities described in clauses
                                                                                (i) through
                                                                                (iii) above, for the benefit or at the direction of any person or entity other than such
                                                                                Client,
                                                                                including yourself. Unless you are a member of a Client’s Authorized Workforce, you may
                                                                                not access the
                                                                                Service for the purpose of uploading any information, including but not limited to
                                                                                laboratory results,
                                                                                to patient files, regardless of whether your organization has an agreement with First
                                                                                Outcomes or any
                                                                                Client.</p></li>
                                                                        <li><p>3.1.4 Admin “users”. A user with the assigned role of Admin (a “Admin User”) must be
                                                                                registered with a
                                                                                company domain email. In order to log in to an Instance, an Admin User may be required
                                                                                to authenticate
                                                                                both email and cell phone via two-factor authentication. By logging in, an Admin User
                                                                                agrees to accept
                                                                                email and text communication regarding his or her Instance and attendant
                                                                                responsibilities as an Admin
                                                                                User. First Outcomes reserves the right at any time to restrict or disable access for
                                                                                any Admin User who
                                                                                fail to meet the minimum requirements set forth above.</p></li>
                                                                        <li><p>3.1.5 All other “users”. If you are a “user” as defined herein, you hereby agree and
                                                                                acknowledge that
                                                                                you are subject to, and we may enforce against you, all of the covenants, obligations,
                                                                                restrictions,
                                                                                limitations, acknowledgements, Consents, representations and warranties set forth in
                                                                                these TOS that are
                                                                                applicable to the person addressed as “you” in these TOS, and you hereby grant and make
                                                                                all rights,
                                                                                waivers and releases set forth in these TOS that are granted and made by the person
                                                                                addressed as “you”
                                                                                in these TOS, but you are entitled to none of, and hereby waive and agree not to
                                                                                exercise or assert any
                                                                                of, the rights, remedies or benefits under these TOS. Nothing in this section authorizes
                                                                                or shall be
                                                                                deemed to authorize your use of the Service and if your use is not expressly permitted
                                                                                under these TOS,
                                                                                you may be held directly liable and required to pay damages to First Outcomes for
                                                                                improperly accessing
                                                                                the Service.​</p></li>
                                                                </ul>
                                                        </li>
                                                </ul>
                                        </li>
                                        <li>3.2 Beta Features; Updates
                                                <ul style={{ listStyleType: "none" }}>
                                                        <li><p>3.2.1 Beta Features. If you are invited to access any Beta Features of the Service or you
                                                                access any
                                                                Beta Features of the Service, you acknowledge that: (a) such features have not been made
                                                                commercially
                                                                available by First Outcomes; (b) such features may not operate properly, be in final
                                                                form or fully
                                                                functional; (c) such features may contain errors, design flaws or other problems; (d) it
                                                                may not be
                                                                possible to make such features fully functional; (e) use of such features may result in
                                                                unexpected
                                                                results, corruption or loss of data, or other unpredictable damage or loss; (f) such
                                                                features may change
                                                                and may not become generally available; and (g) First Outcomes is not obligated in any
                                                                way to continue
                                                                to provide or maintain such features for any purpose in providing the ongoing Service.
                                                                These Beta
                                                                Features are provided “AS IS”, with all faults. You assume all risk arising from use of
                                                                such features,
                                                                including, without limitation, the risk of damage to your computer system or the
                                                                corruption or loss of
                                                                data. We may in our sole discretion discontinue Beta Services at any time, and may never
                                                                make them
                                                                generally available.</p></li>
                                                        <li><p>3.2.2 Updates. We will be updating the service from time to time to add new features and
                                                                fix bugs. These
                                                                updates usually take a few seconds, but sometimes require a longer suspension of the
                                                                Service. In such
                                                                cases, we will notify you at least 48 hours in advance, and we will strive to schedule
                                                                it so that your
                                                                business is minimally impacted. We also provide software to support special features for
                                                                tablets and
                                                                other devices. If you use this software, it will automatically check if your version is
                                                                current. If it
                                                                is not, you may be required to install a newer version before access to the Service is
                                                                allowed.</p></li>
                                                </ul>
                                        </li>
                                        <li>3.3 Verification
                                                <p>You agree that your use of the Service, or certain features or functionality of the Service, may
                                                        be
                                                        subject to verification by us of your identity and credentials as a health care provider or
                                                        health care
                                                        professional under applicable law, and to your ongoing qualification as such. You agree that we
                                                        may use
                                                        and disclose Your Personal Information for such purposes, including making inquiry of third
                                                        parties
                                                        concerning your identity and professional and practice credentials. You authorize such third
                                                        parties to
                                                        disclose to us such information as we may request for such purposes, and you agree to hold them
                                                        and us
                                                        harmless from any claim or liability arising from the request for or disclosure of such
                                                        information.
                                                        Notwithstanding the applicable provisions at Section 16, you agree that we may terminate your
                                                        access to
                                                        or use of the Service at any time if we are unable at any time to determine or verify your
                                                        identity,
                                                        qualifications or credentials.</p></li>
                                        <li>3.4 Permitted Uses
                                                <ul style={{ listStyleType: "none" }}>
                                                        <li><p>3.4.1. Subject to the terms of these TOS, you may use Health Information for any purpose
                                                                expressly
                                                                permitted by applicable law, including treatment, payment and health care
                                                                operations.</p></li>
                                                        <li><p>3.4.2 If you are granted access rights to a patient’s Protected Health Information
                                                                through the Service,
                                                                you may use such information for setting up appointments, for care coordination, for
                                                                treatment and for
                                                                obtaining payment for treatment; provided that, except as expressly authorized in our
                                                                Policies and
                                                                Procedures, (i) you may access only information pertaining to individuals with whom you
                                                                have a treatment
                                                                relationship or for whom a Client who has a treatment relationship with the individual
                                                                has given you
                                                                authorization to use their health information; and (ii) to the extent applicable to you,
                                                                you may use
                                                                only the minimum necessary information for performing billing any services.</p></li>
                                                        <li><p>3.4.3 You will not use the Service for any purposes other than those described in Section
                                                                3.4.1 or
                                                                Section 3.4.2. In particular:
                                                                (a) You may not reproduce, publish, or distribute content in connection with the Service
                                                                that infringes
                                                                any third party’s trademark, copyright, patent, trade secret, publicity, privacy, or
                                                                other personal or
                                                                proprietary right;
                                                                (b) You may not use the Service to transmit illegal, obscene, threatening, libelous,
                                                                harassing, or
                                                                offensive messages, or otherwise unlawful material;
                                                                (c) You may not duplicate, copy, sell, resell or exploit any portion of the Service, use
                                                                of the Service,
                                                                or allow any person that is not part of your Authorized Workforce to access the Service
                                                                without the
                                                                express written permission of First Outcomes’s Chief Executive Officer (“CEO”);
                                                                (d) You may not commercialize any portion of the Service or any information or software
                                                                associated with
                                                                or used to access, exploit, or use the Service; and
                                                                (e) Except as expressly permitted in writing by First Outcomes’s CEO, you may not
                                                                modify, port, adapt,
                                                                make screen captures from or translate the Service. In addition, to further safeguard
                                                                the
                                                                confidentiality, integrity and availability of the information and other elements housed
                                                                in the Service,
                                                                as well as the stability of the Service, you agree that you will not (nor attempt to),
                                                                nor will you
                                                                authorize anyone to (or authorize anyone to attempt to):
                                                                (f) (i) abuse or misuse the Service, including gaining or attempting to gain
                                                                unauthorized access to the
                                                                Service, or altering or destroying information housed in the Service; (ii) take any
                                                                action that imposes
                                                                or may impose (to be determined in our sole discretion) an unreasonable or
                                                                disproportionately large load
                                                                on our infrastructure; (iii) interfere or attempt to interfere with the proper
                                                                working of our Site or
                                                                Services or any activities conducted on or with the Service; (iv) bypass our robot
                                                                exclusion headers,
                                                                robots.txt rules, or any other measures we may use to prevent or restrict access to our
                                                                Service; (v) use
                                                                the Service in a manner that interferes with other users’ use of the Service; (vi)
                                                                use the Service in
                                                                any manner that violates our Policies and Procedures;
                                                                (g) circumvent any technical measures we have put in place to safeguard the Service or
                                                                the
                                                                confidentiality, integrity or accessibility of any information housed thereon, or any
                                                                technical measures
                                                                we have put in place to restrict access to the Service solely to the class of persons
                                                                expressly so
                                                                authorized pursuant to Sections 3.1.1 through 3.1.3;
                                                                (h) access any portion of the Service other than with a standard commercial browser
                                                                (such as Microsoft
                                                                Edge, Mozilla Firefox, Chrome, Android or iOS) or through mobile applications developed
                                                                and operated by
                                                                us;
                                                                (i) “frame” or “mirror” any part of the Service; or
                                                                (j) use any computer program, bot, macro, robot, spider, offline reader, site
                                                                search/retrieval
                                                                application, scraper, browser add-on, rich internet application, artificial-intelligence
                                                                or
                                                                machine-learning technology, or other manual or automatic device, tool, or process to
                                                                access, retrieve,
                                                                index, data mine, or in any way reproduce or circumvent the navigational structure or
                                                                presentation of
                                                                the Service; for the avoidance of doubt, this specifically prohibits you from using
                                                                automated software
                                                                in connection with the Service, and prohibits you from circumventing any security
                                                                measure, access
                                                                control system, or other technological control or measure in the Service.
                                                                You agree that your abusive use of the Service in violation of this Section 3.4.3 may
                                                                cause damage and
                                                                harm to us, including impaired goodwill, reduced performance, and increased expenses.
                                                                You also agree
                                                                that monetary damages for your abusive use of the Service are difficult to determine,
                                                                and that if you,
                                                                or others acting with you, request more than 500 pages of the Service in any 24-hour
                                                                period, you, and
                                                                those acting with you, will be liable for liquidated damages in the amount of one dollar
                                                                ($1.00) for
                                                                each page request or upload request made during that 24-hour period which exceeds those
                                                                limits</p></li>
                                                </ul>
                                        </li>
                                        <li><p>3.5 Clinical Support Information; Information Exchange.
                                                We may provide information to assist you in care coordination and clinical decision-making. This
                                                may
                                                include information and reminders concerning patient not meeting VBP metrics, failing below
                                                medical
                                                protocol minimums or exceeding medical protocol maximums. You agree that the information and
                                                materials
                                                available through the Service are for informational and educational purposes only and are not
                                                intended
                                                to constitute professional advice, diagnosis or treatment, or to substitute for your
                                                professional
                                                judgment. You assume full risk and responsibility for the use of information you obtain from or
                                                through
                                                the Service, and we are not responsible or liable for any claim, loss, or liability arising from
                                                use of
                                                the information. You will review the definitions, functionality, and limitations of the Service,
                                                and to
                                                make an independent determination of their suitability for your use. We and our suppliers,
                                                partners and
                                                licensors disclaim all warranties, whether expressed or implied, including any warranty as to
                                                the
                                                quality, accuracy, and suitability of the information provided by the Service for any
                                                purpose.</p></li>
                                        <li>3.6 Safeguards
                                                <ul style={{ listStyleType: "none" }}>
                                                        <li><p>3.6.1 You will implement and maintain appropriate administrative, physical and technical
                                                                safeguards to
                                                                protect information within the Service. Such safeguards shall comply with federal,
                                                                state, and local
                                                                requirements, including the Privacy Rule and the Security Rule, whether or not you are
                                                                otherwise subject
                                                                to HIPAA. You will maintain appropriate security with regard to all personnel, systems,
                                                                and
                                                                administrative processes used by you or members of your Workforce to transmit, store and
                                                                process
                                                                electronic health information through the use of the Service.</p></li>
                                                        <li><p>3.6.2 You will immediately notify us of any breach or suspected breach of the security of
                                                                the Service of
                                                                which you become aware, or any unauthorized use or disclosure of information within or
                                                                obtained from the
                                                                Service, and you will take such actions to mitigate the breach, suspected breach, or
                                                                unauthorized use or
                                                                disclosure of information within or obtained from the Service as we may direct, and will
                                                                cooperate with
                                                                us in investigating and mitigating the same. You have consented to the terms and
                                                                conditions of the BAA
                                                                for the purposes of compliance with HIPAA.</p></li>
                                                </ul>
                                        </li>
                                        <li><p>3.7 User Identification
                                                We authorize you and your Authorized Workforce to use the Credentials uniquely assigned to, or
                                                selected
                                                by, each such individual User. You acquire no ownership rights in any such Credentials, and such
                                                Credentials may be revoked, reset or changed at any time in the discretion of us or the Client.
                                                You will
                                                adopt and maintain reasonable and appropriate security precautions for your Credentials to
                                                prevent their
                                                disclosure to or use by unauthorized persons. Each member of your Authorized Workforce shall
                                                have and
                                                use a unique identifier. You will ensure that no member of your Workforce uses Credentials
                                                assigned to
                                                another Workforce member. You may not attempt to conceal your identity by using multiple
                                                Internet
                                                Protocol addresses or email addresses, or by any other means, to use the Service</p></li>
                                        <li><p>3.8 No Third-Party Access
                                                Except as required by law, you will not permit any third party (other than persons who satisfy
                                                the definition of Authorized Workforce, and in all cases meet the requirements of Section 3.1.3)
                                                to use
                                                or access the Service without the prior written consent of First Outcomes’s CEO. Nor will you
                                                authorize
                                                or assist any person or entity in accessing, or attempting to access, any portion of the Service
                                                via any
                                                means other than a commercial browser (such as Microsoft Edge, Mozilla Firefox, Chrome, iOS or
                                                Android)
                                                or a mobile app that we have authored and provided to you. You will promptly notify us of any
                                                order or
                                                demand for compulsory disclosure of health information if the disclosure requires access to or
                                                use of
                                                the Service. You will cooperate fully with us in connection with any such demand. You will also
                                                notify
                                                us if any person or entity, whether or not a member of your Authorized Workforce, (a)
                                                attempts to access
                                                the Service by any means other than a commercial browser, (b) claims to offer a service or
                                                system that
                                                “integrates with” our Service or (c) requests to use your Credentials or requests that you
                                                obtain
                                                Credentials in order to access the Service in a manner that would violate these TOS if you
                                                engaged in
                                                such activity.</p></li>
                                        <li>
                                                3.9 Your Workforce
                                                You may permit your Authorized Workforce to use the Service on your behalf, subject to the terms
                                                of
                                                these TOS. You will:
                                                <ul style={{ listStyleType: "none" }}>
                                                        <li>
                                                                <p>3.9.1 require each member of your Authorized Workforce to have unique Credentials, and
                                                                        will
                                                                        provide the legal name(s) of each such member for which you are seeking Credentials;</p>
                                                        </li>
                                                        <li><p>3.9.2 train all members of your Authorized Workforce in the requirements of these TOS and
                                                                the
                                                                Policies
                                                                and Procedures relating to their access to and use of the Service, and ensure that they
                                                                comply
                                                                with such
                                                                requirements;</p></li>
                                                        <li><p>3.9.3 take appropriate disciplinary action against any member of your Workforce who
                                                                violates the
                                                                terms
                                                                of these TOS or the Policies and Procedures;</p></li>
                                                        <li><p>3.9.4 ensure that only the person to whom a specific set of Credentials have been
                                                                assigned
                                                                accesses the
                                                                Service with such Credentials; and</p></li>
                                                        <li><p>3.9.5 be bound by these TOS, along with any employer, organization, or other entity for
                                                                which you
                                                                are
                                                                acting as an agent.</p></li>
                                                </ul>
                                                <li><p>3.11 Forums
                                                        In the future we may offer forums for the exchange of information among our users.</p></li>
                                                <li>3.12 Compliance
                                                        <ul style={{ listStyleType: "none" }}>
                                                                <li><p>3.12.1 You are solely responsible for ensuring that your use of the Service complies with
                                                                        applicable
                                                                        law, including laws relating to the maintenance of the privacy, security, and
                                                                        confidentiality of patient
                                                                        and other health information. You will not grant any user, including members of your
                                                                        Authorized
                                                                        Workforce, any rights to access or use our Service that they would not be allowed to
                                                                        have under
                                                                        applicable laws. We offer no assurance that your use of the Service under the terms of
                                                                        these TOS will
                                                                        not violate any law or regulation applicable to you. To the extent that the Service
                                                                        allows you to send
                                                                        and/or receive SMS messages, you acknowledge that SMS messaging is not encrypted and
                                                                        represent that you
                                                                        have reviewed your organization’s use of this capability and determined that such use is
                                                                        compliant with
                                                                        all applicable laws and regulations, including but not limited to HIPAA and 42 CFR Part
                                                                        2. You
                                                                        acknowledge that we may share Your Information with third parties if we determine in
                                                                        good faith that
                                                                        disclosure of Your Information is necessary to (i) comply with a court order, warrant or
                                                                        other legal
                                                                        process, (ii) protect the rights, property or safety of First Outcomes or others, (iii)
                                                                        investigate or
                                                                        enforce suspected breaches of these TOS, or (iv) allow our third-party partners to
                                                                        comply with their
                                                                        obligations under federal or state law.</p></li>
                                                                <li><p>3.12.2 The Parties acknowledge and agree that (i) any fees charged or amounts paid
                                                                        hereunder are not
                                                                        intended, nor will they be construed to be, an inducement or payment for referral of
                                                                        patients among
                                                                        First Outcomes, Client, or any third party and (ii) they will not enter into any
                                                                        agreements, or
                                                                        otherwise make any payments, for the purpose of rewarding the referral of patients among
                                                                        First Outcomes,
                                                                        Client, or any third party.</p></li>
                                                                <li><p>3.12.3 The Parties will each separately maintain effective compliance programs consistent
                                                                        with the
                                                                        relevant compliance guidelines set forth by the Office of the Inspector General of the
                                                                        Department of
                                                                        Health and Human Services. The Parties will cooperate with each other to provide
                                                                        accurate and full
                                                                        responses to any material inquiry or concern of either Party related to compliance and
                                                                        to any reasonable
                                                                        request by either Party for clarification, documentation, or further information
                                                                        concerning Client
                                                                        billing or Client's provision of, or referrals related to, health services for its
                                                                        patients.</p></li>
                                                                <li><p>3.12.4 Client warrants to First Outcomes on a continuing basis throughout the term of its
                                                                        Service
                                                                        Agreement that Client will not bill or claim payment in any form, directly or
                                                                        indirectly, from any
                                                                        government health care program or other third-party payer for the cost of any First
                                                                        Outcomes Services,
                                                                        including, without limitation, on a government cost report.</p></li>
                                                        </ul>
                                                </li>
                                                <li><p>
                                                        3.13 Professional Responsibility
                                                        You will be solely responsible for the professional and technical services you provide. We make
                                                        no
                                                        representations concerning the completeness, accuracy or utility of any information in the
                                                        Service, or
                                                        concerning the qualifications or competence of persons who placed it there. We have no liability
                                                        for the
                                                        consequences to you or your patients of your use of the Service.
                                                        You agree that the sole and exclusive responsibility for any medical decisions or actions with
                                                        respect
                                                        to a patient’s medical care and for determining the accuracy, completeness or appropriateness of
                                                        any
                                                        billing, clinical, coding, diagnostic, medical or other information provided by the Service
                                                        resides
                                                        solely with the health care provider. First Outcomes assumes no responsibility for how such
                                                        materials
                                                        are used. In addition, you agree that any prompts or alerts contained in the Service are tools
                                                        available
                                                        to the health care provider for augmenting the documentation of the patient’s electronic medical
                                                        records, and are not intended in any way to eliminate, replace or substitute for, in whole or in
                                                        part,
                                                        the health care provider’s judgment and analysis of the patient’s condition.</p></li>
                                                <li><p>3.14 Cooperation
                                                        You will cooperate with us in the administration of the Service, including providing reasonable
                                                        assistance in evaluating the Service and collecting and reporting data requested by us for
                                                        purposes of
                                                        administering the Service.</p></li>
                                                <li><p>3.15 Indemnification
                                                        You hereby agree to indemnify, defend, and hold harmless us and other users, and our and their
                                                        respective affiliates, officers, directors, employees and agents, from and against any claim,
                                                        cost or
                                                        liability, including reasonable attorneys’ fees, arising out of or relating to: (a) the use
                                                        of the
                                                        Service by you or your Workforce; (b) any breach by you or your Workforce of any
                                                        representations,
                                                        warranties or agreements contained in these TOS; (c) the actions of any person gaining
                                                        access to the
                                                        Service under Credentials assigned to you or a member of your Workforce; (d) the actions of
                                                        anyone using
                                                        Credentials assigned to you or any member of your Workforce that adversely affects the Service
                                                        or any
                                                        information accessed through the Service; and (e) your negligent or willful misconduct, or
                                                        that of any
                                                        member of your Workforce. Your indemnification obligations in these TOS (including this Section
                                                        3.15)
                                                        are cumulative, and are not intended to, nor do they, limit your indemnification obligations
                                                        elsewhere
                                                        in these TOS or at law, even if such obligations arise or are occasioned or triggered by a
                                                        single
                                                        assertion, claim, circumstance, action, event or transaction.</p></li>
                                                <li><p>3.16 Your Information; Accuracy
                                                        You shall have all responsibility for the accuracy, quality, integrity, legality, reliability,
                                                        appropriateness, and intellectual property ownership of Your Information and First Outcomes
                                                        shall not be
                                                        responsible or liable for the deletion, correction, destruction, damage, loss of use, or failure
                                                        of any
                                                        of Your Information, regardless of the cause or reason therefor.
                                                        You represent and warrant to First Outcomes that all data, information, documentation and other
                                                        material
                                                        provided under your account or by you are true, correct and accurate. If you learn that any item
                                                        provided to First Outcomes as part of the Service is not true, correct or accurate, you must
                                                        immediately
                                                        notify First Outcomes via phone (so as not to delay resolution or to prevent submission of
                                                        incorrect
                                                        claim) and in writing, and provide the true, correct and accurate information to First
                                                        Outcomes.</p></li>

                                                <li><h4>Use of Information</h4>
                                                        <ul style={{ listStyleType: "none" }}>
                                                                <li><p>4.1 Purpose of Service.
                                                                        The purpose of the Service is to (i) allow patients to cancel, reschedule, and schedule their
                                                                        appointments via text (ii) to allow staff to assist with cancellation, rescheduling, and
                                                                        scheduling when
                                                                        the patient is not able to complete this service via text, (iii) to proactively contact your
                                                                        patients
                                                                        and schedule preventative care visits, (iv) to store Value Based Contracts (VBP), (v) to
                                                                        proactively
                                                                        contact your patients and schedule care visits necessary to meet VBP contracts, (vi) to retrieve
                                                                        data
                                                                        from Remote Patient Monitoring (RPM) devices, (vii) to analyze RPM data per medical protocols
                                                                        and alert
                                                                        your Authorized Workforce if protocol limits are exceeded. You authorize us, as your business
                                                                        associate
                                                                        under the BAA, to use and disclose Your Information as follows:</p></li>
                                                                <ul style={{ listStyleType: "none" }}>
                                                                        <li><p>4.1.1 We will permit unrestricted access to Your Health Information to you and your
                                                                                Authorized
                                                                                Workforce. You are responsible for ensuring that your use of Your Health Information is
                                                                                consistent with
                                                                                the relevant legal restrictions.</p></li>
                                                                        <li><p>4.1.2 We may disclose or permit access to Your Information to entities such as, but not
                                                                                limited to,
                                                                                health plans, accountable care organizations, independent practice associations, your
                                                                                authorized service
                                                                                providers and other parties responsible for payment and their business associates for the
                                                                                purpose of
                                                                                obtaining (or confirming eligibility or authorization for) payment for services you provide,
                                                                                unless you
                                                                                advise us in writing that, with respect to a specific service provided to a specified
                                                                                patient,</p></li>
                                                                        <li><p>4.1.3 We may De-Identify Your Information and use and disclose De-Identified Information for
                                                                                any purpose
                                                                                whatsoever, including as provided by Section 5 and Section 7.2.</p></li>
                                                                        <li><p>4.1.4 We may create limited data sets from Your Health Information, and disclose them for any
                                                                                purpose
                                                                                for which you may disclose a limited data set; and you hereby authorize us to enter into
                                                                                data use
                                                                                agreements on your behalf for the use of limited data sets, in accordance with applicable
                                                                                law and
                                                                                regulation.</p></li>
                                                                        <li><p>4.1.5 We may use Your Information in order to prepare analyses and reports, such as
                                                                                quality-metrics
                                                                                reports, or any other reports the Service makes available. Preparation of such analyses and
                                                                                reports may
                                                                                include the use of data aggregation services relating to your treatment and health care
                                                                                operations,
                                                                                which we may perform using Your Health Information. Such reporting will be done in a manner
                                                                                that does
                                                                                not make any disclosure of Your Health Information that you would not be permitted to
                                                                                make.</p></li>
                                                                        <li><p>4.1.6 We may use Your Information for the proper management and administration of the Service
                                                                                and our
                                                                                business, and to carry out our legal responsibilities, which may include us disclosing such
                                                                                information
                                                                                to one of our business associates that has entered into a business associate agreement. We
                                                                                may also
                                                                                disclose Your Information for such purposes if the disclosure is required by law (as such
                                                                                term is
                                                                                defined in 45 CFR §164.103), or we obtain reasonable assurances (as such term is interpreted
                                                                                or
                                                                                applicable in connection with or under HIPAA) from the recipient that it will be held
                                                                                confidentially and
                                                                                used or further disclosed only (a) as required by law (as such term is defined in 45 CFR
                                                                                §164.103), or
                                                                                (b) for the purpose for which it was disclosed to the recipient, and the recipient notifies
                                                                                us of any
                                                                                instances of which it is aware in which the confidentiality of the information has been
                                                                                breached.
                                                                                Without limiting the foregoing, we may permit access to the system by our contracted system
                                                                                developers
                                                                                under appropriate confidentiality agreements.</p></li>
                                                                        <li><p>4.1.7 We may use Your Health Information and Directory Information (defined below) to contact
                                                                                your
                                                                                patients on your behalf for any purpose for which you would be permitted to contact them,
                                                                                including:
                                                                                (a) For treatment and health care operations messages, including sending appointment
                                                                                notifications (such
                                                                                as appointment requests, confirmations, reminders, cancellations and the like) and messages
                                                                                about
                                                                                currently prescribed medications (including refill reminders), or post-visit treatment
                                                                                satisfaction
                                                                                surveys, invitations and administrative messages concerning Patient Portal access, and the
                                                                                like;
                                                                                (b) To provide information about health-related products or services that you provide, or
                                                                                that we
                                                                                provide on your behalf as your business associate.</p></li>
                                                                        <li><p> 4.1.8 From time to time we may incorporate information we receive from your authorized
                                                                                service providers
                                                                                (including Third-Party Applications as discussed in Section 11.2), our third-party partners,
                                                                                or covered
                                                                                entities (and their business associates) who are providing or paying for medical services
                                                                                for one or
                                                                                more of your patients, into the Service we provide to you. Such information may include,
                                                                                without
                                                                                limitation, clinical information such as lab results, imaging results, and prescription
                                                                                history; and
                                                                                shall, upon incorporation into the Service, be treated as “Your Health Information” for all
                                                                                purposes
                                                                                hereunder. You hereby authorize us to request and receive such information on your behalf
                                                                                from such
                                                                                authorized service providers or our third party partners.</p></li>
                                                                        <li><p>4.1.11 We may use or disclose Your Health Information for other purposes, as from time to
                                                                                time described
                                                                                in our Policies and Procedures; provided that we will not make or permit any such use or
                                                                                disclosure that
                                                                                would violate applicable law or regulation if made by you or your business associate.</p>
                                                                        </li>
                                                                </ul>
                                                                <li><p>4.2 Responsibility for Misuse by Other Users
                                                                        You acknowledge that in granting access to the Service for the purposes set forth in Section
                                                                        4.1, we
                                                                        will rely on the assurances of the recipients of the information as to (i) their identity and
                                                                        credentials, (ii) the purposes for which they are accessing the system, and (iii) the nature and
                                                                        extent
                                                                        of the information to which they will have access. You acknowledge that, while the Service will
                                                                        contain
                                                                        certain technical safeguards against misuse of the Service, it will rely to a substantial extent
                                                                        on the
                                                                        representations and undertakings of users of the Service. You agree that we will not be
                                                                        responsible for
                                                                        any unlawful access to or use of Your Health Information by any user resulting from the user’s
                                                                        misrepresentation to us, or breach of the user’s user agreement or our Policies and
                                                                        Procedures.</p></li>
                                                                <li>4.3 Specially Protected Information
                                                                        We apply the standards of the Privacy Rule in permitting access to the Service. You acknowledge
                                                                        that
                                                                        other federal and state laws impose additional restrictions on the use and disclosure of certain
                                                                        types
                                                                        of health information, or health information pertaining to certain classes of individuals. You
                                                                        agree
                                                                        that you are solely responsible for ensuring that Your Health Information may properly be
                                                                        disclosed for
                                                                        the purposes set forth in Section 4.1, subject to the restrictions of the Privacy Rule and
                                                                        applicable
                                                                        law, including those laws that may be more restrictive than the Privacy Rule. In particular, you
                                                                        will:
                                                                        <ul style={{ listStyleType: "none" }}>
                                                                                <li><p>4.3.1 not make available to other users through the Service any information in violation
                                                                                        of any
                                                                                        restriction on use or disclosure (whether arising from your agreement with such users or
                                                                                        under
                                                                                        law);</p></li>
                                                                                <li><p>4.3.2 obtain all necessary consents, authorizations or releases from individuals required
                                                                                        for
                                                                                        making
                                                                                        their health information available through the Service for the purposes set forth in
                                                                                        Section
                                                                                        4.1;</p></li>
                                                                                <li><p>4.3.3 include such statements (if any) in your notice of privacy practices as may be
                                                                                        required in
                                                                                        connection with your use of the Service; and</p></li>
                                                                                <li><p>4.3.4 not place in the Service any information that you know or have reason to believe is
                                                                                        false
                                                                                        or
                                                                                        materially inaccurate.</p></li>
                                                                        </ul>
                                                                        <li><p>4.4 Care Coordination and Clinical Data Exchange
                                                                                We will enable Clinical Data Exchange between You and your business associate(s) or those
                                                                                covered
                                                                                entities (and their respective business associates), including health systems, Accountable Care
                                                                                Organizations, payers, and laboratories who desire to transmit such data to you with respect to
                                                                                patients
                                                                                they have a treatment or payment relationship or with whom you have Consented to provide such
                                                                                data (all
                                                                                third parties collectively referred herein as “Transmission Partner”). While such
                                                                                transmission integrations are in effect, such Transmission Partner may send and receive clinical
                                                                                data to
                                                                                and from your account on a routine and recurring basis. You may disable an integration with any
                                                                                Transmission Partner by contacting us through one of the methods described
                                                                                at www.firstoutcomes.com, but
                                                                                any clinical data already received by such Transmission Partner will remain in its possession,
                                                                                subject
                                                                                to the terms of your independent agreements with such Transmission Partner, if any. You are
                                                                                solely
                                                                                responsible for ensuring that You have all necessary consents from Your patients applicable to
                                                                                the
                                                                                sharing of any of Your Health Information under applicable law with respect to each Transmission
                                                                                Partner
                                                                                with whom you have Consented to share any information under this Section 4.6.</p></li>
                                                                </li>
                                                        </ul>
                                                </li>
                                                <li><h4>Providing Physician Data to Payers and Others</h4>
                                                        <p>Without limiting the provisions of Section 7.2, you agree that we may provide De-Identified
                                                                Health
                                                                Information and other information (including Your Personal Information and information
                                                                concerning your
                                                                practice) to any medical group, independent practice association of physicians, health plan or
                                                                other
                                                                organization with which you have a contract to provide medical services, or to whose members or
                                                                enrollees you provide medical services. Such information may identify you but will not identify
                                                                any
                                                                individual to whom you provide services. Such information may include aggregated data concerning
                                                                your
                                                                patients, diagnoses, procedures, orders and the like.</p></li>
                                                <li><h4>Support and Assessment Resources</h4>
                                                        <p>
                                                                This section is a placeholder for future use.</p></li>
                                                <li><h4>Intellectual Property Rights</h4>
                                                        <ul style={{ listStyleType: "none" }}>
                                                                <li><p>7.1 Individually Identifiable Health Information
                                                                        You retain all rights with regard to Your Health Information so long as you keep your account
                                                                        current
                                                                        and all balances due and owing are paid, and we will only use such information as expressly
                                                                        permitted in
                                                                        these TOS.</p></li>
                                                                <li><p>7.2 De-Identified Information
                                                                        In consideration of our provision of the Service, you hereby transfer and assign to us all
                                                                        right, title
                                                                        and interest in and to all De-Identified Information that we make from Your Information pursuant
                                                                        to
                                                                        Section 4.1.5. You agree that we may use, disclose, market, license and sell such De-Identified
                                                                        Information for any purpose without restriction, and that you have no interest in such
                                                                        information, or
                                                                        in the proceeds of any sale, license, or other commercialization thereof. You acknowledge that
                                                                        the
                                                                        rights conferred by this Section are a principal consideration for the provision of the Service,
                                                                        without
                                                                        which we would not enter into these TOS.</p></li>
                                                                <li><p>7.3 Other Works and Information
                                                                        You hereby grant to us a nonexclusive, royalty-free, fully paid-up, perpetual, irrevocable,
                                                                        worldwide
                                                                        and fully sub-licensable right to use, reproduce, modify, adapt, publish, translate, create
                                                                        derivative
                                                                        works from, distribute, and display any information, material or work product – other than Your
                                                                        Information that has not been De-Identified – you provide to this site or the Service. You agree
                                                                        that we
                                                                        may use, disclose, market, license, and sell such information and works, including derivative
                                                                        products,
                                                                        without restriction. This includes, for example, custom templates that you create using the
                                                                        Service, and
                                                                        information (other than Your Information that has not been De-Identified) that you contribute to
                                                                        forums,
                                                                        discussion groups and the like. You may provide content or material to this site by uploading
                                                                        such
                                                                        content into the Service, participating in forums, discussion groups and the like, or by using
                                                                        the site
                                                                        to create custom templates and the like. Furthermore, you agree that we may use, disclose,
                                                                        market,
                                                                        license and sell such material or content, and that you have no interest in the information, or
                                                                        in the
                                                                        proceeds of any sale, license, or other commercialization thereof. You warrant and agree that
                                                                        any
                                                                        material you provide will not infringe or otherwise violate the intellectual property or other
                                                                        rights of
                                                                        others, and will not be otherwise unlawful, infringing, threatening, libelous, defamatory,
                                                                        obscene,
                                                                        pornographic, or in violation of any law.</p></li>
                                                                <li><p>7.4 Trade Secrets
                                                                        In order to protect our trade secrets herein, you affirm that neither you nor any of your
                                                                        employers,
                                                                        officers, executives, shareholders, partners, Workforce, or other business associates of any
                                                                        kind are
                                                                        currently or will become for a period of two (2) years after termination or expiration of any
                                                                        license
                                                                        granted to access or use the Service, either directly or indirectly, an officer, executive,
                                                                        shareholder,
                                                                        partner, employee, independent contractor, advisor, consultant, or any other business associate
                                                                        of any
                                                                        kind of any business entity who does currently operate in or intends to enter into competition
                                                                        with us
                                                                        during the period of time you are licensed to use the Service. If we come to know or have
                                                                        reasonable
                                                                        cause to believe that you are in violation of this affirmation, we reserve the right to
                                                                        immediately,
                                                                        permanently, and without notice terminate your access rights and privileges. Any such use shall
                                                                        subject
                                                                        you, as well as any of your officers, executives, shareholders, or partners, to legal action,
                                                                        which may
                                                                        include both civil and criminal penalties, as applicable. You acknowledge that any such civil
                                                                        action may
                                                                        include, but is not limited to, relief sought in the form of: injunctive relief; actual,
                                                                        punitive,
                                                                        statutory, or liquidated damages; any amount of unjust enrichment accrued as a result of such
                                                                        use; and
                                                                        attorney’s fees.
                                                                        You acknowledge that all Ancillary Documentation is the confidential information of First
                                                                        Outcomes, and
                                                                        you will not use or disclose Ancillary Documentation to any person or entity who is not
                                                                        specifically
                                                                        authorized in writing by First Outcomes to receive it. You may not use or disclose any Ancillary
                                                                        Documentation except to the extent that such use or disclosure of such Ancillary Documentation
                                                                        relates
                                                                        to your use of the Service for your own site-level administration. You will use your best
                                                                        efforts to
                                                                        safeguard the Ancillary Documentation and protect it against disclosure, misuse, espionage,
                                                                        loss,
                                                                        misappropriation, and theft.
                                                                        You will not reverse engineer, decompile, disassemble, or otherwise attempt to discover or
                                                                        reduce to
                                                                        human readable form the source code of the Service, except to the extent allowed under any
                                                                        applicable
                                                                        law. Any attempt to do so must utilize a clean room design. If applicable law permits such
                                                                        activities,
                                                                        any information so discovered must be promptly disclosed to us and shall be deemed to be our
                                                                        confidential proprietary information. You will not access the service for the purposes of
                                                                        copying the
                                                                        flow, process, or any other Intellectual Property belonging to us for the purposes of
                                                                        incorporating any
                                                                        such Intellectual Property into a competitive product. If you access the service on behalf of or
                                                                        for the
                                                                        benefit of a competitor or for any competitive purposes, you must immediately cease such access
                                                                        and
                                                                        report your unauthorized access to First Outcomes at contact@First Outcomes.com.
                                                                        In the event that, at the conclusion of any resulting litigation, the Service is held to have
                                                                        been
                                                                        legitimately used in any manner to derive a new product, that derivative product may then only
                                                                        be used
                                                                        for noncommercial purposes and may not be marketed, distributed, or otherwise made available in
                                                                        any way.
                                                                        This provision shall not be read to authorize, explicitly or implicitly, use of the Service in
                                                                        such a
                                                                        manner.</p></li>
                                                                <li><p>7.5. Copyright and Ownership
                                                                        All content included in or made available through any First Outcomes product or service, such as
                                                                        input
                                                                        screens and forms, charts, text, graphics, logos, button icons, images, audio clips, digital
                                                                        downloads,
                                                                        and data compilations is the property of First Outcomes, or its content suppliers, and is
                                                                        protected by
                                                                        United States and international copyright laws. The compilation of all content included in or
                                                                        made
                                                                        available through the Service is the exclusive property of First Outcomes and is protected by
                                                                        U.S. and
                                                                        international copyright laws. All content posted on the Service by you must comply with U.S.
                                                                        copyright
                                                                        law.
                                                                        The Service is the intellectual property of and is owned by First Outcomes. The structure,
                                                                        organization,
                                                                        flows, processes, and source code of the Service (collectively, “Intellectual Property”) are the
                                                                        valuable trade secrets and confidential information of First Outcomes. The Service is protected
                                                                        by law,
                                                                        including but not limited to the copyright laws of the United States and by international treaty
                                                                        provisions. Except as expressly stated herein, this agreement does not grant you any
                                                                        intellectual
                                                                        property rights in the Service. All rights not expressly granted are reserved by First Outcomes
                                                                        Inc.
                                                                        To the extent that Ancillary Documentation includes intellectual property owned by First
                                                                        Outcomes, you
                                                                        agree that First Outcomes does not grant you any intellectual property rights to any such
                                                                        Ancillary
                                                                        Documentation. To the extent that Ancillary Documentation includes intellectual property owned
                                                                        by third
                                                                        parties, you will not use or disclose such Ancillary Documentation in contravention of any
                                                                        agreement
                                                                        between First Outcomes and the third party.
                                                                        We shall have a royalty-free, worldwide, irrevocable, perpetual license to use and incorporate
                                                                        into the
                                                                        Service any suggestions, enhancement requests, recommendations, or other feedback provided by
                                                                        you
                                                                        relating to the operation of the Service. The look and feel of the Service, including the
                                                                        process and
                                                                        flows, are copyright ©2019-2020 First Outcomes Inc. All rights reserved. You may not duplicate,
                                                                        copy, or
                                                                        reuse any portion of the HTML/CSS or visual design elements without the express written
                                                                        permission of
                                                                        First Outcomes’s CEO. Portions may be Patent Pending.</p></li>
                                                        </ul>
                                                </li>
                                                <li><h4>Individuals’ Rights</h4>
                                                        <p>
                                                                You are solely responsible for affording individuals their rights with respect to relevant
                                                                portions of
                                                                Your Health Information, such as the rights of access and amendment. You will not undertake to
                                                                afford an
                                                                individual any rights with respect to any information in the Service other than Your Health
                                                                Information.</p></li>
                                                <li><h4>Business Associate Agreement</h4>
                                                        <p>
                                                                You agree to be bound by the terms and conditions of the BAA, which is incorporated herein by
                                                                reference.</p></li>
                                                <li><h4>Computer Systems</h4>
                                                        <p>
                                                                You agree and acknowledge that you will be required to acquire, install, configure and maintain
                                                                all
                                                                hardware, software and communications systems necessary to access the Service and go through all
                                                                necessary training (your “Computer Systems“). Your Computer Systems will comply with the
                                                                specifications
                                                                from time to time established by us. You will ensure that your Implementation is compatible with
                                                                the
                                                                Service. If we notify you that your Computer Systems are incompatible with the Service, you will
                                                                eliminate the incompatibility, and we may suspend Service to you until you do so.</p></li>
                                                <li><h4>Confidential Information</h4>
                                                        <ul style={{ listStyleType: "none" }}>
                                                                <li><p>11.1 You may not disclose our Confidential Information to any other person, and you may not use
                                                                        any
                                                                        Confidential Information except for the purpose of these TOS. Except as otherwise provided in
                                                                        these TOS,
                                                                        you may not, without the prior written consent of First Outcomes’s CEO, at any time, during or
                                                                        after the
                                                                        Term of these TOS, directly or indirectly, divulge or disclose Confidential Information for any
                                                                        purpose.
                                                                        In addition, except for the purposes of using the Service, you will not use Confidential
                                                                        Information for
                                                                        any other purposes. You will hold all Confidential Information in strict confidence and take all
                                                                        measures necessary to prevent unauthorized copying, use, or disclosure of Confidential
                                                                        Information, and
                                                                        to keep the Confidential Information from falling into the public domain or into the possession
                                                                        of
                                                                        persons not bound to maintain its confidentiality. You will disclose Confidential Information
                                                                        only to
                                                                        members of your Authorized Workforce who have a need to use it for the purposes of these TOS.
                                                                        You will
                                                                        inform all such recipients of the confidential nature of Confidential Information and will
                                                                        instruct them
                                                                        to deal with Confidential Information in accordance with the terms of these TOS. You will
                                                                        promptly
                                                                        advise us in writing of any improper disclosure, misappropriation, or misuse of the Confidential
                                                                        Information by any person, which may come to your attention.</p></li>
                                                                <li><p>11.2 You agree that we will suffer irreparable harm if you fail to comply with your obligations
                                                                        set
                                                                        forth in Section 14.1, and you further agree that monetary damages will be inadequate to
                                                                        compensate us
                                                                        for any such breach. Accordingly, you agree that we will, in addition to any other remedies
                                                                        available to
                                                                        us at law or in equity, be entitled to the issuance of injunctive relief to enforce the
                                                                        provisions
                                                                        hereof, immediately and without the necessity of posting a bond.</p></li>
                                                        </ul>
                                                        <li><h4>Disclaimer, Exclusion of Warranties, and Limitation of Liability</h4>
                                                                <ul style={{ listStyleType: "none" }}>
                                                                        <li><p>12.1 Carrier Lines
                                                                                YOU ACKNOWLEDGE THAT ACCESS TO THE SERVICES WILL BE PROVIDED OVER VARIOUS FACILITIES AND
                                                                                COMMUNICATIONS
                                                                                LINES, AND INFORMATION WILL BE TRANSMITTED OVER LOCAL EXCHANGE AND INTERNET BACKBONE CARRIER
                                                                                LINES AND
                                                                                THROUGH ROUTERS, SWITCHES, AND OTHER DEVICES (COLLECTIVELY, “CARRIER LINES”) OWNED, MAINTAINED,
                                                                                AND
                                                                                SERVICED BY THIRD-PARTY CARRIERS, UTILITIES, AND INTERNET SERVICE PROVIDERS, ALL OF WHICH ARE
                                                                                BEYOND OUR
                                                                                CONTROL. WE ASSUME NO LIABILITY FOR, OR RELATING TO, THE INTEGRITY, PRIVACY, SECURITY,
                                                                                CONFIDENTIALITY,
                                                                                OR USE OF ANY INFORMATION WHILE IT IS TRANSMITTED ON THE CARRIER LINES, OR ANY DELAY, FAILURE,
                                                                                INTERRUPTION, INTERCEPTION, LOSS, TRANSMISSION, OR CORRUPTION OF ANY DATA OR OTHER INFORMATION
                                                                                ATTRIBUTABLE TO TRANSMISSION ON THE CARRIER LINES. USE OF THE CARRIER LINES IS SOLELY AT YOUR
                                                                                RISK AND
                                                                                IS SUBJECT TO ALL APPLICABLE LOCAL, STATE, NATIONAL, AND INTERNATIONAL LAWS.</p></li>
                                                                        <li><p>12.2 No Warranties
                                                                                ACCESS TO THE SERVICES AND THE INFORMATION CONTAINED ON THE SERVICES IS PROVIDED “AS IS” AND “AS
                                                                                AVAILABLE” WITHOUT ANY WARRANTY OF ANY KIND, AND WE DISCLAIM ALL WARRANTIES, EXPRESSED OR
                                                                                IMPLIED,
                                                                                STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                                                                                FITNESS
                                                                                FOR A PARTICULAR PURPOSE, NONINFRINGEMENT AND TITLE. YOU ARE SOLELY RESPONSIBLE FOR ANY AND ALL
                                                                                ACTS OR
                                                                                OMISSIONS TAKEN OR MADE IN RELIANCE ON THE SERVICES OR THE INFORMATION IN THE SERVICES,
                                                                                INCLUDING USE OF
                                                                                SMS MESSAGING FEATURES AND INACCURATE OR INCOMPLETE INFORMATION. IT IS EXPRESSLY AGREED THAT IN
                                                                                NO EVENT
                                                                                SHALL WE BE LIABLE FOR ANY SPECIAL, INDIRECT, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING BUT
                                                                                NOT
                                                                                LIMITED TO, LOSS OF PROFITS OR REVENUES, LOSS OF USE, LOSS OF GOODWILL, OR LOSS OF INFORMATION
                                                                                OR DATA,
                                                                                WHETHER A CLAIM FOR ANY SUCH LIABILITY OR DAMAGES IS PREMISED UPON BREACH OF CONTRACT, BREACH OF
                                                                                WARRANTY, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER THEORY OF LIABILITY, EVEN IF WE HAVE BEEN
                                                                                APPRISED
                                                                                OF THE POSSIBILITY OR LIKELIHOOD OF SUCH DAMAGES. WE DISCLAIM ANY AND ALL LIABILITY FOR
                                                                                ERRONEOUS
                                                                                TRANSMISSIONS AND LOSS OF SERVICE RESULTING FROM COMMUNICATION FAILURES BY TELECOMMUNICATION
                                                                                SERVICE
                                                                                PROVIDERS OR THE SERVICES. NO FIRST OUTCOMES EMPLOYEE OR AGENT IS AUTHORIZED TO MAKE ANY
                                                                                STATEMENT THAT
                                                                                ADDS TO OR AMENDS THE WARRANTIES, CONDITIONS, OR LIMITATIONS CONTAINED IN YOUR SERVICE AGREEMENT
                                                                                OR
                                                                                THESE TOS.</p></li>
                                                                        <li><p>12.3 Conditions for Breach
                                                                                We will not be deemed to be in violation of these TOS unless you have first given us written
                                                                                notice
                                                                                specifying the nature of the default, and we have failed within thirty (30) days of receipt of
                                                                                the
                                                                                notice either to cure the default or, if cure within such period is not practicable, to be
                                                                                diligently
                                                                                proceeding to cure the default.</p></li>
                                                                        <li><p>12.4 Unauthorized Access; Lost or Corrupt Data
                                                                                WE ARE NOT RESPONSIBLE FOR UNAUTHORIZED ACCESS TO YOUR DATA, FACILITIES OR EQUIPMENT BY PERSONS
                                                                                USING
                                                                                THE SERVICES OR FOR UNAUTHORIZED ACCESS TO, ALTERATION, THEFT, CORRUPTION, LOSS OR DESTRUCTION
                                                                                OF YOUR
                                                                                DATA FILES, PROGRAMS, PROCEDURES, OR INFORMATION THROUGH THE SERVICES, WHETHER BY ACCIDENT,
                                                                                FRAUDULENT
                                                                                MEANS OR DEVICES, OR ANY OTHER MEANS. YOU ARE SOLELY RESPONSIBLE FOR VALIDATING THE ACCURACY OF
                                                                                ALL
                                                                                OUTPUT AND REPORTS, AND FOR PROTECTING YOUR DATA AND PROGRAMS FROM LOSS BY IMPLEMENTING
                                                                                APPROPRIATE
                                                                                SECURITY MEASURES. YOU HEREBY WAIVE ANY DAMAGES OCCASIONED BY LOST OR CORRUPT DATA,
                                                                                INCORRECT REPORTS,
                                                                                OR INCORRECT DATA FILES RESULTING FROM PROGRAMMING ERROR, OPERATOR ERROR, EQUIPMENT OR SOFTWARE
                                                                                MALFUNCTION, SECURITY VIOLATIONS, OR THE USE OF THIRD-PARTY SOFTWARE. WE ARE NOT RESPONSIBLE FOR
                                                                                THE
                                                                                CONTENT OF ANY INFORMATION TRANSMITTED OR RECEIVED THROUGH OUR PROVISION OF THE SERVICES.</p>
                                                                        </li>
                                                                        <li><p>12.5 Limitation of Liability
                                                                                NOTWITHSTANDING ANYTHING IN THESE TOS OR THE SERVICE AGREEMENT TO THE CONTRARY, OUR AGGREGATE
                                                                                LIABILITY
                                                                                UNDER THE SERVICE AGREEMENT, REGARDLESS OF THEORY OF LIABILITY, SHALL BE LIMITED TO THE
                                                                                AGGREGATE FEES
                                                                                ACTUALLY PAID BY YOU UNDER THE SERVICE AGREEMENT FOR THE TWO (2) MONTH PERIOD PRECEDING THE
                                                                                EVENT FIRST
                                                                                GIVING RISE TO THE CLAIM, UP TO A LIMIT OF $2,000 IN THE AGGREGATE. IN THE EVENT YOU RECOVER
                                                                                INSURANCE
                                                                                PROCEEDS PURSUANT TO YOUR INSURANCE, SUCH PROCEEDS SHALL CONSTITUTE AN OFFSET AGAINST ANY
                                                                                DAMAGES
                                                                                CLAIMED BY YOU. YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF
                                                                                OR
                                                                                RELATED TO THE SERVICE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES,
                                                                                OTHERWISE SUCH CAUSE OF ACTION SHALL BE PERMANENTLY BARRED. IT IS EXPRESSLY UNDERSTOOD AND
                                                                                AGREED THAT
                                                                                IN THE EVENT ANY REMEDY HEREUNDER IS DETERMINED TO HAVE FAILED OF ITS ESSENTIAL PURPOSE, ALL
                                                                                LIMITATIONS
                                                                                OF LIABILITY AND EXCLUSIONS OF DAMAGES SET FORTH HEREIN SHALL REMAIN IN EFFECT.</p></li>
                                                                </ul>
                                                        </li>
                                                </li>
                                                <li><h4>Insurance</h4>
                                                        <p>
                                                                You will obtain and maintain such policies of general liability, errors and omissions, and
                                                                professional
                                                                liability insurance with reputable insurance companies as is usually carried by persons engaged
                                                                in your
                                                                business covering the term of these TOS.</p></li>
                                                <li><h4>Modification; Suspension; Termination</h4>
                                                        <ul style={{ listStyleType: "none" }}>
                                                                <li><p>14.1 Termination upon Notice
                                                                        Absent any agreement to the contrary, you may terminate the Service Agreement in accordance with
                                                                        the
                                                                        terms thereof.</p></li>
                                                                <li><p>14.2 Modification
                                                                        We may update or change the Service, or the terms set forth in these TOS from time to time.
                                                                        Accordingly,
                                                                        we recommend that you review the TOS on a regular basis. You understand and agree that your
                                                                        continued
                                                                        use of the Service after the TOS has been updated or changed constitutes your acceptance of the
                                                                        revised
                                                                        TOS. Without limiting the foregoing, if we make a change to the TOS that materially affects your
                                                                        use of
                                                                        the Service, we may post notice or notify you via email or our website(s) of any such
                                                                        change.</p></li>
                                                                <li><p>14.3 Termination, Suspension or Amendment as a Result of Government Regulation
                                                                        Notwithstanding anything to the contrary in these TOS, we have the right, on notice to you,
                                                                        immediately
                                                                        to terminate, suspend, or amend these TOS, without liability: (a) to comply with any order
                                                                        issued or
                                                                        proposed to be issued by any governmental agency; (b) to comply with any provision of law,
                                                                        any
                                                                        standard
                                                                        of participation in any reimbursement program, or any accreditation standard; (c) if
                                                                        performance
                                                                        of any
                                                                        term of these TOS by either Party would cause it to be in violation of law.</p></li>
                                                                <li><p>14.5 Judicial or Administrative Procedures; Credentialing
                                                                        We may terminate your Service Agreement and these TOS, and your access to the Service,
                                                                        immediately upon
                                                                        notice to you: (a) if you are named as a defendant in a criminal proceeding for a violation
                                                                        of
                                                                        federal
                                                                        or state law; (b) if a finding or stipulation is made or entered into that you have violated
                                                                        any
                                                                        standard or requirement of federal or state law relating to the privacy or security of health
                                                                        information in any administrative or civil proceeding; (c) you are excluded from
                                                                        participation
                                                                        in a
                                                                        federal or state health care program; (d) you cease to be qualified to provide services as a
                                                                        health care
                                                                        professional, or we are unable to verify your qualifications as such or (e) as otherwise set
                                                                        forth in
                                                                        the Service Agreement.</p></li>
                                                                <li><p>14.6 Suspension of Access
                                                                        We may suspend access to the Service by you or any member of your Workforce immediately pending
                                                                        your
                                                                        cure of any breach of these TOS, or in the event we determine in our sole discretion that access
                                                                        to or
                                                                        use of the Service by you or the member of your Workforce may jeopardize the Service or the
                                                                        confidentiality, privacy, security, integrity or availability of information within the Service,
                                                                        or that
                                                                        you or the member of your Workforce has violated or may violate these TOS or our Policies and
                                                                        Procedures, or has jeopardized or may jeopardize the rights of any third party, or that any
                                                                        person is or
                                                                        may be making unauthorized use of the Service with any Credentials assigned to you or a member
                                                                        of your
                                                                        Workforce. We may terminate the access of any member of your Authorized Workforce upon
                                                                        termination or
                                                                        change in status of his or her employment with you. Our election to suspend the Service shall
                                                                        not waive
                                                                        or affect our rights to terminate these TOS as permitted under these TOS.</p></li>
                                                                <li><p>14.7 Obligations after Termination
                                                                        Upon termination of the Service Agreement for any reason, you will (i) cease all use of the
                                                                        Service and
                                                                        (ii) pay the outstanding balance of any fees due to us. After your content has been deleted from
                                                                        the
                                                                        Service, it cannot be recovered. All provisions of the TOS which, by their nature, should
                                                                        survive
                                                                        termination shall survive termination, including, but not limited to, Sections 1, 3.15, 5, 7,
                                                                        9.10,
                                                                        11.2, 12 through 15, 16, 17.3, and 18 through 23.</p></li>
                                                        </ul>
                                                </li>
                                                <li><h4>No Solicit or Hire Clause</h4>
                                                        <p>You acknowledge that First Outcomes invests considerable time and expense in the training of its
                                                                employees and independent subcontractors in the services to be provided under the Service
                                                                Agreement. You
                                                                agree that for the full term of the Service Agreement, and for 2 years after its termination,
                                                                you will
                                                                not solicit or employ in any capacity, whether as a direct employee, independent contractor or
                                                                as a
                                                                representative of another company providing similar services to you as the Service provided by
                                                                First
                                                                Outcomes, any person employed by First Outcomes at any time during the term of the Service
                                                                Agreement
                                                                whose duties involve providing the Service, whether for yourself or other First Outcomes
                                                                customers. In
                                                                addition to equitable remedies, you acknowledge and agree that actual damages in the event of a
                                                                breach
                                                                of this Section 18 are difficult to ascertain at present. You have reviewed and found the amount
                                                                of one
                                                                hundred thousand dollars ($200,000) to be a reasonable estimate of damages First Outcomes would
                                                                incur if
                                                                you breach this Section 15. You have reviewed and will not challenge this amount as
                                                                unreasonable, and
                                                                you agree that you will not contest an order entering a judgment in this amount without delay
                                                                and that
                                                                you will not present any defenses or counterclaims.</p></li>
                                                <li><h4>Applicable Law</h4>
                                                        <p>
                                                                The interpretation of the Service Agreement, including these TOS, and the resolution of any
                                                                disputes
                                                                arising out of or related to the Service Agreement, including these TOS shall be governed by the
                                                                laws of
                                                                the State of New York, without regards to its conflicts of laws provisions. If any action or
                                                                other
                                                                proceeding is brought on or in connection with the Service Agreement, including these TOS, the
                                                                venue of
                                                                such action shall be exclusively in the applicable state or federal courts in and for Suffolk
                                                                County,
                                                                New York.</p></li>
                                                <li><h4>Arbitration</h4>
                                                        <ul style={{ listStyleType: "none" }}>
                                                                <li><p>17.1 EXCEPT FOR CLAIMS BY EITHER PARTY UNDER ANY OF THE SECTIONS
                                                                        OF THESE TOS LISTED IN SECTION</p></li>
                                                                <li><p>17.2, ANY
                                                                        DISPUTE, CLAIM OR CONTROVERSY ARISING OUT OF OR RELATING TO THE SERVICE AGREEMENT INCLUDING
                                                                        THESE TOS OR
                                                                        THE BREACH, TERMINATION, ENFORCEMENT, INTERPRETATION OR VALIDITY THEREOF, INCLUDING THE
                                                                        DETERMINATION OF
                                                                        THE SCOPE OR APPLICABILITY OF THIS AGREEMENT TO ARBITRATE, SHALL BE SUBJECT TO FINAL AND BINDING
                                                                        ARBITRATION GOVERNED BY THE FEDERAL ARBITRATION ACT (9 U.S.C. §§ 1 ET SEQ.). THE ARBITRATION
                                                                        SHALL BE
                                                                        CONDUCTED BEFORE A SINGLE ARBITRATOR IN ACCORDANCE WITH THE JAMS STREAMLINED ARBITRATION RULES &
                                                                        PROCEDURES THAT ARE IN EFFECT AT THE TIME ARBITRATION IS INITIATED, AS MODIFIED BY THESE TOS.
                                                                        JUDGMENT
                                                                        ON THE AWARD MAY BE ENTERED IN ANY COURT HAVING JURISDICTION. THIS CLAUSE SHALL NOT PRECLUDE
                                                                        EITHER
                                                                        PARTY FROM SEEKING TEMPORARY OR PRELIMINARY INJUNCTIVE RELIEF IN CONNECTION WITH AN ARBITRABLE
                                                                        CONTROVERSY, BUT ONLY UPON THE GROUND THAT THE AWARD TO WHICH THAT PARTY MAY BE ENTITLED MAY BE
                                                                        RENDERED
                                                                        INEFFECTUAL WITHOUT SUCH PROVISIONAL RELIEF.</p></li>
                                                                <li><p>17.2 THIS AGREEMENT TO ARBITRATE SHALL NOT APPLY TO INTELLECTUAL PROPERTY CLAIMS BROUGHT BY
                                                                        FIRST
                                                                        OUTCOMES OR CLAIMS BY ANY PARTY BROUGHT UNDER AND TO ENFORCE ANY ONE OR MORE OF THE FOLLOWING
                                                                        SECTIONS
                                                                        OF THESE TOS: 2.1; 3.1.2; 3.1.3; 3.2; 3.4.3(c)-(j); 3.8; 3.9; 15 or, TO THE EXTENT APPLICABLE TO
                                                                        THE
                                                                        FOREGOING SECTIONS, 14.6.</p></li>
                                                                <li><p>17.3 THE PARTIES AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN AN INDIVIDUAL
                                                                        CAPACITY, AND
                                                                        NOT AS A PLAINTIFF, CLASS MEMBER, OR PRIVATE ATTORNEY GENERAL IN ANY PURPORTED CLASS,
                                                                        REPRESENTATIVE, OR
                                                                        PRIVATE-ATTORNEY-GENERAL PROCEEDING. BY ENTERING INTO THESE TOS, YOU AND WE ARE EACH WAIVING THE
                                                                        RIGHT
                                                                        TO A JURY TRIAL OR TO PARTICIPATE IN A CLASS ACTION. THE ARBITRATOR MAY NOT CONSOLIDATE MORE
                                                                        THAN ONE
                                                                        PERSON’S CLAIM WITH YOUR CLAIMS OR OUR CLAIMS AND MAY NOT OTHERWISE PRESIDE OVER ANY
                                                                        REPRESENTATIVE,
                                                                        CLASS, OR PRIVATE-ATTORNEY-GENERAL PROCEEDING. THE ARBITRATOR MAY AWARD DECLARATORY OR
                                                                        INJUNCTIVE RELIEF
                                                                        ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE
                                                                        RELIEF
                                                                        WARRANTED BY THAT PARTY’S INDIVIDUAL CLAIM, IN ADDITION TO
                                                                        REASONABLE ATTORNEYS’ FEES AND COSTS.</p></li>
                                                                <li><p>17.4 Any part of this agreement to arbitrate that shall prove to be invalid, void, or illegal
                                                                        shall in
                                                                        no way affect, impair, or invalidate any other provision of these TOS to arbitrate, and such
                                                                        other
                                                                        provisions shall remain in full force and effect.</p></li>
                                                        </ul>
                                                        <li><h4>Amending Client Information; Assignability of these TOS; Assurances</h4>
                                                                <ul style={{ listStyleType: "none" }}>
                                                                        <li><p>18.1 Amending Client Information
                                                                                If you are a Client, you are required to submit to us all information necessary to confirm
                                                                                yourself as
                                                                                the Client, and maintain the accuracy of such information, in a timely fashion, during the term
                                                                                of these
                                                                                TOS. You are also required to maintain the accuracy of all information associated with each
                                                                                Credential.
                                                                                We shall be entitled to rely on all information you submit to us under these TOS, including
                                                                                pursuant to
                                                                                Section 3.1 or this Section 18.1. If you contact us and assert that you have authority to act on
                                                                                behalf
                                                                                of a Client or any of its account(s) or data, you hereby agree to submit to us such written
                                                                                certifications, assurances (which may include a written opinion of your counsel identifying us
                                                                                as
                                                                                beneficiaries entitled to rely on such opinion), instruments or judicial process as we, in our
                                                                                sole
                                                                                discretion, may request.</p></li>
                                                                        <li><p>18.2 Assignments
                                                                                The Service Agreement, including these TOS, may be transferred in its entirety by a Client in
                                                                                connection
                                                                                with the sale, transfer or reorganization of all or substantially all of the practice or
                                                                                business to
                                                                                which the Service Agreement relates; provided that each of the following conditions are
                                                                                satisfied in
                                                                                full: (a) an authorized representative of the transferor or transferee notifies us in
                                                                                writing of
                                                                                the
                                                                                transfer, the legal name of the transferee, and date of transfer;(b) the transferor or
                                                                                transferee
                                                                                submits to us such written certifications, assurances (which may include a written opinion of
                                                                                your
                                                                                counsel identifying us as beneficiaries entitled to rely on such opinion) or instruments as we,
                                                                                in our
                                                                                sole discretion, may request; and  (c) we are satisfied, in our sole discretion, of the
                                                                                validity
                                                                                of the
                                                                                certifications, assurances or instruments submitted pursuant to clause
                                                                                (d). Upon our recognition
                                                                                of a
                                                                                transfer by a Client, the Administrative Rights and all User accounts of such Client’s
                                                                                Authorized
                                                                                Workforce shall automatically transfer to such Client’s recognized transferee. Except as
                                                                                expressly set
                                                                                forth in this Section 18.2, you may not assign or transfer the Service Agreement, including
                                                                                these TOS,
                                                                                in whole or in part, without the prior written consent of First Outcomes’s CEO, which may be
                                                                                withheld at
                                                                                our sole discretion. We may freely assign the Service Agreement, including these TOS in
                                                                                connection with
                                                                                a merger, acquisition, or sale of assets, or by operation of law or otherwise. In the event that
                                                                                the
                                                                                Service Agreement is assigned or transferred by a Client in accordance with this Section 18.2,
                                                                                we may in
                                                                                our sole discretion update the Service Agreement to the pricing on our most recent Price List as
                                                                                of the
                                                                                date of such assignment or transfer.
                                                                                18.3 Assurances
                                                                                By requesting or directing us to take any action described in Section 18.1 or Section 18.2 with
                                                                                respect
                                                                                to any Client or any account(s) or data held by such account(s), you represent and warrant that
                                                                                (i) you
                                                                                have the authority to act on such Client’s behalf or to control such account(s) or data, and
                                                                                (ii) your
                                                                                request or direction is not in furtherance of any purpose or action that would violate any
                                                                                provision of
                                                                                these TOS, applicable law or the rights of any person or entity. YOU HEREBY WAIVE AND
                                                                                UNCONDITIONALLY
                                                                                RELEASE US, OUR AFFILIATES, AND OUR AND THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES AND
                                                                                AGENTS, FROM
                                                                                ANY AND ALL CLAIMS, DEMANDS, DAMAGES, DEBTS, LIABILITIES, EXPENSES, ACTIONS AND CAUSES OF
                                                                                ACTIONS OF
                                                                                EVERY KIND AND NATURE, WHETHER NOW KNOWN OR UNKNOWN, ARISING OUT OF OR IN CONNECTION WITH ANY
                                                                                ACTION WE
                                                                                TAKE OR DO NOT TAKE IN RESPONSE TO ANY REQUEST, DIRECTION, INFORMATION, CERTIFICATION, ASSURANCE
                                                                                OR
                                                                                INSTRUMENTS WE RECEIVE FROM YOU IN ACCORDANCE WITH SECTION 18.1 OR SECTION 18.2. ACCORDINGLY,
                                                                                YOU AGREE
                                                                                TO WAIVE THE BENEFIT OF ANY LAW, INCLUDING, TO THE EXTENT APPLICABLE, CALIFORNIA CIVIL CODE §
                                                                                1542 (OR
                                                                                SIMILAR PROVISIONS OF THE LAWS OF OTHER STATES), WHICH STATES,
                                                                                A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST
                                                                                IN HIS
                                                                                OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE
                                                                                MATERIALLY
                                                                                AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.
                                                                                You hereby agree to indemnify, defend, and hold harmless us and other users, and our and their
                                                                                respective affiliates, officers, directors, employees and agents, from and against any claim,
                                                                                cost or
                                                                                liability, including reasonable attorneys’ fees arising from: (a) any action we take in reliance
                                                                                on any
                                                                                information, certification, assurance or instrument you provide to us, or (b) any action we take
                                                                                that
                                                                                complies with any request or direction you at any time make or made.​</p></li></ul></li>
                                                        <li><h4>Force Majeure</h4><p>
                                                                No party to the Service Agreement, including these TOS, shall be deemed in violation if it is
                                                                prevented
                                                                from performing any of its the obligations thereunder by reason of: (a) severe weather and
                                                                storms; (b)
                                                                earthquakes or other natural occurrences; (c) strikes or other labor unrest; (d) power failures;
                                                                (e)
                                                                nuclear or other civil or military emergencies; (f) acts of legislative, judicial, executive, or
                                                                administrative authorities; or (g) any other circumstances that are not within its reasonable
                                                                control.</p></li>
                                                        <li><h4>Severability</h4><p>
                                                                Any provision of the Service Agreement, including these TOS that shall prove to be invalid,
                                                                void, or
                                                                illegal, shall in no way affect, impair, or invalidate any other provision of the Service
                                                                Agreement.
                                                                Such other provisions shall remain in full force and effect and the unenforceable term or
                                                                provision
                                                                shall be replaced by such enforceable term or provision as comes closest to the intention
                                                                underlying the
                                                                unenforceable term or provision.</p></li>
                                                        <li><h4>Notices</h4><p>
                                                                Any and all notices required or permitted under these TOS shall be sent by United States mail to
                                                                the
                                                                address provided below or to such other and different addresses as the Parties may designate in
                                                                writing.
                                                                If you supply us with an electronic mail address, we may give notice by email message addressed
                                                                to such
                                                                address; provided that if we receive notice that the email message was not delivered, we will
                                                                give the
                                                                notice by United States mail
                                                                To us:
                                                                First Outcomes Inc.
                                                                Attention: Chief Executive Officer
                                                                8 THE GREEN, STE A
                                                                DOVER, DE 19901

                                                                To you, at the current contact information on file with us at the time notice is given.</p></li>
                                                </li>
                                                <li><h4>No Third-Party Beneficiaries</h4><p>
                                                        Except as expressly provided for in Sections 2.2, 3.15, 14 and 18.3, nothing express or implied
                                                        in the
                                                        Service Agreement, including these TOS, is intended to confer, nor shall confer, upon any person
                                                        or
                                                        entity other than the parties and their respective successors or assigns any rights, remedies,
                                                        obligations, or liabilities whatsoever.</p></li>
                                                <li><h4>Privacy Policy</h4><p>
                                                        Clients and their Authorized Workforce are responsible for determining uses and disclosures of
                                                        patient
                                                        medical information maintained in the First Outcomes System, in accordance with their legal and
                                                        professional responsibilities as health care professionals and state and federal medical privacy
                                                        laws,
                                                        including the federal Health Insurance Portability and Accountability Act (“HIPAA”). To the
                                                        extent that
                                                        First Outcomes receives or maintains patient medical information in the course of providing the
                                                        Service,
                                                        that information is secured, used and disclosed only in accordance with First Outcomes legal
                                                        obligations
                                                        as a “business associate” under HIPAA.
                                                        Last updated May 2, 2020.</p>
                                                </li></li></ol>

                        </div>
                </Modal>
        )
}

export default TermsOfService;