import loginUser from "../../services/loginService";


export async function onLogin(values, navigate, setError) {
  setError("");
  const userData = {
    username: values.username,
    password: values.password,
  };

  try {
    const response = await loginUser(userData);
    if (response && response.data.success) {
      sessionStorage.setItem('userId', response.data.user.userId);
      sessionStorage.setItem('userName', response.data.user.username);
      sessionStorage.setItem('isPhoneVerified', response.data.user.phoneVerified);
      sessionStorage.setItem('isEmailVerified', response.data.user.emailVerified);
      sessionStorage.setItem('lastLoginAt', response.data.user.lastLoginAt);
      sessionStorage.setItem('firstname', response.data.user.firstname);
      sessionStorage.setItem('roleId', response.data.user.role);

      if (response.data.user.lastLoginAt === "0" || response.data.user.lastLoginAt === null || response.data.user.lastLoginAt === undefined || response.data.user.lastLoginAt === "") {
        navigate("/set-Password");
      } else {
        navigate("/dashboard");
      }
      return "success";
    } else {
      const firstError = response?.data?.businessRules?.[0]?.description || "Error Login";
      const lockWaitTime = response?.data?.lockWaitTime;
      if (firstError === "Multiple failed attempts") {
        setError(`Too many login attempts. Please try again in ${lockWaitTime} from now.`);
      } else if (firstError !== "Error Login") {
        setError(firstError);
      }
      else if (response.data.loginStatus === "4") {
        setError("User has been deactivated. Please contact system administrator.");
      }
      // else if (response.data.loginStatus === "5") {
      //   setError("Invalid user.");
      // }
      else {
        setError("Error Login");
      }

      return "invalid";
    }
  } catch (error) {
    setError("An error occurred during login.");
    return "error";
  }
}

export function redirectForgotPassword(navigate) {
  navigate('/Forgot-Password');
}
