import React, { useState } from 'react';
import { TimePicker, message } from 'antd';
import moment from 'moment';

const Test = () => {
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [error, setError] = useState(false);

    const handleStartTimeChange = (time) => {
        if (endTime && time.isAfter(endTime)) {
            setError(true);
            setStartTime(null);  // Clear start time when there's an error
            message.error('Start time should be before end time');
        } else {
            setError(false);
            setStartTime(time);
        }
    };

    const handleEndTimeChange = (time) => {
        if (startTime && time.isBefore(startTime)) {
            setError(true);
            setEndTime(null);  // Clear end time when there's an error
            message.error('End time should be after start time');
        } else {
            setError(false);
            setEndTime(time);
        }
    };

    const disabledTime = (current) => {
        if (!startTime || !endTime) {
            return null;
        }
        if (current && (current.isBefore(startTime) || current.isAfter(endTime))) {
            return {
                disabled: true,
            };
        }
        return null;
    };

    return (
        <div>
            <TimePicker
                value={startTime}
                onChange={handleStartTimeChange}
                status={error ? 'error' : ''}
                placeholder="Select Start Time"
                disabledTime={disabledTime}
            />
            <TimePicker
                value={endTime}
                onChange={handleEndTimeChange}
                status={error ? 'error' : ''}
                placeholder="Select End Time"
                style={{ marginLeft: 16 }}
                disabledTime={disabledTime}
            />
            {error && <div style={{ color: 'red' }}>Please select valid start and end times.</div>}
        </div>
    );
};

export default Test;
