// formValidationRules.js
const CreateUserValidation = {
    username: [
        { required: true, message: "User Name is required" },
    ],
    role: [
        { required: true, message: "Role is required" }
    ],
    provider: [
        { required: true, message: "Provider is required" }
    ],
    firstname: [
        { required: true, message: "First Name is required" },
    ],
    lastname: [
        { required: true, message: "Last Name is required" },
    ],
    email: [
        { required: true, message: "Email is required" },
        { type: 'email', message: 'Please enter a valid email address' },
    ],
    securitypin: [
        { required: true, message: "Security Pin is required" },

    ],
    practicelocation: [
        { required: true, message: "Practice location is required" }
    ],
    timezone: [
        { required: true, message: "Time Zone is required" }
    ],
    status: [],

    locationid: [
        { required: true, message: "Location ID is required" },
    ],
    locationname: [
        { required: true, message: "Location Name is required" },
    ],
    streetaddress: [
        { required: true, message: "Street Address is required" },
    ],
    city: [
        { required: true, message: "City is required" },
    ],
    state: [
        { required: true, message: "State is required" },
    ],
    zip: [
        { required: true, message: "Zip is required" },
    ],
    phone: [
        { required: true, message: "Phone is required" },
    ],
    country: [
        { required: true, message: "Country is required" },
    ],
    speciality: [
        { required: true, message: "speciality is required" },
    ],
};

export { CreateUserValidation };