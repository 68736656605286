import { useRoutes } from "react-router-dom";
import Login from "./pages/components/login/login";
import Dashboard from './pages/components/dashboard/dashboard';
import { ProtectedRoute } from "./pages/components/protectedRoute";
import SetPassword from "./pages/components/users/setPassword";
import ChangePassword from "./pages/components/users/changePassword";
import UserNotifyVerification from "./pages/components/userNotifyVerification/userNotifyVerification";
import Unauthorised from "./pages/components/common/Unauthorised";
import NotFound from "./pages/components/common/notFound";
import AppError from "./pages/components/common/appError";
import MyCalendar from "./pages/components/utilities/testUseEffect";
import ResetPassword from "./pages/components/users/resetPassword";
import ExpirePage from "./pages/components/common/expirePage";
import AppointmentCRMForm from "./pages/components/appointments/appointmentCrmForm";
import BookAppointment from "./pages/components/utilities/calendra";
import AppointmentAdd from "./pages/components/utilities/appointmemtAdd";
import MasterPage from "./pages/components/IpadRegistration/masterPage";
import StepForm from "./pages/components/IpadRegistration/stepForm";
import Test from "./pages/components/utilities/testUseEffect";
import SecuritySuccess from "./pages/components/common/securitySuccess";
import CMSTest from "./pages/CRM/cmsTest";
import CRMSteps from "./pages/CRM/CRMSteps";
import CrmNew from "./pages/CRM/CrmNew";
import CMSLastest from "./pages/CRM/cmsLastest";
import Report from "./pages/components/reports/test";

export default function App() {

    const routes = useRoutes([
        {
            path: "/",
            element: <Login />
        },
        {
            path: "/login",
            element: <Login />
        },
        {
            path: "/unauthorised",
            element: <Unauthorised />
        },
        {
            path: "/not-found",
            element: <NotFound />
        },
        {
            path: "/server-error",
            element: <AppError />
        },
        {
            path: "/set-Password",
            element:
                <ProtectedRoute>
                    <SetPassword />
                </ProtectedRoute>
        },
        {
            path: "/change-Password",
            element:
                <ProtectedRoute>
                    <ChangePassword />
                </ProtectedRoute>
        },
        {
            path: "/reset-Password/:id",
            element:
                <ResetPassword />
        },
        {
            path: "/userNotifyVerification",
            element:
                <ProtectedRoute>
                    <UserNotifyVerification />
                </ProtectedRoute>
        },
        {
            path: "/dashboard",
            element:
                <ProtectedRoute>
                    <Dashboard />
                </ProtectedRoute>
        },

        {
            path: "*",
            element: <NotFound />
        },
        {
            path: "/expired",
            element: <ExpirePage />
        },
        {
            path: "/appt",
            element: <BookAppointment />
        },
        {
            path: "/test",
            element: <Test />
        },
        {
            path: "/ipad-registration",
            element: <StepForm />
        },
        {
            path: "/security-success", // Add the SecuritySuccess route
            element: <SecuritySuccess />
        },
        // {
        //     path: "/CRMSteps", // Add the SecuritySuccess route
        //     element: <CRM />
        // },
        {
            path: "/CRM", // Add the SecuritySuccess route
            element: <CRMSteps />
        },
        {
            path: "/cmstest", // Add the SecuritySuccess route
            element: <CMSTest />
        },
        {
            path: "/CMSLastest", // Add the SecuritySuccess route
            element: <CMSLastest />
        },
        {
            path: "/report",
            element: <Report />
        },


    ]);

    return routes;
}