import React, { useState, useEffect } from 'react';
import { Table, Button, Space, Input, Form, Tooltip, Tag, Dropdown, Menu } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined, CaretDownOutlined ,PlusOutlined} from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { RiFileExcel2Line } from "react-icons/ri";
import "../../../../src/assets/styles/userList.css";
import "../../../assets/styles/common.css"
import pdfFile from '../../../assets/images/VaccineCalenda.png';
import { getreportData } from '../../services/reportService';


const Grid = ({ columnData, tableData, createButton, initialTableData, csvFileName, searchButton, downloadCSV, createButtonIcon, createButtonName, handleCreate,
    showEdit, showDelete, showView, handleEdit, handleDelete, handleLinkEdit, handleView }) => {
    const [form] = Form.useForm();
    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const [sortedData, setSortedData] = useState([]);
    const [tableDataInternal, setTableDataInternal] = useState(initialTableData);

    //Table dataIndex value & tag color
    const Active = 1;
    const Inactive = 2;
    const Pending = 3;

    const statusActive = "Active";
    const statusInactive = "Inactive";
    const statusPending = "Pending";

    const activeColor = "green";
    const inactiveColor = "red";
    const pendingColor = "gold";
    const defaultColor = "blue";

    // const handleRefresh = () => {
    //     setRefreshData(prevState => !prevState);
    // };
    // const handleCloseDrawer = () => {
    //     setDrawerVisible(false);
    // };


    useEffect(() => {
        setTableDataInternal(initialTableData);
    }, [initialTableData]);

    useEffect(() => {
        setTableDataInternal(tableData);
    }, [tableData]);

    const handleTableChange = (pagination, filters, sorter) => {
        // Access the filtered data from the 'tableData' array
        let newData = tableData
            .filter((record) =>
                Object.values(record).some((value) =>
                    value && typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
                )
            );

        // Apply filters
        for (const columnKey in filters) {
            const columnFilters = filters[columnKey];
            if (columnFilters && columnFilters.length > 0) {
                newData = newData.filter((record) => columnFilters.includes(String(record[columnKey])));
            }
        }

        // Update state with the current filtered data
        setFilteredData(newData);

        // Apply sorting
        if (sorter && sorter.columnKey && sorter.order) {
            newData = newData.sort((a, b) => {
                const sortOrder = sorter.order === 'ascend' ? 1 : -1;
                return String(a[sorter.columnKey]).localeCompare(String(b[sorter.columnKey])) * sortOrder;
            });
        }

        // Update state with the current sorted data
        setSortedData(newData);
    };
    const handleOpenPdf = () => {
        window.open(pdfFile, '_blank'); // Open the PDF file in a new tab
    };

    const generateColumns = (data) => {
        if (!Array.isArray(data)) {
            console.error("Data is not an array");
            return [];
        }

        return data.map((column) => {
            const { title, dataIndex, key, feature, render, width, hidden, filters, fixed, tags } = column;
            let columnConfig = {
                title,
                dataIndex,
                key,
                width,
                hidden,
                fixed,
                filters: dataIndex === 'action' ? [] : filters, // Remove filters only for "Action" column
            };

            if (feature && feature.includes('sorter')) {
                columnConfig.sorter = (a, b) => {
                    const aValue = a[dataIndex] || '';
                    const bValue = b[dataIndex] || '';
                    return String(aValue).localeCompare(String(bValue));
                };
            }
            if (tags) {
                columnConfig.render = (text, record) => {
                    let tagText;
                    let tagColor;

                    switch (record[dataIndex]) {
                        case Active:
                            tagText = statusActive;
                            tagColor = activeColor;
                            break;
                        case Inactive:
                            tagText = statusInactive;
                            tagColor = inactiveColor;
                            break;
                        case Pending:
                            tagText = statusPending;
                            tagColor = pendingColor;
                            break;
                        default:
                            tagText = text;
                            tagColor = defaultColor;
                            break;
                    }
                    return (
                        <Space>
                            <Tag color={tagColor}>{tagText}</Tag>
                        </Space>
                    );
                };
            }


            if (feature && feature.includes('filter')) {
                const uniqueValues = [...new Set(tableData.map(item => item[dataIndex]))];
                columnConfig.filters = uniqueValues
                    .filter(value =>
                        value !== null && value !== undefined && (typeof value === 'string' || typeof value === 'number') && String(value).length > 0)
                    .map(value => ({ text: String(value), value: String(value) }));

                columnConfig = {
                    ...columnConfig,
                    filterSearch: true,
                    onFilter: (value, record) => {
                        const dataIndexValue = record[dataIndex];
                        const valueString = String(value).toLowerCase();

                        if (typeof dataIndexValue === 'string') {
                            return dataIndexValue.toLowerCase().includes(valueString); // Case-insensitive search
                        } else if (typeof dataIndexValue === 'number') {
                            return String(dataIndexValue).includes(valueString); // Case-insensitive search
                        } else {
                            return false;
                        }
                    },
                };
            }


            if (render && Array.isArray(render)) {
                columnConfig.render = (text, record) => (
                    <Space>
                        {render.includes('edit') && showEdit && (
                            <Tooltip title="Edit">
                                <Button
                                    type="primary"
                                    id="useredit"
                                    className='gridIconEdit'
                                    icon={<EditOutlined />}
                                    onClick={() => handleEdit(record)}
                                />
                            </Tooltip>
                        )}
                        {render.includes('delete') && showDelete && (
                            <Tooltip title="Delete">
                                <Button
                                    id="delete"
                                    type="primary"
                                    danger
                                    className='gridIconDelete'
                                    icon={<DeleteOutlined />}
                                    onClick={() => handleDelete(record)}
                                />
                            </Tooltip>
                        )}
                        {render.includes('view') && (
                            <Tooltip title="View">
                                <Button
                                    type="primary"
                                    id="view"
                                    className='gridIconView'
                                    icon={<EyeOutlined />}
                                    onClick={() => handleView(record)}
                                />
                            </Tooltip>
                        )}

                        {render.includes('badge') && (
                            <span
                                style={{
                                    backgroundColor: record.regStatus === 'complete' || record.regStatus === 'patient complete' ? '#098609' : record.regStatus === 'partially complete' ? '#f9a11b' : record.regStatus === 'Incomplete' ? '#c50c0c' : 'blue',
                                    color: record.regStatus === 'partially complete' ? 'black' : 'white',
                                    padding: '4px 8px',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    fontWeight: '500',
                                }}
                            >
                                {record.regStatus}
                            </span>
                        )}

                        {['complete', 'patient complete'].includes(record.regStatus) && render.includes('dropdown') && (
                            <Dropdown
                                menu={{
                                    items: [
                                        {
                                            key: '1',
                                            label: (
                                                <Button type="text" onClick={handleOpenPdf}>
                                                    Insurance PDF
                                                </Button>
                                            )
                                        },
                                        {
                                            key: '2',
                                            label: (
                                                <Button type="text" onClick={() => console.log('Download PDF clicked!')}>
                                                    Profile PDF
                                                </Button>
                                            )
                                        },
                                        {
                                            key: '3',
                                            label: (
                                                <Button type="text" onClick={() => console.log('Download PDF clicked!')}>
                                                    Insurance PDF
                                                </Button>
                                            )
                                        }
                                    ]
                                }}
                                placement="bottomLeft"
                                arrow
                            >
                                <Button type="primary" icon={<CaretDownOutlined />}>
                                    Download PDF
                                </Button>
                            </Dropdown>
                        )}

                        {['Incomplete', 'partially complete'].includes(record.regStatus) && render.includes('link') && (
                            <Button type="link" onClick={() => handleLinkEdit(record)}>
                                List
                            </Button>
                        )}

                        {render.includes('appointmentIdLink') && record[dataIndex] && (
                            <Button type="link" onClick={() => handleView(record)}>
                                {record[dataIndex]}
                            </Button>
                        )}
                    </Space>
                );
            }

            return columnConfig;
        });
    };



    const columns = generateColumns(columnData?.columns || []);

    const prepareCSVData = () => {
        const visibleColumns = columns.filter((column) => !column.hidden && column.key !== 'action');
        //  header row
        const headerRow = visibleColumns.map((column) => column.title);
        // Use sortedData if available, otherwise use filteredData
        let dataToExport = sortedData.length > 0 ? sortedData : tableData;
        // Apply search filter
        if (searchText) {
            dataToExport = dataToExport.filter((record) =>
                Object.values(record).some(
                    (value) =>
                        value &&
                        typeof value === 'string' &&
                        value.toLowerCase().includes(searchText.toLowerCase())
                )
            );
        }
        //  data rows based on the data to export
        const dataRows = dataToExport.map((record) =>
            visibleColumns.map((column) => {
                if (column.render) {
                    return column.render(record[column.dataIndex], record);
                } else {
                    return record[column.dataIndex];
                }
            })
        );
        return [headerRow, ...dataRows];
    };



    // const expandedRowRender = (record) => (
    //     <p style={{ margin: 0 }}>
    //         hi
    //     </p>
    // );
    return (
        <>
            <div className="">


                <Table
                    // expandable={{
                    //     expandedRowRender,
                    //     rowExpandable: (record) => (record) => record.expandable, 
                    // }}
                    // rowClassName={() => 'custom-row-class'}
                    onChange={handleTableChange}
                    columns={columns}
                    dataSource={tableDataInternal.filter((record) =>
                        Object.values(record).some((value) =>
                            value && typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
                        )
                    )}
                    bordered
                    title={() => (
                        <div className="table-title">
                            {/* Conditionally render the Create-button based on createButton */}
                            {createButton && (
                                <Button
                                    id="createbutton"
                                    type="primary"
                                    icon={<PlusOutlined />}
                                    className="create-button"
                                    onClick={handleCreate}
                                >
                                    {createButtonName}
                                </Button>
                            )}

                            {searchButton && (
                                <div className='tablesearch'>
                                    <Input.Search
                                        placeholder="Search"
                                        id="search"
                                        allowClear
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                </div>
                            )}
                            <span></span>

                            {downloadCSV && (
                                <div className="CSV-actions">
                                    <CSVLink data={prepareCSVData()} filename={`${csvFileName}.csv`} className="csv-download-button">
                                        {/* <CloudDownloadOutlined /> */}
                                        <RiFileExcel2Line style={{ color: 'green' }} />
                                    </CSVLink>
                                </div>
                            )}
                        </div>

                    )}
                    footer={() => ''}
                    // scroll={{ x: 'max-content', y: 'auto' }
                    scroll={{ x: 'max-content', y: 'auto' }
                }
                    size="small"
                    responsive={['xs', 'sm', 'lg', 'md', 'xl', 'xll']}
                    rowClassName={(record, index) => index % 2 === 0 ? 'even-row' : 'odd-row'}
                />
            </div>
            {/* <Drawer
                title="Create User"
                width={500}
                onClose={() => handleCardOnClose()}
                visible={drawerVisible}
            >
                {drawerVisible && <Register form={form}  setDrawerVisible={setDrawerVisible} handleCloseDrawer={handleCloseDrawer}  onRefresh={handleRefresh} resetForm={form.resetFields} props={registerCardProps} />}
            </Drawer> */}

        </>
    );
};

export default Grid;