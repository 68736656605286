import axiosInterceptor from "../../middleware/axiosInterceptor";


export async function getCreatePatient(patientData)  {
    const url = "/api/calendar/createPatient"
    try {
        const response = await axiosInterceptor.post(url,patientData);
        return response.data;
    } catch (error) {
        return { success: false, error: error.message };
    }
};