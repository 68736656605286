//#region Local Imports
import { message } from 'antd';
import { resetPasswordIntialService, resetPasswordSubmitService } from '../../../services/setPasswordService';
import { MAPPING_CONST, MESSAGES } from '../../utilities/mappingData';
import {removeFormatting} from '../../utilities/utility';
//#endregion

export const FetchUserData = async (token, setResData, setError) => {
    try {
        const response = await resetPasswordIntialService(token);
        if (response && response.data && response.data.success) {
            setResData(response.data.users[0]);
        }
        else {
            setError(true);
        }
    }
    catch (error) {
        console.error("Error while validate token", error.message);
    }
};

export async function submitHandler(submittedData, navigate, setError) {
    try {
        submittedData.phone = removeFormatting(submittedData.phone);
        const response = await resetPasswordSubmitService(submittedData);
        if (response && response.data && response.data.success) {
            if (!response.data.users) {
                // if output = 1 (validation failed);
                // if output = 2 (Expired);
                if (response.data.output === MAPPING_CONST.RESET_PASS_INCORRECT_DATA) {
                    message.error(MESSAGES.MESSAGE_RESET_PASS_INCORRECT);
                }
                else {
                    setError(true);
                }
            }
            else {

                message.success(MESSAGES.MESSAGE_RESET_PASS_SUCCESS);

                const data = response.data.users[0];
                sessionStorage.setItem('userId', data.userId);
                sessionStorage.setItem('userName', data.username);
                sessionStorage.setItem('isPhoneVerified', data.phoneVerified);
                sessionStorage.setItem('isEmailVerified', data.emailVerified);
                sessionStorage.setItem('lastLoginAt', data.lastLoginAt);
                sessionStorage.setItem('firstName', data.firstname);
                sessionStorage.setItem('roleId', data.role.roleid);

                navigate("/dashboard");
            }
        } else {
            if (response && response.data && response.data.businessRules && response.data.businessRules.length > 0) {
                const firstError = response.data.businessRules[0].description;
                setError(true);
            }
            else {
            }
        }
    } catch (error) {
        console.error("Error:", error);
    }
};

