import React, { useState, useEffect } from 'react';
import { Skeleton,Modal } from 'antd';
import { fetchData, handleBack, handleEditClick, handleCreate,handleModalConfirm } from './providerHandler';
import { ProviderGridColumns } from './providerGridColumns';
import { ProviderForm } from './providerForm';
import Grid from '../grid/newGrid';
import { CheckOutlined } from '@ant-design/icons';
import { columnVisibility } from '../../../utils/utility';
import { screenActionCodes, actionPermission } from '../../utils/moduleScreenCodes'

function ProviderDefault(props) {
  const [providerData, setProviderData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [visible, setVisible] = useState(false);
  const [keytype, setkeytype] = useState(null);

  useEffect(() => {
    fetchData(setProviderData, setLoading);
  }, []);
 

  const [modalData, setModalData] = useState({
    visible: false,
    type: 'delete',
  });


  /* HIDE/SHOW columns based on Given array with PERMISSION */
  const hideColumns = ["action"];
  if (!actionPermission(props, screenActionCodes.CONF_PRO_EDIT) && !actionPermission(props, screenActionCodes.CONF_PRO_DELETE)) {
    columnVisibility(ProviderGridColumns, hideColumns, true);
  }

  const handleDelete = (record) => {
    setModalData({
      ...modalData,
      visible: true,
      record: record,
    });
  };

  return (
    <div>
      {/* <Spin spinning={loading}> */}
      <Skeleton active loading={loading} paragraph={{ rows: 10 }}>
        <div className="">
          {visible ? (
            <ProviderForm providerData={selectedProvider}
              onBack={() => handleBack(setVisible)}
              opType={keytype} />

          ) : (
            <Grid
              columnData={ProviderGridColumns}
              tableData={providerData}
              setTableData={setProviderData}
              initialTableData={providerData}
              csvFileName="ProviderList"
              searchButton={true}
              downloadCSV={true}
              showEdit={actionPermission(props, screenActionCodes.CONF_PRO_EDIT)}
              showDelete={actionPermission(props, screenActionCodes.CONF_PRO_DELETE)}
              handleEdit={(provider) => handleEditClick(provider, setSelectedProvider, setVisible, setkeytype)}
              handleDelete={handleDelete}
              createButton={true}
              createButtonName={"Create Provider"}
              createButtonIcon={<CheckOutlined />}
              handleCreate={(provider) => handleCreate(provider, setSelectedProvider, setVisible, setkeytype)}
            />
          )}


          <Modal
            title={modalData.type === 'delete' ? 'Delete Provider Detail' : ''}
            open={modalData.visible}
            onOk={() => handleModalConfirm(modalData, fetchData, providerData, setLoading, setModalData)}
            onCancel={() => setModalData({ ...modalData, visible: false })}
            okText="Delete"
            cancelText="Cancel"
            okButtonProps={{ danger: true }}
          >
            {modalData.type === 'delete' && modalData.record ? (
              <p>
                Are you sure you want to delete the Provider
                <span className='deletedynamicName'>
                  "{modalData.record.identificationId
                  }"
                </span> ?
              </p>
            ) : null}
          </Modal>

        </div>
      </Skeleton>
      {/* </Spin> */}
    </div>
  );
}

export default ProviderDefault;
