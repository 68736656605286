import React, { useEffect, useState } from 'react';
// import ReactDOM from 'react-dom';
import {
    Form,
    Input,
    Button,
    Radio,
    Divider,
    Row,
    Col,
    Spin,
    Checkbox, DatePicker,
    Modal, Space, Select, Table
} from "antd";

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { savePatientMedicalHistory } from "../../services/ipadRegistartionService";
const { Option } = Select;
const PatientMedicalHistory = (props) => {
    const [form] = Form.useForm();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showCardioItem, setShowCardioItem] = useState(false);
    const [showNeurologyItem, setShowNeurologyItem] = useState(false);
    const [showLiverItem, setShowLiverItem] = useState(false);
    const [showRespiratoryItem, setShowRespiratoryItem] = useState(false);
    const [showOncologyItem, setShowOncologyItem] = useState(false);
    const [showDiabetesItem, setShowDiabetesItem] = useState(false);
    const [showThyroidItem, setShowThyroidItem] = useState(false);
    const [showAddictionsItem, setShowAddictionsItem] = useState(false);
    const [showEyeItem, setShowEyeItem] = useState(false);
    const [showOtherItem, setShowOtherItem] = useState(false);
    const [medicalConditions, setMedicalConditions] = useState(null);
    const [hospitalizationDetails, setHospitalizationDetails] = useState([{ Date: '', Reason: '' }]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (props.patientRegistrationInfo?.patientRegistrationFormData) {
            setDataLoaded(true);
            let info = props.patientRegistrationInfo.patientRegistrationFormData?.PatientMedicalHistory ?? [];
            const conditions = ['Cardio', 'Neurology', 'Liver', 'Respiratory', 'Oncology', 'Diabetes', 'Thyroid', 'Addictions', 'Eye', 'Other'];
            conditions.forEach(condition => {
                if (info.hasOwnProperty(condition)) {
                    const setItemFunction = `setShow${condition}Item`;
                    const correspondingStateSetter = eval(setItemFunction);
                    if (typeof correspondingStateSetter === 'function') {
                        correspondingStateSetter(info[condition]);
                    }
                }
            });
            if (info.hasOwnProperty('medicalConditions')) {
                setMedicalConditions(info.medicalConditions);
            }
            if (hospitalizationDetails.length > 0 && info?.Hospitalization) {
                setHospitalizationDetails(info.Hospitalization);
            }
        }
    }, [props.patientRegistrationInfo]);
    const handleCheckboxChange = (condition, checked) => {
        if (condition === 'Cardio') {
            setShowCardioItem(checked);
        } else if (condition === 'Neurology') {
            setShowNeurologyItem(checked);
        } else if (condition === 'Liver') {
            setShowLiverItem(checked);
        } else if (condition === 'Respiratory') {
            setShowRespiratoryItem(checked);
        } else if (condition === 'Oncology') {
            setShowOncologyItem(checked);
        } else if (condition === 'Diabetes') {
            setShowDiabetesItem(checked);
        } else if (condition === 'Thyroid') {
            setShowThyroidItem(checked);
        } else if (condition === 'Addictions') {
            setShowAddictionsItem(checked);
        } else if (condition === 'Eye') {
            setShowEyeItem(checked);
        } else if (condition === 'Other') {
            setShowOtherItem(checked);
        }

        setMedicalConditions((prevMedicalConditions) => {
            if (prevMedicalConditions !== null) {
                if (prevMedicalConditions.trim() !== "") {
                    const conditionsArray = prevMedicalConditions.split(', ');

                    const updatedConditions = checked
                        ? [...conditionsArray, condition].join(', ')
                        : conditionsArray.filter((c) => c !== condition).join(', ');

                    return updatedConditions;
                } else {
                    return checked ? condition : "";
                }
            } else {
                return checked ? condition : "";
            }
        }
        );
    };
    const onMedicalHistoryPreviousClick = () => {
        props.handlePreviousClick(props);
    }
    const onMedicalHistoryNextClick = async (values) => {
        try {
            const updatedValues = values;
            updatedValues.medicalConditions = medicalConditions ? medicalConditions : null;
            updatedValues.Hospitalization = hospitalizationDetails ? hospitalizationDetails : null;
            setIsSubmitting(true);
            let historyResponse = await savePatientMedicalHistory(props.uniqueHash, values);
            if (historyResponse.message === 'Success') {
                props.handleNextClick(props);
            } else {
                Modal.error({
                    title: 'This is an error message',
                    content: 'See Console',
                });
            }
        } finally {
            if (!isSubmitting) {
                return (
                    <div className="spinner-overlay">
                        <div className="spinner">
                            <Spin size="large" tip="Loading..." />
                        </div>
                    </div>
                );
            }
            setIsSubmitting(false);
        }
    }

    if (!dataLoaded) {
        return (
            <div className="spinner-overlay">
                <div className="spinner">
                    <Spin size="large" tip="Loading..." />
                </div>
            </div>
        );
    }
    const patientInfo = props.patientRegistrationInfo.patientRegistrationFormData?.PatientMedicalHistory ?? [];

    let patientDetails = {
        Cardio: patientInfo?.Cardio || "",
        Neurology: patientInfo?.Neurology || "",
        Liver: patientInfo?.Liver || "",
        Respiratory: patientInfo?.Respiratory || "",
        Oncology: patientInfo?.Oncology || "",
        Diabetes: patientInfo?.Diabetes || "",
        Thyroid: patientInfo?.Thyroid || "",
        Addictions: patientInfo?.Addictions || "",
        Eye: patientInfo?.Eye || "",
        Other: patientInfo?.Other || "",
        OtherValues: patientInfo?.OtherValues || "",
        // medicalConditions: patientInfo?.medicalConditions || "",
        childhoodDiseases: patientInfo?.childhoodDiseases || [],
        cardioValues: patientInfo?.cardioValues || "",
        NeurologyValues: patientInfo?.NeurologyValues || "",
        liverValues: patientInfo?.liverValues || "",
        respiratoryValues: patientInfo?.respiratoryValues || "",
        OncologyValues: patientInfo?.OncologyValues || "",
        DiabetesValues: patientInfo?.DiabetesValues || "",
        ThyroidValues: patientInfo?.ThyroidValues || "",
        AddictionsValues: patientInfo?.AddictionsValues || "",
        EyeValues: patientInfo?.EyeValues || "",
    };

    const columns = [
        {
            title: 'Date',
            dataIndex: 'Date',
            render: (_, record, index) => (
                <DatePicker className="form-control" size="large"
                    value={record.Date ? moment(record.Date, 'YYYY-MM-DD') : ''}
                    format="MM/DD/YYYY"
                    onChange={(e) => handleInputChange(e, 'Date', index)} />
            ),
        },
        {
            title: 'Reason',
            dataIndex: 'Reason',
            render: (_, record, index) => (
                <Input
                    value={record.Reason}
                    onChange={(e) => handleInputChange(e, 'Reason', index)}
                />
            ),
        },
        {
            dataIndex: 'operation',
            render: (_, __, index) =>
                <Button type="primary" danger onClick={() => handleRemoveRow(index)}>Remove</Button>
        },
    ];
    const handleInputChange = (e, fieldName, index) => {
        if (fieldName === 'Date') {
            const formattedDate = moment(e).format('YYYY-MM-DD');
            const newData = [...hospitalizationDetails];
            newData[index][fieldName] = formattedDate;
            setHospitalizationDetails(newData);
        }
        else {
            const newData = [...hospitalizationDetails];
            newData[index][fieldName] = e.target.value;
            setHospitalizationDetails(newData);
        }
    };
    const handleAddRow = () => {
        if (hospitalizationDetails.length < 4) {
            setHospitalizationDetails([...hospitalizationDetails, { Date: '', Reason: '' }]);
        }
    };
    const handleRemoveRow = (index) => {
        const newData = [...hospitalizationDetails];
        newData.splice(index, 1);
        setHospitalizationDetails(newData);
    };
    return (
        <div>
            <h3 className="mb-3">Patient Medical History</h3>
            <Divider />
            <h5>Check (<i className="fa fa-check"></i>) any of the following medical conditions that apply</h5>
            <Form form={form} layout="vertical" size="large" scrollToFirstError={true}
                onFinish={onMedicalHistoryNextClick}
                initialValues={{
                    ...patientDetails
                }}>
                <Form.Item name="Cardio" valuePropName="checked">
                    <Checkbox value="Cardio" onChange={(e) => handleCheckboxChange('Cardio', e.target.checked)}
                    >
                        Cardio</Checkbox>
                </Form.Item>
                {showCardioItem && (
                    <div>
                        <h6>(Select all that apply)</h6>
                        <Form.Item name="cardioValues" rules={[{ required: true, message: 'Select all that apply.' }]}>
                            <Checkbox.Group style={{ width: '100%' }} className="round-checkbox-group">
                                <Checkbox value="Anemia">Anemia</Checkbox>
                                <Checkbox value="Angina">Angina</Checkbox>
                                <Checkbox value="Low Blood Pressure">Low Blood Pressure</Checkbox>
                                <Checkbox value="Bruise Easily">Bruise Easily</Checkbox>
                                <Checkbox value="Heart Attack">Heart Attack</Checkbox>
                                <Checkbox value="Cardiac Pacemaker">Cardiac Pacemaker</Checkbox>
                                <Checkbox value="Chest Pain">Chest Pain</Checkbox>
                                <Checkbox value="Heart Value Problems">Heart Value Problems</Checkbox>
                                <Checkbox value="High Blood Pressure">High Blood Pressure</Checkbox>
                                <Checkbox value="Swollen Ankles">Swollen Ankles</Checkbox>
                                <Checkbox value="Heart Murmur">Heart Murmur</Checkbox>
                                <Checkbox value="Rheumatic Fever">Rheumatic Fever</Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                        <Divider />
                    </div>
                )}
                <Form.Item name="Neurology" valuePropName="checked">
                    <Checkbox value="Neurology"
                        onChange={(e) => handleCheckboxChange('Neurology', e.target.checked)}>Neurology</Checkbox>
                </Form.Item>
                {showNeurologyItem && (
                    <div>
                        <h6>(Select all that apply)</h6>
                        <Form.Item
                            name="NeurologyValues" rules={[{ required: true, message: 'Select all that apply.' }]}
                        >
                            <Checkbox.Group style={{ width: '100%' }} className="round-checkbox-group">
                                <Checkbox value="Epilepsy">Epilepsy</Checkbox>
                                <Checkbox value="Fainting Spells">Fainting Spells</Checkbox>
                                <Checkbox value="Stroke">Stroke</Checkbox>
                                <Checkbox value="Headaches">Headaches</Checkbox>
                                <Checkbox value="Migraines">Migraines</Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                        <Divider />
                    </div>
                )}
                <Form.Item name="Liver" valuePropName="checked">
                    <Checkbox value="Liver"
                        onChange={(e) => handleCheckboxChange('Liver', e.target.checked)}>Liver</Checkbox>
                </Form.Item>
                {showLiverItem && (
                    <div>
                        <h6>(Select all that apply)</h6>
                        <Form.Item
                            name="liverValues" rules={[{ required: true, message: 'Select all that apply.' }]}
                        >
                            <Checkbox.Group style={{ width: '100%' }} className="round-checkbox-group">
                                <Checkbox value="Liver Disease">Liver Disease</Checkbox>
                                <Checkbox value="Clotting Disorders">Clotting Disorders</Checkbox>
                                <Checkbox value="Hepatitis A">Hepatitis A</Checkbox>
                                <Checkbox value="Hepatitis B">Hepatitis B</Checkbox>
                                <Checkbox value="Hepatitis C">Hepatitis C</Checkbox>
                                <Checkbox value="Kidney Trouble">Kidney Trouble</Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                        <Divider />
                    </div>
                )}
                <Form.Item name="Respiratory" valuePropName="checked">
                    <Checkbox value="Respiratory"
                        onChange={(e) => handleCheckboxChange('Respiratory', e.target.checked)}>
                        Respiratory</Checkbox>
                </Form.Item>
                {showRespiratoryItem && (
                    <div>
                        <h6>(Select all that apply)</h6>
                        <Form.Item
                            name="respiratoryValues" rules={[{ required: true, message: 'Select all that apply.' }]}
                        >
                            <Checkbox.Group style={{ width: '100%' }} className="round-checkbox-group">
                                <Checkbox value="Asthma">Asthma</Checkbox>
                                <Checkbox value="Persistent Cough">Persistent Cough</Checkbox>
                                <Checkbox value="Hay Fever">Hay Fever</Checkbox>
                                <Checkbox value="Respiratory Problem">Respiratory Problem</Checkbox>
                                <Checkbox value="Sinus Trouble">Sinus Trouble</Checkbox>
                                <Checkbox value="Tuberculosis">Tuberculosis</Checkbox>
                                <Checkbox value="Emphysema">Emphysema</Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                        <Divider />
                    </div>
                )}
                <Form.Item name="Oncology" valuePropName="checked">
                    <Checkbox value="Oncology"
                        onChange={(e) => handleCheckboxChange('Oncology', e.target.checked)}>Oncology</Checkbox>
                </Form.Item>
                {showOncologyItem && (
                    <div>
                        <h6>(Select all that apply)</h6>
                        <Form.Item
                            name="OncologyValues" rules={[{ required: true, message: 'Select all that apply.' }]}
                            initialValue={[]}
                        >
                            <Checkbox.Group style={{ width: '100%' }} className="round-checkbox-group">
                                <Checkbox value="Cancer">Cancer</Checkbox>
                                <Checkbox value="Leukemia">Leukemia</Checkbox>
                                <Checkbox value="Radiation Treatment">Radiation Treatment</Checkbox>
                                <Checkbox value="Chemotherapy">Chemotherapy</Checkbox>
                                <Checkbox value="Transfusions">Transfusions</Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                        <Divider />
                    </div>
                )}
                <Form.Item name="Diabetes" valuePropName="checked">
                    <Checkbox value="Diabetes"
                        onChange={(e) => handleCheckboxChange('Diabetes', e.target.checked)}>Diabetes</Checkbox>
                </Form.Item>
                {showDiabetesItem && (
                    <div>
                        <h6>(Select all that apply)</h6>
                        <Form.Item
                            name="DiabetesValues" rules={[{ required: true, message: 'Select all that apply.' }]}
                        >
                            <Checkbox.Group style={{ width: '100%' }} className="round-checkbox-group">
                                <Checkbox value="Recent Weight Loss">Recent Weight Loss</Checkbox>
                                <Checkbox value="Special Diet">Special Diet</Checkbox>
                                <Checkbox value="Stomach Problem">Stomach Problem</Checkbox>
                                <Checkbox value="Ulcer">Ulcer</Checkbox>
                                <Checkbox value="Diabetes Type 1">Diabetes Type 1</Checkbox>
                                <Checkbox value="Diabetes Type 2">Diabetes Type 2</Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                        <Divider />
                    </div>
                )}
                <Form.Item name="Thyroid" valuePropName="checked">
                    <Checkbox value="Thyroid"
                        onChange={(e) => handleCheckboxChange('Thyroid', e.target.checked)}>Thyroid</Checkbox>
                </Form.Item>
                {showThyroidItem && (
                    <div>
                        <h6>(Select all that apply)</h6>
                        <Form.Item
                            name="ThyroidValues" rules={[{ required: true, message: 'Select all that apply.' }]}
                        >
                            <Checkbox.Group style={{ width: '100%' }} className="round-checkbox-group">
                                <Checkbox value="Thyroid Problems">Thyroid Problems</Checkbox>
                                <Checkbox value="Autoimmunine Dis">Autoimmunine Dis</Checkbox>
                                <Checkbox value="Arthritis">Arthritis</Checkbox>
                                <Checkbox value="Cold Sores">Cold Sores</Checkbox>
                                <Checkbox value="Ear Infections">Ear Infections</Checkbox>
                                <Checkbox value="Joint Replacement">Joint Replacement</Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                        <Divider />
                    </div>
                )}
                <Form.Item name="Addictions" valuePropName="checked">
                    <Checkbox value="Addictions"
                        onChange={(e) => handleCheckboxChange('Addictions', e.target.checked)}>Addictions</Checkbox>
                </Form.Item>
                {showAddictionsItem && (
                    <div>
                        <h6>(Select all that apply)</h6>
                        <Form.Item
                            name="AddictionsValues" rules={[{ required: true, message: 'Select all that apply.' }]}
                        >
                            <Checkbox.Group style={{ width: '100%' }} className="round-checkbox-group">
                                <Checkbox value="Smoking Habit">Smoking Habit</Checkbox>
                                <Checkbox value="Substance Addiction"> Substance Addiction</Checkbox>
                                <Checkbox value="Frequently Tired">Frequently Tired</Checkbox>
                                <Checkbox value="Nervousness">Nervousness</Checkbox>
                                <Checkbox value="Depression">Depression</Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                        <Divider />
                    </div>
                )}
                <Form.Item name="Eye" valuePropName="checked">
                    <Checkbox value="Eye" onChange={(e) => handleCheckboxChange('Eye', e.target.checked)}>Eye</Checkbox>
                </Form.Item>
                {showEyeItem && (
                    <div>
                        <h6>(Select all that apply)</h6>
                        <Form.Item
                            name="EyeValues" rules={[{ required: true, message: 'Select all that apply.' }]}
                        >
                            <Checkbox.Group style={{ width: '100%' }} className="round-checkbox-group">
                                <Checkbox value="Glaucoma">Glaucoma</Checkbox>
                                <Checkbox value="Corrective Lenses">Corrective Lenses</Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                        <Divider />
                    </div>
                )}
                <Form.Item name="Other" valuePropName="checked">
                    <Checkbox value="Other"
                        onChange={(e) => handleCheckboxChange('Other', e.target.checked)}>Other</Checkbox>
                </Form.Item>
                {showOtherItem && (
                    <div>
                        <Form.Item
                            name="OtherValues" rules={[{ required: true, message: 'Select all that apply.' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Divider />
                    </div>
                )}
                <Form.Item name="medicalConditions">
                    <div id="medicalHistory" className="form-group med_history">
                        <h5>Medical History:</h5>
                        <div style={{ overflowWrap: 'break-word' }} id="medicalHistoryValue">
                            {medicalConditions}
                        </div>
                    </div>
                </Form.Item>
                <Form.Item label="Childhood Diseases" name="childhoodDiseases">
                    <Select mode="multiple">
                        <Option key="1" value="Measels">
                            Measels
                        </Option>
                        <Option key="2" value="Chicken Pox">
                            Chicken Pox
                        </Option>
                        <Option key="3" value="Scarlet Fever">
                            Scarlet Fever
                        </Option>
                        <Option key="4" value="Mumps">
                            Mumps
                        </Option>
                        <Option key="5" value="Rubella">
                            Rubella
                        </Option>
                    </Select>
                </Form.Item>
                <Form.Item className="pad-15" label="Hospitalization" name="Hospitalization">
                    <Table
                        dataSource={hospitalizationDetails}
                        columns={columns}
                        pagination={false}
                        bordered
                        rowKey={(record, index) => index}
                    />
                    <Button type="primary" style={{ marginTop: 5 }} icon={<PlusOutlined />} onClick={handleAddRow} visible={hospitalizationDetails.length <= 4}>
                        Add
                    </Button>
                </Form.Item>
                <Form.Item className="form-footer-button">
                    {isSubmitting ? (
                        <div className="spinner-overlay">
                            <div className="spinner">
                                <Spin size="large" tip="Loading..." />
                            </div>
                        </div>
                    ) : (
                        <Row gutter={12}>
                            <Col>
                                <Button type="primary" onClick={onMedicalHistoryPreviousClick}>Previous</Button>
                            </Col>
                            <Col>
                                <Button type="primary" htmlType="submit">Next</Button>
                            </Col>
                        </Row>
                    )}
                </Form.Item>
            </Form>
        </div>
    )
}

export default PatientMedicalHistory;
