import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Space, Col, Row, DatePicker, Checkbox, Typography, Modal, Select, Spin, Radio } from "antd";
import moment from "moment/moment";
import {
    saveMetroConsent8Form,
    saveConsentForms,
    savePatientSignature,
    saveHIVConsent,
    saveAcknowledgementConsent,
    saveAuthorizationConsent,
    saveAccessPatientConsent,
    verifyConsentsPassword
} from "../../services/ipadRegistartionService";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import SignatureCanvas from 'react-signature-canvas'

const { Text } = Typography;

const MetroConsents = (props) => {
    const signatureRef = useRef(null);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [manualRefConsentForm] = Form.useForm();
    const [signatureRefForm] = Form.useForm();
    const [examinationRefForm] = Form.useForm();
    const [financialRefForm] = Form.useForm();
    const [authorizationRefForm] = Form.useForm();
    const [releaseHealthInfoRefForm] = Form.useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [form] = Form.useForm();
    const [healthRefForm] = Form.useForm();
    const [touched, setTouched] = useState(false);
    const [modalManualPopup, setModalManualPopup] = useState(false);
    const [isModalConsentOpen, setIsModalConsentOpen] = useState(false);
    const [isExaminationConsentModalOpen, setIsExaminationConsentModalOpen] = useState(false);
    const [isFinancialConsentModalOpen, setIsFinancialConsentModalOpen] = useState(false);
    const [isAuthorizationConsentModalOpen, setIsAuthorizationConsentModalOpen] = useState(false);
    const [isReleaseHealthInfoConsentOpen, setIsReleaseHealthInfoConsentOpen] = useState(false);
    const [consentFormIframeUrl, setConsentFormIframeUrl] = useState('');
    const currentPageURL = window.location.href;
    const [isModalSignatureVisible, setModalSignatureVisible] = useState(false);
    const [signatureUrl, setSignatureUrl] = useState('');
    const [canvasWidth, setCanvasWidth] = useState(window.innerWidth >= 450 ? 420 : 320);
    const [pathDetails, setPathDetails] = useState([]);
    const [modifiedURL, setModifiedURL] = useState(null);

    const consent2 = 'pr/metro/consent/financial-agreement';
    const consent3 = 'pr/c3';
    const consent4 = 'pr/c4';
    const consent5 = 'pr/c5';
    const consent6 = 'pr/metro/consent/access-ny-health-info';
    const consent7 = 'pr/lish/consent/determination-sliding-fee/Metro';
    const consent8 = 'pr/c6';

    useEffect(() => {
        const url = new URL(window.location.href.split('#')[0]);
        if (url.searchParams.has('prId')) {
            url.searchParams.delete('prId'); // Remove the 'prId' query parameter
        }
        setModifiedURL(url.toString());

        window.scrollTo(0, 0);
        const handleResize = () => {
            setCanvasWidth(window.innerWidth >= 450 ? 450 : 350);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (props.patientRegistrationInfo?.patientRegistrationInfo) {
            let info = props.patientRegistrationInfo.patientRegistrationFormData?.ConsentForm ?? [];
            if (info?.pathDetails) {
                setPathDetails(info.pathDetails);
            }
            setDataLoaded(true);
        }
    }, [props.patientRegistrationInfo]);

    useEffect(() => {
        if (isModalSignatureVisible) {
            const patientName = form.getFieldValue('signature');
            signatureRefForm.setFieldsValue({
                ShareInfoPatientName: patientName,
            });
            signatureRef.current.fromDataURL('');
        }
    }, [isModalSignatureVisible]);
    const validateCheckbox = (rule, value) => {
        if (value) {
            return Promise.resolve();
        }
        return Promise.reject(rule.message);
    };
    const uniqueHash = props.uniqueHash;
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const onConsentsPreviousClick = () => {
        props.handlePreviousClick(props);
    }
    const onConsentFieldsNextClick = async (values) => {
        const formattedSignedDate = moment(values.signed_date).format('YYYY-MM-DD');
        const formattedDate = moment().format('YYYY-MM-DD');
        const updatedValues = { ...values, signed_date: formattedSignedDate };
        updatedValues.pathDetails = pathDetails;
        // updatedValues.consent_1_signed_date = formattedDate;
        // updatedValues.consent_2_signed_date = formattedDate;
        // updatedValues.consent_3_signed_date = formattedDate;
        // updatedValues.consent_4_signed_date = formattedDate;
        // updatedValues.consent_5_signed_date = formattedDate;
        // updatedValues.consent_6_signed_date = formattedDate;
        // updatedValues.consent_7_signed_date = formattedDate;
        // updatedValues.consent_8_signed_date = formattedDate;
        await consentFormNext(updatedValues);
    }
    const consentFormNext = async (updatedValues) => {
        setIsSubmitting(true);
        try {
            let ConsentFields = await saveConsentForms(props.uniqueHash, updatedValues);
            if (ConsentFields.message === 'Success') {
                props.handleNextClick(props);
            } else {
                Modal.error({
                    title: 'This is an error message',
                    content: 'Error Occured',
                });
            }
        } finally {
            if (!isSubmitting) {
                return (
                    <div className="spinner-overlay">
                        <div className="spinner">
                            <Spin size="large" tip="Loading..." />
                        </div>
                    </div>
                );
            }
            setIsSubmitting(false);
        }
    }
    const handleHealthInfoSubmit = async (values) => {
        try {
            setIsSubmitting(true);
            const modalFormValues = await healthRefForm.validateFields();
            if (modalFormValues) {
                const formattedRepSignDate = moment(values.ShareInfoSignDate).format('YYYY-MM-DD');
                const updatedValues = { ...values, ShareInfoSignDate: formattedRepSignDate };
                let response = await saveMetroConsent8Form(uniqueHash, updatedValues);
                if (response.message === 'Success') {
                    setIsModalOpen(false);
                } else {
                    Modal.error({
                        title: 'This is an error message',
                        content: 'Error occured',
                    });
                }
            }
        } finally {
            if (!isSubmitting) {
                return (
                    <div className="spinner-overlay">
                        <div className="spinner">
                            <Spin size="large" tip="Loading..." />
                        </div>
                    </div>
                );
            }
            setIsSubmitting(false);
        }
    }

    const resetCheckbox = () => {
        form.setFieldsValue({ consent_8_signed_at: false });
    };

    const handleCancel = () => {
        resetModalState();
        setIsModalOpen(false);
        resetCheckbox();
    };
    const handleExaminationCancel = () => {
        form.setFieldsValue({
            consent1: false,
        });
        healthRefForm.resetFields();

        setIsExaminationConsentModalOpen(false);
        form.setFieldsValue({ consent_1_signed_at: false });
    };
    const handleFinancialCancel = () => {
        form.setFieldsValue({
            consent3: false,
        });
        financialRefForm.resetFields();

        setIsFinancialConsentModalOpen(false);
        form.setFieldsValue({ consent_3_signed_at: false });
    };
    const handleAuthorizationCancel = () => {
        form.setFieldsValue({
            consent5: false,
        });
        authorizationRefForm.resetFields();

        setIsAuthorizationConsentModalOpen(false);
        form.setFieldsValue({ consent_5_signed_at: false });
    };
    const handleReleaseHealthCancel = () => {
        form.setFieldsValue({
            consent4: false,
        });
        releaseHealthInfoRefForm.resetFields();

        setIsReleaseHealthInfoConsentOpen(false);
        form.setFieldsValue({ consent_4_signed_at: false });
    };
    const showExaminationModal = () => {
        setIsExaminationConsentModalOpen(true);
    };
    const showFinancialModal = () => {
        setIsFinancialConsentModalOpen(true);
    };
    const showAccessPatientModal = () => {
        setIsAuthorizationConsentModalOpen(true)
    };
    const showReleasePatientModal = () => {
        setIsReleaseHealthInfoConsentOpen(true)
    };
    const handleExaminationConsentSubmit = async (values) => {
        try {
            setIsSubmitting(true);
            const modalFormValues = await examinationRefForm.validateFields();
            if (modalFormValues) {
                const HIVConsent = examinationRefForm.getFieldValue('HIVConsent');
                let response = await saveHIVConsent(uniqueHash, HIVConsent);
                if (response.message === 'Success') {
                    setIsExaminationConsentModalOpen(false);
                } else {
                    Modal.error({
                        title: 'This is an error message',
                        content: 'Error occured',
                    });
                }
            }
        } finally {
            if (!isSubmitting) {
                return (
                    <div className="spinner-overlay">
                        <div className="spinner">
                            <Spin size="large" tip="Loading..." />
                        </div>
                    </div>
                );
            }
            setIsSubmitting(false);
        }
    }
    const handleAcknowledgementConsentSubmit = async (values) => {
        try {
            setIsSubmitting(true);
            const modalFormValues = await financialRefForm.validateFields();
            if (modalFormValues) {
                let response = await saveAcknowledgementConsent(uniqueHash, values);
                if (response.message === 'Success') {
                    setIsFinancialConsentModalOpen(false);
                } else {
                    Modal.error({
                        title: 'This is an error message',
                        content: 'Error occured',
                    });
                }
            }
        } finally {
            if (!isSubmitting) {
                return (
                    <div className="spinner-overlay">
                        <div className="spinner">
                            <Spin size="large" tip="Loading..." />
                        </div>
                    </div>
                );
            }
            setIsSubmitting(false);
        }
    }
    const handleAuthorizationConsentSubmit = async (values) => {
        try {
            setIsSubmitting(true);
            const modalFormValues = await authorizationRefForm.validateFields();
            if (modalFormValues) {
                let response = await saveAuthorizationConsent(uniqueHash, values);
                if (response.message === 'Success') {
                    setIsAuthorizationConsentModalOpen(false);
                } else {
                    Modal.error({
                        title: 'This is an error message',
                        content: 'Error occured',
                    });
                }
            }
        } finally {
            if (!isSubmitting) {
                return (
                    <div className="spinner-overlay">
                        <div className="spinner">
                            <Spin size="large" tip="Loading..." />
                        </div>
                    </div>
                );
            }
            setIsSubmitting(false);
        }
    }
    const handleAccessPatientConsentSubmit = async (values) => {
        try {
            setIsSubmitting(true);
            const modalFormValues = await releaseHealthInfoRefForm.validateFields();
            if (modalFormValues) {
                const formattedSignedDate = values.AuthorizationExpiryDate ?
                    moment(values.AuthorizationExpiryDate).format('YYYY-MM-DD') : null;
                const updatedValues = { ...values, AuthorizationExpiryDate: formattedSignedDate };
                let response = await saveAccessPatientConsent(uniqueHash, updatedValues);
                if (response.message === 'Success') {
                    setIsReleaseHealthInfoConsentOpen(false);
                } else {
                    Modal.error({
                        title: 'This is an error message',
                        content: 'Error occured',
                    });
                }
            }
        } finally {
            if (!isSubmitting) {
                return (
                    <div className="spinner-overlay">
                        <div className="spinner">
                            <Spin size="large" tip="Loading..." />
                        </div>
                    </div>
                );
            }
            setIsSubmitting(false);
        }
    }
    const resetModalState = () => {
        form.setFieldsValue({
            consent7: false,
        });
        healthRefForm.resetFields();
    };

    const handleConsent7Click = () => {
        form.setFieldsValue({ consent_8_signed_at: true });
    };
    const handleConsent1Click = () => {
        form.setFieldsValue({ consent_1_signed_at: true });
    };
    const handleConsent3Click = () => {
        form.setFieldsValue({ consent_3_signed_at: true });
    };
    const handleConsent4Click = () => {
        form.setFieldsValue({ consent_4_signed_at: true });
    };
    const handleConsent5Click = () => {
        form.setFieldsValue({ consent_5_signed_at: true });
    };
    const onCancelManualVerify = () => {
        setModalManualPopup(true);
    };
    const handleManualVerifySubmit = async (values) => {
        const modalFormValues = await manualRefConsentForm.validateFields();
        if (modalFormValues) {
            let response = await verifyConsentsPassword(props.uniqueHash, modalFormValues);
            if (response.message === 'Success') {
                const consentFormValues = await form.validateFields();
                const formattedSignedDate = moment(consentFormValues.signed_date).format('YYYY-MM-DD');
                const updatedValues = { ...consentFormValues, signed_date: formattedSignedDate };
                await consentFormNext(updatedValues);
                // setModalManualPopup(false);
                props.handleNextClick(props);
            } else {
                Modal.error({
                    title: 'This is an error message',
                    content: 'Invalid Pin',
                });
            }
        }
    };
    if (!dataLoaded) {
        return (
            <div className="spinner-overlay">
                <div className="spinner">
                    <Spin size="large" tip="Loading..." />
                </div>
            </div>
        );
    }
    const openModalWithUrl = (url) => {
        setConsentFormIframeUrl(url);
        setIsModalConsentOpen(true);
    };
    const closeConsentModal = () => {
        setConsentFormIframeUrl('');
        setIsModalConsentOpen(false);
    };
    const showSignatureModal = () => {
        setModalSignatureVisible(true);
    };

    const handleSignatureSave = async () => {
        const patientName = signatureRefForm.getFieldValue('ShareInfoPatientName');
        form.setFieldsValue({
            signature: patientName,
        });
        const signatureDataUrl = signatureRef.current.toDataURL();
        let signatureResponse = await savePatientSignature(signatureDataUrl);
        if (signatureResponse.message === 'Success') {
            setPathDetails(signatureResponse.pathDetails);
            setSignatureUrl(signatureDataUrl);
            setModalSignatureVisible(false);
        } else {
            Modal.error({
                title: 'This is an error message',
                content: 'Error Occured',
            });
        }
    };

    const handleSignatureCancel = () => {
        setModalSignatureVisible(false);
    };

    const clearSignature = () => {
        signatureRef.current.clear();
        setSignatureUrl('');
    };
    const patientInfo = props.patientRegistrationInfo.patientRegistrationFormData?.ConsentForm ?? [];

    let patientDetails = {
        consent_1_signed_at: !!patientInfo?.consent_1_signed_at,
        consent_2_signed_at: !!patientInfo?.consent_2_signed_at,
        consent_3_signed_at: !!patientInfo?.consent_3_signed_at,
        consent_4_signed_at: !!patientInfo?.consent_4_signed_at,
        consent_5_signed_at: !!patientInfo?.consent_5_signed_at,
        consent_6_signed_at: !!patientInfo?.consent_6_signed_at,
        consent_7_signed_at: !!patientInfo?.consent_7_signed_at,
        consent_8_signed_at: !!patientInfo?.consent_8_signed_at,

    };
    function parseDate(dateString) {
        if (dateString) {
            const parsedDate = moment(dateString, 'YYYY-MM-DD');
            return parsedDate.isValid() ? parsedDate : null;
        }
        return null;
    }

    return (
        <div>
            {dataLoaded ? (
                <Form form={form} layout="vertical"
                    onFinish={onConsentFieldsNextClick}
                    initialValues={{
                        ...patientDetails,
                        signature: props.patientRegistrationInfo.patientRegistrationFormData?.ConsentForm?.signature || '',
                        witness_signature: props.patientRegistrationInfo.patientRegistrationFormData?.ConsentForm?.witness_signature || '',
                        representative_signature: props.patientRegistrationInfo.patientRegistrationFormData?.ConsentForm?.representative_signature || '',
                        representative_relationship: props.patientRegistrationInfo.patientRegistrationFormData?.ConsentForm?.representative_relationship || '',
                        signed_date: patientInfo?.signed_date
                            ? moment(patientInfo.signed_date, 'YYYY-MM-DD')
                            : moment(),
                    }}>
                    <h3>Consents</h3>
                    <Space>
                        <p className="text-muted">To read the consent form, click on the blue link.
                            Accept each consent by clicking on each box.
                        </p>
                    </Space>
                    <Form.Item
                        name="consent_1_signed_at"
                        valuePropName="checked"
                        rules={[
                            {
                                validator: validateCheckbox,
                                message: 'The examination consent metro field is required.'
                            }
                        ]}
                        onClick={showExaminationModal}
                    >
                        <Checkbox className="custom-checkbox">
                            <a href="#" onClick={handleConsent1Click}
                                className="hover-underline">General
                                Consent for
                                Examination and Treatment Medical / Dental / Behavioral / Rehabilitation / Routine
                                Immunizations / HIV Testing</a>
                            <br />
                            <Text className="text-muted">I confirm that I have read/ or have had read to me the
                                information.
                                I further confirm that I fully understand the information and all my questions have been
                                answered.
                            </Text>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        name="consent_2_signed_at"
                        valuePropName="checked"
                        rules={[
                            {
                                validator: validateCheckbox,
                                message: 'The financial agreement consent metro field is required.'
                            }
                        ]}
                    >
                        <Checkbox className="custom-checkbox">
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                openModalWithUrl(consent2);
                            }}
                                className="hover-underline">Financial Agreement</a>
                            <br />
                            <Text className="text-muted">I confirm that I have read/ or have had read to me the
                                information.
                                I further confirm that I fully understand the information and all my questions have been
                                answered.
                            </Text>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        name="consent_3_signed_at"
                        valuePropName="checked" onClick={showFinancialModal}
                        rules={[
                            {
                                validator: validateCheckbox,
                                message: 'The patient acknowledge consent metro field is required.'
                            }
                        ]}
                    >
                        <Checkbox className="custom-checkbox">
                            <a href="#" onClick={handleConsent3Click}
                                className="hover-underline">Patient acknowlegement of receipt / consent form</a>
                            <br />
                            <Text className="text-muted">By signing below I do hereby acknowledge receipt, review,
                                and the opportunity to ask any questions about the documents/information mentioned in
                                the form.
                            </Text>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        name="consent_4_signed_at"
                        valuePropName="checked" onClick={showReleasePatientModal}
                        rules={[
                            {
                                validator: validateCheckbox,
                                message: 'The release health info consent metro field is required.'
                            }
                        ]}
                    >
                        <Checkbox className="custom-checkbox">
                            <a href="#" onClick={handleConsent4Click}
                                className="hover-underline">Authorization for Ongoing Release of Protected Health
                                Information (including Alcohol/Drug Treatment and Mental Health Information)
                                and Confidential HIV/AIDS- related information</a>
                            <br />
                            <Text className="text-muted">I have read the information in the form and all my questions
                                about this form have been answered.
                                I acknowledge that I accept all of the above. All items on this form have been completed
                                and I have been provided a copy of this form.
                            </Text>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item name="consent_5_signed_at" valuePropName="checked" rules={[{
                        validator: validateCheckbox,
                        message: 'The access patient info consent metro field is required.'
                    }]} onClick={showAccessPatientModal}>
                        <Checkbox className="custom-checkbox">
                            <a href="#" onClick={handleConsent5Click}
                                className="hover-underline">Authorization for Access to Patient Information</a>
                            <br />
                            <Text className="text-muted">I confirm that I have read/ or have had read to me the
                                information.
                                I further confirm that I fully understand the information and all my questions have been
                                answered.
                            </Text>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item name="consent_6_signed_at" valuePropName="checked" rules={[{
                        validator: validateCheckbox,
                        message: 'The access my health info consent metro field is required.'
                    }]}>
                        <Checkbox className="custom-checkbox">
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                openModalWithUrl(consent6);
                            }} className="hover-underline">Authorization for Access to Patient Information New York
                                State Department
                                of Health Through a Health Information Exchange Organization</a>
                            <br />
                            <Text className="text-muted">I confirm that my questions about this form have been answered
                                and I have been provided a copy of this form.
                            </Text>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item name="consent_7_signed_at" valuePropName="checked" rules={[{
                        validator: validateCheckbox,
                        message: 'The determination sliding fee consent field is required.'
                    }]}>
                        <Checkbox className="custom-checkbox">
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                openModalWithUrl(consent7);
                            }} className="hover-underline">View Eligibility Determination Sliding Fee Discounts.</a>
                            <br />
                            <Text className="text-muted">I certify that all information shown in the form is true,
                                accurate and correct.
                                I understand that that if Metro determines that I misrepresented or falsified
                                information,
                                I will no longer receive discounts and may be asked to pay back discounts previously
                                provided.
                            </Text>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item name="consent_8_signed_at" valuePropName="checked" rules={[{
                        validator: validateCheckbox,
                        message: 'consent form to share information via text messages is required.'
                    }]} onClick={showModal}>
                        <Checkbox className="custom-checkbox">
                            <a href="#" onClick={handleConsent7Click} className="hover-underline">Consent to share
                                information via Text Messages from METRO</a>
                            <br />
                            <Text className="text-muted">I hereby authorize METRO to share information via Text
                                Messages.
                            </Text>
                        </Checkbox>
                    </Form.Item>
                    <Space>
                        <p className="text-muted font-weight-bold mt-3">I confirm that I have read and agreed to the
                            checked consent forms above.</p>
                    </Space>
                    <Row style={{ padding: 5 }}>
                        <Col style={{ padding: 5 }} xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item label="Signature"
                                name="signature"
                                rules={[{
                                    required: true,
                                    message: 'Patient or Caregiver full name is required.'
                                },]}>

                                <Input onClick={showSignatureModal} placeholder="Enter your full name to sign" />
                            </Form.Item>
                        </Col>
                        <Col style={{ padding: 5 }} xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item label="Signature" name="signatureImage">
                                <div className="form-control signUrl" style={{ height: '32px' }}
                                    onClick={showSignatureModal}>
                                    {signatureUrl !== '' ? <img
                                        style={{ width: '120px', height: '32px', marginTop: '-7px' }}
                                        className="signUrl"
                                        src={signatureUrl}
                                        alt="Signature"
                                        id="sign-url"
                                    /> : (pathDetails.hasOwnProperty('path')) ? <img
                                        style={{ width: '120px', height: '32px', marginTop: '-7px' }}
                                        className="signUrl"
                                        src={`${modifiedURL}/storage/images?path=${pathDetails.path}`}
                                        alt="Signature"
                                        id="sign-url"
                                    /> : null}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col style={{ padding: 5 }} xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item label="Date"
                                name="signed_date"
                                rules={[{ required: true, message: 'This field is required.' }]}
                            >
                                <DatePicker format="MM/DD/YYYY" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ padding: 5 }}>
                        <Col style={{ padding: 5 }} xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item label="Print Name of Legal Representative(if applicable)"
                                name="representative_signature">
                                <Input placeholder="Enter your full name to sign" />
                            </Form.Item>
                        </Col>
                        <Col style={{ padding: 5 }} xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item label="Nature of Relationship to Patient(if applicable)"
                                name="representative_relationship">
                                <Select
                                    showSearch
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={[
                                        {
                                            value: 'Self',
                                            label: 'Self',
                                        },
                                        {
                                            value: 'Spouse',
                                            label: 'Spouse',
                                        },
                                        {
                                            value: 'Mother',
                                            label: 'Mother',
                                        },
                                        {
                                            value: 'Father',
                                            label: 'Father',
                                        },
                                        {
                                            value: 'Daughter',
                                            label: 'Daughter',
                                        },
                                        {
                                            value: 'Son',
                                            label: 'Son',
                                        },
                                        {
                                            value: 'GrandMother',
                                            label: 'GrandMother',
                                        },
                                        {
                                            value: 'GrandFather',
                                            label: 'GrandFather',
                                        },
                                        {
                                            value: 'Other',
                                            label: 'Other',
                                        }
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                        <Col style={{ padding: 5 }} xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item label="Witness Signature"
                                name="witness_signature"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item className="form-footer-button">
                        <Space>
                            <Button type="primary" size="large" onClick={onConsentsPreviousClick}>Previous</Button>
                            <Button type="primary" size="large" htmlType="submit">Submit</Button>
                        </Space>
                    </Form.Item>
                </Form>
            ) : (
                <div className="spinner-overlay">
                    <div className="spinner">
                        <Spin size="large" tip="Loading..." />
                    </div>
                </div>
            )}
            <Modal className="modal-text-uppercase" title={<div style={{ textAlign: 'center' }}>
                CONSENT TO SHARE INFORMATION VIA TEXT MESSAGES FROM METRO</div>}
                open={isModalOpen}
                style={{ top: 20 }}
                onOk={healthRefForm.submit}
                maskClosable={false}
                onCancel={handleCancel} width={1300}
            >
                <p>
                    By signing below, I authorize METRO to send me text messages to help support the care that I
                    receive.
                    These messages will be for myself or the patient(s) of the center that I support.
                    These messages will be utilized to help with:
                </p>
                <ul>
                    <li>rescheduling of upcoming appointments</li>
                    <li>reminders about needed doctor visits</li>
                    <li>results of my labs, imaging studies and other clinical tests</li>
                    <li>information to help manage illnesses</li>
                    <li>follow-up well check texts after a visit or procedure</li>
                </ul>
                <p>
                    I understand that message/data rates may apply to messages sent through METRO to my cell phone.
                    If I lose or share my phone, I am aware that someone else may see my private information.
                    If my contact information/cell phone number changes I will inform METRO of these changes as soon as
                    possible.
                </p>
                <p>
                    I understand that I am under no obligation to authorize METRO to send me text messages as part of
                    this program.
                </p>
                <p>
                    I may opt-out of receiving these communications from METRO at any time by calling
                    METRO 169531596 or by replying STOP to any message sent.
                </p>
                {dataLoaded ? (
                    <Form form={healthRefForm} layout="vertical" onFinish={handleHealthInfoSubmit}
                        initialValues={{
                            ShareInfoPatientName: props.patientRegistrationInfo.patientRegistrationFormData?.ShareInfoTextMessageConsentForm?.ShareInfoPatientName,
                            ShareInfoPatientSignature: props.patientRegistrationInfo.patientRegistrationFormData?.ShareInfoTextMessageConsentForm?.ShareInfoPatientSignature, // Set to the actual selected value
                            ShareInfoPatientRelationship: props.patientRegistrationInfo.patientRegistrationFormData?.ShareInfoTextMessageConsentForm?.ShareInfoPatientRelationship, // Set to the actual selected value
                            ShareInfoSignDate: props.patientRegistrationInfo.patientRegistrationFormData?.ShareInfoTextMessageConsentForm?.ShareInfoSignDate
                                ? moment(props.patientRegistrationInfo.patientRegistrationFormData?.ShareInfoTextMessageConsentForm?.ShareInfoSignDate, 'YYYY-MM-DD')
                                : moment(),
                        }}>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label="NAME"
                                    name="ShareInfoPatientName"
                                    rules={[{ required: true, message: 'This field is required.' }]}>
                                    <Input placeholder="Enter your full name to sign" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="SIGNATURE"
                                    name="ShareInfoPatientSignature" rules={[{
                                        required: true,
                                        message: 'This Field is required.'
                                    }]}>
                                    <Input placeholder="Enter your full name to sign" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="RELATIONSHIP"
                                    name="ShareInfoPatientRelationship" rules={[{
                                        required: true,
                                        message: 'This Field is required.'
                                    }]}>
                                    <Select
                                        showSearch
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={[
                                            {
                                                value: 'Self',
                                                label: 'Self',
                                            },
                                            {
                                                value: 'Spouse',
                                                label: 'Spouse',
                                            },
                                            {
                                                value: 'Mother',
                                                label: 'Mother',
                                            },
                                            {
                                                value: 'Father',
                                                label: 'Father',
                                            },
                                            {
                                                value: 'Daughter',
                                                label: 'Daughter',
                                            },
                                            {
                                                value: 'Son',
                                                label: 'Son',
                                            },
                                            {
                                                value: 'GrandMother',
                                                label: 'GrandMother',
                                            },
                                            {
                                                value: 'GrandFather',
                                                label: 'GrandFather',
                                            },
                                            {
                                                value: 'Other',
                                                label: 'Other',
                                            }
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Date"
                                    name="ShareInfoSignDate"
                                    rules={[{ required: true, message: 'This field is required.' }]}
                                >
                                    <DatePicker format="MM/DD/YYYY" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                ) : (
                    <div className="spinner-overlay">
                        <div className="spinner">
                            <Spin size="large" tip="Loading..." />
                        </div>
                    </div>
                )}
            </Modal>
            <Modal className="manual-validate-modal"
                open={modalManualPopup}
                onOk={() => {
                    if (!manualRefConsentForm.getFieldValue('manualCheck') && !manualRefConsentForm.getFieldValue('pin')) {
                        manualRefConsentForm.setFields([
                            { name: 'manualCheck', errors: ['Please check the checkbox.'] },
                            { name: 'pin', errors: ['This field is required.'] },
                        ]);
                    } else if (!manualRefConsentForm.getFieldValue('pin')) {
                        manualRefConsentForm.setFields([
                            {
                                name: 'pin',
                                errors: ['This field is required.'],
                            },
                        ]);
                    } else if (!manualRefConsentForm.getFieldValue('manualCheck')) {
                        manualRefConsentForm.setFields([{
                            name: 'manualCheck',
                            errors: ['Please check the checkbox.']
                        },]);
                    } else {
                        manualRefConsentForm.submit();
                    }
                }}
                maskClosable={false}
                cancelText={null}
                onCancel={onCancelManualVerify} width={500}
            >
                <Form form={manualRefConsentForm} layout="vertical" onFinish={handleManualVerifySubmit}>
                    <Form.Item label="Enter your pin" name="pin"
                        rules={[{ required: true, message: 'This field is required.' }]}>
                        <Input.Password placeholder="Enter your pin" />
                    </Form.Item>
                    <Form.Item name="manualCheck" valuePropName="checked"
                        rules={[{ required: true, message: 'Please check the checkbox.' }]}>
                        <Checkbox>Manually Verified</Checkbox>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal open={isModalConsentOpen} style={{ top: 20 }} width={1300}
                onCancel={closeConsentModal} footer={null}>
                <iframe
                    title="Modal Content"
                    src={consentFormIframeUrl}
                    style={{ width: '100%', height: '650px', border: 'none' }}
                />
            </Modal>
            <Modal
                title="Signature" width={canvasWidth + 50}
                visible={isModalSignatureVisible}
                onOk={handleSignatureSave}
                onCancel={handleSignatureCancel}
                footer={[
                    <Button key="back" onClick={handleSignatureCancel}>
                        Cancel
                    </Button>,
                    <Button key="clear" onClick={clearSignature}>
                        Clear Signature
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleSignatureSave}>
                        Save Signature
                    </Button>,
                ]}
            >
                <Form form={signatureRefForm} layout="vertical">
                    <Form.Item label="Patient Signature"
                        name="ShareInfoPatientName"
                        rules={[{ required: true, message: 'This field is required.' }]}>
                        <Input placeholder="Enter your full name to sign" />
                    </Form.Item>
                    <Form.Item label="Signature Board">
                        <div>
                            <SignatureCanvas ref={signatureRef} canvasProps={{
                                width: canvasWidth,
                                height: 150,
                                style: { border: "1px dashed #ccc" },
                            }} />
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal className="modal-text-uppercase" title={<div style={{ textAlign: 'center' }}>
                CONSENT FOR EXAMINATION AND TREATMENT MEDICAL / DENTAL / BEHAVIORAL / REHABILITATION / ROUTINE
                IMMUNIZATIONS / HIV TESTING</div>}
                open={isExaminationConsentModalOpen}
                style={{ top: 20 }}
                onOk={examinationRefForm.submit}
                maskClosable={false}
                onCancel={handleExaminationCancel} width={1300}
            >
                <div>
                    <p>1. I hereby authorize Metro Community Health Centers (MCHC) and/or its staff, including
                        physicians,
                        dentists, nurses and other staffb
                        to provide such medical and/or dental care and to administer such routine diagnostic tests and
                        procedures, including, but not limited to:
                        diagnostic x-rays; physical examination(s), administration and/or injection of pharmaceutical
                        products, including routine immunizations
                        and medications; drawing of blood specimens, use of local anesthesia and other non-invasive
                        procedures as in the judgment of Metro
                        CHC’s personnel and/or my/the patient’s physician(s) are deemed necessary or advisable in my/the
                        patient’s care. I acknowledge that
                        this consent includes all future appointments and care rendered, and that further consent is not
                        necessary unless I revoke this consent in
                        writing </p>

                    <p>2. I acknowledge that medicine is not an exact science and that diagnosis or treatment may
                        involve
                        risk of injury or even death, and no
                        guarantees or assurances have been made to me concerning the results of treatments or
                        examinations
                        at Metro CHC.
                    </p>

                    <p>3. Metro CHC may have the ability to access prescription medications I have filled or taken
                        elsewhere, through connecting to
                        medication history data as reported through pharmacy benefit managers or otherwise. Knowledge of
                        other medications can help my
                        doctor know whether there may be drug interactions or whether symptoms may be caused by a
                        medication. Metro CHC has no
                        responsibility as to accuracy of data obtained. I authorize Metro CHC to access this
                        information.</p>

                    <p>4. I understand that Metro CHC supports education and training and may provide clinical
                        opportunities
                        for trainees in various
                        disciplines. I understand that I have the right to refuse to have a trainee participate in my
                        care.</p>

                    <p>5. I authorize Metro CHC to dispose of or use specimens taken for laboratory, pathology or other
                        purposes.</p>

                    <p>6. I understand my (patient’s) rights and responsibilities as a patient.</p>

                    <p>7.<strong> OCCUPATIONAL EXPOSURE:</strong> Regardless of whether I consent to elective HIV
                        testing as
                        set forth in Section 8 below,
                        unless I cross out this provision, if a healthcare worker involved in my care and treatment
                        becomes
                        exposed to certain bodily
                        fluids resulting in the possibility of transmission of a blood borne disease, my blood will be
                        tested for HIV, Hepatitis B and
                        Hepatitis C to determine risk of exposure.</p>

                    <p>8. HIV TESTING: Your Health Care Provider is required to make an offer of HIV testing as part of
                        routine care to all persons
                        between ages 13 and 64. You are strongly encouraged to accept testing since, as with other
                        medical
                        screenings, it may provide you
                        with important information about your health and give you what you need to make good decisions
                        for
                        staying healthy. I have been
                        given information regarding HIV, including seven points of information regarding HIV testing,
                        how
                        HIV can be transmitted, that there
                        is treatment for HIV/AIDS, how to keep myself and others safe from HIV infection, that testing
                        is
                        voluntary and can be done
                        anonymously, how my HIV-related information will be kept confidential and what laws protect
                        people
                        with HIV/AIDS from
                        discrimination. I understand that the results of my HIV test will be documented on my medical
                        chart.
                        My consent may be given orally
                        and documented by Metro CHC.<br />
                        <strong>Please select one of the following:</strong><br />
                    </p>
                    <Form form={examinationRefForm} layout="vertical" onFinish={handleExaminationConsentSubmit}
                        initialValues={{ HIVConsent: props.patientRegistrationInfo.patientRegistrationFormData?.HIVConsent, }}>
                        <Form.Item
                            name="HIVConsent"
                            rules={[{ required: true, message: 'This field is required.' }]}>
                            <Radio.Group>
                                <Space direction="vertical">
                                    <Radio value="I do not want an HIV Test at this time"> I DO NOT WANT AN HIV TEST AT
                                        THIS TIME. </Radio>
                                    <Radio value="I wish to receive an HIV test"> I WISH TO RECEIVE AN HIV
                                        TEST. </Radio>
                                    <Radio value="I wish to receive information as to an HIV test"> I WISH TO RECEIVE
                                        INFORMATION AS TO AN HIV TEST. </Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                    <p>9. I understand that if I disagree with particular provision listed above, I have the right to
                        cross
                        out any provision and will initial next
                        to my cross-out so that Metro CHC knows that I refused that provision.
                        I confirm that I have read/ or have had read to me the information above. I further confirm that
                        I
                        fully understand the above
                        information and all my questions have been answered.</p>
                </div>
            </Modal>
            <Modal className="modal-text-uppercase" title={<div style={{ textAlign: 'center' }}>PATIENT ACKNOWLEDGMENT OF
                RECEIPT/CONSENT FORM
            </div>}
                open={isFinancialConsentModalOpen}
                style={{ top: 20 }}
                onOk={financialRefForm.submit}
                maskClosable={false}
                onCancel={handleFinancialCancel} width={1300}
            >
                <div>
                    <p>By signing below I do hereby acknowledge receipt, review, and the opportunity to ask any
                        questions about the following documents/information:<br />
                        • Patient’s Rights and Responsibilities<br />
                        • Notice of Privacy Practices<br />
                        • Grievance/Appeal Procedure<br />
                        • NYS Surprise Bill Law<br />
                        • Planning in Advance for Your Medical/Dental/Specialty treatment<br />
                        • “Appointing Your Health Care Agent” - NEW YORK STATE’s PROXY LAW<br />
                        • A sample, “Health Care Proxy”<br />
                        • NYC Department of Health and Mental Hygiene’s Guide for Patients and Families:<br />
                        “Deciding about Health Care.”<br />
                        • Information regarding HIV testing<br />
                        • Information about Healthix (Health Information Exchange Organization)<br />
                        - I further attest that I have informed Metro Community Health Centers, Inc. of the existence,
                        if any, of
                        instructions pertaining to Advanced Directives, Living Wills, DNR Orders, Health Care Proxy, or
                        other form of an
                        expression of patient self-determination.<br />
                        I have/will provide a copy of the duly executed instrument and acknowledge that said copy will
                        become a part of the patient medical record.<br />
                        - I have an Advance Directive:
                    </p>
                    <Form form={financialRefForm} onFinish={handleAcknowledgementConsentSubmit}
                        initialValues={{
                            AdvanceDirective: props.patientRegistrationInfo?.patientRegistrationFormData?.AcknowledgementConsent?.AdvanceDirective,
                            Type: props.patientRegistrationInfo?.patientRegistrationFormData?.AcknowledgementConsent?.Type,
                        }}>
                        <Form.Item
                            name="AdvanceDirective"
                            rules={[{ required: true, message: 'This field is required.' }]}>
                            <Radio.Group>
                                <Radio value="Yes"> Yes</Radio>
                                <Radio value="No">No </Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Space>
                            <Form.Item label="Type" name="Type">
                                <Input />
                            </Form.Item>
                        </Space>
                    </Form>
                    <p>- I understand and acknowledge that it is the responsibility of the patient, his/her
                        representative,
                        to inform Metro Community Health Centers, Inc.<br />
                        immediately of any change in the conditions of the above mentioned expression of patient
                        self-determination.<br />
                        - I further acknowledge that I have been provided with a copy of Metro Community Health Centers,
                        Inc.<br />
                        Notice of Privacy Practices and have therefore been advised of how medical information about me
                        may be used and disclosed by
                        Metro Community Health Centers, Inc. and how I may obtain access to this information.<br />
                        I also hereby consent to the disclosure of my health information by Metro Community Health
                        Centers, Inc.
                        for the following purposes: (1) to provide for, arrange or coordinate my health care treatment;
                        (2)
                        to enable Metro Community Health Centers, Inc.<br />
                        to obtain payment for the services it provides to me; and (3) to permit Metro Community Health
                        Centers, Inc.
                        to carry out ordinary health care and business operations such as quality assurance, service
                        planning and general administration.
                        Electronically
                    </p>
                </div>
            </Modal>
            <Modal className="modal-text-uppercase"
                title={<div style={{ textAlign: 'center' }}>CONSENT FOR AUTHORIZATION FOR
                    ACCESS TO PATIENT INFORMATION
                </div>}
                open={isAuthorizationConsentModalOpen}
                style={{ top: 20 }}
                onOk={authorizationRefForm.submit}
                maskClosable={false}
                onCancel={handleAuthorizationCancel} width={1300}
            >
                <div>
                    <p>I request that health information regarding my care and treatment be accessed as set forth on
                        this form.
                        I can choose whether or not to allow Metro Community Health Centers to obtain access to my
                        medical records
                        through the health information exchange organization called Healthix.<br />
                        If I give consent, my medical records from different places where I get health care can be
                        accessed using a
                        statewide computer network.<br />
                        Healthix is a not for-profit organization that shares information about people’s health
                        electronically
                        and meets the privacy and security standards of HIPAA and New York State Law.<br />
                        To learn more visit Healthix’s website at www.healthix.org
                        The choice I make in this form will NOT affect my ability to get medical care.
                        The choice I make in this form does NOT allow health insurers to have access to my information
                        for the purpose of
                        deciding whether to provide me with health insurance coverage or pay my medical bills.
                        My Consent Choice. ONE box is checked to the left of my choice.<br />
                        I can fill out this form now or in the future.<br />
                        I can also change my decision at any time by completing a new form.<br />
                    </p>
                    <Form form={authorizationRefForm} onFinish={handleAuthorizationConsentSubmit}
                        initialValues={{
                            AccessHealthInfo: props.patientRegistrationInfo.patientRegistrationFormData?.PatientAccessConsent?.AccessHealthInfo,
                        }}>
                        <Form.Item
                            name="AccessHealthInfo"
                            rules={[{ required: true, message: 'This field is required.' }]}>
                            <Radio.Group>
                                <Radio value="Yes"> 1. I GIVE CONSENT FOR METRO COMMUNITY HEALTH CENTERS TO ACCESS ALL
                                    OF MY
                                    ELECTRONIC HEALTH INFORMATION THROUGH HEALTHIX TO PROVIDE HEALTH CARE.</Radio>
                                <Radio value="No">2. I DENY CONSENT FOR METRO COMMUNITY HEALTH CENTERS TO ACCESS MY
                                    ELECTRONIC HEALTH INFORMATION
                                    THROUGH HEALTHIX FOR ANY PURPOSE.
                                    IF I WANT TO DENY CONSENT FOR ALL PROVIDER ORGANIZATIONS AND HEALTH PLANS
                                    PARTICIPATING
                                    IN HEALTHIX TO ACCESS MY ELECTRONIC HEALTH INFORMATION THROUGH HEALTHIX,
                                    I MAY DO SO BY VISITING HEALTHIX’S WEBSITE AT WWW.HEALTHIX.ORG OR CALLING HEALTHIX
                                    AT 877-695-4749. </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                    <p>
                        If I want to deny consent for all Provider Organizations and Health Plans participating in
                        Healthix
                        to access my electronic health information through Healthix,
                        I may do so by visiting Healthix’s website at www.healthix.org or calling Healthix at
                        877-695-4749.</p>
                </div>
            </Modal>

            <Modal className="modal-text-uppercase"
                title={<div style={{ textAlign: 'left' }}>Authorization for Ongoing Release of Protected Health
                    Information (including Alcohol/Drug Treatment and
                    Mental Health Information) and Confidential HIV/AIDS- related information
                </div>}
                open={isReleaseHealthInfoConsentOpen}
                style={{ top: 20 }}
                onOk={releaseHealthInfoRefForm.submit}
                maskClosable={false}
                onCancel={handleReleaseHealthCancel} width={1300}
            >
                <div>
                    <p>1. I, or my authorized representative, request that health information regarding my care and
                        treatment
                        be released as set forth on this form. I
                        understand that this authorization may include disclosure of: Information relating to
                        Alcohol/Drug
                        Treatment and Mental Health Treatment, unless I
                        restrict the release of this information in Item 9; and Confidential HIV/AIDS- related
                        information
                        only if I place my initials on the appropriate line in
                        Item 9. I specifically authorize the release of such information to Person(s)/Entity indicated
                        in Item 6.
                    </p>
                    <p>2. With some exceptions, health information once disclosed may be re-disclosed by the recipient.
                        If I am authorizing the release of HIV/AIDSrelated, alcohol or drug treatment, or mental health
                        treatment
                        information, the recipient is prohibited from re-disclosing such information or using the
                        disclosed information for any other purpose without my authorization unless permitted to do so
                        under
                        applicable federal or state law. If I experience
                        discrimination because of the release or disclosure of HIV/AIDS-related information,
                        I may contact the New York State Division of Human Rights at
                        1-888-392-3644. This agency is responsible for protecting my rights.</p>

                    <p>3. I have the right to revoke this authorization any time by writing to the provider/entity
                        listed below
                        in Item 5, except to the extent that action has
                        already been taken based on this authorization. I have the right to inspect/receive copy of the
                        information
                        to be released.</p>

                    <p>4. Signing this authorization is voluntary. I understand that my treatment, payment, enrollment
                        in health plan,
                        or eligibility for benefits will not be
                        conditional upon my authorization of this disclosure. However, I do understand that I may be
                        denied treatment in some circumstances if I do not sign
                        this consent.</p>
                    <Form form={releaseHealthInfoRefForm} onFinish={handleAccessPatientConsentSubmit}
                        initialValues={{
                            NameAddressProvider: props.patientRegistrationInfo.patientRegistrationFormData?.ReleasePatientHealthConsent?.NameAddressProvider || '',
                            NameAddressPerson: props.patientRegistrationInfo.patientRegistrationFormData?.ReleasePatientHealthConsent?.NameAddressPerson || '',
                            ReleasePurpose: props.patientRegistrationInfo.patientRegistrationFormData?.ReleasePatientHealthConsent?.ReleasePurpose || '',
                            OtherValue: props.patientRegistrationInfo.patientRegistrationFormData?.ReleasePatientHealthConsent?.OtherValue || '',
                            AuthorizationExpiryDate: props.patientRegistrationInfo.patientRegistrationFormData?.ReleasePatientHealthConsent?.AuthorizationExpiryDate
                                ? moment(props.patientRegistrationInfo.patientRegistrationFormData?.ReleasePatientHealthConsent?.AuthorizationExpiryDate, 'YYYY-MM-DD')
                                : moment(),
                            InformationRelease: props.patientRegistrationInfo.patientRegistrationFormData?.ReleasePatientHealthConsent?.InformationRelease || '',
                            ProtectedHealthInformation: props.patientRegistrationInfo.patientRegistrationFormData?.ReleasePatientHealthConsent?.ProtectedHealthInformation || '',
                            SensitiveInfo: props.patientRegistrationInfo.patientRegistrationFormData?.ReleasePatientHealthConsent?.SensitiveInfo || '',
                            InitialIndication: props.patientRegistrationInfo.patientRegistrationFormData?.ReleasePatientHealthConsent?.InitialIndication || '',
                        }}>
                        <Space>
                            <p className="margin-0">5. Name and Address of Provider/Entity to RELEASE this
                                information:</p>
                            <Form.Item
                                name="NameAddressProvider"
                                rules={[{ required: true, message: 'This field is required.' }]}>
                                <Input placeholder="Enter NA if not applicable" />
                            </Form.Item>
                        </Space><br />
                        <b>(Enter NA if not applicable)</b>
                        <p>Metro Community Health Centers, Inc.</p>
                        <p className="margin-0">6. Name and Address of Person(s)/Entity to RECEIVE this information:
                            All Administrative Staff and Authorized Employees of Residence/ Group Home:</p>
                        <Space>
                            <Form.Item
                                name="NameAddressPerson"
                                rules={[{ required: true, message: 'This field is required.' }]}>
                                <Input placeholder="Enter NA if not applicable" />
                            </Form.Item>
                        </Space><br />
                        <b>(Enter NA if not applicable)</b>
                        <p className="margin-0">7. Purpose for release of information:</p>
                        <Form.Item>
                            <Space>
                                <Form.Item
                                    name="ReleasePurpose"
                                    rules={[{ required: true, message: 'This field is required.' }]}>
                                    <Radio.Group>
                                        <Radio value='At my request'>At my request</Radio>
                                        <Radio value='Continuity of Care/Treatment'>Continuity of Care/Treatment</Radio>
                                        <Radio value='Other'>Other</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item name="OtherValue">
                                    <Input></Input>
                                </Form.Item>
                            </Space>
                        </Form.Item>
                        <Space>
                            <p>8. Authorization expiration date or event:</p>
                            <Form.Item
                                name="AuthorizationExpiryDate"
                                rules={[{ required: true, message: 'This field is required.' }]}>
                                <DatePicker className="form-control" format="MM/DD/YYYY" />
                            </Form.Item>
                        </Space>
                        <p> (if expiration date/event is not
                            specified,
                            this authorization will expire
                            when the above named person/entity, authorized to receive this information, is no longer
                            involved in
                            the
                            patient’s care)</p>
                        <Space>
                            <p className="margin-0">9. Information to be released:</p>
                            <Form.Item
                                name="InformationRelease"
                                rules={[{ required: true, message: 'This field is required.' }]}>
                                <Input placeholder="Enter NA if not applicable" />
                            </Form.Item>
                        </Space><br />
                        <b>(Enter NA if not applicable)</b><br />
                        <Space>
                            <p className="margin-0">Any and All Protected Health Information (written or oral),
                                except:</p>
                            <Form.Item
                                name="ProtectedHealthInformation"
                                rules={[{ required: true, message: 'This field is required.' }]}>
                                <Input placeholder="Enter NA if not applicable" />
                            </Form.Item>
                        </Space><br />
                        <b>(Enter NA if not applicable)</b>
                        <p className="margin-0">I authorize the disclosure of the following Sensitive Health information
                            if the requested
                            portion of my record contains it: Behavioral and Mental
                            Health Services, Treatment for Alcohol and Drug abuse, STD (Sexually transmitted diseases),
                            genetic testing (including test results). I have the
                            right to restrict the release of my Sensitive health information listed above
                            (indicate restrictions here, if any):</p>
                        <Space>
                            <Form.Item
                                name="SensitiveInfo"
                                rules={[{ required: true, message: 'This field is required.' }]}>
                                <Input placeholder="Enter NA if not applicable" />
                            </Form.Item>
                        </Space><br />
                        <b>(Enter NA if not applicable)</b>
                        <p className="margin-0">By placing my initials bellow, I authorize the disclosure to include the
                            following Sensitive
                            health information: Include: (indicate by initialing)</p>
                        <Space>
                            <Form.Item
                                name="InitialIndication"
                                rules={[{ required: true, message: 'This field is required.' }]}>
                                <Input placeholder="Enter NA if not applicable" />
                            </Form.Item>
                        </Space><br />
                        <b>(Enter NA if not applicable)</b> HIV/AIDS related information
                    </Form>
                    <p>I have read the above information and all my questions about this form have been answered.
                        By signing below, I acknowledge that I accept all of the
                        above. All items on this form have been completed and I have been provided a copy of this
                        form. </p>
                </div>
            </Modal>
        </div>
    );
};

export default MetroConsents;
