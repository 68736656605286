import { Menu, Dropdown, Tooltip, Button, Tag } from "antd";
import { EyeOutlined } from '@ant-design/icons';

export const columnData = (isIpadAppt) => {


  const columns = [
    {
      title: "Action",
      key: "action",
      render: ["view"],
      fixed: true,
      width: 60,
      feature: false,
      hidden: false,
    },
    {
      title: "APPT Id",
      dataIndex: "appointmentId",
      key: "appointmentId",
      hidden: false,
      width: 100,
      render: ["appointmentIdLink"],
    },
    {
      title: "Patient Name",
      dataIndex: "patientName",
      key: "patientName",
      feature: false,
      hidden: false,
    },
    {
      title: "Patient Dob",
      dataIndex: "patientDob",
      key: "patientDob",
      width: 200,
      feature: ["sorter", "filter"],
      filters: [],
      hidden: false,
    },
    {
      title: "Provider Name",
      dataIndex: "providerName",
      key: "providerName",
      width: 200,
      feature: ["sorter", "filter"],
      filters: [],
      hidden: false,
    },
    {
      title: "APPT Time",
      dataIndex: "appointmentTime",
      key: "appointmentTime",
      width: 300,
      feature: ["sorter", "filter"],
      filters: [],
      hidden: false,
    },
    {
      title: "APPT Date",
      dataIndex: "appointmentDate",
      key: "appointmentDate",
      width: 200,
      feature: ["sorter", "filter"],
      filters: [],
      hidden: true,
    },
    {
      title: "APPT Status",
      dataIndex: "appointmentStatus",
      key: "appointmentStatus",
      width: 200,
      feature: ["sorter", "filter"],
      filters: [],
      hidden: false,
    },
    {
      title: "APPT Type",
      dataIndex: "appointmentType",
      key: "appointmentType",
      width: 200,
      feature: ["sorter", "filter"],
      filters: [],
    },
    {
      title: "APPT Location",
      dataIndex: "appointmentLocation",
      key: "appointmentLocation",
      feature: ["sorter", "filter"],
      filters: [],
      width: 200,
      hidden: false
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      width: 100,
      feature: ["sorter", "filter"],
      filters: [],
      hidden: false,
    },
    {
      title: "Portal",
      dataIndex: "portal",
      key: "portal",
      width: 100,
      feature: ["sorter", "filter"],
      filters: [],
      hidden: false,
    },
    {
      title: "Reason for visit",
      dataIndex: "reason",
      key: "reason",
      width: 200,
      feature: ["sorter", "filter"],
      filters: [],
      hidden: false,
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: 200,
      hidden: true,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      width: 200,
      hidden: true,
    },
  ];

  if (isIpadAppt === true) {
    columns.push(
      {
        title: "RPA Status",
        dataIndex: "rpaStatus",
        key: "rpaStatus",
        width: 200,
      },
      {
        title: "Registration Link",
        dataIndex: "regLink",
        key: "regLink",
        render: ["dropdown", "link"],
        width: 200,
      },

      // {
      //   title: "Registration Status",
      //   dataIndex: "regStatus",
      //   key: "regStatus ",
      //   width: 200,
      // }
      {
        title: "Registration Status",
        dataIndex: "regStatus",
        key: "regStatus",
        render: ["badge"],
        width: 200,

      },
      {
        title: "Unique Hash",
        dataIndex: "uniqueHash",
        key: "uniqueHash",
        width: 200,
        hidden:true
      },
    );
  }



  return { columns };
};
