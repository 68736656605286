import React from "react";
import ddcImage from '../../../../assets/images/ddc-logo.png'; 

const ConsentForm1 = () => {
    return (
        <div>
            <div className="form-row">
                <div className="form-group col-md-6">
                <img src={ddcImage} className='DDC-Image' alt="DDC logo" />
                </div>
                <div className="form-group col-md-2">
                    <h2><b>CONSENT FORM FOR E-MAIL RELEASE</b></h2>
                </div>
            </div>
            <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossOrigin="anonymous"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossOrigin="anonymous"></script>
            <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossOrigin="anonymous"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-validate/1.19.3/jquery.validate.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-validate/1.19.3/additional-methods.js"></script>

            <div>
                <br />
                I, (or Patient’s Representative) want to communicate via e-mail with
                (Northeast Primary Care PC) on matters related to my health and/or my medical treatment. I understand that any Confidential Health Information
                I send to the practice is not secure and is sent at my own risk. I will not hold the practice, or any of its workforce members, liable for loss of any
                confidentially associated with information transmitted via e-mail.
                <br />
                <br />
                I also understand that it is not the policy of the practice to encrypt any Confidential Health Information I request to be sent to me via e-mail.
                Because this information is not encrypted I understand that it is not secure. I acknowledge this risk and will not hold the practice or any of its
                workforce members liable for any loss of confidentiality associated with such transmissions.
                <br />
            </div>
            <br />
            <b>YOU CAN CLOSE THIS TAB AFTER VIEWING AND GO BACK TO FORM</b>
        </div>
    );
};

export default ConsentForm1;
