import React, { useState } from 'react';
import { Tabs, Form, Input, Button } from 'antd';
import PatientForm from './patientForm';
import Others from './others';
import ClaimForm from './claimForm';
import { ArrowLeftOutlined, ArrowRightOutlined, CheckOutlined } from '@ant-design/icons'; // Importing icons


const { TabPane } = Tabs;
const CRMSteps = () => {
    const [form] = Form.useForm();
    const [activeTab, setActiveTab] = useState("1"); // State to track active tab

    const handleTabChnages = (key) => {
        setActiveTab(key);
    }

    const handleNext = () => {
        const nextTab = parseInt(activeTab) + 1; // Increment current tab index
        setActiveTab(nextTab.toString()); // Update active tab
    };

    const handlePrevious = () => {
        const previousTab = parseInt(activeTab) - 1; // Decrement current tab index
        setActiveTab(previousTab.toString()); // Update active tab
    };

    return (
        <div>

            <div>
                <div className='Header-StyleTab'>
                    <label className='Header-Text'>Health Insurance Claim Form</label>
                </div>
                <Tabs activeKey={activeTab} type="card" className='tttt' onChange={handleTabChnages}>
                    <TabPane tab="Patient" key="1">
                        <PatientForm />
                    </TabPane>

                    <TabPane tab="Claim" key="2">
                        <ClaimForm />
                    </TabPane>

                    <TabPane tab="Others" key="3">
                        <Others />
                    </TabPane>
                </Tabs>
            </div>

            {/* Buttons for next and previous */}
            <div className="steps-action">
                <div className="steps-button">
                    {activeTab !== "1" && (
                        <Button icon={<ArrowLeftOutlined />} onClick={handlePrevious}>Previous</Button>
                    )}
                    {activeTab !== "3" && (
                        <Button icon={<ArrowRightOutlined />} onClick={handleNext} style={{ marginLeft: '10px', }} type='primary'>Next</Button>
                    )}
                    {activeTab === "3" && (
                        <Button icon={<CheckOutlined />} style={{ marginLeft: '10px', }} type='primary'>Finish</Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CRMSteps;
