import React, { useEffect, useState } from "react";
import {
    DatePicker, Dropdown, Form, Radio, Input,
    Row, Col, Select, Button, message, Modal
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { getProviderInitialData } from "../../services/providerService";
import { getCreatePatient } from "../../services/createPatientService";
import dayjs from "dayjs";
import { formatPhoneNumber } from "../utilities/utility";


const { Option } = Select;
const CreatePatient = ({ onBack }) => {
    const [insurances, setInsurances] = useState([]);
    const [form] = Form.useForm();
    const [error, setError] = useState("");
    const [phone, setPhoneNumber] = useState('');


    useEffect(() => {
        fetchProviderForm();
    }, []);

    const fetchProviderForm = async () => {
        try {
            const InitialDataResponse = await getProviderInitialData();
            if (InitialDataResponse && InitialDataResponse.data && InitialDataResponse.data.success) {
                setInsurances(InitialDataResponse.data.insurances);
            }
        } catch (error) {
            console.error('Error fetching provider data:', error);
        };
    }

    const handleSubmit = async (values) => {
        try {
            const formattedDob = dayjs(values.dob).format('YYYY-MM-DD');

            let phonecelltype;
            if (phonecelltype === "home") {
                values.phonecelltype = 0;
            }
            else {
                values.phonecelltype = 1;
            }

            let saveCreatePatient = {
                firstname: values.firstname,
                lastname: values.lastname,
                dob: formattedDob,
                gender: values.gender,
                phonepreferred: values.phonepreferred,
                phonecelltype: values.phonecelltype,
                zip: values.zip,
                emergencycontactname: values.emergencycontactname,
                emergencycontactphone: values.emergencycontactphone,
                insurancename: values.insurancename,
                notes: values.notes
            };

            const response = await getCreatePatient(saveCreatePatient);
            if (response.success) {
                Modal.success({ title: "Success", content: "Patient created successfully." });
                form.resetFields();
            } else {
                if (response && response.data && response.data.businessRules && response.data.businessRules.length > 0) {
                    const firstError = response.data.businessRules[0].description;
                    setError(firstError);
                    Modal.error({
                        title: "Error",
                        content: firstError,
                    });
                } else {
                    setError("Error saving data");
                }
            }
        } catch (error) {
            console.error('Error creating patient. Please try again.', error);
        }
    };

    return (
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item
                        label="First Name"
                        name="firstname"
                        rules={[{ required: true, message: 'First name is required' }]}
                    >
                        <Input autoFocus />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Last Name"
                        name="lastname"
                        rules={[{ required: true, message: 'Last name  is required' }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="DOB"
                        name="dob"
                        rules={[{ required: true, message: 'Date of birth  is required' }]}
                    >
                        <DatePicker />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item
                        label="Gender"
                        name="gender"
                        rules={[{ required: true, message: 'Gender is required' }]}
                    >
                        <Radio.Group>
                            <Radio value="male">Male</Radio>
                            <Radio value="female">Female</Radio>
                            <Radio value="other">Other</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="phonepreferred"
                        label="Phone"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your phone number',
                            },
                        ]}
                        className="form-item-margin"
                    >
                        <Input
                            type="tel"
                            maxLength={14}
                            placeholder='(999) 999-9999'
                            value={phone}
                            onChange={(e) => {
                                const value = e.target.value;
                                const formattedValue = formatPhoneNumber(value);
                                setPhoneNumber(formattedValue);
                                form.setFieldsValue({ phonepreferred: formattedValue });
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="phonecelltype"
                        rules={[{ required: true, message: 'Phone type  is required' }]}
                    >
                        <Radio.Group>
                            <Radio value="home">Home</Radio>
                            <Radio value="cell">Cell</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Emergency Contact Name"
                        name="emergencycontactname"
                        rules={[{ required: true, message: 'Emergency Contact Name is required' }]}

                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={8}>
                    {/* <Form.Item
                        label="Emergency Contact Phone"
                        name="emergencycontactphone"
                        rules={[{ required: true, message: 'Emergency Contact Phone is required' }]}
                    >
                        <Input />
                    </Form.Item> */}
                    <Form.Item
                        name="emergencycontactphone"
                        label="Emergency Contact Phone"
                        rules={[
                            {
                                required: true,
                                message: 'Emergency Contact Phone is required',
                            },
                        ]}
                        className="form-item-margin"
                    >
                        <Input
                            type="tel"
                            maxLength={14}
                            placeholder='(999) 999-9999'
                            value={phone}
                            onChange={(e) => {
                                const value = e.target.value;
                                const formattedValue = formatPhoneNumber(value);
                                setPhoneNumber(formattedValue);
                                form.setFieldsValue({ emergencycontactphone: formattedValue });
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Insurance"
                        name="insurancename"
                        rules={[{ required: true, message: 'Insurance is required' }]}
                    >
                        <Select
                            placeholder="Select an insurance"
                            id="insuranceType"
                            className="select-input input w-100"
                        >
                            {insurances && insurances.map(q => (
                                <Option key={q.insuranceId} value={q.insuranceId}>
                                    {q.insuranceName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Zip Code"
                        name="zip"
                        rules={[{ required: true, message: 'zipcode is required' }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        label="Notes"
                        name="notes"
                    >
                        <TextArea rows={3} />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="end" gutter={[16, 16]}>
                <Col className="buttongap">
                    <Button type="primary" htmlType="submit">
                        Create
                    </Button>
                </Col>
                <Col className="buttongap">
                    <Button htmlType="button" onClick={onBack} >
                        Close
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}

export default CreatePatient;
