import React from 'react';
import { Form, Input, DatePicker, Radio, Row, Col } from 'antd';

import "../../assets/styles/common.css";

const ClaimForm = () => {
  const [form] = Form.useForm();

  return (
    <Form form={form} layout="vertical" style={{ padding: '5px' }}>
      <div className='commonborder'>
        <h3>Physician Or Supplier Information : </h3>
        <Row gutter={[16]}>
          <Col xs={24} md={12} className='form-group'>
            <Form.Item label="Date Of Current Illness, Injury, Or Pregnancy (LMP)">
              <Row gutter={16}>
                <Col xs={12} sm={12} className='form-group'>
                  <Form.Item name="currentIllnessDate">
                    <DatePicker format="MM/DD/YY" style={{ width: '100%' }} className="input" />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} className='form-group'>
                  <Form.Item name="currentIllnessQual">
                    <Input style={{ width: '100%' }} className="input" placeholder='QUAL' />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>

          <Col xs={24} md={12} className='form-group'>
            <Form.Item label="Other Date">
              <Row gutter={16}>
                <Col xs={12} sm={12} className='form-group'>
                  <Form.Item name="otherDateQual">
                    <Input style={{ width: '100%' }} className="input" placeholder='QUAL' />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} className='form-group'>
                  <Form.Item name="otherDate">
                    <DatePicker format="MM/DD/YY" style={{ width: '100%' }} className="input" />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} md={12} className='form-group'>
            {/* //<label>DATE PATIENT UNABLE TO WORK IN CURRENT OCCUPATION</label> */}

            <Form.Item label="Dates Patient Unable To Work In Current Occupation">
              <Row gutter={16}>
                <Col xs={12} sm={12} className='form-group'>
                  <Form.Item name="unableToWorkFrom">
                    <DatePicker format="MM/DD/YY" style={{ width: '100%' }} className="input" placeholder='From Date' />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} className='form-group'>
                  <Form.Item name="unableToWorkTo">
                    <DatePicker format="MM/DD/YY" style={{ width: '100%' }} className="input" placeholder='To Date' />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>

          <Col xs={24} md={12} className='form-group'>
            <label></label>
            <Form.Item label="Name Of Referring Provider Or Other Source" name="referringProvider">
              <Input style={{ width: '100%' }} className="input" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} md={12} className='form-group'>
            <Row gutter={16}>
              <Col xs={24} sm={12} className='form-group'>
                <Form.Item label="Qual" name="providerQual">
                  <Input style={{ width: '100%' }} className="input" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className='form-group'>
                <Form.Item label="Npi" name="providerNPI">
                  <Input style={{ width: '100%' }} className="input" />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col xs={24} md={12} className='form-group'>
            <Form.Item label="Hospitalization Dates Related To Current Services">
              <Row gutter={16}>
                <Col xs={12} sm={12} className='form-group'>
                  <Form.Item name="hospitalizationFrom">
                    <DatePicker format="MM/DD/YY" style={{ width: '100%' }} className="input" placeholder='From Date' />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} className='form-group'>
                  <Form.Item name="hospitalizationTo">
                    <DatePicker format="MM/DD/YY" style={{ width: '100%' }} className="input" placeholder='To Date' />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} md={12} className='form-group'>
            <Form.Item label="Additional Claim Information (Designated by NUCC) " name="additionalClaimInfo">
              <Input style={{ width: '100%' }} className="input" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} className='form-group'>
            <Row gutter={16}>
              <Col xs={12} sm={12} className='form-group'>
                <Form.Item label="Outside Lab?" name="outsideLab">
                  <Radio.Group>
                    <Radio value="yes">YES</Radio>
                    <Radio value="no">NO</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} className='form-group'>
                <Form.Item label="$ Charges" name="labCharges">
                  <Input style={{ width: '100%' }} className="input" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item label="Diagnosis Or Nature Of Illness Or Injury Relate A-L to service line below (Diagnosis Pointer in Others)">
              <Row gutter={[16, 16]}>
                {['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'].map((placeholder, index) => (
                  <Col xs={12} sm={8} md={6} lg={4} className='form-group' key={index}>
                    <Input placeholder={placeholder} />
                  </Col>
                ))}
              </Row>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={12} md={6} className='form-group'>
            <Form.Item label="Resubmission Code">
              <Input style={{ width: '100%' }} className="input" />
            </Form.Item>
          </Col>

          <Col xs={12} md={6} className='form-group'>
            <Form.Item label="Orginal Ref. No.">
              <Input style={{ width: '100%' }} className="input" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} className='form-group'>
            <Form.Item label="Prior Authorization Number">
              <Input style={{ width: '100%' }} className="input" />
            </Form.Item>
          </Col>
        </Row>

      </div>
    </Form>
  );
};

export default ClaimForm;
