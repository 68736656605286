import axiosInterceptor from "../../middleware/axiosInterceptor";

export async function sendNotificationService(userData) {
  const url = "/api/send-notify-verification";
  let response = null;

  try {
    response = await axiosInterceptor.post(url, userData);
  } catch (error) {
    console.error(error);
  }

  return response;
}

export async function validateNotifyVerification(jsonData) {

  const url = "/api/validate-notify-verification";
  let response = null;

  try {
    response = await axiosInterceptor.post(url, jsonData);
  } catch (error) {
    console.error(error);
  }

  return response;
}
