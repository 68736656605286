import React, { useState, useCallback } from "react";
import { Row, Col, Button, Form, Input, Image, Alert } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import Logo from "../../../assets/images/MoblieViewLogo.png";
import "../../../assets/styles/login.css";
import { useNavigate } from 'react-router-dom';
import ForgotPassword from "../users/forgotPassword";
import { onLogin, redirectForgotPassword } from './loginHandler';


function Login() {
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const openForgotPassword = () => {
    setShowForgotPassword(true);
  };

  const closeForgotPassword = () => {
    setShowForgotPassword(false);
  };

  // const inputFocus = useCallback((inputElement) => {
  //   if (inputElement) {
  //     inputElement.focus();
  //   }
  // }, []);
  return (
    <div>
      {showForgotPassword ? (
        <ForgotPassword onClose={closeForgotPassword} />
      ) : (
        <Row justify="center">
          <Col className="divSideImage" xs={26} sm={14} md={14} lg={14} xl={14}>
            {/* <Image preview={false} src={SideImage}  width={700}/> */}
          </Col>
          <Col className="divLoginContent" xs={22} sm={10} md={10} lg={10} xl={10}>
            <div className="row alertLogin">
              <div id="alert" className="login-alert">
                {error ? <Alert description={error} type="error" showIcon closable /> : null}
              </div>
            </div>

            <div className="row">

              <div>
                <Image preview={false} src={Logo} width={200} />
              </div>
            </div>
            <h4 className="heading">Log in to continue</h4>

            <Form
              name="normal_login"
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={(values) => onLogin(values, navigate, setError)}
            >
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Username is required",
                  },
                ]}
              >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} id="username" autoFocus placeholder="Username" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Password  is required",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  id="password"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
              <Row className="divForgotPassword">
                <Form.Item>
                  <a onClick={openForgotPassword} id="forgotpassword">Forgot password?</a>
                </Form.Item>
              </Row>
              <Form.Item>
                <Button
                  id="signin"
                  className="loginButton"
                  type="primary"
                  htmlType="submit"
                >
                  Sign In
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default Login;
