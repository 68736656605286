import React from "react";
import ddcImage from '../../../../assets/images/ddc-logo.png'; 
import "../../../../assets/styles/stepForm.css"
const ConsentForm1 = () => {
    return (
        <div>
            <div className="Model-Header">
                <div>
                <img src={ddcImage} className='DDC-Image' alt="DDC logo" />
                </div>
                <div>
                    <h2><b>CONSENT FORM FOR USE/DISCLOSURE OF HEALTH INFORMATION</b></h2>
                </div>
            </div>
            <div>
                <b>NOTICE TO PATIENT:</b>
                <br />
                BY SIGNING THIS FORM, YOU GRANT US CONSENT TO USE AND DISCLOSE YOUR PROTECTED HEALTH CARE INFORMATION FOR THE PURPOSES OF
                TREATMENT, VARIOUS ACTIVITIES ASSOCIATED WITH PAYMENT AND HEALTH CARE OPERATIONS. OUR NOTICE OF PRIVACY PRACTICES PROVIDES
                MORE DETAILS ON OUR TREATMENT, PAYMENT ACTIVITIES AND HEALTH CARE OPERATIONS. IF THERE IS NOT A COPY OF THE NOTICE ACCOMPANYING
                THIS CONSENT FORM, PLEASE ASK FOR ONE. WE ENCOURAGE YOU TO READ IT SINCE IT PROVIDES DETAILS ON HOW INFORMATION ABOUT YOU MAY
                BE USED AND/OR DISCLOSED AND DESCRIBES CERTAIN RIGHTS YOU HAVE REGARDING YOUR HEALTH CARE INFORMATION.
                AS STATED IN OUR NOTICE OF PRIVACY PRACTICES, WE RESERVE THE RIGHT TO CHANGE OUR PRIVACY PRACTICES. IF WE SHOULD DO SO, WE WILL
                ISSUE A REVISED NOTICE. SINCE REVISIONS MAY APPLY TO YOUR HEALTH CARE INFORMATION, YOU HAVE THE RIGHT TO RECEIVE A COPY BY
                CONTACTING OUR PRIVACY OFFICER.
                YOU HAVE THE RIGHT TO REVOKE YOUR CONSENT BY GIVING WRITTEN NOTICE TO OUR PRIVACY OFFICER. THE REVOCATION WILL NOT AFFECT
                ACTIONS THAT WERE ALREADY TAKEN IN RELIANCE UPON THIS CONSENT. YOU SHOULD ALSO UNDERSTAND THAT IF YOU REVOKE THIS CONSENT WE
                MAY DECLINE TO TREAT YOU.
                <br />
                YOU ARE ENTITLED TO A COPY OF THIS CONSENT FORM AFTER YOU HAVE SIGNED IT.
            </div>
            <br />
            <b>YOU CAN CLOSE THIS TAB AFTER VIEWING AND GO BACK TO FORM</b>
        </div>
    );
};

export default ConsentForm1;
