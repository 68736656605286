import React, { useState, useEffect } from 'react';
import { Tabs, Skeleton } from "antd";
import { moduleConfiguration } from "../services/configService";
import { moduleScreenConfiguration, getModuleComponents } from "./moduleScreenCodes";

function DefaultModule(props) {
    const [loading, setLoading] = useState(true);
    const [tabConfig, setTabConfig] = useState([]);
    const [screenConfiguration, setScreenConfiguration] = useState(moduleScreenConfiguration);

    useEffect(() => {
        setLoading(true);
        const moduleReqData = props.moduleCode;
        async function fetchModuleConfig(moduleReqData) {
            try {
                setScreenConfiguration(moduleScreenConfiguration);
                const response = await moduleConfiguration(moduleReqData);
                
                setTabConfig(getModuleComponents(response, screenConfiguration));
            } catch (error) {
                console.error("Error fetching ModuleConfig:", error);
            } finally {
                setLoading(false);
            }
        }

        fetchModuleConfig(moduleReqData);
    }, [screenConfiguration]);

    const MyTabsComponent = () => {
        const defaultActiveKey = tabConfig.find(tab => tab.isActive)?.screenCode;

        return (
            <Tabs defaultActiveKey={defaultActiveKey}>
                {tabConfig.map((tab) => (
                    <Tabs.TabPane
                        key={tab.screenCode}
                        tab={
                            <span>
                                {tab.icon} {tab.label}
                            </span>
                        }
                    >
                        {tab.content}
                    </Tabs.TabPane>
                ))}

            </Tabs>
        );
    };

    return (
        <div>
            {/* <Spin spinning={loading}> */}
            <Skeleton active loading={loading} paragraph={{ rows: 10 }}>
                <MyTabsComponent />
            </Skeleton>
            {/* </Spin> */}
        </div >
    );


}

export default DefaultModule;
