import React, { useState, useEffect } from 'react';
import { Modal, Skeleton } from 'antd';
import { fetchData, handleCancel } from './practiceLocationHandler';
import { PracticeLocationGridColumns } from './practiceLocationGridColumns';
import { ProviderGridColumns } from '../provider/providerGridColumns';
import "../../../assets/styles/practiceLocation.css"
import { providerLocation } from '../../services/PracticeLocationService';
import Grid from '../grid/newGrid';
import handleEditProvider from "../../components/provider/provider"
import { screenActionCodes, actionPermission } from '../../utils/moduleScreenCodes'
import { columnVisibility } from '../../../utils/utility';
import { CheckOutlined } from '@ant-design/icons';
import { PracticeLocationSave } from './practiceLocationSave';
import { handleModalConfirm } from "../../components/practiceLocation/practiceLocationHandler";

function PracticeLocationDefault(props) {
    const [practiceLocations, setLocationData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [isEditVisible, setEditVisible] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const ProvidercolumnData = { ...ProviderGridColumns };
    const [keytype, setkeytype] = useState(null);
    const [isCreateVisible, setCreateVisible] = useState(false);

    const [modalData, setModalData] = useState({
        visible: false,
        type: 'delete',
    });

    ProvidercolumnData.columns = ProvidercolumnData.columns.filter(column => column.key !== 'action' && column.dataIndex !== 'providerid');
    useEffect(() => {
        fetchData(setLocationData, setLoading);
    }, []);
    

    const handleLinkClick = (provider) => {
        setSelectedProvider(provider);
        setVisible(true);
        setEditVisible(false);
    };

    const handleCloseModal = () => {
        setSelectedProvider(null);
        setVisible(false);
        setEditVisible(false);
    };

    const handleEdit = (location) => {
        setSelectedLocation(location);
        setVisible(true);
        setEditVisible(true);
        setCreateVisible(false);
        setkeytype('update');
    };

    const handleCreate = () => {
        setVisible(true);
        setEditVisible(false);
        setCreateVisible(true);
        setkeytype('create');
    };

    // const handleLinkEdit = async (locationid) => {
    //     try {
    //         let locationProvider = []
    //         const response = await providerLocation(locationid);
    //         console.log("locationId",locationid);
    //         if (response && response.provider) {
    //             setSelectedProvider(response.provider[0]);
    //         }
    //         handleLinkClick(locationProvider); // Set the selected provider in the state            
    //     } catch (error) {
    //         console.error("Error fetching provider details:", error);
    //     }
    // };

    const handleLinkEdit = async (locationid) => {
        try {
            const response = await providerLocation(locationid);
            if (response && response.provider) {
                setSelectedProvider(response[0]);
            }
            else {
                setSelectedProvider([]);
            }
            handleLinkClick(response);          
        } catch (error) {
            console.error("Error fetching provider details:", error);
        }
    };
    

    const handleLinkEditPracticeLocation = (record) => {
        handleLinkEdit(record.practiceLocationid)
    };

    const handleDelete = (record) => {
        setModalData({
            ...modalData,
            visible: true,
            record: record,
        });
    };
    const practiceLocationColumns = PracticeLocationGridColumns.columns.filter(column => column.key !== 'provider');
    /* HIDE/SHOW columns based on Given array with PERMISSION */
    const hideColumns = ["action"];
    if (!actionPermission(props, screenActionCodes.CONF_PRAC_LOC_EDIT) && !actionPermission(props, screenActionCodes.CONF_PRAC_LOC_DELETE)) {
        columnVisibility(PracticeLocationGridColumns, hideColumns, true);
    }
    return (
        <div>
            {/* <Spin spinning={loading}> */}
            <Skeleton active loading={loading} paragraph={{ rows: 10 }}>

                <div>
                    {!visible &&
                        <div>
                            {/* <div>
                                <Button icon={<CheckOutlined />} type="primary" id="Crete" onClick={handleCreate}>Create Location</Button>
                            </div> */}
                            <div>
                                <Grid
                                    columnData={{ ...PracticeLocationGridColumns, columns: practiceLocationColumns }}
                                    tableData={practiceLocations}
                                    setTableData={setLocationData}
                                    initialTableData={practiceLocations}
                                    csvFileName="PracticeLocationList"
                                    searchButton={true}
                                    downloadCSV={true}
                                    showEdit={actionPermission(props, screenActionCodes.CONF_PRAC_LOC_EDIT)}
                                    showDelete={actionPermission(props, screenActionCodes.CONF_PRAC_LOC_DELETE)}
                                    handleEdit={handleEdit}
                                    handleDelete={handleDelete}
                                    handleLinkEdit={handleLinkEditPracticeLocation}
                                    createButton={true}
                                    createButtonName={"Create Location"}
                                    createButtonIcon={<CheckOutlined />}
                                    handleCreate={handleCreate}
                                />
                            </div>
                        </div>
                    }

                    <Modal
                        title={modalData.type === 'delete' ? 'Delete Appointment Type' : ''}
                        open={modalData.visible}
                        onOk={() => handleModalConfirm(modalData, fetchData, setLocationData, setLoading, setModalData)}
                        onCancel={() => setModalData({ ...modalData, visible: false })}
                        okText="Delete"
                        cancelText="Cancel"
                        okButtonProps={{ danger: true }}
                    >
                        {modalData.type === 'delete' && modalData.record ? (
                            <p>
                                Are you sure you want to delete the practice location
                                <span className='deletedynamicName'>
                                    "{modalData.record.locationName
                                    }"
                                </span> ?
                            </p>
                        ) : null}
                    </Modal>

                    <div className="">
                        {visible && (
                            isCreateVisible ? (
                                <PracticeLocationSave
                                    practiceLocations={selectedLocation}
                                    onCancel={() => handleCancel(setSelectedLocation, setSelectedProvider, setVisible)}
                                    opType={keytype}
                                />
                            )
                                : isEditVisible ? (
                                    // Render UpdatePracticeLocation component when selectedLocation is not null
                                    <PracticeLocationSave
                                        practiceLocations={selectedLocation}
                                        onCancel={() => handleCancel(setSelectedLocation, setSelectedProvider, setVisible)}
                                        opType={keytype}
                                    />

                                ) :
                                    (
                                        <Modal
                                            title="List of provider"
                                            open={visible}
                                            onCancel={handleCloseModal}
                                            width={900}
                                            height={400}
                                            marginTop={400}
                                            footer={null}
                                            className="providerGrid"

                                        // closeIcon={<Button onClick={handleCloseModal} shape="circle" icon={<CloseOutlined />} />} // Custom close button at the top
                                        >
                                            <Grid
                                                columnData={ProvidercolumnData}
                                                tableData={selectedProvider}
                                                setTableData={setSelectedProvider}
                                                initialTableData={selectedProvider}
                                                csvFileName="ProviderList"
                                                searchButton={true}
                                                downloadCSV={false}
                                                showEdit={actionPermission(props, screenActionCodes.CONF_PRAC_LOC_EDIT)}
                                                showDelete={actionPermission(props, screenActionCodes.CONF_PRAC_LOC_DELETE)}
                                                handleEdit={handleEditProvider}
                                                handleDelete={handleEditProvider}
                                                createButton={false}
                                                createButtonName={false}
                                            />
                                        </Modal>
                                    )
                        )}
                    </div>
                </div>
            </Skeleton>
            {/* </Spin> */}
        </div>
    );
}

export default PracticeLocationDefault;
