import React from "react";
import { Input, Form, TimePicker } from "antd";
import TextArea from "antd/lib/input/TextArea";
import dayjs from 'dayjs';

const ScheduleAppointmentDetailsForm = ({ eventDetails }) => {
    const formatDateTime = (time) => time ? dayjs(time).format("MM/DD/YYYY hh:mm A") : null;

    const labelWrapperProps = {
        labelAlign: 'left',
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };

    return (
        <Form colon={false} direction="vertical" size="large" {...labelWrapperProps}>
            <Form.Item label="Patient Name">
                <Input value={eventDetails.patientName} placeholder="Enter Patient Name" disabled />
            </Form.Item>
            <Form.Item label="DOB">
                <Input value={eventDetails.patientDob} placeholder="YYYY-MM-DD" disabled />
            </Form.Item>
            <Form.Item label="Phone Number">
                <Input value={eventDetails.phoneNumber} placeholder="Enter Phone Number" pattern="[0-9]*" disabled />
            </Form.Item>
            <Form.Item label="Appt Start Time">
                <Input value={formatDateTime(eventDetails.appointmentTime)} disabled />
            </Form.Item>
            <Form.Item label="Appt End Time">
                <Input value={formatDateTime(eventDetails.endTime)} disabled />
            </Form.Item>
            <Form.Item label="Visit Type">
                <Input value={eventDetails.visitType} disabled />
            </Form.Item>
            <Form.Item label="Location">
                <Input value={eventDetails.appointmentLocation} disabled />
            </Form.Item>
            <Form.Item label="Reason for Visit">
                <Input value={eventDetails.reason} disabled />
            </Form.Item>
            <Form.Item label="Notes">
                <TextArea value={eventDetails.notes} type="textarea" disabled />
            </Form.Item>
        </Form>
    );
};

export default ScheduleAppointmentDetailsForm;
