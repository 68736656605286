import { connect } from "react-redux";
import axiosInterceptor from "../../middleware/axiosInterceptor";

export const fetchUserProfile = async (userId=null) => {
    if(!userId){
        userId = sessionStorage.getItem('userId');
    }
    
    const url = "/api/users/" + userId;
    try {
        const response = await axiosInterceptor.get(url);
        return response;
    } catch (error) {
        console.error(error);
    }
};


//Update method - edit profile 

export const updateShowProfile = async (updatedData) => {
    const url = "/api/user/update";
    try {
        const response = await axiosInterceptor.put(url, updatedData);
        return response;
    } catch (error) {
        console.error(error);
    }
};


