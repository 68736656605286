import axiosInterceptor from "../../middleware/axiosInterceptor";

export async function setPasswordService(formData) {
  const url = "/api/user/set-password";
  let response = null;

  try {
    response = await axiosInterceptor.post(url, formData);

  }
  catch (error) {
    console.error(error);
  }

  return response;
}

/* Reset Password */

export async function resetPasswordIntialService(token) {
  const url = "api/user/reset-password/load/" + token;
  let response = null;
  try {
    response = await axiosInterceptor.get(url);
  }
  catch (error) {
    console.error(error);
  }
  return response;
}

export async function resetPasswordSubmitService(formData) {
  const url = "api/user/reset-password/update";
  let response = null;
  try {
    response = await axiosInterceptor.post(url, formData);
  }
  catch (error) {
    console.error(error);
  }
  return response;
}

export async function resetPasswordSend(data) {
  const url = "api/user/reset-password/send/";
  let response = null;
  try {
    response = await axiosInterceptor.post(url, data);
  }
  catch (error) {
    console.error(error);
  }
  return response;
}