import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
    Form, Input, Select, Col, Row, Button, Tabs, TimePicker, Table, Tooltip,
    Space, Skeleton, Divider, Collapse, Icon, message
} from 'antd';
import { DeleteOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

import "../../../assets/styles/updateProvider.css"
import {
    fetchPopulateData, getPraticeLocationbyId, handleDeleteDOP, onSavePraticelocation,
    onUpdatePraticelocation
} from "../../components/practiceLocation/practiceLocationHandler";
import "../../../assets/styles/common.css"
import Configuration from "../../components/practice/configuration";
import { formatPhoneNumber } from "../utilities/utility";
import { CreateUserValidation } from "../../components/users/handlers/createUserValidation";
import { handleNumKeyDown } from "../utilities/utility";
import dayjs from 'dayjs';

const { Panel } = Collapse;
const { Option } = Select;
const { TabPane } = Tabs;

const PracticeLocationSave = ({ practiceLocations, onCancel, opType }) => {
    const [form] = Form.useForm();
    const [timeZones, setTimeZone] = useState('');
    const [holiday, setholiday] = useState('');
    const [country, setcountry] = useState('');
    const [loading, setLoading] = useState(true);
    const [daysOfOperation, setDaysOfOperation] = useState([]);
    const [operations, setOperations] = useState({
        day: '',
        fromTime: null,
        toTime: null
    });


    const [contacts, setContacts] = useState([]);
    const [contact, setContact] = useState();

    const initialState = {
        practiceId: "1",
        locationId: "",
        locationName: "",
        streetAddress: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        country: "",
        timeZone: "",
        holiday: [],
        dayOfOperation: [],
        phoneticName: "",
        pbxBandWidthPhone: "+12345622804",
        pbxPhone: "",
        alternatepbxPhone: "",
        workingHoursOptNumber: "",
        afterHoursOptNumber: "",
        startTime: "",
        endTime: "",
        createdBy: sessionStorage.getItem('userId'),
    };

    const requiredState = {
        locationId: "",
        locationName: "",
        streetAddress: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        country: "",
        timeZone: "",
    };

    const errMessage = "Field should not empty";
    const [formData, setFormData] = useState(initialState);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        fetchPopulateData(setTimeZone, setholiday, setcountry, setLoading);
        {
            if (practiceLocations != null) {
                getLocationField();
            };
        }
    }, []);

    // const getLocationField = async () => {
    //     const response = await getPraticeLocationbyId(form, practiceLocations.practiceLocationid,
    //         setFormData, setDaysOfOperation, setLoading);
    // };
    const getLocationField = async () => {
        setLoading(true);
        const response = await getPraticeLocationbyId(form, practiceLocations.practiceLocationid,
            setFormData, setDaysOfOperation, setLoading);
        if (response && response.data && response.data.success) {
            const practiceLocation = response.data.practiceLocations[0];
            const timePickerValues = {
                startTime: practiceLocation.startTime ? dayjs(practiceLocation.startTime, 'HH:mm') : null,
                endTime: practiceLocation.endTime ? dayjs(practiceLocation.endTime, 'HH:mm') : null,
            };
            setFormData(prevState => ({
                ...prevState,
                ...timePickerValues,
            }));
        }
        setLoading(false);
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const handlePhoneChange = (e) => {
        const { name, value } = e.target;
        const code = e.target.getAttribute('code');
        const formattedValue = formatPhoneNumber(value);
        setFormData({ ...formData, [name]: formattedValue });
        form.setFieldsValue({ [name]: formattedValue });
        setErrors({ ...errors, [name]: '' });
    };

    const paginationConfig = {
        pageSize: 5,
        showSizeChanger: false,
    };

    const handleAddAppointment = () => {
        if (operations.day && operations.fromTime && operations.toTime) {
            const isDuplicate = daysOfOperation.some(
                (item) =>
                    item.day === operations.day &&
                    item.fromTime === operations.fromTime &&
                    item.toTime === operations.toTime
            );

            if (isDuplicate) {
                message.error('This day and time range is already added.');
                return;
            }
            setStartTime(null);
            setEndTime(null);
            let tempDaysOfOperation = [...daysOfOperation];
            tempDaysOfOperation.push(operations);
            setDaysOfOperation(tempDaysOfOperation);
            setOperations({
                day: '',
                fromTime: null,
                toTime: null
            });
            form.setFieldsValue({
                day: '',
                fromTime: null,
                toTime: null
            });
        }
    };

    // const handleTimeChange = (time, timeString, name) => {
    //     setOperations({ ...operations, [name]: timeString });
    // };

    // const handleTimeChange = (time, timeString, name) => {
    //     setOperations(prevOperations => ({
    //         ...prevOperations,
    //         [name]: timeString
    //     }));
    // };
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [error, setError] = useState(false);

    const handleStartTimeChange = (time, timeString) => {

        if (endTime && time.isAfter(endTime)) {
            setError(true);
            setStartTime(null);
            message.error('Start time should be before end time');
        } else {
            setError(false);
            setStartTime(time);
        }
        setOperations(prevOperations => ({
            ...prevOperations,
            fromTime: timeString
        }));
    };

    const handleEndTimeChange = (time, timeString) => {
        if (startTime && time.isBefore(startTime)) {
            setError(true);
            setEndTime(null);
            message.error('End time should be after start time');
        } else {
            setError(false);
            setEndTime(time);
        }
        setOperations(prevOperations => ({
            ...prevOperations,
            toTime: timeString
        }));
    };


    const disabledTime = (current) => {
        if (!startTime || !endTime) {
            return null;
        }
        if (current && (current.isBefore(startTime) || current.isAfter(endTime))) {
            return {
                disabled: true,
            };
        }
        return null;
    };
    // const handlePBXTimeChange = (time, timeString, name) => {
    //     setFormData({ ...formData, [name]: timeString });
    // };
    const handlePBXTimeChange = (name, value, setFormData) => {
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleChange = (value) => {
        setFormData({ ...formData, holiday: value });
    };

    const columns = [
        {
            title: 'Action',
            key: 'action',
            render: (text, record, index) => (
                <Space>
                    <Tooltip title="Delete">
                        <Button type="primary" className="gridIconDelete" danger icon={<DeleteOutlined />}
                            onClick={() => handleDeleteDOP(index, daysOfOperation, setDaysOfOperation)} />
                    </Tooltip>
                </Space>
            ),
            width: 100,
        },
        {
            title: 'Day',
            dataIndex: 'day',
            key: 'day',
        },
        {
            title: 'From Time',
            dataIndex: 'fromTime',
            key: 'fromTime',
        },
        {
            title: 'To Time',
            dataIndex: 'toTime',
            key: 'toTime',
        },
    ];

    return (
        <div className="container-height">
            <Skeleton active loading={loading} paragraph={{ rows: 10 }}>
                <Tabs defaultActiveKey="1" type="card">
                    <TabPane tab="Practice Location" key="1">

                        <div className="update-provider-content">
                            <Form form={form} colon={false} layout="vertical" requiredMark="required">
                                <>
                                    <Row gutter={[16, 16]}>
                                        <Col span={8} className='form-group'>
                                            <Form.Item name="locationId" label="Location ID" className="form-item-margin"
                                                rules={[{ required: true, message: errMessage }]}>
                                                <Input name="locationId" id="locationId" value={formData.locationId} onChange={handleInputChange} className="input" autoFocus
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={8} className='form-group'>
                                            {/* <span>Location Name</span> */}
                                            <Form.Item label="Location Name" name="locationName" className="form-item-margin"
                                                rules={[{ required: true, message: errMessage }]}>

                                                <Input name="locationName" id="locationName" value={formData.locationName} onChange={handleInputChange} className="input"
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={8} className='form-group'>
                                            {/* <span>Street Address</span> */}
                                            <Form.Item label="Street Address" name="streetAddress" className="form-item-margin"
                                                rules={[{ required: true, message: errMessage }]}>
                                                <Input name="streetAddress" id="streetAddress" value={formData.streetAddress} onChange={handleInputChange} className="input"
                                                    rules={CreateUserValidation.streetaddress} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col span={8} className='form-group'>
                                            <Form.Item label="City" name="city" className="form-item-margin"
                                                rules={[{ required: true, message: errMessage }]}>
                                                <Input name="city" id="city" value={formData.city} onChange={handleInputChange} className="input"
                                                    rules={CreateUserValidation.streetaddress} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} className='form-group'>
                                            <Form.Item label="State" name="state" className="form-item-margin"
                                                rules={[{ required: true, message: errMessage }]}>
                                                <Input name="state" id="state" value={formData.state} onChange={handleInputChange} className="input"
                                                    rules={CreateUserValidation.streetaddress} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} className='form-group'>
                                            <Form.Item label="Zip" name="zip" className="form-item-margin"
                                                rules={[{ required: true, message: errMessage }]}>
                                                <Input name="zip" id="zip" value={formData.zip} onChange={handleInputChange}
                                                    className="input" maxLength={5} onKeyDown={handleNumKeyDown} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col span={8} className='form-group'>
                                            <Form.Item label="Phone" name="phone" className="form-item-margin"
                                                rules={[{ required: true, message: errMessage }]}>
                                                <Input name="phone" type="tel" maxLength={14} id="phone" code="PHCODE" className="input" placeholder='(999) 999-9999'
                                                    onChange={handlePhoneChange} value={formData.phone} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} className='form-group'>
                                            <Form.Item label="Country" name="country" className="form-item-margin"
                                                rules={[{ required: true, message: errMessage }]}>
                                                <Select defaultValue={formData.country} className="select-input input w-100" name="country"
                                                    placeholder="Select a country" value={formData.country}
                                                    onChange={(value) => setFormData({ ...formData, country: value })}>

                                                    {country && country.map(q => (
                                                        <Option key={q.countryid} value={q.countryid}>
                                                            {q.countryname}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} className='form-group'>
                                            <Form.Item label="Time Zone" name="timeZone" className="form-item-margin"
                                                rules={[{ required: true, message: errMessage }]}>
                                                <Select name="timeZone" placeholder="Select a time zone" id="timezone" className="select-input input w-100"
                                                    onChange={(value) => setFormData({ ...formData, timeZone: value })} value={formData.timeZone}>

                                                    {timeZones && timeZones.map(q => (
                                                        <Option key={q.timezoneid} value={q.timezoneid}>
                                                            {q.timezonedisplay}
                                                        </Option>
                                                    ))}

                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col span={24} className='form-group'>
                                            <Form.Item label="Holidays" name="holiday" className="form-item-margin">
                                                <Select mode="multiple" allowClear style={{ width: '100%' }} placeholder="Select a holidays"
                                                    onChange={handleChange} name="holiday" id="holiday" defaultValue={[]}
                                                    value={formData.holiday}>

                                                    {holiday && holiday.map(q => (
                                                        <Option key={q.holidayid} value={q.holidayid} name={q.holidayname}>
                                                            {q.holidayname}
                                                        </Option>
                                                    ))}

                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>

                                <Collapse>
                                    <Panel header="Days of Operation" key="1">
                                        <Row gutter={[16, 16]}>
                                            <Col span={6} className='form-group'>
                                                <Form.Item label="Day" name="day" className="form-item-margin"
                                                    rules={[{ required: false, message: errMessage }]}>
                                                    <Select placeholder="Please select a Day" className="select-input input w-100"
                                                        onChange={(value) => setOperations({ ...operations, day: value })}>
                                                        <Option value="Sunday">Sunday</Option>
                                                        <Option value="Monday">Monday</Option>
                                                        <Option value="Tuesday">Tuesday</Option>
                                                        <Option value="Wednesday">Wednesday</Option>
                                                        <Option value="Thursday">Thursday</Option>
                                                        <Option value="Friday">Friday</Option>
                                                        <Option value="Saturday">Saturday</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6} className='form-group'>
                                                <Form.Item label="From Time" name="fromTime" className="form-item-margin"
                                                    rules={[{ required: false, message: errMessage }]}>
                                                    <TimePicker use12Hours format="h:mm a" name="fromtime" id="fromtime" className="input" value={startTime} status={error ? 'error' : ''}
                                                        placeholder="Select Start Time"
                                                        disabledTime={disabledTime}
                                                        onChange={(time, timeString) => handleStartTimeChange(time, timeString, "fromTime")} inputReadOnly={true} ></TimePicker>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6} className='form-group'>
                                                <Form.Item label="To Time" name="toTime" className="form-item-margin"
                                                    rules={[{ required: false, message: errMessage }]}>
                                                    <TimePicker use12Hours format="h:mm a" name="toTime" id="toTime" className="input" value={endTime} status={error ? 'error' : ''}
                                                        placeholder="Select End Time"
                                                        style={{ marginLeft: 16 }}
                                                        disabledTime={disabledTime}
                                                        onChange={(time, timeString) => handleEndTimeChange(time, timeString, "toTime")} inputReadOnly={true}></TimePicker>
                                                </Form.Item>
                                                {error && <div style={{ color: 'red' }}>Please select valid start and end times.</div>}

                                            </Col>
                                            <Col span={6} className='form-group'>
                                                <Button type="primary" onClick={handleAddAppointment} style={{ marginLeft: 16, marginTop: 31 }}
                                                    disabled={!(operations && operations.day && operations.fromTime && operations.toTime)}
                                                // disabled={!operations.day || !operations.fromTime || !operations.toTime}
                                                // disabled={!operations?.day || !operations?.fromTime || !operations?.toTime}
                                                >
                                                    Add
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 16]}>
                                            <Table className='Table w-100' columns={columns} dataSource={daysOfOperation} rowKey="day"
                                                pagination={paginationConfig}
                                            />
                                        </Row>
                                    </Panel>
                                    <Panel header="Voice Bot" key="2">
                                        <Row gutter={[16, 16]}>
                                            <Col span={8} className='form-group'>
                                                <Form.Item label="Phonetic Name" name="phoneticName" className="form-item-margin">
                                                    <Input name="phoneticName" id="phoneticName" value={formData.phoneticName} onChange={handleInputChange} className="input" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8} className='form-group'>
                                                {opType === "create" && (
                                                    <Form.Item label="PBX Phone 1" name="pbxPhone" className="form-item-margin">
                                                        <Input name="pbxPhone" type="tel" maxLength={14} id="pbxPhone" className="input"
                                                            onChange={handlePhoneChange} value={formData.pbxPhone} placeholder='(999) 999-9999' /></Form.Item>
                                                )}
                                            </Col>
                                            <Col span={8} className='form-group'>
                                                {opType === "create" && (
                                                    <Form.Item label="PBX Phone 2" name="alternatepbxPhone" className="form-item-margin">
                                                        <Input name="alternatepbxPhone" id="alternatepbxPhone" type="tel" maxLength={14}
                                                            value={formData.alternatepbxPhone} onChange={handlePhoneChange} className="input"
                                                            placeholder='(999) 999-9999' />
                                                    </Form.Item>
                                                )}
                                            </Col>
                                        </Row>
                                    </Panel>
                                    <Panel header="Option Number" key="3">
                                        <Row gutter={[16, 16]}>
                                            <Col span={6} className='form-group'>
                                                <Form.Item label="Working Hours Opt Number" name="workingHoursOptNumber" className="form-item-margin">
                                                    <Input name="workingHoursOptNumber" code="WRKHRSCODE" type="tel" maxLength={14} id="workingHoursOptNumber" className="input"
                                                        onChange={handlePhoneChange} value={formData.workingHoursOptNumber} placeholder='(999) 999-9999' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6} className='form-group'>
                                                <Form.Item label="After Hours Opt Number" name="afterHoursOptNumber" className="form-item-margin">
                                                    <Input name="afterHoursOptNumber" code="AFTHRSCODE" type="tel" maxLength={14} id="afterHoursOptNumber" className="input"
                                                        onChange={handlePhoneChange} value={formData.afterHoursOptNumber} placeholder='(999) 999-9999' /></Form.Item>
                                            </Col>
                                            <Col span={6} className='form-group'>
                                                {/* <Form.Item label="Off hour Start Time" name="startTime" className="form-item-margin">
                                                    <TimePicker format="HH:mm" name="startTime" id="startTime" className="input"
                                                        value={moment(formData.startTime, "HH:mm")}
                                                        onChange={(time, timeString) => handlePBXTimeChange(time, timeString, "startTime")} ></TimePicker>
                                                </Form.Item> */}
                                                <Form.Item label="Off hour Start Time">
                                                    <TimePicker
                                                        format="HH:mm"
                                                        value={formData.startTime} id="startTime"
                                                        onChange={time => handlePBXTimeChange('startTime', time, setFormData)}
                                                    />
                                                </Form.Item>

                                            </Col>
                                            {/* <Col span={6} className='form-group'>
                                                <Form.Item label="Off hour End Time" name="endTime" className="form-item-margin">
                                                    <TimePicker format="HH:mm" name="endTime" id="endTime" className="input"
                                                        value={moment(formData.endTime, "HH:mm")}
                                                        onChange={(time, timeString) => handlePBXTimeChange(time, timeString, "endTime")
                                                        } ></TimePicker>
                                                </Form.Item>
                                            </Col> */}
                                            <Col span={6} className='form-group'>
                                                <Form.Item label="Off hour End Time">
                                                    <TimePicker
                                                        format="HH:mm"
                                                        value={formData.endTime} id="endTime"
                                                        onChange={time => handlePBXTimeChange('endTime', time, setFormData)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                                {/* </fieldset> */}
                                <div className="button-footer">

                                    {(opType === "add" || opType === "create") && (
                                        <Button htmlType='submit' icon={<CheckOutlined />} type="primary" id="Add"
                                            onClick={async () => {
                                                const success = await onSavePraticelocation(
                                                    form, formData, daysOfOperation, requiredState, initialState, setFormData, setDaysOfOperation,
                                                    setOperations, setErrors
                                                );
                                                if (success) {
                                                    onCancel(); // Call onCancel after saving
                                                }
                                            }}> Add Practice Location </Button>
                                    )}
                                    {opType === "update" && (
                                        <Button htmlType='submit' type="primary" id="Update" icon={<CheckOutlined />}
                                            onClick={async () => {
                                                const success = await onUpdatePraticelocation(
                                                    form, practiceLocations.practiceLocationid, formData, daysOfOperation, requiredState,
                                                    initialState, setFormData, setDaysOfOperation, setOperations, setErrors);
                                                if (success) {
                                                    onCancel(); // Call onCancel after updating
                                                }
                                            }}>Update Practice Location</Button>
                                    )}

                                    <Button onClick={onCancel} icon={<CloseOutlined />} id="Cancel">Cancel</Button>
                                </div>
                            </Form>
                        </div>

                    </TabPane>
                    {opType === "update" && (
                        <TabPane tab="Configuration" key="2">
                            <Configuration level={"LOCATION"} practiceId={1} providerId={null}
                                locationId={practiceLocations.practiceLocationid} />
                        </TabPane>
                    )}
                </Tabs>
            </Skeleton>
        </div >
    );
};

export { PracticeLocationSave };
