//#region Packages
import React, { useState, useEffect, useRef } from 'react';
import { Modal, Drawer, Skeleton } from 'antd';
import { PlusOutlined } from '@ant-design/icons'; // Import Drawer component
import Grid from '../grid/newGrid';
import "../../../assets/styles/userList.css"
import UpdateUser from './updateUser';
import { getData, handleModalConfirm, handleModalCancel } from './handlers/userHandler';
import { columnData } from './handlers/userGridColumns';
import { columnVisibility } from '../../../utils/utility';
import { screenActionCodes, actionPermission } from '../../utils/moduleScreenCodes';
import { handleRefresh } from '../grid/newGridHandler';
import CreateUser from './createUser';
function UsersDefault(props) {
    const effectRan = useRef(false);

    // const handleCloseDrawer = (type) => {
    //     setDrawerVisible(prevState => ({
    //         ...prevState, [type]: ![type]
    //     }));
    // };

    const handleCreateDrawer = () => {
        setDrawerVisible(prevState => ({
            ...prevState, createDrawer: !prevState.createDrawer
        }));
    };
    const handleEditDrawer = () => {
        setDrawerVisible(prevState => ({
            ...prevState, updateDrawer: !prevState.updateDrawer
        }));
    };

    const [drawerVisible, setDrawerVisible] = useState({ createDrawer: false, updateDrawer: false });
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userId, setUserId] = useState(null);
    const [modalData, setModalData] = useState({ type: null, visible: false, user: null });
    const [userData, setUserData] = useState([]);
    const [deletedUserName, setDeletedUserName] = useState('');
    const [loading, setLoading] = useState(true);
    const [registerCardProps, setRegisterCardProps] = useState(false);

    useEffect(() => {
        if (!effectRan.current) {
            getData(setLoading, setUserData, () => {
                // Handle refresh or any other actions after data update
                // handleCloseDrawer();
                handleCreateDrawer();
                handleEditDrawer();
                // setRefreshData(false);  // Reset refreshData after handling the refresh
            });
        }

        return () => effectRan.current = true;
    }, []);

    const handleEditUser = (record) => {
        setSelectedUser(record);
        setUserId(record.userId);
        handleEditDrawer();
    };

    // const handleDeleteUser = (record) => {
    //     handleDelete(record.userId, setModalData);
    // };
    const handleDeleteUser = (record) => {
        setModalData({
            type: 'delete',
            visible: true,
            user: {
                userId: record.userId,
                loggedInUserId: sessionStorage.getItem('userId'),
                username: record.username
            }
        });
    };


    /* HIDE/SHOW columns based on Given array with PERMISSION */
    const hideColumns = ["action"];
    if (!actionPermission(props, screenActionCodes.CONF_USER_EDIT) && !actionPermission(props, screenActionCodes.CONF_USER_DELETE)) {
        columnVisibility(columnData, hideColumns, true);
    }

    const handleCreate = () => {
        handleCreateDrawer();
    };

    return (
        <div>
            {/* <Spin spinning={loading}> */}
            <Skeleton active loading={loading} paragraph={{ rows: 10 }}>
                <Grid
                    columnData={columnData}
                    tableData={userData}
                    setTableData={setUserData}
                    createButton={actionPermission(props, screenActionCodes.CONF_USER_ADD)}
                    initialTableData={userData}
                    csvFileName="UserList"
                    searchButton={true}
                    downloadCSV={true}
                    showEdit={actionPermission(props, screenActionCodes.CONF_USER_EDIT)}
                    showDelete={actionPermission(props, screenActionCodes.CONF_USER_DELETE)}
                    handleEdit={handleEditUser}
                    handleDelete={handleDeleteUser}
                    createButtonIcon={<PlusOutlined />}
                    createButtonName={"Create User"}
                    handleCreate={handleCreate}
                />
                {/* <Modal
                    title={modalData.type === 'delete'}
                    open={modalData.visible}
                    onOk={() => { handleModalConfirm(modalData, setDeletedUserName, setLoading, setUserData, setModalData) }}
                    onCancel={() => { handleModalCancel(setModalData, modalData) }}
                    okText="Delete"
                    cancelText="Cancel"
                    className='deletemodel'
                    okButtonProps={{ danger: modalData.type === 'delete' }}
                    footer={[
                        <div key="buttons" className='Buttons'>
                            <div className='btnCancel'>
                                <Button key="back" onClick={() => { handleModalCancel(setModalData, modalData) }}>
                                    Cancel
                                </Button>
                            </div>
                            <div className='btnDelete'>
                                <Button key="submit" type="primary" danger={modalData.type === 'delete'} onClick={() => { handleModalConfirm(modalData, setDeletedUserName, setLoading, setUserData, setModalData) }}>
                                    Delete
                                </Button>
                            </div>
                        </div>
                    ]}
                >
                    {modalData.type === 'delete' ? (
                        <p>Are you sure you want to delete this user?</p>
                    ) : (
                        modalData.user && <UpdateUser user={modalData.user} onCancel={handleModalCancel} />
                    )}
                </Modal> */}
                <Modal
                    title={modalData.type === 'delete' ? 'Delete User' : ''}
                    open={modalData.visible}
                    onOk={() => { handleModalConfirm(modalData, setDeletedUserName, setLoading, setUserData, setModalData) }}
                    onCancel={() => { handleModalCancel(setModalData, modalData) }}
                    okText="Delete"
                    cancelText="Cancel"
                    className='deletemodel'
                    okButtonProps={{ danger: true }}
                >
                    {modalData.type === 'delete' && modalData.user ? (
                        <p>
                            Are you sure you want to delete the user
                            <span className='deletedynamicName'>
                                "{modalData.user.username}"
                            </span> ?
                        </p>
                    ) : null}
                </Modal>

                <Drawer
                    title="Update user"
                    open={drawerVisible.updateDrawer}
                    onClose={() => { setDrawerVisible("updateDrawer"); setSelectedUser(false) }}
                    maskClosable={false}
                >
                    {selectedUser && (
                        // <UpdateUser keyType="user"
                        //     userData={selectedUser}
                        //     userId={userId}
                        //     onCancel={() => setDrawerVisible(false)} // Close drawer on cancel
                        //     onUpdateSuccess={handleUpdateSuccess(setLoading, setUserData, () => setDrawerVisible(false))} // Close drawer on update success
                        //     visible={drawerVisible}
                        // />

                        <UpdateUser keyType="user"
                            userUpdateData={userData}
                            setUserUpdateData={setUserData}
                            userId={userId}
                            onCancel={() => handleEditDrawer()}
                            handleCloseDrawer={handleEditDrawer}
                            drawerVisible={drawerVisible}
                            setLoading={setLoading}
                            setEditModalVisible={setEditModalVisible}
                            setDrawerVisible={setDrawerVisible}
                        />
                    )}
                </Drawer>
                <CreateUser
                    drawerVisible={drawerVisible.createDrawer}
                    setDrawerVisible={setDrawerVisible}
                    handleRefresh={handleRefresh}
                    registerCardProps={registerCardProps}
                    tableDataInternal={userData}
                    setTableDataInternal={setUserData}
                />
            </Skeleton>
            {/* </Spin> */}
        </div>
    );
};

export default UsersDefault;
