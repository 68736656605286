// Modify your formatPhoneNumber function to accept an input value and format it dynamically
export function formatPhoneNumber(value) {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
        // Adjust the format based on the length of the input value
        let formattedValue = '';
        if (match[1]) {
            formattedValue += '(' + match[1];
        }
        if (match[2]) {
            formattedValue += ') ' + match[2];
        }
        if (match[3]) {
            formattedValue += '-' + match[3];
        }
        return formattedValue;
    }
    return value;
};

export function checkEmpty(value) {
    if (value === null || value === undefined || value === ""
        || (typeof (value) === Object && value == {})
    ) {
        return true;
    }
    else {
        return false;
    }
}

/*
* Common function to HIDE/SHOW columns based on Given array
* Match 'colKeysToMatch' array to given 'defaultColumns' and set column.hidden property to true/false
* Return defaultColumns.
*/
export function columnVisibility(defaultColumns, colKeysToMatch, shouldHide) {
    const updatedColumns = defaultColumns.columns.map(column => {
        if (colKeysToMatch.includes(column.key)) {
            // Update the hidden property to 'true'
            column.hidden = shouldHide;
        }
        return column;
    });

    if (updatedColumns.length > 0) {
        // Clear the existing array and push the updated columns only if there are values
        defaultColumns.length = 0;
        Array.prototype.push.apply(defaultColumns, updatedColumns);
    }

    return defaultColumns;
}

