import React, { useState, useEffect } from 'react';
import { Select, Input, Button, Form, Tabs, Menu, message } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { getConfigurations, saveConfigurations } from '../../services/configurationService';
import "../../../assets/styles/configuration.css";
import AppointmentType from '../practice/appointmentType';
import ProviderPage from '../practice/ProviderPage';

const { Option } = Select;
const { TabPane } = Tabs;
const { SubMenu } = Menu;

const componentMappings = {
  CONFIG_APP_TYPE: AppointmentType,
  PAGE_PROVIDER: ProviderPage
  // Add other component mappings as needed.
};

const ConfigDetail = () => {
  const [configurations, setConfigurations] = useState([]);
  const [configurationresponse, setConfigurationresponse] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [selectedComponent, setSelectedComponent] = useState("");
  const [advancedTableData, setAdvancedTableData] = useState([]);

  useEffect(() => {
    fetchConfigurations();
  }, []);

  const fetchConfigurations = async () => {
    try {
      const body = {
        level: "PRACTICE",
        practiceId: 1,
        providerId: null,
        practiceLocationId: null
      };

      const response = await getConfigurations(body);
      setConfigurations(response);

      const initialValues = {};
      response.forEach((config) => {
        initialValues[config.keyName] = JSON.parse(config.value)[0];
      });
      setInputValues(initialValues);
    } catch (error) {
      console.error('Error fetching configurations:', error);
    }
  };

  const getComponentSelection = (compCode) => {
    const filteredConfig = configurations.filter(config => config.componentCode === compCode);
    setConfigurationresponse(filteredConfig);
  };

  const handleValueChange = (keyName, value) => {
    setInputValues((prev) => ({ ...prev, [keyName]: value }));
  };

  const handleComponentSelection = (value) => {
    setSelectedComponent(value);
    getComponentSelection(value);
  };

  const onFinishBasic = async () => {
    const Configurations = configurations.map((config) => {
      const inputValue = inputValues[config.keyName];
      const defaultValue = JSON.parse(config.defaultValue);
      let valueToUse = inputValue !== undefined ? inputValue : defaultValue;
      return {
        praticeId: config.practiceId,
        practicelocationid: config.practicelocationid,
        providerid: config.providerid,
        keyId: config.keyId,
        keyName: config.keyName,
        keyDescription: config.keyDescription,
        keyCode: config.keyCode,
        defaultValue: JSON.stringify(defaultValue),
        keyTypeName: config.keyTypeName,
        keyTypeCode: config.keyTypeCode,
        componentName: config.componentName,
        componentCode: config.componentCode,
        value: JSON.stringify({ valueToUse }),
        levelvalue: config.levelvalue,
      };
    });

    try {
      await saveConfigurations(Configurations);
      message.success('Basic configuration saved successfully');
    } catch (error) {
      message.error('Error saving basic configurations');
      console.error('Error saving basic configurations:', error);
    }
  };

  const handleSaveAppointmentType = (tableData) => {
    setAdvancedTableData(tableData);
  };

  const getKeyValueComponent = (key) => {
    const defaultValueArray = JSON.parse(key.defaultValue);

    if (key.keyTypeCode === "DRP") {
      return (
        <Select
          placeholder={`Select ${key.keyName}`}
          value={inputValues[key.keyName]}
          style={{ width: 200 }}
          onChange={(value) => handleValueChange(key.keyName, value)}
        >
          {defaultValueArray.map((option) => (
            <Option key={`${key.keyTypeCode}-${option}`} value={option}>
              {option}
            </Option>
          ))}
        </Select>
      );
    } else if (key.keyTypeCode === "TEXT") {
      return (
        <Input
          placeholder={`Enter ${key.keyName}`}
          value={inputValues[key.keyName]}
          onChange={(e) => handleValueChange(key.keyName, e.target.value)}
        />
      );
    } else if (key.keyTypeCode === "COMP") {
      const DynamicComponent = componentMappings[key.componentCode];
      if (DynamicComponent) {
        return <DynamicComponent onSendData={configurationresponse} />;
      } else {
        return <div>Component not found</div>;
      }
    } else {
      return <Input disabled />;
    }
  };

  const renderComponentMenu = () => {
    const compConfigurations = configurations.filter(config => config.keyTypeCode === "COMP");

    return (
      <Menu
        style={{ width: 200 }}
        onClick={({ key }) => handleComponentSelection(key)}
        selectedKeys={[selectedComponent]}
        mode="inline"
      >
        {compConfigurations.map((config) => (
          <Menu.Item key={config.componentCode}>
            {config.keyName}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const handleCancel = () => {
    setInputValues('');
    setConfigurationresponse('');
    setSelectedComponent('');
  };
  const renderSelectedComponent = () => {
    if (!selectedComponent) return null;
    const DynamicComponent = componentMappings[selectedComponent];
    return DynamicComponent ? <DynamicComponent key={selectedComponent} onSave={handleSaveAppointmentType} onconfigData={configurationresponse} /> : <div>Component not found</div>;
  };

  // Add this function to determine if the Advanced Configuration tab should be displayed
  const hasAdvancedConfigurations = () => {
    return configurations.some(config => config.keyTypeCode === "COMP" && componentMappings[config.componentCode]);
  };
  const hasBasicConfigurations = () => {
    return configurations.some(config => config.keyTypeCode === "DRP" || config.keyTypeCode === "TEXT");
  };

  return (
    <>
      <Tabs defaultActiveKey="1">
        {hasBasicConfigurations() && (
          <TabPane tab="Basic Configuration" key="1">
            <Form layout='vertical' className="practiceconfig-content">
              <div className="practice-content_sub">

                {configurations.map((config) => {
                  if (config.keyTypeCode !== "COMP") {
                    return (
                      <Form.Item label={config.keyName} key={config.keyId}>
                        {getKeyValueComponent(config)}
                      </Form.Item>
                    );
                  }
                  return null;
                })}
              </div>

            </Form>
            <div className="button-footer">
              <Button icon={<CheckOutlined />} htmlType="submit" onClick={onFinishBasic} type="primary">
                Save
              </Button>
              <Button icon={<CloseOutlined />} onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </TabPane>
        )}
        {hasAdvancedConfigurations() && (
          <TabPane tab="Advanced Configuration" key="2">
            <div style={{ display: 'flex' }}>
              <div>
                {renderComponentMenu()}
              </div>
              <Form layout='vertical' className="practiceconfig-content">
                {renderSelectedComponent()}
              </Form>
            </div>
          </TabPane>
        )}
      </Tabs>
    </>
  );
};

export default ConfigDetail;
