import { fetchRegisterDetails, registerUserDetails } from "../../../services/registerService"
import { Modal } from "antd";


export const handleStatusChange = (checked, form, setStatus) => {
  setStatus(checked);
  form.setFieldsValue({
    status: checked ? "Active" : "Inactive"
  });
};

export async function handledynamicFieldsData(value, additionalFields) {
  const dynamicFieldsMappedData = additionalFields.map((field) => {
    let processedValue;

    if (Array.isArray(value[field.name])) {
      processedValue = value[field.name].map((item) => ({ value: item }));
    } else {
      processedValue = [{ value: value[field.name] }];
    }

    return {
      datasecuritytypeid: field.datasecuritytypeid,
      name: field.name,
      type: field.type,
      code: field.code,
      required: field.required,
      value: field.value,
      config: field.config,
      userdata: processedValue,
    };
  });

  return dynamicFieldsMappedData;
}


export async function handleCloseDrawer(setDrawerVisible, form) {
  setDrawerVisible(false);
  form.resetFields();
}


export async function handleSubmit(values, status, selectedTimezone, selectedRole, setError, props, roles,
  additionalFields, setDrawerVisible, form, tableDataInternal, setTableDataInternal) {
  // setError("");
  const userId = sessionStorage.getItem('userId');
  try {
    let roleValue = values.role.value;
    const dynamicFieldsData = await handledynamicFieldsData(values, additionalFields);
    let formData = {
      email: values.email,
      firstname: values.firstname,
      lastname: values.lastname,
      username: values.username,
      timezone: selectedTimezone,
      role: [
        {
          roleid: roleValue,
          datasecuritytypes: dynamicFieldsData
        }
      ],
      createdby: userId,
    };
    const response = await registerUserDetails(formData);


   
    if (response && response.data && response.data.success) {
      Modal.success({ title: "Success", content: "User created successfully." });
      await handleCloseDrawer(setDrawerVisible, form);
      let users = tableDataInternal;
      users.push(response.data.users[0]);
      setTableDataInternal(users);
    }

    else {
      if (response && response.data && response.data.businessRules && response.data.businessRules.length > 0) {
        const firstError = response.data.businessRules[0].description;
        setError(firstError);
      } else {
        setError("Error Register");
      }
    }
  } catch (error) {
    setError("Error: Failed to submit");
  }
};
