import { Navigate } from "react-router-dom";
// import { useAuth } from "../../contexts/auth/useAuth";

export const ProtectedRoute = ({ children }) => {
    // const { user } = useAuth();
    const user = sessionStorage.getItem('userId');
   if (!user) {
        // user is not authenticated
        return <Navigate to="/unauthorised" />;
    }
    return children;
};
