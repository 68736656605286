import React from "react";
import ddcImage from '../../../../assets/images/ddc-logo.png'; 

const ConsentForm4 = () => {
    return (
        <div>
           <div class="form-row">
                <div class="form-group col-md-6">
                <img src={ddcImage} className='DDC-Image' alt="DDC logo" />
                </div>
                <div class="form-group col-md-2">
                    <h2><b>CONSENT FORM FOR ASSIGNMENT OF BENEFITS</b></h2>
                </div>
            </div>

            <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
            <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-validate/1.19.3/jquery.validate.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-validate/1.19.3/additional-methods.js"></script>

            <div>
            <br/>
            I hereby assign my insurance benefits to be paid directly to
            Northeast Primary Care for services rendered, by check made out and mailed to:
            <br/>
            <br/>
            <b/>
            Northeast Primary Care
            <br/>
            1991 Marcus Ave, Suite M200
            <br/>
            New Hyde Park, NY 11042
            <b/>
            <br/>
            <br/>
            If my current policy prohibits direct payment to this practice, I hereby also instruct and direct
            my insurance carrier to make out the check to me and Mail it to the above address for the
            expense benefits allowable.
            This agreement also will confirm that I will forward any payment made to me by my health
            insurance company to reference to the service rendered to me by these doctors.
            <br/>
            <br/>
            I authorize Northeast Primary Care to deposit checks made in my name.
            </div>
            <br/>
            <b>YOU CAN CLOSE THIS TAB AFTER VIEWING AND GO BACK TO FORM</b>
        </div>
    );
};

export default ConsentForm4;
