
import { message } from "antd";
import { fetchRegisterDetails } from "../../../services/registerService";
import { resetPasswordSend } from '../../../services/setPasswordService';
import { MESSAGES } from '../../utilities/mappingData';

// export const handleRoleChange = (value, setShowAdditionalFields, setSelectedRole, roles = [], setAdditionalFields) => {
//     value = value.key ? value.key : value;
//     let selectedRole = roles.find((role) => role.id == value);
//     // let selectedRole = roles.find((role) => role.id == value.key);
//     console.log("awerhuj", selectedRole);
//     setSelectedRole(selectedRole.id);

//     setAdditionalFields(selectedRole.datasecuritytype);

// };

export const handleRoleChange = (value, setShowAdditionalFields, setSelectedRole, roles = [], setAdditionalFields) => {
    value = value.key ? value.key : value;
    let selectedRole = roles.find((role) => role.id == value);
    setSelectedRole(selectedRole.id);
    if (selectedRole && selectedRole.datasecuritytype) {
        const filteredTypes = selectedRole.datasecuritytype.filter(item => item.type !== null);
        setAdditionalFields(filteredTypes);
    }
};


export const handleTimezoneChange = (value, setSelectedTimezone) => {
    setSelectedTimezone(value);
};

export const handleProviderChange = (value, setSelectedProvider) => {
    setSelectedProvider(value);
};

export const handleLocationChange = (value, setSelectedLocation) => {
    setSelectedLocation(value);
};

export async function handleResetPassword(userId, resetType, enteredPassword, setResetPasswordModalVisible) {
    try {
        const updatedBy = sessionStorage.getItem("userId");
        if (updatedBy !== null) {
            const data = {
                "userid": userId,
                "password": enteredPassword,
                "resettype": resetType,
                "updatedby": updatedBy
            }
            const response = await resetPasswordSend(data);
            if (response.data.success) {
                message.success(MESSAGES.MESSAGE_RESET_PASS_SENT);
                setResetPasswordModalVisible(false);
            }
            else {
                message.error(MESSAGES.RESPONSE_ERROR);
            }
        }
        else {
            message.error(MESSAGES.RESPONSE_ERROR);
        }
    } catch (error) {
        console.error("Error handleResetPassword:", error.message);
    }
};

export const handleResetPasswordCancel = (setResetPasswordModalVisible) => {
    setResetPasswordModalVisible(false);
};

export const handlePasswordOptionChange = (e, setPasswordOption) => {
    setPasswordOption(e.target.value);
};


export async function fetchPopulateData(setTimeZone, setPracticeLocations, setRoles) {
    try {
        const response = await fetchRegisterDetails();
        setTimeZone(response.data.timeZones);
        setPracticeLocations(response.data.practiceLocations);
        setRoles(response.data.roles);

        return response;
    } catch (error) {
        console.error("Error fetching security questions:", error.message);
        return null;
    }
}