import axiosInterceptor from "../../middleware/axiosInterceptor";
export async function practiceList() {
  const url = "/api/practices";

  try {
    const response = await axiosInterceptor.get(url);
    return response.data.practice;
  } catch (error) {
    console.error("Error in practiceList service:", error);
    throw error;
  }
}


export const updatePractice = async (formData) => {
  const url = "/api/practice/update";
  try {
    const response = await axiosInterceptor.put(url, formData);
    return response;
  } catch (error) {
    console.error(error);
  }
};
