import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Result } from 'antd';


export default function NotFound() {
    const navigate = useNavigate();

    function handleButtonClick() {
        navigate('/login');
    }

    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button type="primary" onClick={handleButtonClick}>Login</Button>}
        />
    );
};
