import React, { useState, useEffect } from 'react';
import { Steps, Button, message, Modal, DatePicker } from 'antd';
import LicenseUpload from './License_Upload';
import InsuranceUpload from './Insurance_Upload';
import InsuranceInformation from './Insurance-Information';
import PersonalInformation from './Personal_Information';
import ContactInformation from './Contact_Information';
import Pharmacy_Information from './Pharmacy_Information';
import PrimaryCarePhysician from './Primary_Care_Physician';
import Consents from './Consents';
import FinalPage from './Final_Page';
import ddcImage from '../../../assets/images/ddc-logo.png'; 
import "../../../assets/styles/stepForm.css";
import moment from 'moment';

const { Step } = Steps;

const steps = [
    {
        content: <LicenseUpload />,
    },
    {
        content: <InsuranceUpload />,
    },
    {
        content: <InsuranceInformation />,
    },
    {
        content: <PersonalInformation />,
    },
    {
        content: <ContactInformation />,
    },
    {
        content: <Pharmacy_Information />,
    },
    {
        content: <PrimaryCarePhysician />,
    },
    {
        content: <Consents />,
    },
    {
        content: <FinalPage />,
    },
];

const StepForm = () => {
    const [current, setCurrent] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [dateOfBirth, setDateOfBirth] = useState(null);

    useEffect(() => {
        setIsModalVisible(true);
    }, []);

    const handleDateChange = (date, dateString) => {
        setDateOfBirth(dateString);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handlesteps = (key) => {
        setCurrent(key);
    }

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const handleSubmit = () => {
        message.success('Form submitted successfully!');
    };

    return (
        <>
            <Modal
                title="Enter Patient Date of Birth"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{ disabled: !dateOfBirth }}
            >
                <DatePicker
                    onChange={handleDateChange}
                    format="YYYY-MM-DD"
                    placeholder="Select date of birth"
                />
            </Modal>

            <div className='DDC-Header'>
                <div >
                    <img src={ddcImage} className='DDC-Image' alt="DDC logo" />
                </div>
                <div>
                    <h1>PATIENT REGISTRATION FORM</h1>
                </div>
            </div>
            <div>
                <Steps current={current} onChange={handlesteps}>
                    {steps.map((item, index) => (
                        <Step key={index} />
                    ))}
                </Steps>
                <div className="steps-content">{steps[current].content}</div>
                <div className="steps-action">
                    {current < steps.length - 1 && (
                        <Button type="primary" onClick={() => next()}>
                            Next
                        </Button>
                    )}
                    {current === steps.length - 1 && (
                        <Button type="primary" onClick={handleSubmit}>
                            Submit
                        </Button>
                    )}
                    {current > 0 && (
                        <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                            Previous
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
};

export default StepForm;
