//#region Packages
import React from "react";
import Logo from "../../../assets/images/MoblieViewLogo.png";
import { UserOutlined } from "@ant-design/icons";
import {
    Button,
    Form,
    Input,
    Row,
    Col,
    notification,
    Image,
} from "antd";
//#endregion

//#region Local Imports
import "../../../assets/styles/forgotpassword.css";
import "../../../assets/styles/common.css";
//#endregion

function ForgotPassword({ onClose }) {
    const onSubmit = (values) => {
        
    };

    const warning = () => {
        notification.warning({
            message: "Please contact system administrator",
            duration: 10,
            placement: "topRight",
        });
    };
    return (
        <div>
            <Row justify="center">
                <Col className="divSideImage" xs={26} sm={14} md={14} lg={14} xl={14}>
                    {/* <Image preview={false} src={SideImage} width={700} /> */}
                </Col>
                <Col
                    className="divLoginContent"
                    xs={22}
                    sm={10}
                    md={10}
                    lg={10}
                    xl={10}
                >
                    <div className="row">
                        <div>
                            <Image preview={false} src={Logo} width={200} />
                        </div>
                    </div>
                    <Form onFinish={onSubmit} requiredMark="optional">
                        <div >
                            <h4 className="heading">
                                Provide valid username to reset password
                            </h4>
                        </div>
                        <div className="lblusername">
                            <Form.Item
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Username!",
                                    },
                                ]}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon" />} id="username" placeholder="Username" />
                            </Form.Item>
                            <div className="link">
                                <a onClick={onClose} className="go-back">

                                    Back to login
                                </a>
                                <a href="#" className="foforgotuser" onClick={warning}>
                                    Forgot username?
                                </a>
                            </div>
                        </div>
                        <div className="btnfosubmit">
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default ForgotPassword;
