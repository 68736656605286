//#region Packages
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Card, Checkbox, Select } from 'antd';
import { useNavigate } from "react-router-dom";
//#endregion

//#region Components
import TermsOfService from './terms';
//#endregion

//#region Local Imports
import "../../../assets/styles/setPassword.css";
import "../../../assets/styles/common.css";
import FoLogo from "../../../assets/images/fo-logo.png";

import {formatPhoneNumber,validatePassword} from '../utilities/utility';
import { setPasswordHandler, fetchSecurityQuestionsHandler, handleQuestionChange } from './handlers/setPasswordHandler';
//#endregion

const { Option } = Select;

function SetPassword() {
    const navigate = useNavigate();
    const tempUserId = sessionStorage.getItem('userId');
    const [error, setError] = useState("");
    const [terms, setTerms] = useState(false);
    const [securityQuestions, setSecurityQuestions] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [form] = Form.useForm();
    const [modalVisible, setModalVisible] = useState(false);

    // const onSubmit = async (values) => {
    //     await setPasswordHandler(tempUserId, values, setSelectedQuestion, navigate, form, setTerms);
    // };

    useEffect(() => {
        fetchSecurityQuestionsHandler(setSecurityQuestions);
    }, []);


    const isSubmitDisabled = () => {
        const values = form.getFieldsValue();
        const requiredFields = ['password', 'confirmpassword', 'question', 'answer', 'terms'];
        return requiredFields.some(field => !values[field]);
    };

    return (
        <div className="background-container-color">
            <Row className="divsetpasswordcontent" justify="center" align="middle">
                <Col className="form-col" xl={7} lg={14} md={16}>
                    <div className="guest-logo">
                        <a className="navbar-brand">
                            <img src={FoLogo} alt="Logo" className="logo" />
                        </a>
                    </div>
                    <Card className="set-password-card">
                        <div className="custom-card-content">
                            <Form form={form} colon={false} layout="vertical"
                                onFinish={(value) => {
                                    setPasswordHandler(tempUserId, value, selectedQuestion, navigate,
                                        form, setTerms, setError)
                                }} requiredMark="optional">
                                <div className="guest-box-msg">
                                    <p id="parahead">First time login to change password</p>
                                </div>

                                <Form.Item
                                    name="password"
                                    label="New password" className="form-item-margin"
                                    rules={[{
                                            required: true,
                                            message: 'Please enter your password!',
                                        },
                                        {
                                            validator: validatePassword,
                                        },
                                    ]}
                                >
                                    <Input.Password id="newpassword" />
                                </Form.Item>

                                <Form.Item
                                    name="confirmpassword"
                                    label="Confirm password" className="form-item-margin"
                                    dependencies={['password']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your password!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The new password that you entered do not match!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password id="confirmpassword" />
                                </Form.Item>

                                <Form.Item label="Security question" name="question" className="form-item-margin"
                                    rules={[{
                                        required: true,
                                        message: 'Please select a security question'
                                    }]}>
                                    <Select
                                        placeholder="Select a security question"
                                        id="securityquestion"
                                        onChange={(value) => handleQuestionChange(value, setSelectedQuestion, setError)}
                                        value={selectedQuestion}>
                                        {securityQuestions && securityQuestions.map((q) => (
                                            <Option key={q.id} value={q.id}>{q.phrase}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item label="Answer" name="answer" className="form-item-margin"
                                    rules={[{ required: true, message: 'Please enter your answer' }]}>
                                    <Input id="answer" />
                                </Form.Item>

                                <Form.Item
                                    name="phoneNumber"
                                    label="Phone"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your phone number',
                                        },
                                    ]}
                                    className="form-item-margin"
                                >
                                    <Input
                                        type="tel"
                                        maxLength={14}
                                        id="phonenumber"
                                        placeholder='(999) 999-9999'
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const formattedValue = formatPhoneNumber(value);
                                            form.setFieldsValue({ phoneNumber: formattedValue });
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className="form-item-margin"
                                    name="terms"
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please accept the terms of service',
                                        },
                                    ]}
                                >
                                    <Checkbox id="termsofservice" onChange={(e) => setTerms(e.target.checked)}>
                                        I have read and agree with the <a onClick={() => setModalVisible(true)}>terms of service</a>
                                    </Checkbox>
                                </Form.Item>

                                <div className="btnfosubmit">
                                    <Button type="primary" id="submit" className='btnsubmit' htmlType="submit" disabled={isSubmitDisabled()}>Submit</Button>
                                </div>
                            </Form>
                            <TermsOfService visible={modalVisible} onClose={() => setModalVisible(false)} />
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default SetPassword;
