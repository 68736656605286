import { useEffect } from 'react';
import { message, Modal } from "antd";
// import { fetchRegisterDetails } from "../../../services/registerService"
import { formatPhoneNumber } from '../../utilities/utility';
import { updateShowProfile } from '../../../services/showProfileService';
// import { handleUpdateSuccess } from "../../../components/users/handlers/userHandler"


export const formItemLayout = {
    labelAlign: 'left',
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
export const handleCancel = (setVisible) => {
    setVisible(false);
};

export async function handledynamicFieldsData(value, additionalFields) {
    if (!Array.isArray(additionalFields)) {
        console.error("Additional fields are not an array:", additionalFields);
        return []; // Return an empty array or handle the error as needed
    }

    const dynamicFieldsMappedData = additionalFields.map((field) => {
        let processedValue;

        if (Array.isArray(value[field.name])) {
            processedValue = value[field.name].map((item) => ({ value: item }));
        } else {
            processedValue = [{ value: value[field.name] }];
        }

        return {
            datasecuritytypeid: field.datasecuritytypeid,
            name: field.name,
            type: field.type,
            code: field.code,
            required: field.required,
            value: field.value,
            config: field.config,
            userdata: processedValue,
        };
    });
    return dynamicFieldsMappedData;
}

export async function handledynamicFieldsProfileData(value, additionalFields, userData) {

    if (!Array.isArray(additionalFields)) {
        console.error("Additional fields are not an array:", additionalFields);
        return [];
    }

    const dynamicFieldsMappedProfileData = additionalFields.map((field) => {
        let processedValue;

        if (Array.isArray(value[field.name])) {
            processedValue = value[field.name].map((item) => ({ value: item }));
        } else {
            processedValue = [{ value: value[field.name] }];
        }

        // Conditionally update userdata based on value.securitypin-confirm
        let userdata;

        if (field.name !== 'securitypin' || (value['securitypin-confirm'] === null) || (value['securitypin-confirm'] === "") || (value['securitypin-confirm'] === undefined)) {
            // Find userdata in userData.role.datasecuritytypes for fields other than 'securitypin' or  'securitypin-confirm' is null.
            const roleData = userData?.role?.datasecuritytypes?.find(data => data.name === field.name);
            userdata = roleData ? roleData.userdata : processedValue;
        }
        else if (field.name === 'securitypin' && value['securitypin-confirm'] !== null) {
            userdata = [{ value: value['securitypin-confirm'] }];
        }



        return {
            datasecuritytypeid: field.datasecuritytypeid,
            name: field.name,
            type: field.type,
            code: field.code,
            required: field.required,
            value: field.value,
            config: field.config,
            userdata: userdata,
        };
    });
    return dynamicFieldsMappedProfileData;
}

function isUser(user, userId) {
    return user.userId === userId;
}

export const handleUpdateUser = async (userData, profileData, userId, username, firstname, lastname,
    email, phone, status, securityPin, keyType, props, values, additionalFields,
    practicelocation, selectedRole, selectedPracticeLocation, selectedTimezone,
    setDrawerVisible, userUpdateData, setUserUpdateData, setEditModalVisible, setUserData,
    setLoading, setError, onCloseDrawer) => {

    try {
        const tempUserId = sessionStorage.getItem("userId");
        let updatedData;
        if (keyType === "user") {
            const dynamicFieldsData = await handledynamicFieldsData(
                values,
                additionalFields
            );
            let roleId = null;
            if (Array.isArray(selectedRole) && selectedRole.length > 0) {
                roleId = selectedRole[0];
            } else if (selectedRole && typeof selectedRole === 'object' && 'key' in selectedRole) {
                roleId = selectedRole.key;
            } else {
                console.error("Invalid selectedRole:", selectedRole);
            }


            updatedData = {
                userid: keyType === "profile" ? sessionStorage.getItem("userId") : userId,
                username: keyType === "profile" ? sessionStorage.getItem("userName") : username,
                firstname,
                lastname,
                email,
                timezone: selectedTimezone,
                phone: phone,
                role: keyType === "profile" ? [userData.role] :
                    [
                        {
                            roleid: roleId,
                            datasecuritytypes: dynamicFieldsData,
                        },
                    ],


                usertype: props.keyType,
                status,
                statustype: userData.statustype,
                updatedby: tempUserId,
            };
            const response = await updateShowProfile(updatedData);
            if (response && response.data && response.data.success) {
                if (keyType === "user") {
                    let latestUserList = userUpdateData;
                    let arrayIndex = latestUserList.findIndex((user) => isUser(user, updatedData.userid));
                    if (arrayIndex !== -1) {
                        latestUserList[arrayIndex].username = updatedData.username;
                        latestUserList[arrayIndex].firstname = updatedData.firstname;
                        latestUserList[arrayIndex].lastname = updatedData.lastname;
                        latestUserList[arrayIndex].email = updatedData.email;
                        latestUserList[arrayIndex].phone = updatedData.phone;
                        latestUserList[arrayIndex].role = updatedData.selectedRole;

                        setUserUpdateData(latestUserList);

                    }

                    // setEditModalVisible(false);
                }

                Modal.success({ title: "Success", content: "User updated successfully." });
                setDrawerVisible(false);
                // handleUpdateSuccess(setLoading, setUserData, setEditModalVisible, setDrawerVisible)(onCloseDrawer);
            } else {
                let errorMessage;
                if (
                    response &&
                    response.data &&
                    response.data.businessRules &&
                    response.data.businessRules.length > 0
                ) {
                    errorMessage = response.data.businessRules[0].description;
                } else {
                    errorMessage = "Update failed";
                }
                message.error(errorMessage);
            }
        }
        else {
            const dynamicFieldsMappedProfileData = await handledynamicFieldsProfileData(
                values,
                additionalFields,
                userData
            );
            updatedData = {
                userid: sessionStorage.getItem("userId"),
                username: sessionStorage.getItem("userName"),
                firstname,
                lastname,
                email,
                timezone: selectedTimezone,
                phone: phone,
                role:
                    [
                        {
                            roleid: sessionStorage.getItem("roleId"),
                            datasecuritytypes: dynamicFieldsMappedProfileData,
                        },
                    ],


                usertype: props.keyType,
                status,
                statustype: userData.statustype,
                updatedby: tempUserId,
            };
            const response = await updateShowProfile(updatedData);
            if (response && response.data && response.data.success) {
                Modal.success({ title: "Success", content: "Profile updated successfully." });
                setDrawerVisible(false);
            } else {
                let errorMessage;
                if (
                    response &&
                    response.data &&
                    response.data.businessRules &&
                    response.data.businessRules.length > 0
                ) {
                    errorMessage = response.data.businessRules[0].description;
                } else {
                    errorMessage = "Update failed";
                }
                message.error(errorMessage);
            }

        }

    } catch (error) {
        console.error("Error updating user data:", error);
    }
};


export function useInitializeUserData(userData, setUsername, setFirstname, setLastname, setEmail, setSelectedTimezone,
    setPhoneNumber, setSelectedRole, setSelectedPracticeLocation, setSecurityPin) {
    useEffect(() => {
        // Initialize state variables with values from userData if it's defined
        if (userData) {
            setUsername(userData?.username || '');
            setFirstname(userData.firstname || '');
            setLastname(userData.lastname || '');
            setEmail(userData.email || '');
            setSelectedTimezone(userData.timezone || '');
            setPhoneNumber(userData.phone || '');
            setSelectedRole(userData.roleid || '');
            setSelectedPracticeLocation(userData.practicelocation || '');
            setSecurityPin(userData.securitypin || '');
        }
    }, [userData, setUsername, setFirstname, setLastname, setEmail, setSelectedTimezone,
        setPhoneNumber, setSelectedRole, setSelectedPracticeLocation, setSecurityPin]);
}


export const generateRandomPassword = () => {
    const charset = "ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz123456789";
    let password = "";
    const uppercaseLetter = "ABCDEFGHJKMNPQRSTUVWXYZ";
    const lowercaseLetter = "abcdefghjkmnpqrstuvwxyz";
    const digit = "123456789";
    password += uppercaseLetter[Math.floor(Math.random() * uppercaseLetter.length)];
    password += lowercaseLetter[Math.floor(Math.random() * lowercaseLetter.length)];
    password += digit[Math.floor(Math.random() * digit.length)];

    const passwordLength = 10; // Define the length of the password
    for (let i = 3; i < passwordLength; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
    }

    password = password.split('').sort(() => Math.random() - 0.5).join('');

    //password = "best@123"; // I noticed that you're setting a fixed password here, you might want to remove this line if you want to generate a random password
    return password;
};

export const handleAutoGeneratePassword = (setGeneratedPassword) => {
    const password = generateRandomPassword();
    setGeneratedPassword(password);
};


export const handleCloseDrawerCancel = (keyType, setUsername, setFirstname, setLastname, setEmail, setSelectedTimezone,
    setPhoneNumber, setSelectedRole, setSelectedPracticeLocation, setSecurityPin, profileData, userData, setDrawerVisible, form) => {

        const formattedPhone = formatPhoneNumber(userData.phone);
    setPhoneNumber(formattedPhone);
    if (keyType === "profile") {
        form.setFieldsValue({
            username: userData.username || '',
            firstname: userData.firstname || '',
            lastname: userData.lastname || '',
            email: userData.email || '',
            timezone: userData.timezone || '',
            phone: formattedPhone || ''
        });
    } else if (keyType === "user") {
        setUsername(userData.username);
        setFirstname(userData.firstname);
        setLastname(userData.lastname);
        setEmail(userData.email);
        setSelectedTimezone(userData.timezone);
        setPhoneNumber(userData.phone);
        setSelectedRole(userData.roleid);
        setSelectedPracticeLocation(userData.practicelocation);
        setSecurityPin(userData.securitypin);
    }

    // Call the original handleCloseDrawer function
    setDrawerVisible(false);
};


