import React, { useState } from 'react';
import { Form, Input, Button, Table, DatePicker, Col, Row, Radio, Space, Tooltip, Divider, Flex, Layout } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import "../../assets/styles/CRM.css";

const CMSLastest = () => {
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const [data, setData] = useState([1]);
    const handleSubmit = () => {
        setData(prevData => [...prevData, {}]);
    };
    const columns = [
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space>
                    <Tooltip title="Delete">
                        <Button type="primary" className="gridIconDelete" danger icon={<DeleteOutlined />} />
                    </Tooltip>
                </Space>
            ),
        },
        {
            title: '24.A.Date(S) Of Service',
            children: [
                {
                    title: 'From',
                    dataIndex: 'from',
                    key: 'from',
                    render: (text, record, index) => (
                        <DatePicker
                            className="tblDatepicker"
                            style={{ width: '150px' }}
                            placeholder="From Date"
                            value={text}
                        />
                    ),
                },
                {
                    title: 'To',
                    dataIndex: 'todate',
                    key: 'todate',
                    render: (text, record, index) => (
                        <DatePicker
                            className="tblDatepicker"
                            style={{ width: '150px' }}
                            placeholder="To Date"
                        />
                    ),
                },
            ],
        },
        {
            title: 'B.Place Of Service',
            dataIndex: 'placeOfService',
            key: 'placeOfService',
            render: (text, record, index) => (
                <Input
                    style={{ width: "150px" }}
                    placeholder="Place of Service"
                    value={text}
                    onChange={(e) => handleChange(e, index, 'placeOfService')}
                />
            ),
        },
        {
            title: 'C.EMG',
            dataIndex: 'emg',
            key: 'emg',
            render: (text, record, index) => (
                <Input
                    placeholder="EMG"
                    style={{ width: "100px" }}
                    value={text}
                    onChange={(e) => handleChange(e, index, 'emg')}
                />
            ),
        },
        {
            title: 'D.Procedures,Services,Or Supplies,(explain unusual Circumstance)',
            children: [
                {
                    title: 'CPT/HCPCS',
                    dataIndex: 'cptHcpcs',
                    key: 'cptHcpcs',
                    render: (text, record, index) => (
                        <Input
                            placeholder="CPT/HCPCS"
                            value={text}
                            onChange={(e) => handleChange(e, index, 'cptHcpcs')}
                        />
                    ),
                },
                {
                    title: 'Modifier',
                    dataIndex: 'modifier',
                    key: 'modifier',
                    render: (text, record, index) => (
                        <Input
                            placeholder="Modifier"
                            value={text}
                            onChange={(e) => handleChange(e, index, 'modifier')}
                        />
                    ),
                },
            ]
        },
        {
            title: 'E.Diagnosis Pointer',
            dataIndex: 'diagnosisPointer',
            key: 'diagnosisPointer',
            render: (text, record, index) => (
                <Input
                    placeholder="Diagnosis Pointer"
                    value={text}
                    onChange={(e) => handleChange(e, index, 'diagnosisPointer')}
                />
            ),
        },
        {
            title: 'F.$ Charges',
            dataIndex: 'charges',
            key: 'charges',
            render: (text, record, index) => (
                <Input
                    placeholder="Charges"
                    value={text}
                    onChange={(e) => handleChange(e, index, 'charges')}
                />
            ),
        },
        {
            title: 'G.Days Or Units',
            dataIndex: 'daysOrUnits',
            key: 'daysOrUnits',
            render: (text, record, index) => (
                <Input
                    placeholder="Days or Units"
                    value={text}
                    onChange={(e) => handleChange(e, index, 'daysOrUnits')}
                />
            ),
        },
        {
            title: 'H.EPSDT Family Plan',
            dataIndex: 'epsdtFamilyPlan',
            key: 'epsdtFamilyPlan',
            render: (text, record, index) => (
                <Input
                    placeholder="EPSDT Family Plan"
                    value={text}
                    onChange={(e) => handleChange(e, index, 'epsdtFamilyPlan')}
                />
            ),
        },
        {
            title: 'I.ID. Qual',
            dataIndex: 'idQual',
            key: 'idQual',
            render: (text, record, index) => (
                <Input
                    placeholder="ID Qual"
                    value={text}
                    onChange={(e) => handleChange(e, index, 'idQual')}
                />
            ),
        },
        {
            title: 'J.Rendering Provider ID.#',
            dataIndex: 'providerId',
            key: 'providerId',
            render: (text, record, index) => (
                <Input
                    placeholder="Provider ID"
                    value={text}
                    onChange={(e) => handleChange(e, index, 'providerId')}
                />
            ),
        },
    ];

    const handleChange = (e, index, field) => {
        const newData = [...data];
        newData[index] = {
            ...newData[index],
            [field]: e.target.value,
        };
        setData(newData);
    };


    const { Header, Footer, Sider, Content } = Layout;
    const headerStyle = {
        textAlign: 'center',
        color: '#555050',
        height: 64,
        paddingInline: 48,
        lineHeight: '64px',
        backgroundColor: '#838fd821',
        fontWeight: 'bolder',
        fontSize: '20px',

    };
    const contentStyle = {
        textAlign: 'center',
        minHeight: 120,
        lineHeight: '120px',
        color: '#555050',
        backgroundColor: '#ffffff',
        padding: '25px 25px',
        paddingLeft: '55px',
        paddingRight: '55px'
    };

    const footerStyle = {
        textAlign: 'center',
        color: '#cccccc',
        backgroundColor: '#ffffff',
    };
    const layoutStyle = {
        // borderRadius: 8,
        // overflow: 'hidden',
        // width: 'calc(50% - 8px)',
        // maxWidth: 'calc(50% - 8px)',
    };

    return (
        <>
            <Flex gap="middle" wrap>
                <Layout style={layoutStyle}>
                    <Header style={headerStyle}>CMS-1500 FORM</Header>
                    <Content style={contentStyle}>
                        <Form
                            form={form}
                            layout="vertical">
                            <Row >
                                <Col className="bordered-col" span={15}>
                                    <Form.Item label="1." colon={false} >
                                        <Radio.Group className="radio-group-horizontal">
                                            <Row gutter={[8, 8]}>
                                                <Col span={6}><Radio value="medicare">MEDICARE</Radio></Col>
                                                <Col span={6}><Radio value="medicaid">MEDICAID</Radio></Col>
                                                <Col span={6}><Radio value="tricare">TRICARE</Radio></Col>
                                                <Col span={6}><Radio value="champva">CHAMPVA</Radio></Col>
                                                <Col span={6}><Radio value="groupHealth">GROUP HEALTH PLAN</Radio></Col>
                                                <Col span={6}><Radio value="feca">FECA BLK LUNG</Radio></Col>
                                                <Col span={6}><Radio value="other">OTHER</Radio></Col>
                                            </Row>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={9} className="bordered-col">
                                    <label>1a.Insured's I.d Number (For Program In Item 1)</label>
                                    <Form.Item label="" name="insured">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="bordered-row">
                                <Col span={9} className="bordered-col">
                                    <label>2.Patient's Name (Last Name, First Name, Middle Initial)</label>
                                    <Form.Item name="patientname">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={6} className="bordered-col">
                                    <label>3.Patient's Birth Date</label>
                                    <Form.Item label="" name="dob">
                                        <DatePicker style={{ width: '100%' }} />
                                    </Form.Item></Col>
                                <Col span={9} className="bordered-col"><Form.Item label="4.Insured's Name (Last Name, First Name, Middle Initial)" name="insuredName">
                                    <Input />
                                </Form.Item></Col>
                            </Row>
                            <Row className="bordered-row">
                                <Col span={9} className="bordered-col"> <Form.Item label="5.Patient Address (No., Street)" name="address">
                                    <TextArea rows={1} />
                                </Form.Item></Col>
                                <Col span={6} className="bordered-col">
                                    <Row span={24}  >
                                        <Form.Item label="6.Patient Relationship To Insured" name="patientRelationship">
                                            <Radio.Group>
                                                <Radio value="self">Self</Radio>
                                                <Radio value="spouse">Spouse</Radio>
                                                <Radio value="child">Child</Radio>
                                                <Radio value="other">Other</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Row>
                                </Col>
                                <Col span={9} className="bordered-col"> <Form.Item label="7.Insured's Address (No., Street)" name="insuredAddress">
                                    <TextArea rows={1} />
                                </Form.Item></Col>
                            </Row >

                            <Row className="bordered-row">
                                <Col span={9} >
                                    <Row span={24}>
                                        <Col span={18} className="bordered-col">
                                            <Form.Item label="City" name="insuredState">
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} className="bordered-col">
                                            <Form.Item label="State" name="insuredState">
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row span={24}>
                                        <Col span={12} className="bordered-col">
                                            <Form.Item label="Zip Code" name="insuredZipcode">
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} className="bordered-col">
                                            <Form.Item label="Telephone (Include Area Code)" name="insuredTelephone">
                                                <Input addonBefore="+91" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={6} className="bordered-col">
                                    <Form.Item label="8. Reserved For Nucc Use" name="NUCC">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={9} >
                                    <Row span={24}>
                                        <Col span={18} className="bordered-col">
                                            <Form.Item label="City" name="insuredState">
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} className="bordered-col">
                                            <Form.Item label="State" name="insuredState">
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row span={24}>
                                        <Col span={12} className="bordered-col">
                                            <Form.Item label="Zip Code" name="insuredZipcode">
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} className="bordered-col">
                                            <Form.Item label="Telephone (Include Area Code)" name="insuredTelephone">
                                                <Input addonBefore="+91" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="bordered-row" >
                                <Col span={9}>
                                    <Row className="bordered-row" >
                                        <Col span={24} className="bordered-col borderNone" >
                                            <Form.Item label="9.Other Insured's Name (Last Name, First Name, Middle Initial)" name="otherInsuredName">
                                                <Input />
                                            </Form.Item></Col>
                                        {/* </Row>
                        <Row gutter={[16, 16]} className="bordered-row" > */}
                                        <Col span={24} className="bordered-col borderNone" >
                                            <Form.Item label="a. Other Insured's Policy Or Group Number" name="otherInsuredPolicyNumber">
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        {/* </Row>
                        <Row gutter={[16, 16]} className="bordered-row" > */}
                                        <Col span={24} className="bordered-col borderNone" >
                                            <Form.Item label="b. Reserved For Nucc Use" name="reservedForNUCCUseB">
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        {/* </Row>
                        <Row gutter={[16, 16]} className="bordered-row" > */}
                                        <Col span={24} className="bordered-col borderNone" >
                                            <Form.Item label="c. Reserved For Nucc Use" name="reservedForNUCCUseC">
                                                <Input />
                                            </Form.Item></Col>
                                        {/* </Row>
                        <Row gutter={[16, 16]} className="bordered-row" > */}
                                        <Col span={24} className="bordered-col borderNone" >
                                            <Form.Item label="d. Insurance Plan Name Or Program Name" name="insurancePlanName">
                                                <Input />
                                            </Form.Item></Col>
                                    </Row>
                                </Col>
                                <Col span={6}>
                                    <Row className="bordered-row" >
                                        <Col span={24} className="bordered-col borderNone" style={{ padding: "6px 8px 25px 8px" }}>
                                            <Form.Item label="10.Is Patient's Condition Related To">


                                                <Col span={24} className="bordered-col padding-col borderNone">
                                                    <Form.Item label="A. Employment? (Current Or Previous)" name="employment" style={{ marginBottom: 0 }}>
                                                        <Radio.Group>
                                                            <Radio value="yes">Yes</Radio>
                                                            <Radio value="no">No</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24} className="bordered-col padding-col borderNone" >
                                                    <Form.Item label="B. Auto Accident?" name="autoAccident" style={{ marginBottom: 0 }}>
                                                        <Radio.Group>
                                                            <Radio value="yes">Yes</Radio>
                                                            <Radio value="no">No</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24} className="bordered-col padding-col borderNone" >
                                                    <Form.Item label="C. Other Accident?" name="otherAccident">
                                                        <Radio.Group>
                                                            <Radio value="yes">Yes</Radio>
                                                            <Radio value="no">No</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24} className="bordered-col borderNone" >
                                                    <Form.Item label="d.Claim Codes (Designated by NUCC) " name="additionalClaimInfo">
                                                        <Input style={{ width: '100%' }} className="input" />
                                                    </Form.Item></Col>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={9}>
                                    <Row className="bordered-row" >
                                        <Col span={24} className="bordered-col borderNone">
                                            <Form.Item label="11.Insured's Policy Group Or Feca Number" name="insuredPolicyNumber">
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} className="bordered-col borderNone">
                                            <Form.Item label="a. Insured's Date Of Birth" name="insuredDOB">
                                                <DatePicker style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} className="bordered-col borderNone">
                                            <Form.Item label="b. Other Claim Id (Designated By Nucc)" name="otherClaimID">
                                                <Input />
                                            </Form.Item></Col>
                                        <Col span={24} className="bordered-col borderNone">
                                            <Form.Item label="c. Insurance Plan Name Or Program Name" name="insurancePlanNameOrProgram">
                                                <Input />
                                            </Form.Item></Col>
                                        <Col span={24} className="bordered-col padding-col borderNone">
                                            <Form.Item label="d. Is There Another Health Benefit Plan?" name="anotherHealthBenefitPlan">
                                                <Radio.Group>
                                                    <Radio value="yes">Yes</Radio>
                                                    <Radio value="no">No</Radio>
                                                </Radio.Group>
                                                <label>( <b>If yes,</b> complete items 9, 9a and 9d )</label>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row >
                            <Row className="bordered-row">
                                <Col span={15} className="bordered-col"> <Form.Item label="12.PATIENT'S OR AUTHORIZED PERSON'S SIGNATURE. I authorize the release of any medical or other information necessary to process this claim.
I also request payment of government benefits either to myself or to the party who accepts assignment below" name="form" style={{ marginBottom: 0 }}>
                                </Form.Item>
                                    <Row gutter={16}>
                                        <Col xs={24} md={12} className='form-group'>
                                            <Form.Item label="Signed" name="signed" style={{ marginBottom: 0 }}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12} className='form-group'>
                                            <Form.Item label="Date" name="date" style={{ marginBottom: 0 }}>
                                                <DatePicker style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col span={9} className="bordered-col"> <Form.Item label="13.INSURED'S OR AUTHORIZED PERSON'S SIGNATURE. I authorize payment
         of medical benefits to the undersigned physician or supplier for services 
         described below.">
                                </Form.Item>
                                    <Form.Item label="Signed" name="insuredSignature" style={{ marginTop: 20 }}>
                                        <Input />
                                    </Form.Item></Col>

                            </Row>
                            <Row className="bordered-row">
                                <Col xs={24} md={9} className="bordered-col">
                                    <Form.Item label="14. Date Of Current Illness, Injury, Or Pregnancy (LMP)">
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item name="currentIllnessDate">
                                                    <DatePicker format="MM/DD/YY" style={{ width: '100%' }} className="input" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name="currentIllnessQual">
                                                    <Input style={{ width: '100%' }} className="input" placeholder='QUAL' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6} className="bordered-col">
                                    <Form.Item label="15. Other Date">
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item name="otherDateQual">
                                                    <Input style={{ width: '100%' }} className="input" placeholder='QUAL' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name="otherDate">
                                                    <DatePicker format="MM/DD/YY" style={{ width: '100%' }} className="input" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={9} className="bordered-col">
                                    <Form.Item label="16. Dates Patient Unable To Work In Current Occupation">
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item name="unableToWorkFrom">
                                                    <DatePicker format="MM/DD/YY" style={{ width: '100%' }} className="input" placeholder='From Date' />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name="unableToWorkTo">
                                                    <DatePicker format="MM/DD/YY" style={{ width: '100%' }} className="input" placeholder='To Date' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div>
                                <Row className="bordered-row">
                                    <Col xs={24} md={9} className="bordered-col">
                                        <Form.Item label="17. Name Of Referring Provider Or Other Source" name="referringProvider">
                                            <Input style={{ width: '100%' }} className="input" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={6} className="bordered-col">
                                        <Form.Item label="17a." name="providerQual">
                                            <Input style={{ width: '100%' }} className="input" />
                                        </Form.Item>
                                        <Form.Item label="17b. NPI" name="providerNPI">
                                            <Input style={{ width: '100%' }} className="input" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={9} className="bordered-col">
                                        <Form.Item label="18.Hospitalization Dates Related To Current Services">
                                            <Row gutter={16}>
                                                <Col xs={12} sm={12} className='form-group'>
                                                    <Form.Item name="hospitalizationFrom">
                                                        <DatePicker format="MM/DD/YY" style={{ width: '100%' }} className="input" placeholder='From Date' />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={12} sm={12} className='form-group'>
                                                    <Form.Item name="hospitalizationTo">
                                                        <DatePicker format="MM/DD/YY" style={{ width: '100%' }} className="input" placeholder='To Date' />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row className="bordered-row">
                                    <Col xs={24} md={15} className="bordered-col">
                                        <Form.Item label="19.Additional Claim Information (Designated by NUCC) " name="additionalClaimInfo">
                                            <Input style={{ width: '100%' }} className="input" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={9} >
                                        <Row span={24}>
                                            <Col span={10} className="bordered-col">
                                                <Form.Item label="20. Outside Lab?" name="outsideLab">
                                                    <Radio.Group>
                                                        <Radio value="yes">YES</Radio>
                                                        <Radio value="no">NO</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            <Col span={14} className="bordered-col">
                                                <Form.Item label="$ Charges" name="labCharges">
                                                    <Input style={{ width: '100%' }} className="input" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>
                            </div>
                            <div>
                                <Row className="bordered-row">
                                    <Col xs={24} md={15} className="bordered-col">
                                        <Form.Item label="21.Diagnosis Or Nature Of Illness Or Injury Relate A-L to service line below (24E)">
                                            <Row gutter={[16, 16]}>
                                                {['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'].map((placeholder, index) => (
                                                    <Col xs={12} sm={8} md={6} lg={4} className='form-group' key={index}>
                                                        <Input placeholder={placeholder} />
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={9} className="bordered-col">
                                        <Row gutter={[16, 16]} className="bordered-row">
                                            <Col xs={24} md={24} className="bordered-col">
                                                <Row span={24}>
                                                    <Col xs={24} md={12} className='form-group'>
                                                        <Form.Item label="22. Resubmission Code">
                                                            <Input style={{ width: '100%' }} className="input" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={12} className='form-group'>
                                                        <Form.Item label="Original Ref. No.">
                                                            <Input style={{ width: '100%' }} className="input" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={[16, 16]}></Row>
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 16]} className="bordered-row bordered-col">
                                            <Form.Item label="23.Prior Authorization Number">
                                                <Input style={{ width: '100%' }} className="input" />
                                            </Form.Item>
                                        </Row>
                                    </Col>

                                </Row >
                            </div >
                            <div style={{ overflowX: 'auto' }}>
                                <Table
                                    columns={columns}
                                    dataSource={data}
                                    pagination={false}
                                    bordered
                                    title={() => (
                                        <div className="tabledesign">
                                            <Button
                                                id="createbutton"
                                                type="primary"
                                                onClick={handleSubmit}
                                                icon={<PlusOutlined />}
                                            >
                                                Add New Service
                                            </Button>
                                        </div>
                                    )}
                                />
                            </div>


                            <>
                                <Row className="bordered-row">
                                    <Col xs={24} md={9} className='padding-col bordered-col'>
                                        <Form.Item label="25.Federal Tax ID Number" name="federalta">
                                            <Row gutter={16}>
                                                <Col span={12} xs={12} md={12}>
                                                    <Input style={{ width: '100%' }} />
                                                </Col>
                                                <Col span={12} xs={12} md={12}>
                                                    <Radio.Group>
                                                        <Radio value="SSN">SSN</Radio>
                                                        <Radio value="EIN">EIN</Radio>
                                                    </Radio.Group>
                                                </Col>
                                            </Row>
                                        </Form.Item>

                                    </Col>
                                    <Col xs={24} md={6}>
                                        <Row span={24}>
                                            <Col span={12} className="bordered-col">
                                                <Form.Item label="26.Patient's Account No" name="accountno">
                                                    <Input style={{ width: '100%' }} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12} className="bordered-col">
                                                <Form.Item label="27.Accept Assignment? (For Govt Claims, See Back)" name="ASSIGNMENT">
                                                    <Radio.Group>
                                                        <Radio value="yes">Yes</Radio>
                                                        <Radio value="no">No</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={24} md={9} className="bordered-col">
                                        <Row gutter={16}>
                                            <Col xs={24} md={8} className='form-group'>
                                                <Form.Item label="28.Total Charge" name="CHARGE">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={8} className='form-group'>
                                                <Form.Item label="29.Amount Paid" name="PAID">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={8} className='form-group'>
                                                <Form.Item label="30.Rsvd. for NUCC Use" name="NUCC">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row >
                                {/* <Row gutter={16}>
                        <Col xs={24} md={8}>
                            <Form.Item label="31.SIGNATURE OF PHYSICIAN OR SUPPLIER INCLUDING DEGREES OR 
              CREDENTIALS (I certify that the statement on the reverse apply to this bill and are made a
                part thereof)">
                                <Row gutter={8}>
                                    <Col xs={14} md={16}>
                                        <Form.Item name="physicianSignature" noStyle>
                                            <Input placeholder="Signature" style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={10} md={8}>
                                        <Form.Item name="signatureDate" noStyle>
                                            <DatePicker placeholder="Date" style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={8}>
                            <Form.Item label="32.Service Facility Location Information">
                                <Row gutter={8} className='bordered-row'>
                                    <Col xs={14} md={16}>
                                        <Form.Item >
                                            <Input placeholder='a' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={10} md={8}>
                                        <Form.Item>
                                            <Input placeholder='b' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={8}>
                            <Form.Item label="33.Billing Provider Info & Ph#">

                                <Row gutter={8}>
                                    <Col xs={14} md={16}>
                                        <Form.Item>
                                            <Input placeholder='a' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={10} md={8}>
                                        <Form.Item>
                                            <Input placeholder='b' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>

                    </Row> */}
                                <Row className="bordered-row">
                                    <Col span={9} className="bordered-col">
                                        <Form.Item label="31.SIGNATURE OF PHYSICIAN OR SUPPLIER INCLUDING DEGREES OR 
              CREDENTIALS (I certify that the statement on the reverse apply to this bill and are made a
                part thereof)">
                                            <Row gutter={8}>
                                                <Col xs={14} md={16}>
                                                    <Form.Item name="physicianSignature" noStyle>
                                                        <Input placeholder="Signature" style={{ width: '100%' }} />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={10} md={8}>
                                                    <Form.Item name="signatureDate" noStyle>
                                                        <DatePicker placeholder="Date" style={{ width: '100%' }} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} className="bordered-col">
                                        <Form.Item label="33.Billing Provider Info & Ph#">
                                            <Row gutter={8}>
                                                <Col xs={14} md={12}>
                                                    <Form.Item>
                                                        <Input placeholder='a' />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={10} md={12}>
                                                    <Form.Item>
                                                        <Input placeholder='b' />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                    <Col span={9} className="bordered-col"> <Form.Item label="33.Billing Provider Info & Ph#">

                                        <Row gutter={8}>
                                            <Col xs={14} md={12}>
                                                <Form.Item>
                                                    <Input placeholder='a' />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={10} md={12}>
                                                <Form.Item>
                                                    <Input placeholder='b' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form.Item></Col>
                                </Row>
                            </>
                        </Form ></Content>
                    <Footer style={footerStyle}></Footer>
                </Layout>
            </Flex>



        </>
    )
}
export default CMSLastest;
