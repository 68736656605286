import { locationList } from "../../../services/PracticeLocationService";
import { providerList } from "../../../services/providerService";
import { fetchAppointmentStatus, fetchPatients } from "../../../services/appointmentService";
import { AppointmentType } from "../../../services/appointmentTypeService";

export const fetchStatusService = async (setStatusList) => {
    try {
        const appointmentStatus = await fetchAppointmentStatus();
        setStatusList(appointmentStatus.appointmentStatus);
    } catch (error) {
        console.error('Error fetching appointment status data:', error);
    }
};

export const fetchLocationData = async (setLocationData) => {
    try {
        const locations = await locationList();
        setLocationData(locations);
    } catch (error) {
        console.error('Error fetching location data:', error);
    }
};

export const fetchProviderData = async (setProviderData) => {
    try {
        const providers = await providerList();
        setProviderData(providers);
    } catch (error) {
        console.error('Error fetching provider data:', error);
    }
};

export const fetchAppointmentType = async (setAppointmentType) => {
    try {
        const appointment = await AppointmentType();
        setAppointmentType(appointment);
    } catch (error) {
        console.error('Error fetching provider data:', error);
    }
};


export const fetchPatientData = async (setPatientsData) => {
    try {
        const patients = await fetchPatients();
        setPatientsData(patients.patients);
    } catch (error) {
        console.error('Error fetching patients data:', error);
    }
};

// export const handleSearch = async (value, setSearchInput, setPatientsData, setMessage) => {
//     setSearchInput(value);
//     if (!value || value.length < 2) {
//         setMessage('Please enter 2 or more characters');
//         setPatientsData([]);
//     } 
//     else {
//         try {
//             const patients = await fetchPatients(value);
//             setPatientsData(patients.patients);
//         } catch (error) {
//             console.error('Error fetching patients data:', error);
//         }
//     }
// };

// export const handleSearch = async (value, setSearchInput, setPatientsData, setMessage) => {
//     setSearchInput(value);
//     if (!value || value.length < 2) {
//         setMessage('Please enter 2 or more characters');
//         setPatientsData([]);
//     } else {
//         setMessage('');
//         try {
//             const patients = await fetchPatients(value);
//             setPatientsData(patients.patients);
//         } catch (error) {
//             console.error('Error fetching patients data:', error);
//         }
//     }
// };

export const handleSearch = async (value, setSearchInput, setPatientsData, setMessage) => {
    setSearchInput(value);
    if (!value || value.length < 2) {
        if (setMessage) {
            setMessage('Please enter 2 or more characters');
        }
        setPatientsData([]);
    } else {
        if (setMessage) {
            setMessage('');
        }
        try {
            const patients = await fetchPatients(value);
            setPatientsData(patients.patients);
        } catch (error) {
            console.error('Error fetching patients data:', error);
        }
    }
};

export const handlePhysicianSearch = async (value, setSearchInput, setPhysiciansData) => {
    setSearchInput(value);
    if (!value || value.length < 2) {
        // Show a message prompting to enter a maximum of 2 characters
        setPhysiciansData([]);
    } else {
        try {
            const physician = await providerList(value);
            setPhysiciansData(physician.physician);
        } catch (error) {
            console.error('Error fetching patients data:', error);
        }
    }
};

export const handleViewClick = (appointment, setSelectedAppointment, setCRMVisible) => {
    setSelectedAppointment(appointment);
    setCRMVisible(true);
};

export const formItemLayout = {
    labelAlign: 'left',
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
export const handleBack = (setCRMVisible) => {
    setCRMVisible(false);
};
