import axiosInterceptor from "../../middleware/axiosInterceptor";



export const fetchPracticeLocation = async (providerId) => {
     const url = "/api/appointmentBlock/get/" + providerId;
    try {
      const response = await axiosInterceptor.get(url);
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  export const saveAppointmentBlock = async (appointmentBlockData) => {
    const url = "/api/save/appointmentBlock";
    try {
      const response = await axiosInterceptor.post(url, appointmentBlockData);
      return response;
    } catch (error) {
      console.error(error);
    }
  };