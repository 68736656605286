export const columnData = {
  columns: [
    {
      title: "ID",
      dataIndex: "userId",
      key: "userId",
      hidden: true,
      width: 100,

    },
    {
      title: "Action",
      key: "action",
      render: ["edit", "delete"],
      fixed: true,
      // width: 100,
      feature: false,
      hidden: false,
    },
    {
      title: "First Name",
      dataIndex: "firstname",
      key: "firstname",
      width: 150,
      feature: ["sorter", "filter"],
      filters: [],
      hidden: false,
    },
    {
      title: "Last Name",
      dataIndex: "lastname",
      key: "lastname",
      width: 150,
      feature: ["sorter", "filter"],
      filters: [],
      hidden: false,
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
      width: 150,
      feature: ["sorter", "filter"],
      filters: [],
      hidden: false,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
      feature: ["sorter", "filter"],
      filters: [],
      hidden: false,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: 200,
      feature: ["sorter", "filter"],
      filters: [],
    },
    {
      title: "Providers",
      dataIndex: "provider",
      key: "provider",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: false
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: 150,
      feature: ["sorter", "filter"],
      filters: [],
      hidden: false,
    },
    {
      title: "Last Login",
      dataIndex: "lastLoginAt",
      key: "lastLoginAt",
      // feature: ["sorter", "filter"],
      // filters: [],
      width: 200,
      hidden: false,
    },
    {
      title: "Account Start Date",
      dataIndex: "createdAt",
      key: "createdAt",
      // feature: ["sorter", "filter"],
      // filters: [],
      width: 200,
      hidden: false,
    },
    {
      title: "Time Zone",
      dataIndex: "timezone",
      key: "timezone",
      feature: ["sorter", "filter"],
      filters: [],
      hidden: true,
      width: 200,
    },
    {
      title: "Practice Location",
      dataIndex: "practiceLocation",
      key: "practiceLocation",
      hidden: true,
      feature: ["sorter", "filter"],
      filters: [],
      width: 200,
    },
    {
      title: "securityPin",
      dataIndex: "securityPin",
      feature: ["sorter", "filter"],
      filters: [],
      hidden: true,
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 200,
      hidden: false,
      tags: true,
    },
    // {
    //   title: "Status Code",
    //   dataIndex: "statuscode",
    //   width: 100,
    // },
  ],
};
