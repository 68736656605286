import React, { useEffect, useState } from 'react';
import { Drawer, Modal } from 'antd';
import { AppointmentTypeGridColumns } from './handlers/appointmentTypeGridColumns';
import { AppointmentType } from '../../services/appointmentTypeService';
import Grid from '../grid/newGrid';
import AppointmentTypeForm from './appointmentTypeForm';
import { screenActionCodes, actionPermission } from '../../utils/moduleScreenCodes';
import { handleModalConfirm } from '../appointmentType/handlers/appointmentTypeHandler';


const AppointmentTypeDefault = (props) => {
    const [dataSource, setDataSource] = useState([]);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [modalData, setModalData] = useState({
        visible: false,
        type: 'delete',
    });

    useEffect(() => {
        fetchAppointmentTypes();
    }, []);

    const fetchAppointmentTypes = async () => {
        try {
            const appointmentTypes = await AppointmentType();
            setDataSource(appointmentTypes);
        } catch (error) {
            console.error('Error fetching appointment types:', error);
        }
    };

    const handleEdit = (AppointmentType) => {
        setSelectedRecord(AppointmentType);
        setDrawerVisible(true);
    };

    const handleCreate = () => {
        setSelectedRecord(null);
        setDrawerVisible(true);
    };

    const handleDelete = (record) => {
        setModalData({
            ...modalData,
            visible: true,
            record: record,
        });
    };

    return (
        <>
            <Grid
                columnData={AppointmentTypeGridColumns}
                tableData={dataSource}
                setTableData={setDataSource}
                createButton={actionPermission(props, screenActionCodes.CONF_APTY_ADD)}
                initialTableData={dataSource}
                csvFileName="AppointmentType"
                searchButton={true}
                downloadCSV={true}
                showEdit={actionPermission(props, screenActionCodes.CONF_APTY_EDIT)}
                showDelete={actionPermission(props, screenActionCodes.CONF_APTY_DELETE)}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                createButtonIcon={true}
                createButtonName={"Add Appointment Type"}
                handleCreate={handleCreate}
            />

            <Modal
                title={modalData.type === 'delete' ? 'Delete Appointment Type' : ''}
                open={modalData.visible}
                onOk={() => handleModalConfirm(modalData, fetchAppointmentTypes, setModalData)}
                onCancel={() => setModalData({ ...modalData, visible: false })}
                okText="Delete"
                cancelText="Cancel"
                okButtonProps={{ danger: true }}
            >
                {modalData.type === 'delete' && modalData.record ? (
                    <p>
                        Are you sure you want to delete the appointment type
                        <span className='deletedynamicName'>
                            "{modalData.record.appointmentName}"
                        </span> ?
                    </p>
                ) : null}
            </Modal>


            <Drawer
                title={selectedRecord ? "Edit Appointment Type" : "Add Appointment Type"}
                placement="right"
                onClose={() => setDrawerVisible(false)}
                open={drawerVisible}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                maskClosable={false}
            >
                {drawerVisible &&
                    <AppointmentTypeForm
                        record={selectedRecord}
                        onClose={() => setDrawerVisible(false)}
                        isEditing={!!selectedRecord}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        setDataSource={setDataSource}
                        tableData={dataSource}
                        setDrawerVisible={setDrawerVisible}
                    />
                }
            </Drawer>
        </>
    );
};

export default AppointmentTypeDefault;
