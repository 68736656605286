import React, { useState, useEffect } from 'react';
import { Button, Popover, Select, Switch, Form, DatePicker, message } from "antd";
import "../../../assets/styles/appointment.css";
import {
  fetchProviderData, fetchLocationData, fetchStatusService, formItemLayout, fetchPatientData,
  handleSearch, fetchAppointmentType
} from "./handler/appointmentHandler";
import dayjs from 'dayjs';
import "../../../assets/styles/common.css";

const { RangePicker } = DatePicker;

const AppointmentFilter = ({ onSubmit, visible, setVisible }) => {
  const [form] = Form.useForm();
  const [statusList, setStatusList] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [providerData, setProviderData] = useState([]);
  const [patientsData, setPatientsData] = useState([]);
  const [appointmentType, setAppointmentType] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  const dateFormat = 'YYYY-MM-DD';
  const currentDate = dayjs().startOf('day');

  const defaultValues = {
    date_range: [currentDate, currentDate],
  };

  const onFinish = (values) => {
    if (values.date_range) {
      const formattedValues = {
        ...values,
        date_range: values.date_range.map(date => date.format(dateFormat)),
      };
      onSubmit(formattedValues);
    } else {
      message.error('Date range is not defined in the filter values', 3, onClose);
    }
  };

  const onClose = () => {};

  const onFilterReset = () => {
    form.resetFields();
  };

  const onFilterClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    fetchStatusService(setStatusList);
    fetchLocationData(setLocationData);
    fetchProviderData(setProviderData);
    fetchPatientData(setPatientsData);
    fetchAppointmentType(setAppointmentType);
  }, []);

  const customTitle = (
    <div className="custom-title">
      <span>Appointment Filter</span>
      <Button
        type="link"
        onClick={() => setVisible(false)}
        className="custom-button"
      > X </Button>
    </div>
  );

  const renderDropdown = (menuItems) => {
    if (!searchInput || searchInput.length < 2) {
      return (
        <div className='dropdown-message'>
          Please enter 2 or more characters
        </div>
      );
    }
    return menuItems;
  };

  const renderDropdownOptions = (data, keyField) => {
    const uniqueValues = Array.from(new Set(data.map(item => item[keyField])));
    return uniqueValues.map((value, index) => (
      <Select.Option key={index} value={value}>
        {value}
      </Select.Option>
    ));
  };

  const renderDropdownProvider = (data) => {
    const uniqueProviderNames = [...new Set(data.map(provider => `${provider.firstName} ${provider.lastName}`))];
    return uniqueProviderNames.map((name, index) => (
      <Select.Option key={index} value={name}>
        {name}
      </Select.Option>
    ));
  };

  const popoverContent = (
    <div className='filterForm'>
      <Form
        name="basic"
        {...formItemLayout}
        colon={false}
        initialValues={defaultValues}
        form={form}
        onFinish={(values) => onFinish(values)}
      >
        <Form.Item label="Date Range" name="date_range">
          <RangePicker
            defaultValue={[currentDate, currentDate]}
            format={dateFormat}
          />
        </Form.Item>

        <Form.Item label="Status" name="status">
          <Select
            mode="multiple"
            allowClear
            placeholder="Appointment Status"
            maxTagCount={4}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {statusList && statusList.length > 0 && renderDropdownOptions(statusList, 'appointmentStatusName')}
          </Select>
        </Form.Item>

        <Form.Item label="Location" name="location">
          <Select
            mode="multiple"
            allowClear
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder="Location"
            maxTagCount={2}
            className='location-dropdown'
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {locationData && locationData.length > 0 && renderDropdownOptions(locationData, 'locationName')}
          </Select>
        </Form.Item>

        <Form.Item label="Patients" name="patients">
          <Select
            mode="multiple"
            allowClear
            filterOption={false}
            defaultActiveFirstOption={false}
            placeholder="Patients"
            maxTagCount={2}
            onSearch={(value) => handleSearch(value, setSearchInput, setPatientsData)}
            value={searchInput}
            dropdownRender={(menu) => renderDropdown(menu)}
          >
            {patientsData && patientsData.length > 0 && patientsData.map((patient) => (
              <Select.Option key={patient.patientId} value={`${patient.firstName} ${patient.lastName}`}>
                {`${patient.firstName} ${patient.lastName}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Providers" name="providers">
          <Select
            mode="multiple"
            allowClear
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder="Providers"
            maxTagCount={2}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {renderDropdownProvider(providerData)}
          </Select>
        </Form.Item>

        <Form.Item label="Appointment Type" name="appointment_type">
          <Select
            mode="multiple"
            allowClear
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder="Appointment Type"
            maxTagCount={2}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {appointmentType && appointmentType.length > 0 && renderDropdownOptions(appointmentType, 'appointmentName')}
          </Select>
        </Form.Item>
        <Form.Item label="Show Test Data" name="show_test_data" valuePropName="checked">
          <Switch />
        </Form.Item>

        <Form.Item className='button-alignment'>
          <Button type="primary" htmlType="submit" className="button-spacing primary-button">Apply</Button>
          <Button htmlType="button" onClick={onFilterReset} className="button-spacing btn btn-outline-primary">Reset</Button>
          <Button type="danger" onClick={onFilterClose} className="danger-button">Close</Button>
        </Form.Item>
      </Form>
    </div>
  );

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <Popover
        className='appointment-container'
        placement="bottomLeft"
        title={customTitle}
        content={popoverContent}
        trigger="click"
        autoAdjustOverflow={false}
        open={visible}
        onOpenChange={setVisible}
        overlayClassName="custom-overlay"
        destroyTooltipOnHide={false}
        mask={true}
      >
        {/* <Tooltip placement="bottomRight" title='Appointment Filter'>
          <Button className='filter-button' type="primary" icon={<FilterFilled />} onClick={() => setVisible(!visible)}></Button>
      </Tooltip> */}
      </Popover>
    </div>
  );
}

export default AppointmentFilter;
