import axiosInterceptor from "../../middleware/axiosInterceptor";

export default async function changePasswordService(formData) {
  const url = "/api/user/change-password";
  let response = null;

  try {
    response = await axiosInterceptor.post(url, formData);


  }
  catch (error) {
    console.error(error);
  }

  return response;
}