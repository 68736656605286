import React from 'react';
import { UsergroupAddOutlined, UnorderedListOutlined, CalendarOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PracticeDefault from "../components/practice/practiceDefault";
import ProviderDefault from "../components/provider/providerDefault";
import UsersDefault from "../components/users/usersDefault";
import PracticeLocationDefault from '../components/practiceLocation/practiceLocationDefault';
import AppointmentDefault from '../components/appointments/appointmentDefault';
import SchedulerDefault from '../components/scheduler/schedulerDefault';
import ConfigDetail from "../components/practice/practiceConfiguration";
import AppointmentTypeDefault from '../components/appointmentType/appointmentTypeDefalut';
import AppointmentCRMForm from '../components/appointments/appointmentCrmForm';
import defaultReport from '../components/reports/report';
/**
 * ALL MODULES AND SCREENS TO BE CONFIGURED HERE AS DESCRIBED BELOW. 
 * ALL "CODES" MUST BE MATCH WITH DATABASE AND EACH SCREENCODE TO BE "UNIQUE".
 * @screenActionCodes - ALL "ACTION" CODE TO BE UPDATED HERE WITH PROPER COMMENT LINE.
 * @moduleScreenConfiguration - EACH "MODULE SCREENS" CODE AND CONFIG TO BE UPDATED HERE.
 * THE NEW ADDITION IN @moduleScreenConfiguration SHOULD BE UNDER THE SAME MODULE. IF IT'S NEW ADD ALONG WITH COMMENT LINE.
**/

/* Default Module screen components Codes for each Module */
const moduleScreenConfiguration = [

    /*Practice Screens*/
    { screenCode: 'SCR-CONF-PRAC', icon: <UnorderedListOutlined />, label: 'Practice', content: <PracticeDefault />, isActive: true },
    { screenCode: 'SCR-PRAC-CONF', icon: <UsergroupAddOutlined />, label: 'Configuration', content: <ConfigDetail />, isActive: false },

    /*Provider Screens*/
    { screenCode: 'SCR-CONF-PROV', icon: <UnorderedListOutlined />, label: 'Providers', content: <ProviderDefault />, isActive: true },

    /*Practice Location Screens*/
    { screenCode: 'SCR-CONF-PRAC-LOC', icon: <UnorderedListOutlined />, label: 'Locations', content: <PracticeLocationDefault />, isActive: true },

    /*User Screens*/
    { screenCode: 'SCR-USERS', icon: <UnorderedListOutlined />, label: 'Users', content: <UsersDefault />, isActive: true },

    /*Dashboard Header - User Profile Screens*/
    { screenCode: 'SCR-PROFILE-SETTING', icon: <UsergroupAddOutlined />, label: 'Profile', content: "", isActive: true },
    { screenCode: 'SCR-ACC-SETTING', icon: <UsergroupAddOutlined />, label: 'Account', content: "", isActive: false },

    /*Appointments*/
    { screenCode: 'SCR-APPMTS', icon: <UnorderedListOutlined />, label: 'Appointments', content: <AppointmentDefault />, isActive: true },
    // { screenCode: 'SCR-APPTY-CRM', icon: <CalendarOutlined />, label: 'Appointment CRM', content: <AppointmentCRMForm />, isActive: true },

    /*Calendar*/
    { screenCode: 'SCR-CALENDAR', icon: <UnorderedListOutlined />, label: 'Scheduler', content: <SchedulerDefault />, isActive: true },

    /* Appointment Type*/
    { screenCode: 'SCR-CONF-APPTY', icon: <UnorderedListOutlined />, label: 'Appointment Types', content: <AppointmentTypeDefault />, isActive: true },
    { screenCode: 'SCR-REPORT', icon: <UnorderedListOutlined />, label: 'Report', content: <defaultReport />, isActive: true },


]


/* Default Screen Action Codes for each Module */
const screenActionCodes = {

    /* MODULE: PRACTICE */ /* SCREEN: PRACTICE DEFAULT */
    CONF_PRAC_VIEW: "ACTION-CONF-PRAC-VIEW", CONF_PRAC_EDIT: "ACTION-CONF-PRAC-EDIT",

    /* MODULE: PROVIDER */ /* SCREEN: PROVIDER DEFAULT */
    CONF_PRO_VIEW: "ACTION-CONF-PRO-VIEW", CONF_PRO_EDIT: "ACTION-CONF-PRO-EDIT", CONF_PRO_DELETE: "ACTION-CONF-PRO-DELETE",

    /* MODULE: PRACTICE LOCATION */ /* SCREEN: PRACTICE LOCATION DEFAULT */
    CONF_PRAC_LOC_VIEW: "ACTION-CONF-PRAC-LOC-VIEW", CONF_PRAC_LOC_EDIT: "ACTION-CONF-PRAC-LOC-EDIT", CONF_PRAC_LOC_DELETE: "ACTION-CONF-PRAC-LOC-DELETE",

    /* MODULE: USERS */ /* SCREEN: USERS DEFAULT */
    CONF_USER_VIEW: "ACTION-USER-VIEW", CONF_USER_EDIT: "ACTION-USER-EDIT", CONF_USER_DELETE: "ACTION-USER-DELETE", CONF_USER_ADD: "ACTION-USER-ADD",

    /* MODULE: APPOINTMENT TYPE */ /* SCREEN: PROVIDER DEFAULT */
    CONF_APTY_VIEW: "ACTION-APTY-CONF-VIEW", CONF_APTY_EDIT: "ACTION-APTY-CONF-EDIT", CONF_APTY_DELETE: "ACTION-APTY-CONF-DELETE", CONF_APTY_ADD: "ACTION-APTY-CONF-ADD",
    /* MODULE: APPOINTMENT  */ /* SCREEN: APPOINTMENT DEFAULT */
    APTY_VIEW: "ACTION-APTY-VIEW",

    /* MODULE: APPOINTMENT CRM  */ /* SCREEN: APPOINTMENT CRM  VIEW for LOG/CONVERSATION/ESCALATION */
    APTY_CRM_LOG: "ACTION-APTY-CRM-LOG", APTY_CRM_CONVO: "ACTION-APTY-CRM-CONVO", APTY_CRM_ESCALATION: "ACTION-APTY-CRM-ESCALATION",
    APTY_CRM_CONFIRM: "ACTION-APTY-CRM-CONFIRM", APTY_CRM_CANCEL: "ACTION-APTY-CRM-CANCEL",
};

/* Return Matched "screenCode" @moduleScreenConfiguration between "moduleConfig" and "moduleScreenConfiguration" */
function getModuleComponents(moduleConfig, moduleScreenConfiguration) {
    let result = [];
    if (moduleConfig.length > 0) {
        for (const screenConfig of moduleScreenConfiguration) {
            const moduleScreen = moduleConfig.find(screen => screen.screenCode === screenConfig.screenCode);
            if (moduleScreen) {
                const config = { ...screenConfig };
                const compActions = moduleScreen.actions;
                config.content = React.cloneElement(config.content, { actionCodes: compActions, screenProps: moduleConfig });
                result.push(config);
            }
        }
    }
    return result;
}

/* Return Button if action Code matches otherwise null */
/* BUTTONCOMPONENT ATTRIBUTES MUST HAS THESE PROPERTY AND THE SAME ORDER
 * <ButtonComponet actionProps={All_ActionCodes_Props} actionCode={Specific_Action_Code} title="Button Text"/>  
*/
const ButtonComponet = ({ actionProps, actionCode, title, ...otherProps }) => {
    const isVisible = actionProps.actionCodes.map(configItem => configItem.actionCode === actionCode).includes(true);
    return (
        isVisible && (<Button {...otherProps}>{title} </Button>)
    );
};

/* Return true if the give 'actionCode' matches with the actionCodesprops */
function actionPermission(actionProps, actionCode) {
    const isVisible = actionProps.actionCodes.map(configItem => configItem.actionCode === actionCode).includes(true);
    return isVisible;
};


export { moduleScreenConfiguration, screenActionCodes, getModuleComponents, ButtonComponet, actionPermission };