import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Checkbox, Button, message, Alert, Space } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import { handleSubmit, handleUpdate } from './handlers/appointmentTypeHandler';
import { AppointmentTypeValidation } from "./handlers/appointmentTypeValidation";
import "../../../assets/styles/appointmentType.css"

const AppointmentTypeForm = ({ record, setDrawerVisible, isEditing, setDataSource, tableData, onClose }) => {
    const [form] = Form.useForm();
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState("");
    const nameInputRef = useRef(null);
    let [value, setValue] = useState(record ? record.duration : 5);
    const [initialValues, setInitialValues] = useState(null);

    const handleIncrease = () => {
        setValue((prevValue) => prevValue + 5);
        form.setFieldsValue({ duration: value + 5 });
    };

    const handleDecrease = () => {
        const newValue = value - 5;
        if (newValue >= 5) {
            setValue(newValue);
            form.setFieldsValue({ duration: newValue });
        }
    };

    const labelWrapperProps = {
        labelAlign: 'left',
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    useEffect(() => {
        if (nameInputRef.current) {
            setTimeout(() => {
                nameInputRef.current.focus();
            }, 0); // Using setTimeout to defer focus operation
        }

        if (record) {
            form.setFieldsValue({
                name: record.appointmentName,
                description: record.description,
                duration: record.duration, // Set the value for the duration field
                isBlocked: record.isBlocked,
                allowMake: record.allowMake,
                allowConfirm: record.allowConfirm,
                allowCancel: record.allowCancel,
                allowReschedule: record.allowReschedule,
                allow48Hour: record.allow48Hour
            });
        } else {
            form.setFieldsValue({
                name: "",
                description: "",
                duration: 5, // Set the value for the duration field
                isBlocked: false,
                allowMake: false,
                allowConfirm: false,
                allowCancel: false,
                allowReschedule: false,
                allow48Hour: false
            });
        }
    }, [record, form]);

    const onFinish = async (values, record) => {

        try {
            setSubmitted(true);
            if (isEditing && record && record.appointmentTypeId) {
                await handleUpdate(values, record.appointmentTypeId, setError, setDataSource, tableData, setDrawerVisible, form);
            } else {
                // Skip validation for duration field if it's 5
                const durationRule = values.duration === 5 ? [] : AppointmentTypeValidation.duration;
                await handleSubmit(values, setError, setDataSource, tableData, setDrawerVisible, form, durationRule);
            }
            setSubmitted(false);
            // onClose();
        } catch (error) {
            message.error("Failed to save appointment type");
        }
    };

    const onCancel = () => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
        setError(""); // Reset error state
        setSubmitted(false);
        onClose();
    };


    useEffect(() => {
        if (nameInputRef.current) {
          setTimeout(() => {
            nameInputRef.current.focus();
          }, 0); // Using setTimeout to defer focus operation
        }
      }, []);


    return (
        <>
            <div className="form-container">
                <Form form={form} layout="horizontal" requiredMark={false} {...labelWrapperProps} colon={false} onFinish={(values) => onFinish(values, record)}>
                    {/* Alert from the server */}
                    <div className="row alertRegister">
                        <div id="alert" className="login-alert" style={{ width: "15px" }}>
                            {error ? <Alert description={error} type="error" showIcon closable /> : null}
                        </div>
                    </div>
                    {/* alert end */}

                    <Form.Item label="Name" name="name" rules={AppointmentTypeValidation.name}>
                        <Input disabled={isEditing} ref={nameInputRef} />
                    </Form.Item>
                    <Form.Item label="Description" name="description" rules={AppointmentTypeValidation.description}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Duration" name="duration"  rules={AppointmentTypeValidation.duration}>
                        <Space.Compact compact>
                            <Button onClick={handleDecrease} className="btnMinusAdjust" >-</Button>
                            <Input
                                className="inputTimerAdjust"
                                value={value}
                                onChange={(e) => setValue(parseInt(e.target.value) || 5)}
                                readOnly
                            />
                            <Button onClick={handleIncrease} className="btnPlusAdjust" >+</Button>
                        </Space.Compact>
                    </Form.Item>
                    <Form.Item label="Is Blocked" name="isBlocked" valuePropName="checked">
                        <Checkbox />
                    </Form.Item>
                    <Form.Item label="Allow Make" name="allowMake" valuePropName="checked">
                        <Checkbox />
                    </Form.Item>
                    <Form.Item label="Allow Confirm" name="allowConfirm" valuePropName="checked">
                        <Checkbox />
                    </Form.Item>
                    <Form.Item label="Allow Cancel" name="allowCancel" valuePropName="checked">
                        <Checkbox />
                    </Form.Item>
                    <Form.Item label="Allow Reschedule" name="allowReschedule" valuePropName="checked">
                        <Checkbox />
                    </Form.Item>
                    <Form.Item label="Allow 48 Hour" name="allow48Hour" valuePropName="checked">
                        <Checkbox />
                    </Form.Item>
                    <div>
                        <div className="button-container">
                            <Form.Item>
                                <Button onClick={onCancel} icon={<CloseOutlined />} >Cancel</Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" id="btnUpdate" >   {isEditing ? 'Update' : 'Add'}</Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>



        </>

    );
};

export default AppointmentTypeForm;
