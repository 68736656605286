import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Layout, Steps, Form, Input, Button, Select, Row, Col, Radio, Anchor, Divider, Modal, Spin } from "antd";

import InputMask from 'react-input-mask';
import moment from 'moment';
import { saveContactInformationForm, savePersonalInformation } from "../../services/ipadRegistartionService";
import { fetchIpadRegistrationData } from '../../services/ipadRegistrationService';
const { Header, Content } = Layout;

const { Option } = Select;
const ContactInformation = (props) => {
    const [form] = Form.useForm();
    const [size, setSize] = useState('small');
    const [dropdownValues, setDropdownValues] = useState([]);
    const [cellPhoneValue, setCellPhoneValue] = useState('');
    const [homePhoneValue, setHomePhoneValue] = useState('');
    const [formData, setFormData] = useState([]);
    const [patientRegistrationInfo, setPatientRegistrationInfo] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formControls, setFormControls] = useState([]);
    const [data, setData] = useState({
        workStatus: [],
        relationship: []
    });

    useEffect(() => {
        const fetchIpadRegistrationHandler = async () => {
            try {
                const response = await fetchIpadRegistrationData();
                setData({
                    workStatus: response[0]["Work Status"] || [],
                    relationship: response[0]["Emergency Contact Relationship"] || []
                },

                ); // Adjusted for the correct property name
            } catch (error) {
                console.error("Error Registration Data:", error.message);
            }
        };

        fetchIpadRegistrationHandler();
    }, []);

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, []);

    // useEffect(() => {
    //     setDropdownValues(props.dropdownList);
    //     if (!(props.patientRegistrationInfo.length === 0)) {
    //         const formControlsForActivePage = props.formControls[props.activeIpadPage];
    //         const formFields = formControlsForActivePage.map(control => control.form_field);
    //         setFormControls(formFields);
    //         setDataLoaded(true);
    //     }
    // }, [props]);

    const [currentStep, setCurrentStep] = useState(1);

    const handleStepChange = (step) => {
        setCurrentStep(step);
    };

    const handleCellPhoneChange = (e) => {
        setCellPhoneValue(e.target.value);
    };
    const handleHomePhoneChange = (e) => {
        setHomePhoneValue(e.target.value);
    };
    const copyCellToHome = () => {
        const cellPhoneValue = form.getFieldValue('PatientPhoneNumber');

        form.setFieldsValue({
            PatientHomePhoneNumber: cellPhoneValue,
        });
    };
    // useEffect(() => {
    //     setDropdownValues(props.dropdownList);
    // }, [props]);

    const onContactInfoPreviousClick = () => {
        props.handlePreviousClick(props);
    }

    const formatPhoneNumber = (phoneNumber) => {
        if (phoneNumber) {
            const digitsOnly = phoneNumber.replace(/\D/g, '');
            return `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6, 10)}`;
        }
        return '';
    };

    const validatePhoneNumber = (rule, value) => {
        if (value && value.replace(/\D/g, '').length < 10) {
            return Promise.reject('Please enter 10 digits');
        }
        return Promise.resolve();
    };

    const validatePhoneNumberWithoutEmpty = (rule, value) => {
        if (!value) {
            return Promise.reject('Phone number is required');
        }
        if (value.replace(/\D/g, '').length !== 10) {
            return Promise.reject('Phone number must have exactly 10 digits');
        }
        return Promise.resolve();
    };


    const onContactInfoNextClick = async (values) => {
        try {
            setIsSubmitting(true);
            const updatedValues = { ...values };
            updatedValues.PatientHomePhoneNumber = formatPhoneNumber(updatedValues.PatientHomePhoneNumber);
            updatedValues.PatientPhoneNumber = formatPhoneNumber(updatedValues.PatientPhoneNumber);
            let contactInfoResponse = await saveContactInformationForm(props.uniqueHash, updatedValues);
            if (contactInfoResponse.message === 'Success') {
                props.handleNextClick(props);
            } else {
                Modal.error({
                    title: 'This is an error message',
                    content: contactInfoResponse.errors.toString(),
                });
            }
        } finally {
            if (!isSubmitting) {
                return (
                    <div className="spinner-overlay">
                        <div className="spinner">
                            <Spin size="large" tip="Loading..." />
                        </div>
                    </div>
                );
            }
            setIsSubmitting(false);
        }
    }

    function parseDate(dateString) {
        if (dateString) {
            const parsedDate = moment(dateString, 'MM-DD-YYYY');
            return parsedDate.isValid() ? parsedDate : null;
        }
        return null;
    }

    // const patientInfo = props.patientRegistrationInfo.patientRegistrationFormData?.ContactInformation ?? [];
    // const contactInfo = props.patientRegistrationInfo?.patientRegistrationInfo;

    // const patientDetails = {
    //     PatientAddress: contactInfo?.address || "",
    //     City: contactInfo?.city || "",
    //     State: contactInfo?.state || "",
    //     Zip: contactInfo?.zip || "",
    //     PatientPhoneNumber: contactInfo?.cell_phone || "",
    //     PatientHomePhoneNumber: patientInfo?.PatientHomePhoneNumber || "",
    //     PatientWorkStatus: patientInfo?.PatientWorkStatus || "",
    //     PatientWorkPhone: patientInfo?.PatientWorkPhone || "",
    //     PatientEmergencyContactName: patientInfo?.PatientEmergencyContactName || "",
    //     EmergencyContactRelationship: patientInfo?.EmergencyContactRelationship || "",
    //     PatientEmergencyContactPhone: patientInfo?.PatientEmergencyContactPhone || "",
    //     HowDidYouFindUs: patientInfo?.HowDidYouFindUs || "",
    //     PatientTravelInfo: patientInfo?.PatientTravelInfo || "",
    //     securePatientPortalCommunicate: patientInfo?.securePatientPortalCommunicate || "",
    // };
    // if (!dataLoaded) {
    //     return (
    //         <div className="spinner-overlay">
    //             <div className="spinner">
    //                 <Spin size="large" tip="Loading..." />
    //             </div>
    //         </div>
    //     );
    // }

    return (
        <div>
            <h2>Contact Information</h2>
            <Form form={form} layout="vertical" size="large" scrollToFirstError={true} onFinish={onContactInfoNextClick}
            // initialValues={patientDetails}
            >
                <Form.Item label="Address" name="PatientAddress"
                    rules={[
                        {
                            required: true,
                            message: 'Address is required',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Row gutter={24}>
                    <Col md={8}>
                        <Form.Item label="City" name="City"
                            rules={[
                                {
                                    required: true,
                                    message: 'The city field is required.',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item label="State" name="State"
                            rules={[
                                {
                                    required: true,
                                    message: 'The state field is required.',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item label="Zip Code" name="Zip"
                            rules={[
                                {
                                    required: true,
                                    message: 'The zip field is required.',
                                },
                                {
                                    pattern: /^\d{5}$/,
                                    message: 'Zip code must be 5 digits.',
                                },
                            ]}
                        >
                            <Input type="text" maxLength={5} onKeyPress={(e) => {
                                const pattern = /^[0-9]*$/;
                                if (!pattern.test(e.key)) {
                                    e.preventDefault();
                                }
                            }} placeholder="___ ___" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item label="Cell Phone" name="PatientPhoneNumber" rules={[{ validator: validatePhoneNumber }]}>
                    <InputMask
                        type="text"
                        className="form-control"
                        mask="(999) 999-9999"
                        placeholder="(NNN) NNN-NNN"
                        onChange={handleCellPhoneChange} />
                </Form.Item>
                <a href="#" onClick={copyCellToHome}>
                    Use cell phone as home phone.
                </a>
                <Form.Item
                    label="Home Phone"
                    name="PatientHomePhoneNumber"
                    rules={[
                        {
                            required: true,
                            message: 'The patient home phone number field is required.',
                            validator: validatePhoneNumberWithoutEmpty,
                        },
                    ]}
                    validateTrigger="onChange"
                >
                    <InputMask
                        type="text"
                        className="form-control"
                        mask="(999) 999-9999"
                        placeholder="(NNN) NNN-NNN"
                        onChange={handleHomePhoneChange} />
                </Form.Item>
                <Form.Item label="Work Status" name="PatientWorkStatus">
                    {/* {dropdownValues.work_status && dropdownValues.work_status.length > 0 ?
                        <Select>
                            {dropdownValues.work_status.map((status, index) => (
                                <Option key={index} value={status}>{status}</Option>
                            ))}
                        </Select>
                        : <Select></Select>
                    } */}
                    <Select>
                        {data.workStatus.map((item) => (
                            <Option key={item.dropdownoptionid} value={item.dropdownoptionid}>
                                {item.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="Work Phone" name="PatientWorkPhone" rules={[{ validator: validatePhoneNumber }]}>
                    <InputMask type="text" className="form-control" id="workPhone" name="workPhone"
                        mask="(999) 999-9999" placeholder="(NNN) NNN-NNN"
                        onChange={({ target }) => setFormData({ ...formData, WorkPhone: target.value })} />
                </Form.Item>
                {formControls.includes('securePatientPortalCommunicate') &&
                    <Form.Item name="securePatientPortalCommunicate"
                        label="Would you like to communicate with us via our secure patient portal">
                        <Radio.Group>
                            <Radio value="Yes"> Yes </Radio>
                            <Radio value="No"> No </Radio>
                        </Radio.Group>
                    </Form.Item>}
                {formControls.includes('securePatientPortalCommunicate') &&
                    <Form.Item label="How does patient travel to appointments" name="PatientTravelInfo">
                        <Input />
                    </Form.Item>
                }
            </Form>
            <Divider></Divider>
            <h2>Emergency Contact Information</h2>
            <Form form={form} layout="vertical" size="large" style={{ marginTop: 16 }} onFinish={onContactInfoNextClick}
            // initialValues={patientDetails}
            >
                <Form.Item label="Patient Emergency Contact Name" name="PatientEmergencyContactName"
                    style={{ marginTop: 16 }}
                    rules={[
                        {
                            required: true,
                            message: 'The patient emergency contact name field is required.',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Relationship"
                    name="EmergencyContactRelationship"
                    style={{ marginTop: 16 }}
                    rules={[
                        {
                            required: true,
                            message: 'The emergency contact relationship field is required.',
                        },
                    ]}
                >
                    {/* <Select>
                        <Option value={null}></Option>
                        {dropdownValues.emergency_contact_relation && dropdownValues.emergency_contact_relation.length > 0
                            ? dropdownValues.emergency_contact_relation.map((status, index) => (
                                <Option key={index} value={status}>
                                    {status}
                                </Option>
                            ))
                            : null
                        }
                    </Select> */}
                    <Select>
                        {data.relationship.map((item) => (
                            <Option key={item.dropdownoptionid} value={item.dropdownoptionid}>
                                {item.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="Emergency Contact Phone" name="PatientEmergencyContactPhone" style={{ marginTop: 16 }}
                    rules={[
                        {
                            required: true,
                            message: 'The patient emergency contact phone field is required.',
                            validator: validatePhoneNumberWithoutEmpty,
                        },
                    ]}
                >
                    <InputMask type="text" className="form-control" id="emergencycontactphone"
                        name="emergencycontactphone"
                        mask="(999) 999-9999" placeholder="(NNN) NNN-NNN" />
                </Form.Item>
                <Divider></Divider>
                <Form.Item label="How did you find us ?" name="HowDidYouFindUs" style={{ marginTop: 16 }}>
                    {dropdownValues.how_did_you_find_us && dropdownValues.how_did_you_find_us.length > 0 ?
                        <Select onChange={(value) => {
                            setFormData({ ...formData, HowDidYouFindUs: value });
                        }}>
                            {dropdownValues.how_did_you_find_us.map((status, index) => (
                                <Option key={index} value={status}>{status}</Option>
                            ))}
                        </Select>
                        : <Select></Select>
                    }
                </Form.Item>
                <Form.Item className="form-footer-button">
                    {isSubmitting ? (
                        <div className="spinner-overlay">
                            <div className="spinner">
                                <Spin size="large" tip="Loading..." />
                            </div>
                        </div>
                    ) : (
                        <Row gutter={12}>
                            <Col>
                                <Button type="primary" onClick={onContactInfoPreviousClick}>Previous</Button>
                            </Col>
                            <Col>
                                <Button type="primary" htmlType="submit">Next</Button>
                            </Col>
                        </Row>
                    )}
                </Form.Item>
            </Form>
        </div>
    );
}
export default ContactInformation;

