import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Button, Select, Alert } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import '../../../assets/styles/register.css';
import "../../../assets/styles/common.css";

import { handleSubmit } from "./handlers/registerHandler";
import { CreateUserValidation } from "../../components/users/handlers/createUserValidation";
import { fetchPopulateData, handleRoleChange } from "./handlers/fetchPopulateHandler";
import { handleCloseDrawer } from "./handlers/userHandler";
import { handleNumKeyDown } from "../utilities/utility";

const { Option } = Select;

const Register = ({ props, setDrawerVisible, tableDataInternal, setTableDataInternal }) => {
  const [form] = Form.useForm();
  const [error, setError] = useState("");
  const [status, setStatus] = useState(false);
  const [timeZone, setTimeZone] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState('');
  const [practiceLocations, setPracticeLocations] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [loading, setLoading] = useState(true);

  const [additionalFields, setAdditionalFields] = useState([]);
  const usernameInputRef = useRef(null);
  const [filteredOptions, setFilteredOptions] = useState([]);


  useEffect(() => {
    fetchPopulateData(
      setTimeZone,
      setPracticeLocations,
      setRoles,
      () => setLoading(false) // Set loading to false when data fetching is complete
    );
  }, []);

  const handleCancelClick = () => {
    handleCloseDrawer(setDrawerVisible, form);
  };


  const labelWrapperProps = {
    labelAlign: 'left',
    labelCol: { span: 8 },
    wrapperCol: { span: 18 },
  };


  const handleUserNameKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault(); // Prevent typing the space character
    }
  };

  useEffect(() => {
    if (usernameInputRef.current) {
      setTimeout(() => {
        usernameInputRef.current.focus();
      }, 0); // Using setTimeout to defer focus operation
    }
  }, []);

  const handleFormFinish = (values, status, selectedTimezone, selectedRole, setError, props, roles, additionalFields, setDrawerVisible, form) => {
    handleSubmit(values, status, selectedTimezone, selectedRole, setError, props, roles, additionalFields,
      setDrawerVisible, form, tableDataInternal, setTableDataInternal);
  }



  return (
    <div className="registerBottomSpacing" >
      <div className="site-card-border-less-wrapper " >
        <Form className="container-height" form={form} layout="horizontal" colon={false} requiredMark={false}
          onFinish={(values) => handleFormFinish(values, status, selectedTimezone, selectedRole, setError, props, roles, additionalFields, setDrawerVisible, form)}>
          <div className="container-height">


            {/* Alert from  the server */}
            <div className="row alertRegister">
              <div id="alert" className="login-alert">
                {error ? <Alert description={error} type="error" showIcon closable /> : null}
              </div>
            </div>
            {/* alert end */}

            {/* Form for user registration  */}
            <Form.Item
              name="username"
              label="User Name"
              rules={CreateUserValidation.username}
              {...labelWrapperProps}
            >
              <Input id="username" ref={usernameInputRef} onKeyDown={handleUserNameKeyDown} />

            </Form.Item>
            <Form.Item
              name="role"
              label="Role"
              rules={CreateUserValidation.role}
              {...labelWrapperProps}
            >
              <Select
                placeholder="Select a role"
                labelInValue
                id="role"
                onChange={(value) => handleRoleChange(value, setShowAdditionalFields, setSelectedRole, roles, setAdditionalFields)}
                value={selectedRole}
              >
                {roles && roles.map(q => (
                  <Option key={q.id} value={q.id}>
                    {q.rolename}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="firstname"
              label="First Name"
              rules={CreateUserValidation.firstname}
              {...labelWrapperProps}
            >
              <Input id="firstname" />
            </Form.Item>
            <Form.Item
              name="lastname"
              label="Last Name"
              rules={CreateUserValidation.lastname}
              {...labelWrapperProps}
            >
              <Input id="lastname" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={CreateUserValidation.email}
              {...labelWrapperProps}
            >
              <Input id="email" />
            </Form.Item>
            {additionalFields && additionalFields.map((item) => {
              let inputType = item.type === 'numeric' ? 'number' : 'text';
              let maxLength = undefined; // Default max length is undefined
              if (item.config) {
                try {
                  const trimmedConfig = item.config.trim(); // Trim whitespace from config string             
                  const config = JSON.parse(trimmedConfig); // Parse the trimmed config JSON string              

                  if (config.type === 'numeric') {
                    inputType = "number";
                    maxLength = parseInt(config.length);
                    if (this.value.length > maxLength) {
                      this.value = this.value.slice(0, maxLength); // Limit the input to maxLength
                    }
                  }
                } catch (error) {
                  console.error('Error parsing config JSON:', error);
                }
              }
              const handleSearch = (inputValue) => {
                const filteredOptions = item.value.filter((option) =>
                  option.name.toLowerCase().includes(inputValue.toLowerCase())
                );
                setFilteredOptions(filteredOptions);
              };

              return (
                <div key={item.name}>
                  <Form.Item
                    name={item.name}
                    label={item.name}
                    rules={[
                      { required: item.required === 1, message: `${item.name} is required` },
                      ...CreateUserValidation.practicelocation
                    ]}
                    {...labelWrapperProps}
                  >
                    {item.type === 'dropdown-multiselect' ? (
                      <Select
                        placeholder="Select a practice location"
                        id={item.id}
                        name={item.name}
                        data-security-type-id={item.id}
                        mode={item.type === 'dropdown-multiselect' ? 'multiple' : undefined}
                        showSearch={item.type === 'dropdown-multiselect'}
                        onSearch={(inputValue) => handleSearch(inputValue)} // Call handleSearch on search input
                        filterOption={(inputValue, option) =>
                          option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                        } // Filter options based on search input
                      >
                        {item.value.map((q) => (
                          <Option key={q.id} value={q.id} name={q.name}>
                            {q.name}
                          </Option>
                        ))}
                      </Select>
                    ) : <></>
                    }
                    {item.type === 'text' ? (
                      <Input
                        id={item.name}
                        name={item.name}
                        onKeyDown={handleNumKeyDown}
                        maxLength={maxLength} // Set max length dynamically
                      />
                    ) : <></>}
                  </Form.Item>
                </div>
              );
            })}
            <Form.Item
              name="timezone"
              label="Time Zone"
              rules={CreateUserValidation.timezone}
              {...labelWrapperProps}
            >
              <Select
                placeholder="Select a time zone"
                id="timezone"
                onChange={(value) => setSelectedTimezone(value)}
                value={selectedTimezone}
              >
                {timeZone && timeZone.map(q => (
                  <Option key={q.timezoneid} value={q.timezonename}>
                    {q.timezonedisplay}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          {/* Register fields end */}

          {/* Buttons */}
          {/* <Row className="button-container buttonBG" justify="space-around">
            <Col span={12} className="buttonRight" ><Form.Item>
              <Button onClick={handleCancelClick} icon={<CloseOutlined />} >Cancel</Button>
            </Form.Item></Col>
            <Col span={12} className="buttonLeft"><Form.Item  >
              <Button type="primary" htmlType="submit" id="register" >
                Register
              </Button>
            </Form.Item></Col>
          </Row> */}

          <div className="rightAlignButtons">
            <div>
              <Form.Item>
                <Button onClick={handleCancelClick} icon={<CloseOutlined />} >Cancel</Button>
              </Form.Item>
            </div>
            <div>
              <Form.Item>
                <Button type="primary" htmlType="submit" id="register" >
                  Register
                </Button>
              </Form.Item>
            </div>
          </div>
          {/* Buttons  end*/}
        </Form>
      </div >
    </div >
  );
};

export default Register;
