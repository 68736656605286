import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Result } from 'antd';


export default function Unauthorised() {
  const navigate = useNavigate();

  function handleButtonClick() {
    navigate('/login');
  }

  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={<Button type="primary" onClick={() => handleButtonClick()}>Login</Button>}
    />
  );
};
