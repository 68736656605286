import React, { useEffect, useState } from 'react';
import { Layout, Form, Input, Button, Space, Col, Row, DatePicker, Modal, Spin } from 'antd';
import InputMask from 'react-input-mask';
import { savePrimaryCarePhysicianInformationForm } from "../../services/ipadRegistartionService";
import moment from 'moment';
const { Header, Content } = Layout;

const PrimaryCarePhysician = (props) => {
    const [form] = Form.useForm();
    // const [dataLoaded, setDataLoaded] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    // useEffect(() => {
    //     if (props.patientRegistrationInfo?.patientRegistrationFormData) {
    //         setDataLoaded(true);
    //     }
    // }, [props]);

    function parseDate(dateString) {
        if (dateString) {
            const parsedDate = moment(dateString, 'YYYY-MM-DD');
            return parsedDate.isValid() ? parsedDate : null;
        }
        return null;
    }

    const onPrimaryCarePhysicianClick = async (values) => {
        setIsSubmitting(true);
        try {
            const updatedValues = { ...values };
            updatedValues.pcpLastVisit = values.pcpLastVisit ? moment(values.pcpLastVisit).format('YYYY-MM-DD') : '';
            updatedValues.PcpPhoneNumber = formatPhoneNumber(updatedValues.PcpPhoneNumber);
            updatedValues.ReferringProviderPhoneNumber = formatPhoneNumber(updatedValues.ReferringProviderPhoneNumber);
            const primaryCarePhysicianInfo = await savePrimaryCarePhysicianInformationForm(props.uniqueHash, updatedValues);
            if (primaryCarePhysicianInfo.message === 'Success') {
                props.handleNextClick(props);
            } else {
                Modal.error({
                    title: 'This is an error message',
                    content: primaryCarePhysicianInfo.errors.toString(),
                });
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const formatPhoneNumber = (phoneNumber) => {
        if (phoneNumber) {
            const digitsOnly = phoneNumber.replace(/\D/g, '');
            return `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6, 10)}`;
        }
        return '';
    };
    const validatePhoneNumber = (rule, value) => {
        if (value && value.replace(/\D/g, '').length < 10) {
            return Promise.reject('Please enter 10 digits');
        }
        return Promise.resolve();
    };


    const handlePopulateReferringFields = () => {
        const primaryFirstName = form.getFieldValue('PrimaryCareProvider');
        const primaryLastName = form.getFieldValue('PrimaryCareProviderLastName');
        const primaryPhoneNumber = form.getFieldValue('PcpPhoneNumber');

        form.setFieldsValue({
            ReferringProvider: primaryFirstName,
            ReferringProviderLastName: primaryLastName,
            ReferringProviderPhoneNumber: primaryPhoneNumber,
        });
    };

    // if (!dataLoaded) {
    //     return (
    //         <div className="spinner-overlay">
    //             <div className="spinner">
    //                 <Spin size="large" tip="Loading..." />
    //             </div>
    //         </div>
    //     );
    // }

    const onPrimaryCarePhysicianPreviousClick = () => {
        props.handlePreviousClick(props);
    };

    const validateFields = (rule, value, callback) => {
        if (value) {
            // Remove any spaces from the beginning and end of the input
            const trimmedValue = value.trim();

            // Regular expression pattern to match special characters and numbers
            const specialCharacterNumberPattern = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\|/\d]+/;

            if (trimmedValue.charAt(0) === '  ') {
                callback('This field cannot start with spaces');
            } else if (specialCharacterNumberPattern.test(trimmedValue)) {
                callback('Field cannot contain special characters or numbers');
            } else {
                callback(); // Validation passed
            }
        } else {
            callback(); // No validation needed if the field is empty
        }
    };
    // const primaryCarePhysician = props.patientRegistrationInfo.patientRegistrationFormData?.PrimaryCarePhysician ?? [];
    // let physicianDetails = {
    //     PrimaryCareProvider:  primaryCarePhysician?.PrimaryCareProvider || '',
    //     PrimaryCareProviderLastName: primaryCarePhysician?.PrimaryCareProviderLastName || '',
    //     PcpPhoneNumber: primaryCarePhysician?.PcpPhoneNumber || '',
    //     pcpLastVisit: parseDate(primaryCarePhysician?.pcpLastVisit) || "",
    //     PcpAddress: primaryCarePhysician?.PcpAddress || '',
    //     PcpCity: primaryCarePhysician?.PcpCity || '',
    //     PcpState: primaryCarePhysician?.PcpState || '',
    //     PcpZip: primaryCarePhysician?.PcpZip || '',
    //     ReferringProvider: primaryCarePhysician?.ReferringProvider || '',
    //     ReferringProviderLastName: primaryCarePhysician?.ReferringProviderLastName || '',
    //     ReferringProviderPhoneNumber: primaryCarePhysician?.ReferringProviderPhoneNumber || ''
    // };

    return (
        <div className="m-t-15">
            <h3>Primary Care Physician</h3>
            <Form form={form} layout="vertical" size="large" onFinish={onPrimaryCarePhysicianClick}
                //   initialValues={{
                //       ...physicianDetails
                //       }}
                >
                <Form.Item label="First Name" name="PrimaryCareProvider" rules={[{validator: validateFields}]}>
                    <Input type="text" className="form-control" />
                </Form.Item>
                <Form.Item label="Last Name" name="PrimaryCareProviderLastName" rules={[{validator: validateFields}]}>
                    <Input type="text" className="form-control" />
                </Form.Item>
                <Form.Item label="Phone Number" name="PcpPhoneNumber" rules={[{validator: validatePhoneNumber}]}>
                    <InputMask mask="(999) 999-9999" placeholder="(NNN) NNN-NNN" className="form-control" id="PhoneNumber" />
                </Form.Item>
                <Form.Item label="When was the last visit with your pcp" name="pcpLastVisit">
                    <DatePicker className="form-control" size="large" format="MM/DD/YYYY" />
                </Form.Item>
                <Form.Item label="Address" name="PcpAddress">
                    <Input />
                </Form.Item>
                <Row gutter={24}>
                    <Col md={8}>
                        <Form.Item label="City" name="PcpCity" rules={[{validator: validateFields}]}>
                            <Input className="form-control" />
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item label="State" name="PcpState" rules={[{validator: validateFields}]}>
                            <Input className="form-control" />
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item label="Zip" name="PcpZip" type="number" rules={[
                            {
                                pattern: /^\d{5}$/,
                                message: 'Zip code must be 5 digits.',
                            },
                        ]} >
                            <Input type="text" className="form-control" maxLength={5} onKeyPress={(e) => {
                                const pattern = /^[0-9]*$/;
                                if (!pattern.test(e.key)) {
                                    e.preventDefault();
                                }
                            }} />
                        </Form.Item>
                    </Col>
                </Row>
                <hr />
                <small id="referringProviderFields" className="btn-link" onClick={handlePopulateReferringFields}>
                    Use primary care information for referring provider.
                </small>
                <Form.Item label="Referring Provider First Name" name="ReferringProvider" rules={[{validator: validateFields}]}>
                    <Input type="text" className="form-control" placeholder="First Name" />
                </Form.Item>
                <Form.Item label="Referring Provider Last Name" name="ReferringProviderLastName" rules={[{validator: validateFields}]}>
                    <Input type="text" className="form-control" placeholder="Last Name" />
                </Form.Item>
                <Form.Item label="Phone Number" name="ReferringProviderPhoneNumber"
                           rules={[{validator: validatePhoneNumber}]}>
                    <InputMask mask="(999) 999-9999" placeholder="(NNN) NNN-NNN" className="form-control" id="ProviderPhoneNumber" />
                </Form.Item>
                <Form.Item className="form-footer-button">
                    {isSubmitting ? (
                        <div className="spinner-overlay">
                            <div className="spinner">
                                <Spin size="large" tip="Loading..." />
                            </div>
                        </div>
                    ) : (
                    <Space>
                        <Button type="primary" onClick={onPrimaryCarePhysicianPreviousClick}>
                            Previous
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Next
                        </Button>
                    </Space>
                        )}
                </Form.Item>
            </Form>
        </div>
    );
};


export default PrimaryCarePhysician;
