import React, { useState, useEffect } from 'react';
import { Form, Input, Col, Row, TimePicker, Skeleton } from 'antd';
import { EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { handleUpdateClick, handleCancelClick, handleTimePickerChange, handleInputChange, updatePracticeData } from "./practiceHandler";
import "../../../assets/styles/practice.css";
import "../../../assets/styles/common.css";
import { practiceList } from "../../services/practiceService";
import { screenActionCodes, ButtonComponet } from '../../utils/moduleScreenCodes';

function PracticeDefault(props) {
    const [editMode, setEditMode] = useState(false);
    const [practiceData, setPracticeData] = useState(null);
    const [initialPracticeData, setInitialPracticeData] = useState(null);
    const [timePickerValues, setTimePickerValues] = useState({});
    const [initialTimePickerValues, setInitialTimePickerValues] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        async function fetchPracticeData() {
            setLoading(true);
            try {
                const practice = await practiceList();
                if (practice && practice.length > 0) {
                    const practiceData = practice[0];
                    setPracticeData(practiceData);
                    setInitialPracticeData(practiceData);

                    const timePickerValues = {
                        workhourfrom: dayjs(practiceData?.workhourfrom, 'HH:mm:ss'),
                        workhourto: dayjs(practiceData?.workhourto, 'HH:mm:ss'),
                        offhourfrom: dayjs(practiceData?.offhourfrom, 'HH:mm:ss'),
                        offhourto: dayjs(practiceData?.offhourto, 'HH:mm:ss'),
                    };

                    setTimePickerValues(timePickerValues);
                    setInitialTimePickerValues(timePickerValues);
                    setEditMode(false);
                } else {
                    console.error("Practice data not found or empty:", practice);
                }
            } catch (error) {
                console.error("Error fetching practice data:", error);
            }
            finally {
                setLoading(false);
            }
        }

        fetchPracticeData();
    }, []);


    return (
        <div>
            <Skeleton active loading={loading} paragraph={{ rows: 10 }}>
                <Form layout='vertical'>
                    <div className="practice-content">
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item label="Practice Name">
                                    <Input name="practiceName" id="practiceName" value={practiceData?.practiceName} onChange={e => handleInputChange('practiceName', e.target.value, setPracticeData)} disabled />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Practice Id" >
                                    <Input name="practiceId" id="practiceId" value={practiceData?.practiceId} onChange={e => handleInputChange('practiceId', e.target.value, setPracticeData)} disabled />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Identification">
                                    <Input name="identification" id="identification" value={practiceData?.identification} onChange={e => handleInputChange('identification', e.target.value, setPracticeData)} disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item label="Work Hours From">
                                    <TimePicker
                                        value={timePickerValues.workhourfrom} id="workhourfrom"
                                        onChange={time => handleTimePickerChange('workhourfrom', time, setTimePickerValues)}
                                        disabled={!editMode}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Work Hours To">
                                    <TimePicker
                                        value={timePickerValues.workhourto} id="workhourto"
                                        onChange={time => handleTimePickerChange('workhourto', time, setTimePickerValues)}
                                        disabled={!editMode}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Off Hours From">
                                    <TimePicker
                                        value={timePickerValues.offhourfrom} id="offhourfrom"
                                        onChange={time => handleTimePickerChange('offhourfrom', time, setTimePickerValues)}
                                        disabled={!editMode}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Off Hours To">
                                    <TimePicker
                                        value={timePickerValues.offhourto} id="offhourto"
                                        onChange={time => handleTimePickerChange('offhourto', time, setTimePickerValues)}
                                        disabled={!editMode}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Verify Insurance">
                                    <Input name="verifyinsurance" id="verifyinsurance" value={practiceData?.verifyinsurance} onChange={e => handleInputChange('verifyinsurance', e.target.value, setPracticeData)} disabled={!editMode} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </Form>
                <div className="button-footer">
                    <ButtonComponet actionProps={props} actionCode={screenActionCodes.CONF_PRAC_EDIT} title="Edit" icon={<EditOutlined />} id="Edit" onClick={() => setEditMode(true)} disabled={editMode} />
                    <ButtonComponet actionProps={props} actionCode={screenActionCodes.CONF_PRAC_EDIT} title="Update" icon={<CheckOutlined />} id="Update" type="primary" onClick={() => {
                        handleUpdateClick(setEditMode);
                        updatePracticeData(practiceData, timePickerValues, setError, setPracticeData, setTimePickerValues);
                    }} disabled={!editMode} />
                    <ButtonComponet actionProps={props} actionCode={screenActionCodes.CONF_PRAC_EDIT} title="Cancel" icon={<CloseOutlined />} id="Cancel" onClick={() => handleCancelClick(setPracticeData, setEditMode, initialPracticeData, setTimePickerValues, initialTimePickerValues)} disabled={!editMode} />
                </div>
            </Skeleton>
        </div>
    );
};

export default PracticeDefault;
