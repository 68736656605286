import axiosInterceptor from "../../middleware/axiosInterceptor";

export const getreportData = async (report) => {
  console.log("report",report)

    const url = "/api/reports/gridData";
    try {
      const response = await axiosInterceptor.post(url, report);
      console.log("response1",response);
      return response.data.report;
      } catch (error) {
      console.error(error);
      return { error: error.message };
    }
       
  };

  export const getReportDropDownData = async () => {
    // console.log("report",report)
      const url = "/api/reports";
      try {
        const response = await axiosInterceptor.get(url);
        console.log("response1",response.data.report );
        return response.data.report;
        } catch (error) {
        console.error(error);
        return { error: error.message };
      }
  
      
    };