import React, { useState } from 'react';
import { Form, Input, Button, DatePicker, Radio, Select, Row, Col, TimePicker } from 'antd';

const { TextArea } = Input;
const { Option } = Select;

const RecurringAppointment = ({ onBack }) => {
    const [form] = Form.useForm();
    const [recurringType, setRecurringType] = useState('bi-weekly');
    const [searchQueries, setSearchQueries] = useState({});

    // const handleFinish = (values) => {
    //     console.log('Scheduled appointment:', values);
    // };

    // const handleSearch = (name, value) => {
    //     setSearchQueries((prev) => ({ ...prev, [name]: value }));
    // };

    // const filterOptions = (input, option) => {
    //     return input.length >= 1 && option.children.toLowerCase().includes(input.toLowerCase());
    // };

    // // Hardcoded JSON data for dropdown options
    // const patients = ["Patient 1", "Patient 2", "Patient 3"];
    // const phones = ["123-456-7890", "098-765-4321", "111-222-3333"];
    // const insurances = ["Insurance A", "Insurance B", "Insurance C"];
    // const locations = ["Location A", "Location B", "Location C"];
    // const physicians = ["Dr. Smith", "Dr. Johnson", "Dr. Brown"];
    // const resources = ["Resource A", "Resource B", "Resource C"];
    // const departments = ["Department A", "Department B", "Department C"];
    // const visitTypes = ["Consultation", "Follow-up", "Routine Checkup"];
    // const reasonsForVisit = ["Consultation", "Follow-up", "Routine Checkup"];

    return (
        <div className="recurring-appointment">
            <Form
                form={form}
                layout="vertical"
                requiredMark={false}
            >
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item name="patientName" label="Patient Name"
                            rules={[{ required: true, message: 'Please select a patient' }]}
                        >
                            <Select placeholder="Search patients">
                                <Select.Option>1</Select.Option>
                                <Select.Option>2</Select.Option>
                                <Select.Option>3</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="dob"
                            label="DOB"
                            rules={[{ required: true, message: 'Please select a date of birth' }]}
                        >
                            <DatePicker />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="phone" label="Phone"
                            rules={[{ required: true, message: 'Please enter a phone number' }]}
                        >
                            <Select placeholder="Search Phone number">
                                <Select.Option>1</Select.Option>
                                <Select.Option>2</Select.Option>
                                <Select.Option>3</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>

                    <Col span={8}>
                        <Form.Item
                            name="insurance"
                            label="Insurance"
                            rules={[{ required: true, message: 'Please enter insurance information' }]}
                        >
                            <Select placeholder="Search insurence">
                                <Select.Option>1</Select.Option>
                                <Select.Option>2</Select.Option>
                                <Select.Option>3</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="location"
                            label="Location"
                            rules={[{ required: true, message: 'Please enter a location' }]}
                        >
                            <Select placeholder="Search Location">
                                <Select.Option>1</Select.Option>
                                <Select.Option>2</Select.Option>
                                <Select.Option>3</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="physician"
                            label="Physician"
                            rules={[{ required: true, message: 'Please enter a physician name' }]}
                        >
                            <Select placeholder="Search Physician">
                                <Select.Option>1</Select.Option>
                                <Select.Option>2</Select.Option>
                                <Select.Option>3</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name="resource"
                            label="Resource"
                            rules={[{ required: true, message: 'Please enter a resource' }]}
                        >
                            <Select placeholder="Search resource">
                                <Select.Option>1</Select.Option>
                                <Select.Option>2</Select.Option>
                                <Select.Option>3</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="department"
                            label="Department"
                            rules={[{ required: true, message: 'Please enter a department' }]}
                        >
                            <Select placeholder="Search department">
                                <Select.Option>1</Select.Option>
                                <Select.Option>2</Select.Option>
                                <Select.Option>3</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="visitType"
                            label="Visit Type"
                            rules={[{ required: true, message: 'Please enter a visit type' }]}
                        >
                            <Select placeholder="Search Visit Type">
                                <Select.Option>1</Select.Option>
                                <Select.Option>2</Select.Option>
                                <Select.Option>3</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            name="reasonForVisit"
                            label="Reason For Visit"
                            rules={[{ required: true, message: 'Please select a reason for visit' }]}
                        >
                            <Select placeholder="Select Reason for Visit">
                                <Select.Option>1</Select.Option>
                                <Select.Option>2</Select.Option>
                                <Select.Option>3</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            name="startDate"
                            label="Start Date"
                            rules={[{ required: true, message: 'Please select a start date' }]}
                        >
                            <DatePicker />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="endDate"
                            label="End Date"
                            rules={[{ required: true, message: 'Please select an end date' }]}
                        >
                            <DatePicker />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name="time"
                            label="Time"
                            rules={[{ required: true, message: 'Please select a time' }]}
                        >
                            <TimePicker />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="recurringType"
                            label="Recurring Type"
                        >
                            <Radio.Group
                                onChange={(e) => setRecurringType(e.target.value)}
                                value={recurringType}
                            >
                                <Radio value="daily">Daily</Radio>
                                <Radio value="weekly">Weekly</Radio>
                                <Radio value="bi-weekly">Bi-weekly</Radio>
                                <Radio value="monthly">Monthly</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="notes"
                            label="Notes"
                        >
                            <TextArea rows={3} />
                        </Form.Item>
                    </Col>
                </Row >
                <Row justify="end" gutter={[16, 16]}>
                    <Col className="buttongap">
                        <Button type="primary" htmlType="submit">
                            Schedule
                        </Button>
                    </Col>
                    <Col className="buttongap">
                        <Button htmlType="button" onClick={onBack}>
                            Close
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default RecurringAppointment;
