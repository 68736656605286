import { message, Modal } from 'antd';
import { cloneDeep } from 'lodash';
import { userList, deleteUser } from '../../../services/userListService';

export async function getData(setLoading, setUserData) {
    try {
        setLoading(true);
        const response = await userList();
        setUserData(response);
    } catch (error) {
        console.error('Error fetching user data:', error);
    } finally {
        setLoading(false);
    }
}

export async function handleEdit(user, setSelectedUser, setUserId, setEditModalVisible) {
    setSelectedUser(user);
    setUserId(user.userId); // Set the userId when editing
    setEditModalVisible(true);
};

// export function handleUpdateSuccess(setLoading, setUserData, setEditModalVisible) {
//     return function () {
//         Modal.success({ title: "Success", content: "User updated  successfully." });
//         setEditModalVisible(false);
//         getData(setLoading, setUserData);
//     };
// }
export function handleUpdateSuccess(
    setLoading,
    setUserData,
    setEditModalVisible,
    setDrawerVisible
  ) {
    return function () {
      Modal.success({ title: "Success", content: "User updated successfully." });
      setDrawerVisible(false); // Close the drawer
      setEditModalVisible(false);
      getData(setLoading, setUserData);
    };
  }

export function handleCancelEdit(setEditModalVisible) {
    return function () {
        setEditModalVisible(false);
    };
}

export function handleAction(type, user, setModalData) {
    setModalData({ type, user, visible: true });
};

export function handleDelete(userId, setModalData) {
    const loggedInUserId = sessionStorage.getItem('userId');
    handleAction('delete', { userId, loggedInUserId }, setModalData);
};

export async function handleModalConfirm(modalData, setDeletedUserName, setLoading, setUserData, setModalData) {
    if (modalData.type === 'delete') {
        try {
            const { userId, loggedInUserId, username } = modalData.user;
            setDeletedUserName(username);

            const deleteData = {
                userid: userId,
                updatedby: loggedInUserId
            };

            const response = await deleteUser(deleteData);
            if (response && response.data && response.data.success) {
                let deletedUserName = response.data
                // message.success(`User "${deletedUserName}" deleted successfully`);
                // message.success(`User deleted successfully`);

                Modal.success({ title: "Success", content: "User deleted  successfully." });
                // Optionally, you can refresh the user list after deletion
                getData(setLoading, setUserData);
            } else {
                message.error("Failed to delete user");
            }
        } catch (error) {
            console.error('Error deleting user:', error);
            message.error("Failed to delete user");
        } finally {
            setModalData({ ...modalData, visible: false });
        }
    }
};

export async function handleModalCancel(setModalData, modalData) {
    setModalData({ ...modalData, visible: false });
};


// Function to extract data with only the required columns
export const getGridData = (userData) => {
    // Deep copy the userData array
    const data = cloneDeep(userData);

    // Map over each user object and return a new object with only the required columns
    return data.map(user => ({
        firstname: user.firstname,
        lastname: user.lastname,
        username: user.username,
        email: user.email,
        provider: user.provider,
        role: user.role,
        lastLoginAt: user.lastLoginAt,
        createdAt: user.createdAt

    }));

};

// handle modal close
// export function handleCloseDrawer(setDrawerVisible, form) {
//     setDrawerVisible(false);
//     // Reset the form fields of register 
//     form.resetFields();
// };
export function handleCloseDrawer(setDrawerVisible, form) {
    if (typeof setDrawerVisible === 'function') { // Check if setDrawerVisible is a function
        setDrawerVisible(false);
    } else {
        console.error('setDrawerVisible is not a function');
    }
    // Reset the form fields of register 
    // form.resetFields();
};


// export async function handleModalConfirm(setDeletedUserName, setModalData, modalData, setLoading, setUserData) {
//     if (modalData.type === 'delete') {
//         try {
//             const { userId, loggedInUserId, username } = modalData.user;
//             setDeletedUserName(username);

//             const deleteData = {
//                 userid: userId,
//                 updatedby: loggedInUserId
//             };

//             const response = await deleteUser(deleteData);
//             if (response && response.data && response.data.success) {
//                 message.success(`User deleted successfully`);
//                 // Refresh the user list after deletion
//                 GetData(setLoading, setUserData);
//             } else {
//                 message.error("Failed to delete user");
//             }
//         } catch (error) {
//             console.error('Error deleting user:', error);
//             message.error("Failed to delete user");
//         } finally {
//             // Update modal visibility after the operation
//             setModalData(prevModalData => ({ ...prevModalData, visible: false }));
//         }
//     }
// }

// export async function handleModalCancel(setModalData, modalData) {
//     setModalData(prevModalData => ({ ...prevModalData, visible: false }));
// }
