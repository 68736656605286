import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PersonalInformation from "./Personal_Information";
import ContactInformation from "./Contact_Information";
import {
    getDropdowns,
    getPatientRegistrationInformation,
    getRegistrationPages, verifyDateOfBirth
} from "../../services/ipadRegistartionService";
import InsuranceInformation from "./Insurance-Information";
import PharmacyInformation from "./Pharmacy_Information";
import PrimaryCarePhysician from "./Primary_Care_Physician";
import Consents from "./Consents";
import License from "./License_Upload";
import Insurance from "./Insurance_Upload"
import FinalPage from "./Final_Page";
import { Button, DatePicker, Form, Modal } from "antd";
import 'antd/dist/reset.css';
// import 'antd/dist/antd.css';
import moment from "moment/moment";
import CurrentMedications from "./Current_Medications";
import Allergies from "./Allergies";
import ImmunizationHistory from "./ImmunizationHistory";
import PatientMedicalHistory from "./PatientMedicalHistory";
import PatientWithSpecialHealthcare from "./PatientWithSpecialHealthcare";
import SlidingFeeDiscountAttestation from "./SlidingFeeDiscountAttestation";
import HealthInformation from "./Health_Info_Consent";
import MetroConsents from "./MetroConsents";
import "../../../assets/styles/ipadRegistration.css"

const components = {
    // PharmacyInformation: PharmacyInformation,
    PersonalInformation: PersonalInformation,
    // ContactInformation: ContactInformation,
    // InsuranceInformation: InsuranceInformation,
    // PrimaryCarePhysician: PrimaryCarePhysician,
    // Consents: Consents,
    License: License,
    // Insurance: Insurance,
    // FinalPage: FinalPage,
    // CurrentMedications: CurrentMedications,
    // Allergies: Allergies,
    // ImmunizationHistory: ImmunizationHistory,
    // PatientMedicalHistory: PatientMedicalHistory,
    // PatientWithSpecialHealthcare: PatientWithSpecialHealthcare,
    // SlidingFeeDiscountAttestation: SlidingFeeDiscountAttestation,
    // HealthInformation: HealthInformation,
    // MetroConsents: MetroConsents
}
const MasterPage = (props) => {
    const [dobRefForm] = Form.useForm();
    const [activeIpadPage, setActiveIpadPage] = useState('PersonalInformation');
    const [dropdownValues, setDropdownValues] = useState([]);
    const [formPages, setFormPages] = useState([]);
    const [patientInfo, setPatientInfo] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [modalDobPopup, setModalDobPopup] = useState(true);
    const [dOBValue, setDOBValue] = useState(null);
    const [formControls, setFormControls] = useState([]);
    const [stepsData, setStepsData] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);
    const currentPageURL = window.location.href;

    useEffect(() => {
        // getPatientRegistrationInfo(props.list);
        // getDropdown();
        // getRegistrationPageInfo();

        const url = new URL(window.location.href.split('#')[0]);
        if (url.searchParams.has('prId')) {
            setModalDobPopup(false);
        }
        if (currentPageURL.includes('pre-registration')) {
            setStepsData([0, 1, 2, 3, 4]);
        } else if (currentPageURL.includes('ipad-registration')) {
            setStepsData([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);
        }
    }, []);

    // useEffect(() => {
    //     if (dOBValue != null && dOBValue != '') {
    //         dobRefForm.setFields([
    //             {
    //                 name: 'dob',
    //                 value: moment(dOBValue, "MM/DD/YYYY")
    //             },
    //         ]);
    //     }
    //     else {
    //         dobRefForm.resetFields();
    //     }

    // }, [dOBValue]);
    const getDropdown = async () => {
        let dropdownList = await getDropdowns(props.practice);
        setDropdownValues(dropdownList);
    }
    const getRegistrationPageInfo = async () => {
        let formPages = await getRegistrationPages(props.formType);
        setActiveIpadPage(formPages.pageName[0]);
        setFormPages(formPages.pageName);
        setFormControls(formPages.formControls);
    }
    const getPatientRegistrationInfo = async (uniqueHash) => {
        let response = await getPatientRegistrationInformation(uniqueHash);
        setPatientInfo(response);
    }
    const handlePreviousClick = async (values) => {
        let response = await getPatientRegistrationInformation(values.uniqueHash);
        setPatientInfo(response);
        let formPages = values.formPages;
        let currentIndex = formPages.indexOf(values.activeIpadPage);
        if (currentIndex > 0) {
            let previousIndex = currentIndex - 1;
            if (values.activeIpadPage === 'PersonalInformation') {
                if (response.patientRegistrationInfo.insurance_type === 'Insured') {
                    setActiveIpadPage(formPages[previousIndex]);
                    setCurrentStep(previousIndex);
                } else {
                    if (values.formType === '2') {
                        let skipIndex = previousIndex - 1;
                        setActiveIpadPage(formPages[skipIndex]);
                        setCurrentStep(skipIndex);
                    }
                    else {
                        setActiveIpadPage(formPages[previousIndex]);
                        setCurrentStep(previousIndex);
                    }
                }
            } else {
                setActiveIpadPage(formPages[previousIndex]);
                setCurrentStep(previousIndex);
            }
        }
        const url = new URL(window.location.href.split('#')[0]);
        if (url.searchParams.has('prId')) {
            setModalDobPopup(false);
        }
        else {
            setModalDobPopup(values.modalDobPopup);
        }
    };

    const handleNextClick = async (values) => {
        let response = await getPatientRegistrationInformation(values.uniqueHash);
        setPatientInfo(response);
        let formPages = values.formPages;
        let currentIndex = formPages.indexOf(values.activeIpadPage);
        if (currentIndex < formPages.length - 1) {
            let nextIndex = currentIndex + 1;
            if (values.activeIpadPage === 'Insurance') {
                if (response.patientRegistrationInfo.insurance_type === 'Insured') {
                    setActiveIpadPage(formPages[nextIndex]);
                    setCurrentStep(nextIndex);
                } else {
                    if (values.formType === '2') {
                        let skipIndex = nextIndex + 1;
                        setActiveIpadPage(formPages[skipIndex]);
                        setCurrentStep(skipIndex);
                    }
                    else {
                        setActiveIpadPage(formPages[nextIndex]);
                        setCurrentStep(nextIndex);
                    }
                }
            } else {
                if (values.activeIpadPage === 'Consents') {
                    setActiveIpadPage(formPages[nextIndex]);
                    setCurrentStep(9);
                } else if (values.activeIpadPage === 'MetroConsents') {
                    setActiveIpadPage(formPages[nextIndex]);
                    setCurrentStep(4);
                } else {
                    setActiveIpadPage(formPages[nextIndex]);
                    setCurrentStep(nextIndex);
                }
            }
        }
        const url = new URL(window.location.href.split('#')[0]);
        if (url.searchParams.has('prId')) {
            setModalDobPopup(false);
        }
        else {
            setModalDobPopup(values.modalDobPopup);
        }
    };
    const handleVerifyDOBSubmit = async (values) => {
        const modalFormValues = await dobRefForm.validateFields();
        if (modalFormValues) {
            const formattedDateOfBirth = moment(values.dob).format('MM/DD/YYYY');
            let response = await verifyDateOfBirth(props.list, formattedDateOfBirth);
            if (response.message === 'Success') {
                setModalDobPopup(false);
            } else {
                Modal.error({
                    title: 'This is an error message',
                    content: 'Invalid DOB',
                });
            }
        }
    };
    const renderComponent = (block, onHandlePreviousClick, onHandleNextClick) => {
        return React.createElement(components[block], {
            uniqueHash: props.list,
            patientRegistrationInfo: patientInfo,
            dropdownList: dropdownValues,
            formPages: formPages,
            activeIpadPage: activeIpadPage,
            handlePreviousClick: onHandlePreviousClick,
            handleNextClick: onHandleNextClick,
            formType: props.formType,
            modalDobPopup: modalDobPopup,
            formControls: formControls,
        });
    }
    const handleDatePickerChange = (date, dateString) => {
        setDOBValue(dateString);
    };
    const handleOpenChange = (open) => {
        if (!open) {
            const dobValue = document.getElementById('patientDOBPicker');
            setDOBValue(dobValue.value);
        }
    };

    return (
        <div>
            {/* <ul
             className="list-unstyled multi-steps"
            >
                {stepsData.map((step) => (
                    <li
                        key={step}
                        id={`step${step}`}
                        className={currentStep === step ? 'is-active' : ''}
                    />
                ))}
            </ul> */}

            {/* {!modalDobPopup && */}
            <div>
                {renderComponent(activeIpadPage, handlePreviousClick, handleNextClick)}
            </div>
            {/* } */}
            {/* <Modal className="dob-modal" open={modalDobPopup}
                onOk={() => {
                    if (!dobRefForm.getFieldValue('dob')) {
                        dobRefForm.setFields([
                            {
                                name: 'dob',
                                errors: ['This field is required.'],
                            },
                        ]);
                    } else {
                        dobRefForm.submit();
                    }
                }}
                okText="Submit"
                maskClosable={false}
                cancelText={null} width={500}
            >
                <Form form={dobRefForm} layout="vertical" onFinish={handleVerifyDOBSubmit}>
                    <Form.Item label="Enter Patient DOB" name="dob"
                        rules={[{ required: true, message: 'This field is required.' }]}>
                        <DatePicker id="patientDOBPicker" format="MM/DD/YYYY" onChange={handleDatePickerChange}
                            showToday={false}
                            onOpenChange={handleOpenChange} />
                    </Form.Item>
                </Form>
            </Modal> */}
        </div>);
}
export default MasterPage;
if (document.getElementById('ipadRegistration')) {
    const thisElement = document.getElementById('ipadRegistration');
    let props = Object.assign({}, thisElement.dataset);
    ReactDOM.render(<MasterPage list={props.list} practice={props.practice}
        formType={props.formId} />, document.getElementById('ipadRegistration'));
}

