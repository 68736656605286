import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {
    Form,
    Input,
    Button,
    Radio,
    Divider,
    Row,
    Col,
    Spin,
    Typography,
    Modal, Space
} from "antd";
import InputMask from "react-input-mask";
import {savePatientWithSpecialHealthcare} from "../../services/ipadRegistartionService";

const PatientWithSpecialHealthcare = (props) => {
    const [qualifyForSpecialHealthcare, setQualifyForSpecialHealthcare] = useState('No');
    const [form] = Form.useForm();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [legalGuardianValue, setLegalGuardianValue] = useState('No');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (props.patientRegistrationInfo?.patientRegistrationFormData) {
            setDataLoaded(true);
            let info = props.patientRegistrationInfo.patientRegistrationFormData?.PatientWithSpecialHealthcare ?? [];
            if(info.hasOwnProperty('SpecialHealthCare')){
                setQualifyForSpecialHealthcare(info.SpecialHealthCare);
            }
            if(info.hasOwnProperty('LegalGuardian')){
                setLegalGuardianValue(info.LegalGuardian);
            }
        }
    }, [props.patientRegistrationInfo]);

    const onSpecialHealthCarePreviousClick = () => {
        props.handlePreviousClick(props);
    }
    const handleFormSubmit = async (values) => {
        try {
            setIsSubmitting(true);
            let specialHealthCareResponse = await savePatientWithSpecialHealthcare(props.uniqueHash, values);
            if (specialHealthCareResponse.message === 'Success') {
            props.handleNextClick(props);
            } else {
                Modal.error({
                    title: 'This is an error message',
                    content: 'See Console',
                });
            }
        } finally {
            if (!isSubmitting) {
                return (
                    <div className="spinner-overlay">
                        <div className="spinner">
                            <Spin size="large" tip="Loading..."/>
                        </div>
                    </div>
                );
            }
            setIsSubmitting(false);
        }
    }
    const handleHealthCareRadioChange = (e) => {
        const newValue = e.target.value;
        setQualifyForSpecialHealthcare((prevValue) => (prevValue === newValue ? null : newValue));
    };
    const handleLegalGuardianChange = (e) => {
        const newValue = e.target.value;
        setLegalGuardianValue((prevValue) => (prevValue === newValue ? null : newValue));
    };

    const formatPhoneNumber = (phoneNumber) => {
        const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phoneNumber;
    };

    const validatePhoneNumber = (rule, value) => {
        if (value && value.replace(/\D/g, '').length < 10) {
            return Promise.reject('Please enter 10 digits');
        }
        return Promise.resolve();
    };

    if (!dataLoaded) {
        return (
            <div className="spinner-overlay">
                <div className="spinner">
                    <Spin size="large" tip="Loading..."/>
                </div>
            </div>
        );
    }
    const patientInfo = props.patientRegistrationInfo.patientRegistrationFormData?.PatientWithSpecialHealthcare ?? [];

    let patientDetails = {
        SpecialHealthCare: patientInfo?.SpecialHealthCare || "",
        LivingSituation: patientInfo?.LivingSituation || "",
        FacilityName: patientInfo?.FacilityName || "",
        ContactName: patientInfo?.ContactName || "",
        AgencyEmail: patientInfo?.AgencyEmail || "",
        SchedulingContactName: patientInfo?.SchedulingContactName || "",
        LegalGuardian : patientInfo?.LegalGuardian || "",
        GuardianName : patientInfo?.GuardianName || "",
        ApplicableGuardianship: patientInfo?.ApplicableGuardianship || "",
        PatientCommunicate: patientInfo?.PatientCommunicate || "",
        CaseManagerName: patientInfo?.CaseManagerName || "",
        CaseManagerAgency: patientInfo?.CaseManagerAgency || "",
        CaseManagerEmail: patientInfo?.CaseManagerEmail || "",
        CaseManagerFax: patientInfo?.CaseManagerFax || "",
    };
    const formattedAgencyPhoneNumber = patientInfo?.AgencyPhoneNumber || "";
    const formattedSchedulingPhoneNumber = patientInfo?.SchedulingPhoneNumber || "";
    const formattedGuardianPhoneNumber = patientInfo?.GuardianPhoneNumber || "";
    const formattedCaseManagerPhoneNumber = patientInfo?.CaseManagerPhoneNumber || "";
    // const SpecialHealthCare = patientInfo?.SpecialHealthCare || "";
    // const LegalGuardian = patientInfo?.LegalGuardian || "";


    return (
        <div>
            <h3 className="mb-3">Patient with special healthcare needs</h3>
            <Divider className="mar-tb-10px" />
            <Form form={form} layout="vertical" colon={false} size="large" scrollToFirstError={true} onFinish={handleFormSubmit}
                  initialValues={{
                      ...patientDetails
                  }}>
                <Form.Item name="SpecialHealthCare" className="custom-form-row"
                           label="Does the patient qualify for special healthcare needs ?">
                    <Radio.Group onChange={handleHealthCareRadioChange}>
                        <Radio value='Yes'>Yes</Radio>
                        <Radio value='No'>No</Radio>
                    </Radio.Group>
                </Form.Item>
                <Divider className="mar-tb-10px"/>
                { qualifyForSpecialHealthcare === 'Yes' && (
                    <Form.Item>
                        <h4>Patient Living Situation</h4>
                        <h4>(Please Select)</h4>
                        <Form.Item name="LivingSituation"
                                   rules={[{required: true, message: 'This field is required.'}]}>
                            <Radio.Group>
                                <Space direction="vertical">
                                    <Radio value="Private Home with Family">Private Home with Family</Radio>
                                    <Radio value="Lives Independently">Lives Independently</Radio>
                                    <Radio value="O.P.W.D.D Certified Setting">O.P.W.D.D Certified Setting</Radio>
                                    <Radio value="Individual Support Services">Individual Support Services</Radio>
                                    <Radio value="Individual Residence Alternative">Individual Residence
                                        Alternative</Radio>
                                    <Radio value="Intermediate Care Facility">Intermediate Care Facility</Radio>
                                    <Radio value="Assisted Living Facility/Nursing Home">Assisted Living
                                        Facility/Nursing Home</Radio>
                                    <Radio value="Other Supported Living Program">Other Supported Living Program</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        <Divider/>
                    </Form.Item>
                )}
                <Form.Item label="Agency / Facility Name (if Applicable):" name="FacilityName">
                    <Input id="inputFacilityName"></Input>
                </Form.Item>
                <Form.Item label="Contact Name" name="ContactName">
                    <Input id="inputContactName"></Input>
                </Form.Item>
                <Form.Item label="Phone Number" name="AgencyPhoneNumber" initialValue={formattedAgencyPhoneNumber}
                           rules={[{validator: validatePhoneNumber}]}>
                    <InputMask type="text" className="form-control" id="inputAgencyPhoneNumber" mask="(999) 999-9999"
                               placeholder="(NNN) NNN-NNN"
                    />
                </Form.Item>
                <Form.Item label="Email" name="AgencyEmail">
                    <Input type="email" id="inputAgencyEmail"/>
                </Form.Item>
                <Divider className="mar-tb-10px"/>
                <Form.Item label="Scheduling Contact Name (If different than patient)"
                           name="SchedulingContactName">
                    <Input id="inputContactName"></Input>
                </Form.Item>
                <Form.Item label="Phone Number" name="SchedulingPhoneNumber" initialValue={formattedSchedulingPhoneNumber}
                           rules={[{validator: validatePhoneNumber}]}>
                    <InputMask type="text" className="form-control" id="inputSchedulingPhoneNumber" mask="(999) 999-9999"
                               placeholder="(NNN) NNN-NNN"
                    />
                </Form.Item>
                <Divider className="mar-tb-10px"/>
                <h4>Guardian Information</h4>
                <Form.Item className="custom-form-row" name="LegalGuardian"
                           label="Does this patient have a Legal Guardian ?">
                    <Radio.Group onChange={handleLegalGuardianChange}>
                        <Radio value='Yes' id="inputLegalGuardianYes">Yes</Radio>
                        <Radio value='No' id="inputLegalGuardianNo">No</Radio>
                    </Radio.Group>
                </Form.Item>
                {legalGuardianValue === 'Yes'  && (
                    <Form.Item>
                        <Form.Item label="If yes, identify the applicable guardianship :" name="ApplicableGuardianship"
                                   rules={[{required: true, message: 'This field is required.'}]}>
                            <Radio.Group>
                                <Space direction="vertical">
                                    <Radio value="Patient">Patient</Radio>
                                    <Radio value="Family Member">Family Member</Radio>
                                    <Radio value="Agency">Agency</Radio>
                                    <Radio value="Attorney">Attorney</Radio>
                                    <Radio value="ACS/Designated Agency">ACS/Designated Agency</Radio>
                                    <Radio value="SDMC">SDMC</Radio>
                                    <Radio value="Custodial Parent">Custodial Parent</Radio>
                                    <Radio value="CAB (Willowbrook Class)">CAB (Willowbrook Class)</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        <Divider className="mar-tb-10px"/>
                    </Form.Item>
                )}
                <h6>Guardian Contact Information :</h6>
                <Form.Item label="Name" name="GuardianName">
                    <Input id="inputGuardianName"></Input>
                </Form.Item>
                <Form.Item label="Phone Number" name="GuardianPhoneNumber" initialValue={formattedGuardianPhoneNumber}
                           rules={[{validator: validatePhoneNumber}]}>
                    <InputMask type="text" className="form-control" id="inputGuardianPhoneNumber" mask="(999) 999-9999"
                               placeholder="(NNN) NNN-NNN"
                    />
                </Form.Item>
                <Divider className="mar-tb-10px"/>
                <Form.Item label="How does the patient communicate?" name="PatientCommunicate">
                    <Radio.Group>
                        <Space direction="vertical">
                            <Radio value="Normally">Normally</Radio>
                            <Radio value="Not Understandable">Not Understandable</Radio>
                            <Radio value="Augmentative Device">Augmentative Device</Radio>
                            <Radio value="Sign Language">Sign Language</Radio>
                            <Radio value="Non-Communicative">Non-Communicative</Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
                <Divider className="mar-tb-10px"/>
                <Form.Item label="Medical Service Coordinator or Case Manager (if applicable) :"
                           name="CaseManagerName">
                    <Input id="inputCaseManagerName"></Input>
                </Form.Item>
                <Form.Item label="Agency" name="CaseManagerAgency">
                    <Input id="inputCaseManagerAgency"></Input>
                </Form.Item>
                <Form.Item label="Phone Number" name="CaseManagerPhoneNumber" initialValue={formattedCaseManagerPhoneNumber}
                           rules={[{validator: validatePhoneNumber}]}>
                    <InputMask type="text" className="form-control" id="inputCaseManagerPhoneNumber" mask="(999) 999-9999"
                               placeholder="(NNN) NNN-NNN"
                    />
                </Form.Item>
                <Form.Item label="Email" name="CaseManagerEmail">
                    <Input type="email" id="inputCaseManagerEmail"/>
                </Form.Item>
                <Form.Item label="Fax" name="CaseManagerFax">
                    <Input/>
                </Form.Item>
                <Form.Item className="form-footer-button">
                    {isSubmitting ? (
                        <div className="spinner-overlay">
                            <div className="spinner">
                                <Spin size="large" tip="Loading..."/>
                            </div>
                        </div>
                    ) : (
                        <Space>
                            <Button type="primary" onClick={onSpecialHealthCarePreviousClick}>Previous</Button>
                            <Button type="primary" htmlType="submit">Next</Button>
                        </Space>
                    )}
                </Form.Item>
            </Form>
        </div>
    );
};
export default PatientWithSpecialHealthcare;
