import DefaultModule from "../../utils/defaultModule";

function Provider(props) {
  return (
    <DefaultModule moduleCode={props} />
  );
}


export default Provider;
