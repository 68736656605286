import React, { useState, useEffect } from 'react';
import { Layout, Steps, Form, Input, Button, Space, Col, Divider, Row, Modal, Spin } from "antd";
import InputMask from 'react-input-mask';
import { savePharmacyInformationForm } from "../../services/ipadRegistartionService";

const { Header, Content } = Layout;
const { Step } = Steps;


const Pharmacy_Information = (props) => {
    const [form] = Form.useForm();
    // const [dataLoaded, setDataLoaded] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState([]);
    const [dropdownValues, setDropdownValues] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // useEffect(() => {
    //     if (props.patientRegistrationInfo?.patientRegistrationFormData) {
    //         setDropdownValues(props.dropdownList);
    //         setDataLoaded(true);
    //     }
    // }, [props]);
    const onPharmacyInfoPreviousClick = () => {
        props.handlePreviousClick(props);
    }

    const handleFormSubmit = (values) => {
        form.setFieldsValue(formData)
    };
    const onPharmacyInfoClick = async (values) => {
        setIsSubmitting(true);
        try {
            const updatedValues = { ...values };
            updatedValues.PharmacyPhone = updatedValues.PharmacyPhone ? formatPhoneNumber(updatedValues.PharmacyPhone) : '';
            let pharmacyInfo = await savePharmacyInformationForm(props.uniqueHash, updatedValues);
            if (pharmacyInfo.message === 'Success') {
                props.handleNextClick(props);
            }
            else {
                Modal.error({
                    title: 'This is an error message',
                    content: pharmacyInfo.errors.toString(),
                });
            }
        } finally {
            if (!isSubmitting) {
                return (
                    <div className="spinner-overlay">
                        <div className="spinner">
                            <Spin size="large" tip="Loading..." />
                        </div>
                    </div>
                );
            }
            setIsSubmitting(false);
        }
    }

    const formatPhoneNumber = (phoneNumber) => {
        // Remove non-digit characters from the phone number
        const digitsOnly = phoneNumber.replace(/\D/g, '');
        // Apply the (NNN) NNN-NNN format
        return `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6)}`;
    };

    const validatePhoneNumber = (rule, value) => {
        if (value && value.replace(/\D/g, '').length < 10) {
            return Promise.reject('Please enter 10 digits');
        }
        return Promise.resolve();
    };

    // if (!dataLoaded) {
    //     return (
    //         <div className="spinner-overlay">
    //             <div className="spinner">
    //                 <Spin size="large" tip="Loading..." />
    //             </div>
    //         </div>
    //     );
    // }
    // const pharmacyInformation = props.patientRegistrationInfo.patientRegistrationFormData?.PharmacyInformation ?? [];
    // const contactInfo = props.patientRegistrationInfo?.patientRegistrationInfo;

    // let patientDetails = {
    //     PharmacyName: pharmacyInformation?.PharmacyName || '',
    //     PharmacyPhone: pharmacyInformation?.PharmacyPhone || null,
    //     PharmacyAddress: pharmacyInformation?.PharmacyAddress || '',
    //     PharmacyCity: pharmacyInformation?.PharmacyCity || contactInfo?.city || '',
    //     PharmacyState: pharmacyInformation?.PharmacyState || contactInfo?.state || '',
    //     PharmacyZip: pharmacyInformation?.PharmacyZip || ''
    // };


    return (
        <div>
            <h3>Pharmacy Information</h3>
            <p className="text-muted">Enter the following information if available</p>
            <Form form={form} layout="vertical" size="large" validateTrigger="onChange" onFinish={onPharmacyInfoClick}
                // initialValues={{
                //     ...patientDetails,
                // }}
                >
                <Form.Item label="Pharmacy Name" name="PharmacyName"
                    rules={[{ required: true, message: 'The pharmacy name is required.' },
                    {
                        validator: (_, value) => {
                            if (value && value.startsWith(' ')) {
                                return Promise.reject('Pharmacy name cannot start with a space');
                            }
                            return Promise.resolve();
                        },
                    }]}>
                    <Input />
                </Form.Item>

                <p className="text-muted">If doesn't have pharmacy please enter "No Pharmacy".</p>

                <Form.Item label="Pharmacy Phone" name="PharmacyPhone"
                    rules={[{ validator: validatePhoneNumber }]}
                >
                    <InputMask type="text" className="form-control" id="inputPharmacyPhone" mask="(999) 999-9999"
                        placeholder="(NNN) NNN-NNN"
                    />
                </Form.Item>

                <Form.Item label="Address" name="PharmacyAddress">
                    <Input />
                </Form.Item>
                <Row gutter={24}>
                    <Col md={8}>
                        <Form.Item label="City" name="PharmacyCity" rules={[
                            {
                                validator: (_, value) => {
                                    if (value && value.startsWith(' ')) {
                                        return Promise.reject('City field cannot start with a space');
                                    }
                                    return Promise.resolve();
                                },
                            }]}>
                            <Input onKeyPress={(e) => {
                                const charCode = e.charCode;
                                // Allow only alphabetic characters and certain special characters (e.g., space)
                                if (
                                    (charCode < 65 || charCode > 90) && // Uppercase letters
                                    (charCode < 97 || charCode > 122) && // Lowercase letters
                                    charCode !== 32 // Space
                                ) {
                                    e.preventDefault();
                                }
                            }} />
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item label="State" name="PharmacyState" rules={[
                            {
                                validator: (_, value) => {
                                    if (value && value.startsWith(' ')) {
                                        return Promise.reject('State field cannot start with a space');
                                    }
                                    return Promise.resolve();
                                },
                            }]} >
                            <Input onKeyPress={(e) => {
                                const charCode = e.charCode;
                                // Allow only alphabetic characters and certain special characters (e.g., space)
                                if (
                                    (charCode < 65 || charCode > 90) && // Uppercase letters
                                    (charCode < 97 || charCode > 122) && // Lowercase letters
                                    charCode !== 32 // Space
                                ) {
                                    e.preventDefault();
                                }
                            }} />
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item label="Zip Code" name="PharmacyZip" type="number" rules={[
                            {
                                pattern: /^\d{5}$/,
                                message: 'Zip code must be 5 digits.',
                            },
                        ]} >
                            <Input type="text" className="form-control" id="inputPharmacyZip"
                                maxLength={5} onKeyPress={(e) => {
                                    const pattern = /^[0-9]*$/;
                                    if (!pattern.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item className="form-footer-button">
                    {isSubmitting ? (
                        <div className="spinner-overlay">
                            <div className="spinner">
                                <Spin size="large" tip="Loading..." />
                            </div>
                        </div>
                    ) : (
                        <Space>
                            <Button type="primary" onClick={onPharmacyInfoPreviousClick}>Previous</Button>
                            <Button type="primary" htmlType="submit" onClick={handleFormSubmit}>Next</Button>
                        </Space>
                    )}
                </Form.Item>
            </Form>
        </div>
    );
}
export default Pharmacy_Information;
