import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Layout, Steps, Form, Input, Button, Select, DatePicker, Divider, Row, Col, Modal, Spin, Space } from "antd";

import ContactInformation from "./Contact_Information";
import { saveInsuranceInformationForm, savePersonalInformation } from "../../services/ipadRegistartionService";
import moment from "moment/moment";
import { fetchIpadRegistrationData } from '../../services/ipadRegistrationService';
const { Header, Content } = Layout;
const { Option } = Select;
const InsuranceInformation = (props) => {
    const [form] = Form.useForm();
    const [dropdownValues, setDropdownValues] = useState([]);
    // const [patientRegistrationInfo, setPatientRegistrationInfo] = useState([]);
    const [formData, setFormData] = useState([]);
    // const [dataLoaded, setDataLoaded] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formControls, setFormControls] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchIpadRegistrationHandler = async () => {
            try {
                const response = await fetchIpadRegistrationData();
                setData(response[0].insurance);
            } catch (error) {
                console.error("Error Registration Data:", error.message);
            }
        };

        fetchIpadRegistrationHandler();
    }, []);


    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, []);

    // useEffect(() => {
    //     if (props?.patientRegistrationInfo) {
    //         setDropdownValues(props.dropdownList);
    //         setPatientRegistrationInfo(props.patientRegistrationInfo);
    //         setDataLoaded(true);
    //     }
    // }, [props.patientRegistrationInfo]);

    // useEffect(() => {
    //     if (!(props.formControls.length === 0)) {
    //         const formControlsForActivePage = props.formControls[props.activeIpadPage];
    //         const formFields = formControlsForActivePage.map(control => control.form_field);
    //         setFormControls(formFields);
    //         setDataLoaded(true);
    //     }
    // }, [props.formControls]);

    // const onInsuranceInfoPreviousClick = () => {
    //     props.handlePreviousClick(props);
    // }
    const onInsuranceInfoNextClick = async (values) => {
        try {
            setIsSubmitting(true);
            const updatedValues = { ...values };
            updatedValues.PrimaryInsuranceDateOfBirth = values.PrimaryInsuranceDateOfBirth ? moment(values.PrimaryInsuranceDateOfBirth).format('YYYY-MM-DD') : null;
            updatedValues.SecondaryInsuranceDateOfBirth = values.SecondaryInsuranceDateOfBirth ? moment(values.SecondaryInsuranceDateOfBirth).format('YYYY-MM-DD') : null;
            updatedValues.effectiveDate = moment().format('YYYY-MM-DD');
            let insuranceInfoResponse = await saveInsuranceInformationForm(props.uniqueHash, updatedValues);
            props.handleNextClick(props);
            if (insuranceInfoResponse.message === 'Success') {
                props.handleNextClick(props);
            } else {
                Modal.error({
                    title: 'This is an error message',
                    content: insuranceInfoResponse.errors.toString(),
                });
            }
        } finally {
            if (!isSubmitting) {
                return (
                    <div className="spinner-overlay">
                        <div className="spinner">
                            <Spin size="large" tip="Loading..." />
                        </div>
                    </div>
                );
            }
            setIsSubmitting(false);
        }
    }
    const handleFormSubmit = (values) => {
        form.setFieldsValue(formData)
    };

    function parseDate(dateString) {
        if (dateString) {
            const parsedDate = moment(dateString, 'YYYY-MM-DD');
            return parsedDate.isValid() ? parsedDate : null;
        }
        return null;
    }

    // const patientInfo = props.patientRegistrationInfo.patientRegistrationInfo;
    // const insuranceInformation = props.patientRegistrationInfo.patientRegistrationFormData?.InsuranceInformation ?? [];

    // const patientDetails = {
    //     PrimaryInsurance: patientInfo?.primary_insurance_name || "",
    //     PolicyHolderName: patientInfo?.primary_insurance_holder_name || "",
    //     PrimaryInsuranceDateOfBirth: parseDate(patientInfo?.primary_insurance_subscriber_dob) || "",
    //     SubscriberPolicyNumber: patientInfo?.subscriber_policy_number || "",
    //     GroupNumber: patientInfo?.group_number || "No group number",
    //     subscriberRelationship: insuranceInformation?.subscriberRelationship || "",
    //     selectedSecondaryInsurance: patientInfo?.secondary_insurance_name || "",
    //     SecondaryPolicyHolderName: patientInfo?.secondary_insurance_holder_name || "",
    //     SecondaryInsuranceDateOfBirth: parseDate(patientInfo?.secondary_insurance_subscriber_dob) || "",
    //     SecondarySubscriberPolicyNumber: patientInfo?.secondary_insurance_subscriber_policy_number || "",
    //     SecondaryGroupNumber: patientInfo?.secondary_insurance_group_number || "No group number",
    //     SecondarySubscriberRelationship: insuranceInformation?.SecondarySubscriberRelationship || "",
    //     secondaryInsuranceFamilySize: insuranceInformation?.secondaryInsuranceFamilySize || "",
    //     secondaryInsuranceFamilyIncome: insuranceInformation?.secondaryInsuranceFamilyIncome || "",
    //     secondaryInsuranceFamilyIncomePer: insuranceInformation?.secondaryInsuranceFamilyIncomePer || "",
    // };

    // if (!dataLoaded) {
    //     return (
    //         <div className="spinner-overlay">
    //             <div className="spinner">
    //                 <Spin size="large" tip="Loading..." />
    //             </div>
    //         </div>
    //     );
    // }

    return (
        <div>
            <h2>Insurance Information</h2>

            <Form
                form={form}
                layout="vertical"
                size="large"
                onFinish={onInsuranceInfoNextClick}
            // initialValues={patientDetails}
            >
                <Form.Item
                    label="Primary Insurance"
                    name="PrimaryInsurance"
                    rules={[
                        {
                            required: true,
                            message: 'The primary insurance field is required.',
                        },
                    ]}
                >


                    {/* <Select value={data}>
                        <Option value={null}></Option>
                        {dropdownValues.insurance && dropdownValues.insurance.length > 0 ? (
                            dropdownValues.insurance.map((status, index) => (
                                <Option key={index} value={status}>
                                    {status}
                                </Option>
                            ))
                        ) : (
                            <Option value={null}>No options available</Option>
                        )}
                    </Select> */}
                    <Select>
                        {data.map((item) => (
                            <Option key={item.dropdownoptionid} value={item.dropdownoptionid}>
                                {item.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="Policy Holder Name" name="PolicyHolderName" style={{ marginTop: 10 }}>
                    <Input placeholder="First Name Last Name" />
                </Form.Item>
                <Form.Item
                    label="Subscriber Date of Birth"
                    name="PrimaryInsuranceDateOfBirth"
                    style={{ marginTop: 10 }}
                    rules={[
                        {
                            required: true,
                            message: 'The primary Date of Birth field is required.',
                        },
                    ]}
                >
                    <DatePicker format="MM/DD/YYYY" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="Subscriber policy number"
                    name="SubscriberPolicyNumber"
                    style={{ marginTop: 10 }}
                    rules={[
                        {
                            required: true,
                            message: 'The Subscriber Policy Number field is required.',
                        },
                    ]}
                >
                    <Input placeholder="Subscriber Policy Number" />
                </Form.Item>
                <Form.Item label="Group number" name="GroupNumber" style={{ marginTop: 10 }}
                    rules={[
                        {
                            required: true,
                            message: 'Enter ‘No group number’ if there is no group number on your health insurance card.',
                        },
                    ]}>
                    <Input placeholder="Group Number" />
                </Form.Item>
                {formControls.includes('Subscriber Relationship') ?
                    <Form.Item label="Patient's relationship to subscriber (If not self)" name="subscriberRelationship"
                        style={{ marginTop: 10 }}>
                        <Select className="form-control">
                            <Option></Option>
                            <Option value="Spouse">Spouse</Option>
                            <Option value="Child">Child</Option>
                            <Option value="Other">Other</Option>
                        </Select>
                    </Form.Item> : null}
                {/* {patientInfo.secondary_insurance_type === 'Yes' && ( */}
                <>
                    <h2>Secondary Insurance (If applicable)</h2>
                    <Form.Item
                        label="Secondary Insurance"
                        name="selectedSecondaryInsurance"
                        rules={[
                            {
                                required: true,
                                message: 'The Secondary Insurance field is required.',
                            },
                        ]}
                    >
                        {/* <Select>
                            <Option value={null}></Option>
                            {dropdownValues.insurance && dropdownValues.insurance.length > 0 ? (
                                dropdownValues.insurance.map((status, index) => (
                                    <Option key={index} value={status}>
                                        {status}
                                    </Option>
                                ))
                            ) : (
                                <Option value={null}>No options available</Option>
                            )}
                        </Select> */}
                        <Select>
                            {data.map((item) => (
                                <Option key={item.dropdownoptionid} value={item.dropdownoptionid}>
                                    {item.value}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Policy Holder Name"
                        name="SecondaryPolicyHolderName"
                        style={{ marginTop: 10 }}
                    >
                        <Input placeholder="First Name Last Name" />
                    </Form.Item>
                    <Form.Item
                        label="Subscriber Date of Birth"
                        name="SecondaryInsuranceDateOfBirth"
                        style={{ marginTop: 10 }}
                        rules={[
                            {
                                required: true,
                                message: 'The Secondary Subscriber Date of Birth field is required.',
                            },
                        ]}
                    >
                        <DatePicker format="MM/DD/YYYY" style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        label="Subscriber policy number"
                        name="SecondarySubscriberPolicyNumber"
                        style={{ marginTop: 10 }}
                        rules={[
                            {
                                required: true,
                                message: 'The Secondary Subscriber Policy Number field is required.',
                            },
                        ]}
                    >
                        <Input placeholder="Subscriber Policy Number" />
                    </Form.Item>
                    <Form.Item
                        label="Group number"
                        name="SecondaryGroupNumber"
                        style={{ marginTop: 10 }}
                        rules={[
                            {
                                required: true,
                                message: 'Enter ‘No group number’ if there is no group number on your health insurance card.',
                            },
                        ]}
                    >
                        <Input placeholder="Group Number" />
                    </Form.Item>
                    {formControls.includes('Subscriber Relationship') ?
                        <Form.Item>
                            <Form.Item label="Patient's relationship to subscriber (If not self)"
                                name="SecondarySubscriberRelationship" style={{ marginTop: 10 }}>
                                <Select className="form-control">
                                    <Option></Option>
                                    <Option value="Spouse">Spouse</Option>
                                    <Option value="Child">Child</Option>
                                    <Option value="Other">Other</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Family Size(The number of people related by birth marriage or adoption living in your home)"
                                name="secondaryInsuranceFamilySize" style={{ marginTop: 10 }}>
                                <Input />
                            </Form.Item>
                            <Row gutter={24}>
                                <Col md={12}>
                                    <Form.Item label="Income"
                                        name="secondaryInsuranceFamilyIncome" style={{ marginTop: 10 }}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col md={12}>
                                    <Form.Item label="Per"
                                        name="secondaryInsuranceFamilyIncomePer" style={{ marginTop: 10 }}>
                                        <Select className="form-control">
                                            <Option></Option>
                                            <Option value="Two Weeks">Two Weeks</Option>
                                            <Option value="Month">Month</Option>
                                            <Option value="Year">Year</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form.Item>
                        : null}
                </>
                {/* )} */}

                <Divider />
                <Form.Item className="form-footer-button">
                    {isSubmitting ? (
                        <div className="spinner-overlay">
                            <div className="spinner">
                                <Spin size="large" tip="Loading..." />
                            </div>
                        </div>
                    ) : (
                        <></>
                        // <Row gutter={12}>
                        //     <Col>
                        //         <Button type="primary" onClick={onInsuranceInfoPreviousClick}>
                        //             Previous
                        //         </Button>
                        //     </Col>
                        //     <Col>
                        //         <Button type="primary" htmlType="submit">
                        //             Next
                        //         </Button>
                        //     </Col>
                        // </Row>
                    )}
                </Form.Item>
            </Form>
        </div>
    );
};

export default InsuranceInformation;
