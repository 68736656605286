import React from "react";
import ddcImage from '../../../../assets/images/ddc-logo.png'; 

const ConsentForm5 = () => {
    return (
        <div>
             <div class="form-row">
                <div class="form-group col-md-6">
                <img src={ddcImage} className='DDC-Image' alt="DDC logo" />
                </div>
                <div class="form-group col-md-2">
                    <h2><b>CONSENT FORM FOR NO SHOW/</b></h2>
                    <h2><b>REFERRAL/</b></h2>
                    <h2><b>DEDUCTIBLE POLICY/PREPAYMENT POLICY</b></h2>
                </div>
            </div>

            <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
            <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-validate/1.19.3/jquery.validate.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-validate/1.19.3/additional-methods.js"></script>

            <div>
            <br/>
            Thank you for trusting your medical care to Northeast Primary Care (NEPC). When you schedule an appointment
            with NEPC, we set aside enough time to provide you with the highest quality care. Should you need to cancel or
            reschedule an appointment, please contact our office as soon as possible, and no later than 48 hours prior to your
            scheduled appointment. This will allow us enough time to schedule other patients who may be waiting for an
            appointment. Please see our Appointment Cancelation/No Show Policy below:

            <b>
            1. No Show –
            </b>

            a. Patients who fail to cancel an appointment at least 24 hours prior to that appointment, will be
            considered a No Show and charged a <b>$50.00 fee</b>.
            <br/>
            b. Patients scheduled for a procedure who fail to cancel an appointment at least 48 hours prior to that
            appointment, will be considered a No Show and charged a <b>$50.00 fee</b>.
            <br/>
            c. No Show Fees are charged to the patient, not the insurance company. These fees are due at the
            time of the patient’s next office visit.
            <br/>
            d. As a courtesy to our patients, when time allows, we make reminder calls for appointments. If you
            do not receive a reminder call or message, this policy will remain in effect. Not receiving a reminder
            from our office does not act as a waiver of this fee.
            <br/>
            e. We understand that emergencies arise and you may not be able to keep your scheduled
            appointment. If you should experience extenuating circumstances, please contact our Office
            Manager, who may be able to waive the No Show Fee.<br/>

            <b>
            2. Specialist Referral –
            </b>

            a. For every insurance plan that requires a referral to see a specialist, a patient must have obtained
            that referral from their Primary Care Physician’s office (PCP) prior to their appointment. It is the
            patient’s responsibility to ensure that a valid referral is on file for the services being rendered.
            Please be courteous to the PCP’s office and request the referral 3 – 7 days prior to the specialist
            appointment.
            <br/>
            b. It is the patient’s responsibility to pick up the referral from the PCP’s office. In some cases, the PCP
            will fax the referral to the specialist at the request of the patient. We do accept faxed referrals.
            c. If that referral is not obtained, patient is fully responsible for full payment of that appointment.

            <b>
            3. In Network Deductible –
            </b>
            Patients are fully responsible for their in-network deductibles associated with
            their care.
            <br/>
            I have read and understand the Medical Appointment No Show/Referral/Deductible Policy and agree
            to its terms.
            </div>
            <br/>
            <b>
            4. Pre Payment Policy
            </b>

            Northeast Primary Care requires your credit card to be kept on file starting $date.
            Your copay will be automatically charged to the credit card on file, on the day of your appointment
            but before your appointment. If you did not make your appointment, the charge is auto-reversed
            at the end of the day. The prepayment of copays using the card on file is a convenience feature for you,
            our patient. You may request an exception to this policy by phone, letter, or an in-person verbal request.
            Patients with verified ACTIVE MEDICAID, WORKER’S COMPENSATION, STUDENT INSURANCE,
            MEDICARE, and a MEDICARE SUPPLEMENT POLICY and PRIVATE PAY coverage are exempt from having a credit card on file.

            <b>YOU CAN CLOSE THIS TAB AFTER VIEWING AND GO BACK TO FORM</b>

        </div>
    );
};

export default ConsentForm5;
