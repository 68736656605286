import React, { useState } from 'react';
import { Modal } from 'antd';
import { sendNotificationService, validateNotifyVerification } from "../../../services/userNotifyVerificationService";
import MFA from "../userNotifyVerification";

export async function fetchData(navigate, setError, setSMSType, setEmailType, setLoading) {
    try {
        setError("");
        setLoading(true); // Set loading to true before data fetching

        const tempUserId = sessionStorage.getItem('userId');

        if (!tempUserId) {
            navigate("/login", { replace: true });
            return;
        }

        const userData = {
            userid: tempUserId,
        };

        const response = await sendNotificationService(userData);

        // Assign response to state
        setSMSType(response.data.notificationDetail.find(item => item.type === "SMS") || "");
        setEmailType(response.data.notificationDetail.find(item => item.type === "EMAIL") || "");

        // Extract SMS and email tokens from the response
        const smsToken = response.data.notificationDetail.find(item => item.type === "SMS")?.token;
        const emailToken = response.data.notificationDetail.find(item => item.type === "EMAIL")?.token;

        // Store tokens in localStorage
        sessionStorage.setItem('smsToken', smsToken);
        sessionStorage.setItem('emailToken', emailToken);


        setLoading(false); // Set loading to false after data fetching is complete
    } catch (error) {
        console.error("Error during code verification:", error);
        setError("An error occurred during code verification.");
        setLoading(false); // Set loading to false in case of error
    }
}
// export async function codeVerify(navigate, setError, tempUserId) {
//     let status = false;
//     let response = null; // Define response outside try...catch block
//     try {
//         setError("");

//         if (!tempUserId) {
//             navigate("/login", { replace: true });
//             return status;
//         }

//         const userData = {
//             userId: tempUserId,
//         };

//         // response = await sendNotificationService(userData); // Assign response here
//         // Mock response data


//         // Assign response data
//         //response = responseData;

//         if (response && response.data.success) {
//             status = true;
//         }
//         else {
//             if (response && response.data && response.data.businessRules && response.data.businessRules.length > 0) {
//                 const firstError = response.data.businessRules[0].description;
//                 setError(firstError);
//             }
//             else {
//                 setError("Error during code verification");
//             }
//         }
//     } catch (error) {
//         console.error("Error during code verification:", error);
//         setError("An error occurred during code verification.");
//     }
//     console.log("Return data from handler==" + response);
//     // Render MFA component if response is available
//     return (
//         <div>
//             {response && <MFA response={response} />}
//         </div>
//     );
// }

export function handlePaste(e, setter) {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");

    // Split the pasted data into individual characters
    const pastedCharacters = pastedData.split('');

    // Set the pasted characters to the input fields one by one
    pastedCharacters.forEach((char, index) => {
        if (index < 6) { // Assuming there are 6 OTP boxes
            setter(prevData => {
                const newData = [...prevData];
                newData[index] = char;
                return newData;
            });
        }
    });
}

// export function handleMobileSubmit(mobileOTP, setPhoneVerified) {

//     setPhoneVerified(true);
// }

// export function handleEmailSubmit(emailOTP) {
//     handleVerification("email", emailOTP);
// }


export async function handleVerification(mobileOTP, emailOTP, setMobileOTP, setEmailOTP, navigate, setResendDisabled, getData, setError, setSMSType, setEmailType, setLoading) {
    let notificationDetail = [];
    const storedSmsToken = sessionStorage.getItem('smsToken');
    const storedEmailToken = sessionStorage.getItem('emailToken');
    const tempUserId = sessionStorage.getItem('userId');

    if (mobileOTP.length === 6) {
        // handleMobileSubmit();
        let smsCode = mobileOTP.toString();
        smsCode = smsCode.replace(/,/g, '');
        notificationDetail.push({
            type: "SMS",
            token: storedSmsToken,
            code: smsCode
        });
    }

    if (emailOTP.length === 6) {
        // handleEmailSubmit();
        let emailCode = emailOTP.toString();
        emailCode = emailCode.replace(/,/g, '');
        notificationDetail.push({
            type: "EMAIL",
            token: storedEmailToken,
            code: emailCode
        });
    }

    const jsonData = {
        userid: tempUserId,
        notificationDetail: notificationDetail
    };

    const otpStatusResponse = await validateNotifyVerification(jsonData);

    if (otpStatusResponse && otpStatusResponse.data.success) {
        const smsStatus = otpStatusResponse.data.notificationDetail.find(item => item.type === "SMS")?.status;
        const emailStatus = otpStatusResponse.data.notificationDetail.find(item => item.type === "EMAIL")?.status;

        if (smsStatus === "invalid" || emailStatus === "invalid") {
            setMobileOTP("");
            setEmailOTP("");
            Modal.error({ title: "OTP Invalid", content: "The OTP you have entered is not valid" });

            // await getData(navigate, setError, setSMSType, setEmailType, setLoading);
        }
        else if (smsStatus === "expired" || emailStatus === "expired") {
            setMobileOTP("");
            setEmailOTP("");
            Modal.error({ title: "OTP Expired", content: "The OTP you have entered is expired" });
            // setResendDisabled(false);
            // await getData(navigate, setError, setSMSType, setEmailType, setLoading);
        }
        else if ((smsStatus === "valid" && emailStatus === "valid")
            || (smsStatus === "valid" && !emailStatus)
            || (emailStatus === "valid" && !smsStatus)
        ) {
            navigate('/dashboard');
        }
    }
}
// export async function handleResendOTP(navigate, setError, setSMSType, setEmailType, setLoading, setTimer, setIsVisible, timer, setResendDisabled) {
//     await getData(navigate, setError, setSMSType, setEmailType, setLoading);
//     // await countdown(setTimer, setIsVisible, timer, setResendDisabled);
// };

export async function getData(navigate, setError, setSMSType, setEmailType, setLoading, setTimer, setIsVisible, timer, setResendDisabled) {
    await fetchData(navigate, setError, setSMSType, setEmailType, setLoading);
    await countdown(setTimer, setIsVisible, timer, setResendDisabled);
}
export async function countdown(setTimer, setIsVisible, timer, setResendDisabled) {
    const interval = setInterval(() => {
        setTimer(prevTimer => {
            if (prevTimer > 0) {
                return prevTimer - 1;
            } else {
                clearInterval(interval);
                setIsVisible(false); // Hide the div when timer reaches 0
                setResendDisabled(false);
                // Additional logic for handling timer reaching 0
                return 0;
            }
        });
    }, 1000);
};
// Format time into minutes:seconds
export function formatTime(timer) {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};
