import React, { useEffect, useState } from "react";
import { Form, Select, Row, Col, Checkbox, Input, Space, Button, DatePicker,message } from "antd";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { handlePatientNameDOMSearch, fetchPracticeLocationsData } from '../scheduler/handlers/scheduleAppointmentHandler';
import { fetchPatientInitialData, fetchProviderInitialData, fetchReasonForVisitDropdownData, fetchVisitTypeDropdownData,ScheduleAppointmentSave } from '../../services/scheduleAppointmentService';
import dayjs from 'dayjs';
const { Option } = Select;

const ScheduleAppointment = ({ onBack }) => {

    const [form] = Form.useForm();
    const [PracticeLocations, setPracticeLocations] = useState([]);
    const [reasonForVisitData, setReasonForVisitData] = useState([]);
    const [searchProviderInput, setSearchProviderInput] = useState('');
    const [loading, setLoading] = useState(true);
    const [filteredProviderData, setPhysiciansData] = useState([]);
    const [filteredResourceData, setResourceData] = useState([]);
    const [visitTypeData, setVisitTypeData] = useState([]);
    const dateFormat = 'MM/DD/YYYY';
    const [searchNameInput, setSearchNameInput] = useState('');
    const [searchDOBInput, setSearchDOBInput] = useState('');
    const [patientNameData, setPatientNameData] = useState([]);
    const [patientDOBData, setPatientDOBData] = useState([]);
    const [messages, setMessages] = useState('');
    const [timeSlots, setTimeSlots] = useState([]);
    let [value, setValue] = useState(15);
    const labelWrapperProps = {
        labelAlign: 'left',
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const generateTimeSlots = (interval) => {
        const slots = [];
        const startTime = new Date();
        startTime.setHours(8, 0, 0); // 8:00 AM

        const endTime = new Date();
        endTime.setHours(16, 45, 0); // 4:45 PM

        while (startTime <= endTime) {
            const hours24 = startTime.getHours();
            const hours12 = hours24 % 12 || 12; // Convert to 12-hour format
            const minutes = startTime.getMinutes();
            const meridiem = hours24 >= 12 ? 'PM' : 'AM';

            const time = `${hours12}:${minutes === 0 ? '00' : minutes < 10 ? '0' + minutes : minutes} ${meridiem}`;
            slots.push(time);
            startTime.setMinutes(startTime.getMinutes() + interval);
        }
        return slots;
    };

    const handleVisitTypeChange = () => {
        const slots = generateTimeSlots(value);
        setTimeSlots(slots);
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                await fetchPracticeLocationsData(setPracticeLocations);
                const reasonData = await fetchReasonForVisitDropdownData(1); // Replace 1 with the actual practiceId if dynamic
                setReasonForVisitData(reasonData);
                setLoading(false);
            } catch (error) {
                console.error('Error in useEffect:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);


    const renderNameDropdown = (menuItems) => {
        if (!searchNameInput || searchNameInput.length < 2) {
            return (
                <div className='dropdown-message'>
                    Please enter 2 or more characters
                </div>
            );
        }
        // if (!searchDOBInput || searchDOBInput.length < 2) {
        //     return (
        //         <div className='dropdown-message'>
        //             Please enter 2 or more characters
        //         </div>
        //     );
        // }
        return menuItems;
    };
    const renderDOBDropdown = (menuItems) => {

        if (!searchDOBInput || searchDOBInput.length < 2) {
            return (
                <div className='dropdown-message'>
                    Please enter 2 or more characters
                </div>
            );
        }
        return menuItems;
    };

    const handleIncrease = () => {
        setValue((prevValue) => prevValue + 5);
        form.setFieldsValue({ duration: value + 5 });
        const updatedTimeSlots = generateTimeSlots(value + 5);
        setTimeSlots(updatedTimeSlots);
    };
    const handleDecrease = () => {
        const newValue = value - 5;
        if (newValue >= 15) {
            setValue(newValue);
            form.setFieldsValue({ duration: newValue });
            const updatedTimeSlots = generateTimeSlots(newValue);
            setTimeSlots(updatedTimeSlots);
        }
    };

    const renderDropdownOptions = (data, keyField) => {
        const uniqueValues = Array.from(new Set(data.map(item => item[keyField])));
        return uniqueValues.map((value, index) => (
            <Select.Option key={index} value={value}>
                {value}
            </Select.Option>
        ));
    };
    const renderDropdownProvider = (data) => {
        const uniqueProviderNames = [...new Set(data.map(provider => `${provider.firstName} ${provider.lastName}`))];
        return uniqueProviderNames.map((name, index) => (
            <Select.Option key={index} value={name}>
                {name}
            </Select.Option>
        ));
    };
    const handlePhysicianSearch = (value, setSearchProviderInput, setPhysiciansData, setMessages, allPhysiciansData) => {
        setSearchProviderInput(value);
        if (value.length < 2) {
            setMessages('Please enter 2 or more characters');
            setPhysiciansData([]);
        } else {
            setMessages('');
            const filtered = allPhysiciansData.filter((provider) =>
                `${provider.firstName} ${provider.lastName}`.toLowerCase().includes(value.toLowerCase())
            );
            setPhysiciansData(filtered);
        }
    };
    const handlePatientSelect = async (value, option) => {
        await fetchAndSetPatientDetails(option.key);
    };

    const handleProviderSelect = async (value, option) => {

        // await fetchVisitTypeDropdownData(option.key);
        const response = await fetchVisitTypeDropdownData(option.key);
        setVisitTypeData(response);
    };

    const fetchPatientDetail = async (patientId) => {
        try {
            const response = await fetchPatientInitialData(patientId);
            return response;
        } catch (error) {
            console.error("Error fetching patient details:", error.message);
            return null;
        }
    };
    const fetchAndSetPatientDetails = async (patientId) => {
        try {

            const response = await fetchPatientDetail(patientId);

            if (!response || !response.scheduleApptInitialData || response.scheduleApptInitialData.length === 0) {
                throw new Error('No patient data found');
            }

            const patientData = response.scheduleApptInitialData[0];
            form.setFieldsValue({
                'patient': patientData.patientName,
                'dob': patientData.dob,
                'phone': patientData.phone,
                'insurance': patientData.insuranceName,
            });
        } catch (error) {
            console.error('Error fetching patient details:', error);
        }
    };


    const fetchProviderByLocId = async (practiceLocationId) => {
        try {
            const response = await fetchProviderInitialData(practiceLocationId);
            return response;
        } catch (error) {
            console.error("Error fetching patient details:", error.message);
            return null;
        }
    };

    const fetchAndSetProviderDetails = async (practiceLocationId) => {
        try {

            const response = await fetchProviderByLocId(practiceLocationId);

            if (!response || !response.scheduleApptInitialData || response.scheduleApptInitialData.length === 0) {
                throw new Error('No patient data found');
            }
            const providerDataResponse = response.scheduleApptInitialData;
            const physiciansData = providerDataResponse.filter(item => item.resourceType === 'provider' && item.physician);
            const resources = providerDataResponse.filter(item => item.resourceType !== 'provider' && item.physician);
            setPhysiciansData(physiciansData);
            setResourceData(resources);
        } catch (error) {
            console.error('Error fetching patient details:', error);
        }
    };
    const handleLocationChange = async (value, option) => {
        await fetchAndSetProviderDetails(option.key);
    };
   
    const onSave = async () => {
        const appointmentData = {
            date: '2024-06-15',
            time: '10:00',
            patientName: 'John Doe',
            reason: 'Regular checkup'
        };
    
        try {
            await ScheduleAppointmentSave(appointmentData);
            message.success('saved successfully ');
        } catch (error) {
            message.error('Error in saving');
        }
    };
   
    return (
        <div className="container-height">
            <Form
                form={form}
                layout="vertical"
                requiredMark={false}
                {...labelWrapperProps}
                colon={false}>
                <Row gutter={[24, 24]}>
                    <Col span={6}>
                        <Form.Item label="Patients" name="patient">
                            <Select
                                allowClear
                                filterOption={false}
                                defaultActiveFirstOption={false}
                                placeholder="Patients"
                                maxTagCount={2}
                                onSearch={(value) => handlePatientNameDOMSearch(value, 'name', setSearchNameInput, setSearchDOBInput, setPatientNameData, setPatientDOBData, setMessages)}
                                value={searchNameInput}
                                onChange={handlePatientSelect}
                                dropdownRender={(menu) => (
                                    <div>
                                        <Input
                                            placeholder="Search patients"
                                            value={searchNameInput}
                                            onChange={(e) => handlePatientNameDOMSearch(e.target.value, 'name', setSearchNameInput, setSearchDOBInput, setPatientNameData, setPatientDOBData, setMessages)}
                                        />
                                        {renderNameDropdown(menu)}
                                    </div>
                                )}
                            >
                                {patientNameData.map(patient => (
                                    <Option key={patient.patientId} value={patient.patientName}>
                                        {patient.patientName}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="DOB" name="dob">
                            <Select
                                allowClear
                                filterOption={false}
                                defaultActiveFirstOption={false}
                                placeholder="DOB"
                                maxTagCount={2}
                                onSearch={(value) => handlePatientNameDOMSearch(value, 'dob', setSearchNameInput, setSearchDOBInput, setPatientNameData, setPatientDOBData, setMessages)}
                                value={searchDOBInput}
                                onChange={handlePatientSelect}
                                dropdownRender={(menu) => (
                                    <div>
                                        <Input
                                            placeholder="Search patients DOB"
                                            value={searchDOBInput}
                                            onChange={(e) => handlePatientNameDOMSearch(e.target.value, 'dob', setSearchNameInput, setSearchDOBInput, setPatientNameData, setPatientDOBData, setMessages)}
                                        />
                                        {renderDOBDropdown(menu)}
                                    </div>
                                )}
                            >
                                {patientDOBData.map(patient => (
                                    <Option key={patient.patientId} value={patient.dob}>
                                        {patient.dob}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Phone" name="phone" className="disableFieldColor">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Insurance" name="insurance">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col span={6}>
                        <Form.Item label="Date" name="date">
                            <DatePicker
                                placeholder="Select a date"
                                defaultValue={dayjs()} format={dateFormat}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Location" name="location">
                            <Select
                                mode="single"
                                allowClear
                                getPopupContainer={(trigger) => trigger.parentNode}
                                placeholder="Location"
                                maxTagCount={2}
                                className="location-dropdown"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={handleLocationChange}
                            >
                                {PracticeLocations.map(location => (
                                    <Option key={location.practiceLocationid} value={location.locationName}>
                                        {location.locationName}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                    </Col>
                    <Col span={6}>
                        <Form.Item label="Physician" name="physician">
                            <Select
                                mode="single"
                                allowClear
                                filterOption={false}
                                defaultActiveFirstOption={false}
                                placeholder="Physician"
                                maxTagCount={2}
                                value={searchProviderInput}
                                onChange={handleProviderSelect}
                                dropdownRender={(menu) => (
                                    <div>
                                        {menu}
                                    </div>
                                )}>
                                {filteredProviderData.map(physician => (
                                    <Option key={physician.providerId} value={physician.physician}>
                                        {physician.physician}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="Resource" name="resource">
                            <Select
                                mode="single"
                                allowClear
                                filterOption={false}
                                defaultActiveFirstOption={false}
                                placeholder="Resource"
                                maxTagCount={2}
                                value={searchProviderInput}
                                dropdownRender={(menu) => (
                                    <div>
                                        {menu}
                                    </div>
                                )}
                            >
                                {filteredResourceData.map(resource => (
                                    <Option key={resource.providerId} value={resource.physician}>
                                        {resource.physician}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col span={6}>
                        <Form.Item label="Department" name="department">
                            <Select disabled>
                                <Option value="option1">Option 1</Option>
                                <Option value="option2">Option 2</Option>
                                <Option value="option3">Option 3</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Visit Type" name="visitType">
                            <Select
                                mode="single"
                                onChange={handleVisitTypeChange}
                                allowClear
                                filterOption={false}
                                defaultActiveFirstOption={false}
                                placeholder="select a visit type"
                                maxTagCount={2}
                                dropdownRender={(menu) => (
                                    <div>
                                        {menu}
                                    </div>
                                )}
                            >
                                {visitTypeData.map(visitType => (
                                    <Option key={visitType.appointmentTypeId} value={visitType.visitName}>
                                        {visitType.visitName}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Reason For Visit" name="reasonForVisit">
                            <Select
                                mode="single"
                                allowClear
                                getPopupContainer={(trigger) => trigger.parentNode}
                                placeholder="Select a visit type"
                                maxTagCount={2}
                                className="location-dropdown"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {reasonForVisitData.map(reasonForVisit => (
                                    <Option key={reasonForVisit.reasonForVisitId} value={reasonForVisit.reasonForVisit}>
                                        {reasonForVisit.reasonForVisit}
                                    </Option>
                                ))}

                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Available Slots" name="availableSlots">
                            <Select placeholder="Select a visit type">
                                {timeSlots.map((slot, index) => (
                                    <Option key={index} value={slot}>{slot}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col span={6}>
                        <Form.Item label="Interval" name="interval">
                            <Space.Compact compact>
                                <Button onClick={handleDecrease} className="btnMinusAdjust" >-</Button>
                                <Input
                                    readOnly
                                    className="inputTimerAdjust"
                                    value={value}
                                    onChange={(e) => setValue(parseInt(e.target.value) || 5)}
                                />
                                <Button onClick={handleIncrease} className="btnPlusAdjust" >+</Button>
                            </Space.Compact>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col span={12}>
                        <Form.Item name="checkbox" valuePropName="checked">
                            <Checkbox>Provider Double Booking</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Form.Item
                            name="note"
                            label="Notes"
                        >
                            <Input.TextArea showCount maxLength={500} className="textAreaField" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 24]} justify="end" className="form-group">
                    <Space style={{ marginTop: '10px' }}>
                        <Col span={1}>
                            <Button className="ant-btn-primary" icon={<CheckOutlined />} onClick={onSave}>Schedule</Button>
                        </Col>
                        <Col span={1}>
                            <Button onClick={onBack} icon={<CloseOutlined />} id="Cancel">Cancel</Button>
                        </Col>
                    </Space>
                </Row>
            </Form>
        </div>
    );
};

export { ScheduleAppointment };
