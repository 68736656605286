//#region Packages
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Card, Skeleton } from 'antd';
import { useNavigate, useLocation } from "react-router-dom";
//#endregion

//#region Local Imports
import "../../../assets/styles/setPassword.css";
import "../../../assets/styles/common.css";
import FoLogo from "../../../assets/images/fo-logo.png";

import { formatPhoneNumber, validatePassword, validatePhone, isValidUUID, validateNumber } from '../utilities/utility';
import { FetchUserData, submitHandler } from './handlers/resetPasswordHandler';
//#endregion

function ResetPassword() {
    const [form] = Form.useForm();
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [isError, setError] = useState(false);
    const [resData, setResData] = useState([]);
    const [dataSecurity, setDataSecurity] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);

    // Define initial state for the form fields
    const initialFields = {
        token: '',
        password: '',
        securityquestionid: '',
        securityanswer: '',
        phone: '',
        updatedby: ''
    };

    // Dynamically create state variables for each field
    const [fields, setFields] = useState(initialFields);
    // Dynamically create state variables for validation errors
    const [errors, setFieldErrors] = useState([]);

    // Handler for input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
        setFieldErrors({ ...errors, [name]: '' }); /* Clear error when input changes */
    };

    const handlePhoneChange = (e) => {
        const { name, value } = e.target;
        const formattedValue = formatPhoneNumber(value);
        setFields({ ...fields, [name]: formattedValue });
        form.setFieldsValue({ [name]: formattedValue });
        setFieldErrors({ ...errors, [name]: '' });
    };

    // Handler for form submission
    const handleSubmit = () => {
        setSubmitLoader(true);
        // Check if there are any validation errors
        const newErrors = {};
        Object.keys(fields).forEach((fieldName) => {
            if (!fields[fieldName]) {
                newErrors[fieldName] = `Please input your ${fieldName}!`;
            }
        });

        setFieldErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            submitHandler(fields, navigate, setError);
            //setFields(initialFields);    // Clear the form fields
        }
        setSubmitLoader(false);
    };

    useEffect(() => {
        const pathParts = location.pathname.split('/');
        const lastPart = pathParts[pathParts.length - 1];
        let tokenId = "";
        if (lastPart && isValidUUID(lastPart)) {
            tokenId = lastPart;
            setFields(prevFields => ({ ...prevFields, token: tokenId }));
        } else {
            setError(true);
        }

        setLoading(true);
        FetchUserData(tokenId, setResData, setError);

    }, []);

    useEffect(() => {
        if (resData && resData.role) {
            setFields(prevFields => ({ ...prevFields, updatedby: resData.userId }));
            setFields(prevFields => ({ ...prevFields, securityquestionid: resData.securityQuestionId }));
            setSecurityPin(resData.role);
            setLoading(false);
        }
    }, [resData]);


    function setSecurityPin(role) {
        const hasSecurityPin = role.datasecuritytypes && role.datasecuritytypes.some(type => type.code === 'SECPIN');
        if (hasSecurityPin) {
            initialFields.securitypin = '';
        }
        setDataSecurity(hasSecurityPin);
    }

    const errMessage = "Field should not be empty";

    return (
        <div>
            {
                !isError &&
                <div className="background-container-color">
                    <Row className="divsetpasswordcontent" justify="center" align="middle">
                        <Col className="form-col" xl={7} lg={14} md={16}>
                            <div className="guest-logo">
                                <a className="navbar-brand">
                                    <img src={FoLogo} alt="Logo" className="logo" />
                                </a>
                            </div>
                            <Card className="set-password-card">
                                <div className="custom-card-content">
                                    <Form
                                        form={form}
                                        colon={false}
                                        layout="vertical"
                                        requiredMark="required"
                                    >
                                        <div className="guest-box-msg">
                                            <p>Reset Password</p>
                                        </div>

                                        {/* Display validation errors summary */}
                                        {/* {Object.keys(errors).length > 0 && (
                                            <div style={{ color: 'red' }}>
                                                {Object.values(errors).map((error, index) => (
                                                    <div key={index}>{error}</div>
                                                ))}
                                            </div>
                                        )} */}

                                        <Skeleton active loading={loading} paragraph={{ rows: 10 }}>
                                            <Form.Item
                                                name="password"
                                                label="New password" className="form-item-margin"
                                                rules={[{
                                                    required: true,
                                                    message: errMessage,
                                                },
                                                {
                                                    validator: validatePassword,
                                                },
                                                ]}
                                            >
                                                <Input.Password name="password" id="newpassword" value={fields.password} onChange={handleChange} />
                                            </Form.Item>

                                            <Form.Item
                                                name="confirmpassword"
                                                label="Confirm password" className="form-item-margin"
                                                dependencies={['password']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: errMessage,
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (!value || getFieldValue('password') === value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error('The new password that you entered do not match!'));
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input.Password name="confirmpassword" id="confirmpassword" />
                                            </Form.Item>

                                            {dataSecurity &&
                                                <>
                                                    <Form.Item
                                                        name="securitypin"
                                                        label="Security Pin" className="form-item-margin"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: errMessage,
                                                            }, {
                                                                validator: validateNumber,
                                                            },
                                                        ]}
                                                    >
                                                        <Input.Password name="securitypin" type="number" id="newsecuritypin" value={fields.securitypin} onChange={handleChange} />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name="confirmsecuritypin"
                                                        label="Confirm Security Pin" className="form-item-margin"
                                                        dependencies={['securitypin']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: errMessage,
                                                            },
                                                            ({ getFieldValue }) => ({
                                                                validator(_, value) {
                                                                    if (!value || getFieldValue('securitypin') === value) {
                                                                        return Promise.resolve();
                                                                    }
                                                                    return Promise.reject(new Error('The new security pin that you entered do not match!'));
                                                                },
                                                            }),
                                                        ]}
                                                    >
                                                        <Input.Password name="confirmsecuritypin" type="number" id="confirmsecuritypin" />
                                                    </Form.Item>
                                                </>
                                            }
                                            {resData.securityQuestionName &&
                                                <>
                                                    <Form.Item name="securityquestionid" hidden>
                                                        <Input name="securityquestionid" value={fields.securityquestionid} />
                                                    </Form.Item>


                                                    <Form.Item label={resData.securityQuestionName} name="securityanswer" className="form-item-margin"
                                                        rules={[{
                                                            required: true,
                                                            message: errMessage
                                                        }]}>
                                                        <Input id="answer" name="securityanswer" value={fields.securityanswer} onChange={handleChange}></Input>
                                                    </Form.Item>
                                                </>
                                            }
                                            <Form.Item
                                                name="phone"
                                                label="Phone"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: errMessage,
                                                    }, {
                                                        validator: validatePhone,
                                                    },
                                                ]}
                                                className="form-item-margin"
                                            >
                                                <Input
                                                    type="tel"
                                                    name="phone"
                                                    maxLength={14}
                                                    id="phonenumber"
                                                    placeholder='(999) 999-9999'
                                                    value={fields.phone}
                                                    onChange={handlePhoneChange}
                                                />
                                            </Form.Item>
                                            <div className="btnfosubmit">
                                                <Button loading={submitLoader} type="primary" id="submit" className='btnsubmit' htmlType='submit' onClick={handleSubmit}>Submit</Button>
                                            </div>
                                        </Skeleton>
                                    </Form>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            }
            {
                isError && navigate("/expired")
            }
        </div>
    );
};

export default ResetPassword;
