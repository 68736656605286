import React, { useEffect, useState, useRef } from "react";
import { Form, Select, Row, Col, Button, Space, Modal, DatePicker } from "antd";
import { Calendar } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import { PlusOutlined } from '@ant-design/icons';
import { ScheduleAppointment } from './scheduleAppointment';
import { fetchPracticeLocationsData, fetchProviderLoadData, fetchVisitTypeLoadData } from '../scheduler/handlers/scheduleAppointmentHandler';
import { getAppointmentData } from "../../services/appointmentService";
import ScheduleAppointmentDetailsForm from "./scheduleAppointmentDetailsForm";
import CreatePatient from "./createPatient";
import RecurringAppointment from "./recurringAppointmnet";
import "../../../assets/styles/scheduleAppointment.css";
import AppointmentBlock from "./appointmentBlock";
import dayjs from "dayjs";
const { MonthPicker } = DatePicker;

const MyCalendar = (props) => {
    const calendarRef = useRef(null);
    const [visible, setVisible] = useState(false);
    const [createPatient, setCreatePatient] = useState(false);
    const [recurringAppointment, setRecurringAppointment] = useState(false);
    const [appointmentsBlock, setAppointmentsBlock] = useState(false);
    const [form] = Form.useForm();
    const [PracticeLocations, setPracticeLocations] = useState([]);
    const [providerData, setProviderData] = useState([]);
    const [visitTypeData, setVisitTypeData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [appointmentData, setAppointmentData] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Initialize selected year 
    const [calendarApi, setCalendarApi] = useState(null); // Store calendar instance


    const labelWrapperProps = {
        labelAlign: 'left',
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [selectedProviders, setSelectedProviders] = useState([]);
    const [selectedVisitTypes, setSelectedVisitTypes] = useState([]);

    const fetchData = async () => {
        try {
            const startDate = new Date(selectedYear, selectedMonth - 1, 1);
            const endDate = new Date(selectedYear, selectedMonth, 0);
            const startDateISO = startDate.toISOString().split('T')[0];
            const endDateISO = endDate.toISOString().split('T')[0];

            const calendarData = {
                roleId: sessionStorage.getItem("roleId"),
                userId: sessionStorage.getItem('userId'),
                code: "IPAD-APPTS",
                startDate: startDateISO,
                endDate: endDateISO,
                appointmentType: "",
                appointmentStatus: "",
                appointmentLocation: selectedLocation.join(","),
                patientName: "",
                providerName: selectedProviders.join(","),
                visitType: selectedVisitTypes.join(",")
            };

            const appointmentTypes = await getAppointmentData(calendarData);

            if (appointmentTypes && appointmentTypes.appointment) {
                const formattedAppointments = [];

                appointmentTypes.appointment.forEach(appointmentType => {
                    appointmentType.forEach(appointment => {
                        const date = new Date(appointment.appointmentTime);
                        const formattedTime = `${('0' + date.getHours()).padStart(2, '0')}:${('0' + date.getMinutes()).padStart(2, '0')} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
                        const hour = ('0' + date.getHours() % 12 || 12).toString().padStart(2, '0');
                        const minute = ('0' + date.getMinutes()).toString().padStart(2, '0');
                        const meridian = date.getHours() >= 12 ? 'PM' : 'AM';

                        formattedAppointments.push({
                            title: `${hour}:${minute} ${meridian} ${appointment.patientName} with ${appointment.providerName}`,
                            start: date,
                            extendedProps: { ...appointment }
                        });
                    });
                });

                setAppointmentData(formattedAppointments);
            } else {
                setAppointmentData([]);
            }

        } catch (error) {
            console.error('Error fetching appointment data:', error);
        }
    };


    useEffect(() => {
        fetchData();
    }, [selectedLocation, selectedProviders, selectedVisitTypes, selectedMonth, selectedYear]); // Include selectedYear

    useEffect(() => {
        fetchPracticeLocationsData(setPracticeLocations, () => setLoading(false));
        fetchProviderLoadData(setProviderData);
        fetchVisitTypeLoadData(setVisitTypeData);
    }, []);


    useEffect(() => {
        if (!visible && !recurringAppointment && !createPatient && !appointmentsBlock && calendarRef.current) {
            const calendar = new Calendar(calendarRef.current, {
                plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin],
                initialView: 'dayGridMonth',
                headerToolbar: {
                    // start: 'prev,next today',
                    start: 'today',
                    center: 'title',
                    end: 'dailyBtn,threedaysBtn,weeklyBtn,monthlyBtn',
                },
                customButtons: {
                    dailyBtn: {
                        text: 'Daily',
                        click: function () {
                            calendar.changeView('timeGridDay');
                        }
                    },
                    threedaysBtn: {
                        text: '3 Days',
                        click: function () {
                            const now = new Date();
                            const start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                            const end = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 3);
                            calendar.gotoDate(start);
                            calendar.changeView('timeGrid', { start, end });
                        }
                    },
                    weeklyBtn: {
                        text: 'Weekly',
                        click: function () {
                            const now = new Date();
                            const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay());
                            const end = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 6);
                            calendar.gotoDate(start);
                            calendar.changeView('timeGridWeek', { start, end });
                        }
                    },
                    monthlyBtn: {
                        text: 'Monthly',
                        click: function () {
                            calendar.changeView('dayGridMonth');
                        }
                    }
                },
                events: appointmentData,
                eventOverlap: false,
                eventDisplay: 'block',
                eventClick: function (info) {
                    setSelectedEvent(info.event.extendedProps);
                    setModalVisible(true);
                },
                dayMaxEvents: true,
            });

            setCalendarApi(calendar); // Store calendar instance in state
            calendar.render();

            return () => {
                calendar.destroy();
                setCalendarApi(null); // Reset calendar instance on cleanup
            };
        }
    }, [appointmentData, visible, recurringAppointment, createPatient, appointmentsBlock]);


    useEffect(() => {
        if (calendarApi) {
            calendarApi.gotoDate(new Date(selectedYear, selectedMonth - 1, 1)); // Update calendar to selected month
        }
    }, [selectedMonth, calendarApi, selectedYear]); // Watch for changes to selectedMonth and calendarApi


    const renderDropdownOptions = (data, keyField) => {
        const uniqueValues = Array.from(new Set(data.map(item => item[keyField])));
        return uniqueValues.map((value, index) => (
            <Select.Option key={index} value={value}>
                {value}
            </Select.Option>
        ));
    };

    const handleScheduleAppointment = () => {
        setVisible(true);
    };
    const handleCreatePatient = () => {
        setCreatePatient(true);
    };
    const handleRecurringAppointment = () => {
        setRecurringAppointment(true);
    };
    const handleAppointmentBlock = () => {
        setAppointmentsBlock(true);
    };
    const handleBack = () => {
        setVisible(false);
        setRecurringAppointment(false);
        setCreatePatient(false);
        setAppointmentsBlock(false);
    };

    const handleLocationChange = (value) => {
        setSelectedLocation(value);
    };

    const handleProviderChange = (value) => {
        setSelectedProviders(value);
    };

    const handleVisitTypeChange = (value) => {
        setSelectedVisitTypes(value);
    };

    const handleModalClose = () => {
        setModalVisible(false);
        setSelectedEvent(null);
    };
    const handleMonthChange = (value) => {
        if (value) {
            setSelectedMonth(value.month() + 1);
            setSelectedYear(value.year()); // Update the year when month changes
        } else {
            setSelectedMonth(new Date().getMonth() + 1);
            setSelectedYear(new Date().getFullYear());
        }
        fetchData(); // Fetch data after updating month
    };


    return (
        <div className="schedule-container">
            {visible ? (
                <ScheduleAppointment onBack={handleBack} />
            ) :
                createPatient ? (
                    <CreatePatient onBack={handleBack} />
                ) :
                    recurringAppointment ? (
                        <RecurringAppointment onBack={handleBack} />
                    ) :
                        appointmentsBlock ? (
                            <AppointmentBlock onBack={handleBack} />
                        )
                            : (
                                <>
                                    <div className="schedule-body">
                                        <Form
                                            form={form} layout="horizontal" requiredMark={false} {...labelWrapperProps}
                                            colon={false}>
                                            <Row gutter={24}>
                                                <Col span={8}>
                                                    <Form.Item label="Practice Location" name="practiceLocation">
                                                        <Select
                                                            mode="multiple"
                                                            allowClear
                                                            getPopupContainer={(trigger) => trigger.parentNode}
                                                            placeholder="Select a location"
                                                            maxTagCount={2}
                                                            className='location-dropdown'
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            onChange={handleLocationChange}
                                                        >
                                                            {PracticeLocations && PracticeLocations.length > 0 && renderDropdownOptions(PracticeLocations, 'locationName')}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item label="Physician" name="physician">
                                                        <Select
                                                            mode="multiple"
                                                            allowClear
                                                            defaultActiveFirstOption={false}
                                                            maxTagCount={2}
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            onChange={handleProviderChange}
                                                        >
                                                            {providerData && providerData.length > 0 && [...new Set(providerData.map(provider => `${provider.firstName} ${provider.lastName}`))].map((provider, index) => (
                                                                <Select.Option key={index} value={provider}>
                                                                    {provider}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item label="Visit Type" name="visitType">
                                                        <Select mode="multiple" allowClear
                                                            defaultActiveFirstOption={false} maxTagCount={2}
                                                            onChange={handleVisitTypeChange}
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            {visitTypeData && visitTypeData.length > 0 && visitTypeData.map((visitType) => (
                                                                <Select.Option key={visitType.practiceId} value={visitType.visitType}>
                                                                    {visitType.visitType}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={24}>
                                                <Space>
                                                    <Col>
                                                        <Button
                                                            id="scheduleAppointment"
                                                            type="primary"
                                                            icon={<PlusOutlined />}
                                                            onClick={handleScheduleAppointment}
                                                        >
                                                            Schedule Appointment
                                                        </Button>
                                                    </Col>
                                                    <Col>
                                                        <Button
                                                            id="scheduleAppointment"
                                                            type="primary"
                                                            icon={<PlusOutlined />}
                                                            onClick={handleCreatePatient}
                                                        >
                                                            Create Patient
                                                        </Button>
                                                    </Col> <Col>
                                                        <Button
                                                            id="scheduleAppointment"
                                                            type="primary"
                                                            icon={<PlusOutlined />}
                                                            onClick={handleRecurringAppointment}
                                                        >
                                                            Recurring Appointment
                                                        </Button>
                                                    </Col>
                                                    <Col>
                                                        <Button
                                                            id="scheduleAppointment"
                                                            type="primary"
                                                            icon={<PlusOutlined />}
                                                            onClick={handleAppointmentBlock}
                                                        >
                                                            Appointment Block
                                                        </Button>
                                                    </Col>
                                                </Space>
                                            </Row>
                                        </Form>
                                    </div>
                                            <MonthPicker
                                                onChange={handleMonthChange}
                                                value={dayjs().month(selectedMonth - 1).year(selectedYear)} // Update year in MonthPicker
                                                className="drpmonth"
                                            />

                                        <div className="TestCalc" ref={calendarRef}></div>
                                </>
                            )}
            <Modal
                title="Appointment Details"
                open={modalVisible}
                onCancel={handleModalClose}
                footer={[
                    <>
                        <Button type="primary" htmlType="submit">Print</Button>
                        <Button type="primary" htmlType="submit">Logs</Button>
                        <Button type="primary" onClick={handleModalClose}>Close</Button>
                    </>
                ]}
            >
                {selectedEvent && <ScheduleAppointmentDetailsForm eventDetails={{ ...selectedEvent, handleClose: handleModalClose }} />}
            </Modal>
        </div>
    );
};
export default MyCalendar;