import {
  CrownFilled,
  SmileFilled,
  SettingFilled,
  MessageFilled,
  PushpinFilled,
  CalendarFilled,
  EnvironmentFilled,
  AreaChartOutlined,
  MessageOutlined,
  UserOutlined,
  CommentOutlined,
  CarryOutFilled,
  // AreaChartOutlined
} from "@ant-design/icons";
import { FcPlanner ,FcServices,FcConferenceCall ,FcBullish   ,FcSettings ,FcKindle  ,FcCollaboration } from "react-icons/fc";

import { BsCalendarCheckFill , BsCalendar2 } from "react-icons/bs";
const convertData = (data) => {
  if (!data || !data.moduleData || !Array.isArray(data.moduleData)) {
    console.error("Invalid or empty data structure.");
    return null;
  }

  const iconMap = {
    Appointments: <FcKindle   />,
    Conversations: <FcCollaboration  />,
    Calendar: <FcPlanner  />,
    Configuration: <FcServices />,
    Users: <FcConferenceCall  />,
    Settings: <FcSettings   />,
    Profile: <SmileFilled />,
    Account: <CrownFilled />,
    Practice: <CrownFilled />,
    Provider: <CrownFilled />,
    "Practice Location": <EnvironmentFilled />,
    // Reports: <AreaChartOutlined />,
    Conversation:<FcCollaboration  />,
    Reports:<FcBullish  />,
  };

  const result = {
    route: {
      path: "/",
      routes: [],
    },
    location: {
      pathname: "/",
    },
  };

  const processModule = (module) => {
    const path = `/${module.name.toLowerCase()}`;

    if (module.activeFlag !== false) {
      const component = {
        path,
        name: module.displayName,
        icon: iconMap[module.displayName],
        component: `./${module.name.toLowerCase()}`,
        routes: [],
        moduleCode: module.code,
      };

      if (module.subModule && module.subModule.length > 0) {
        module.subModule.forEach((subModule) => {
          const subComponent = processModule(subModule);
          component.routes.push(subComponent);
        });
      }

      return component;
    } else {
      return null;
    }
  };

  data.moduleData.forEach((module) => {
    const topLevelComponent = processModule(module);
    if (topLevelComponent) {
      result.route.routes.push(topLevelComponent);
    }
  });

  return result;
};

export default convertData;
