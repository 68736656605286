import React, { useState, useEffect } from "react";
import Appointment from "../appointments/appointment";
import Scheduler from "../scheduler/scheduler";
import Users from "../users/users"
import { LogoutOutlined, UserOutlined, LockOutlined } from "@ant-design/icons";
import { PageContainer, ProCard, ProLayout, } from "@ant-design/pro-components";
import { ConfigProvider, Dropdown, Menu, Avatar, Drawer, Modal, Divider } from "antd";
import enUS from 'antd/es/locale/en_US'
import { useNavigate } from 'react-router-dom';
import Logo from "../../../assets/images/MoblieViewLogo.png";
import convertData from "./dashboardRouteConverter";
import PracticeLocation from "../practiceLocation/practiceLocation";
import Provider from "../provider/provider";
import Practice from "../practice/practice";
import fetchData from "../../services/dashboardServices";
import "../../../assets/styles/dashboard.css";
import PasswordForm from "../users/passwordForm";
import UpdateUser from "./../users/updateUser";
import AppointmentType from "../appointmentType/appointmentType";
import Report from "../reports/test";
// import { Divider } from "rc-menu";
import { FcPortraitMode  ,FcLock   ,FcExport } 
from "react-icons/fc";

function Dasboard() {
  const [defaultProps, setDefaultProps] = useState(null);
  const [pathname, setPathname] = useState("/appointments");
  const [content, setContent] = useState(null);




  useEffect(() => {
    const fetchDataAndConvert = async () => {
      try {
        const data = await fetchData();
        const convertedData = convertData(data);
        setDefaultProps(convertedData);
        setPathname(pathname);
        setContent(<Appointment moduleCode={convertedData.route.routes[0].moduleCode} />);
      } catch (error) {
        console.error(error);

      }
    };
    fetchDataAndConvert();
  }, []);
  const navigate = useNavigate();

  const [loggedUser, setLoggedUser] = useState(window.sessionStorage.getItem('userName'));

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const handleMenuItemClick = (item) => {
    if (item.name.toLowerCase() === "appointments") {
      setPathname(item.path);
      setContent(<Appointment moduleCode={item.moduleCode} />);
    } else if (item.name.toLowerCase() === "calendar") {
      setPathname(item.path);
      setContent(<Scheduler moduleCode={item.moduleCode} />);
    }
    else if (item.name.toLowerCase() === "users") {
      setPathname(item.path);
      setContent(<Users moduleCode={item.moduleCode} />);
    }
    else if (item.name.toLowerCase() === "practice location") {
      setPathname(item.path);
      setContent(<PracticeLocation moduleCode={item.moduleCode} />);
    }
    else if (item.name.toLowerCase() === "provider") {
      setPathname(item.path);
      setContent(<Provider moduleCode={item.moduleCode} />);
    }
    else if (item.name.toLowerCase() === "practice") {
      setPathname(item.path);
      setContent(<Practice moduleCode={item.moduleCode} />);
    }
    else if (item.name.toLowerCase() === "profile") {
      setPathname(item.path);
      setContent(<Users moduleCode={item.moduleCode} />);
    }
    else if (item.name.toLowerCase() === "account settings") {
      setPathname(item.path);
      setContent(<h1>Replace with Account Settings Component</h1>);
    }
    else if (item.name.toLowerCase() === "profile settings") {
      setPathname(item.path);
      setContent(<h1>Replace with Profile Settings Component</h1>);
    }
    else if (item.name.toLowerCase() === "appointment types") {
      setPathname(item.path);
      setContent(<AppointmentType moduleCode={item.moduleCode} />);
    }
    else if (item.name.toLowerCase() === "reports") {
      setPathname(item.path);
      setContent(<Report moduleCode={item.moduleCode} />);
    }
    else if (item.name.toLowerCase() === "report") {
      setPathname(item.path);
      setContent(<Report moduleCode={item.moduleCode} />);
      console.log("modulecode",item.moduleCode)
    }
    
  };

  const defaultSettings = {
    fixSiderbar: true,
    layout: "mix", /* 'side' | 'top' | 'mix' | */
    splitMenus: false,
    navTheme: "light", /* 'dark' | 'light' | 'realDark' | */
    contentWidth: "Fluid",
    colorPrimary: "#1677FF",
    fixedHeader: false,
    footerRender: false,
    siderMenuType: "sub",
    pageTitleRender: false,
  };

  const settings = {
    // fixSiderbar: data.settings.fixSiderbar || defaultSettings.fixSiderbar,
    fixSiderbar: defaultSettings.fixSiderbar,
    layout: defaultSettings.layout,
    splitMenus: defaultSettings.splitMenus,
    navTheme: defaultSettings.navTheme,
    contentWidth: defaultSettings.contentWidth,
    colorPrimary: defaultSettings.colorPrimary,
    fixedHeader: defaultSettings.fixedHeader,
    footerRender: defaultSettings.footerRender,
    siderMenuType: defaultSettings.siderMenuType,
    pageTitleRender: defaultSettings.pageTitleRender, /* To hide Page title. If want to show comment the line 'true' will not work */
  };

  const handlelogoutClick = (k) => {
    if (k === "1") {
      handleCreateUser();
    }
    if (k === "2") {
      setModalVisible(true);
    }
    else if (k === "3") {
      sessionStorage.clear();
      setLoggedUser(null);
      navigate("/");
    }
  };

  const handleCreateUser = () => {
    setDrawerVisible(true);
  };
  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const num = 12;
  if (typeof document === "undefined") {
    return <div />;
  }

  const userMenu = (
    <Menu onClick={(e) => handlelogoutClick(e.key)}>
      <Menu.Item key="1" className="menu-item-custom">
        <FcPortraitMode  /> Profile
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" className="menu-item-custom">
        <FcLock    /> Change Password
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" className="menu-item-custom">
        <FcExport  /> Logout
      </Menu.Item>
    </Menu>

  );

  return (
    <div
      id="test-pro-layout"
      style={{
        height: "auto",
        // overflow: "auto",
      }}
    >
      {/* <ProConfigProvider hashed={false}> */}
      <ConfigProvider
        locale={enUS}
      // getTargetContainer={() => {
      //   return document.getElementById("test-pro-layout") || document.body;
      // }}
      >
        <ProLayout
          prefixCls="my-prefix"
          {...defaultProps}
          location={{
            pathname,
          }}
          token={{
            header: {
              colorBgMenuItemSelected: "rgba(0,0,0,0.04)",
            },
          }}
          siderMenuType="classic"
          menu={{
            collapsedShowGroupTitle: true,
          }}
          avatarProps={{
            src: "https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg",
            size: "small",
            title: loggedUser,
            render: (props, dom) => {
              return (
                // <Dropdown
                //   menu={{
                //     items: [
                //       {
                //         key: "logout",
                //         icon: <LogoutOutlined />,
                //         label: "Logout",                        
                //       },
                //     ],                    
                //   }}
                // >
                //   {dom}
                // </Dropdown>               

                <Dropdown overlay={userMenu}>
                  <span>
                    <Avatar style={{ backgroundColor: '#f9a11b', cursor: 'pointer' }} icon={<UserOutlined />} />
                    <span style={{ paddingLeft: "10px" }}>{loggedUser}</span>
                  </span>
                </Dropdown>
              );
            },
          }}
          headerTitleRender={(logo, title, _) => {
            const customLogo = (
              <>
                <img
                  src={Logo}
                  alt="Logo"
                  style={{ height: "54px" }}
                />
                {/* <div>{title}</div> */}
              </>
            );

            const defaultDom = (
              <a>
                {customLogo}
              </a>
            );

            if (typeof window === "undefined") return defaultDom;
            if (document.body.clientWidth < 1400) {
              return defaultDom;
            }
            if (_.isMobile);

            return (
              <>
                {defaultDom}
              </>
            );
          }}
          menuFooterRender={(props) => {
            if (props?.collapsed) return undefined;
            return (
              <div
                style={{
                  textAlign: "center",
                  paddingBlockStart: 12,
                }}
              >
                <div>Copyright @ {new Date().getFullYear()} FirstOutcomes</div>

              </div>
            );
          }}
          // onMenuHeaderClick={(e) => console.log(e)}
          menuItemRender={(item, dom) => (
            <div
              onClick={() => {
                handleMenuItemClick(item);
              }}
            >
              {dom}
            </div>
          )}
          footerRender={() => {
            return <div>This is Footer</div>
          }}
          {...settings}
        >
          <PageContainer
            //content="Welcome to the ProLayout component"
            token={{
              paddingInlinePageContainerContent: num,
            }}
          // footer={[
          //   <h1 key="1" type="primary">
          //     Page footer
          //   </h1>,
          // ]}
          // extra={[

          //   <h1 key="1" type="primary">
          //    Page header
          //   </h1>,
          // ]}
          >
            <ProCard
              className="layout-container"
            >
              <div> {content} </div>
            </ProCard>
          </PageContainer>
        </ProLayout>
      </ConfigProvider>
      {/* </ProConfigProvider> */}

      <Modal visible={modalVisible}
        title="Change Password"
        open={modalVisible}
        // onCancel={() => setModalVisible(false)}
        onCancel={handleCloseModal}
        width={350}
        maskClosable={false}
        footer={null}
      >
        <Divider />
        <PasswordForm setModalVisible={setModalVisible} />
      </Modal>

      <Drawer
        title="Profile"
        onClose={handleCloseDrawer}
        open={drawerVisible}
        maskClosable={false}
        className="custom-drawer"
      >
        <UpdateUser keyType="profile" setDrawerVisible={setDrawerVisible} />
      </Drawer>

    </div>
  );
}
export default Dasboard;
