
import axiosInterceptor from "../../middleware/axiosInterceptor";

export async function getConfigurations(body) {
  const url = "/api/getConfigurations"
  try {
    const response = await axiosInterceptor.post(url, body);
    return response.data.configDetail;
  } catch (error) {
    console.error("Error in getPracticeConfigurations service:", error);
    throw error;
  }
}

export async function saveConfigurations(configurations) {
  const url = "/api/configurations/save";
  
  try {
    const response = await axiosInterceptor.post(url, configurations);
    return response.data;
  } 
  catch (error) {
    console.error("Error in savePracticeConfigurations service:", error);
    throw error;
  }
}

export async function getAppointmentTypes() {
  const url = "/api/appointmentType";
  
  try {
    const response = await axiosInterceptor.get(url);
    return response.data;
  } catch (error) {
    console.error("Error in getAppointmentTypes service:", error);
    throw error;
  }
}

export async function getLocation() {
  const url = "/api/location/1/locations";
  
  try {
    const response = await axiosInterceptor.get(url);
    return response.data;
  } catch (error) {
    console.error("Error in location service:", error);
    throw error;
  }
}