import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import {
    Layout,
    Form,
    Input,
    Button,
    Select,
    Row,
    Col,
    Table,
    Upload,
    message,
    Space,
    Divider,
    Radio,
    Modal, Typography, Spin, Checkbox
} from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { saveInsuranceUploadForm, verifyInsurancePassword } from "../../services/ipadRegistartionService";
import "../../../assets/styles/ipadRegistration.css"

const { Option } = Select;
const InsuranceUpload = (props) => {
    const [form] = Form.useForm();
    // const [dataLoaded, setDataLoaded] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const fileInputPrimaryFrontRef = useRef(null);
    const [manualRefInsuranceForm] = Form.useForm();
    const fileInputPrimaryBackRef = useRef(null);
    const fileInputSecondaryBackRef = useRef(null);
    const fileInputSecondaryFrontRef = useRef(null);
    const backgroundDivPrimaryFrontRef = useRef(null);
    const backgroundDivPrimaryBackRef = useRef(null);
    const backgroundDivSecondaryFrontRef = useRef(null);
    const backgroundDivSecondaryBackRef = useRef(null);
    const [primaryErrorMessage, setPrimaryErrorMessage] = useState(false);
    const [secondaryErrorMessage, setSecondaryErrorMessage] = useState(false);
    const [filePrimaryFront, setFilePrimaryFront] = useState(null);
    const [filePrimaryBack, setFilePrimaryBack] = useState(null);
    const [fileSecondaryFront, setFileSecondaryFront] = useState(null);
    const [fileSecondaryBack, setFileSecondaryBack] = useState(null);
    const [insuranceChoice, setInsuranceChoice] = useState('selfpay');
    const [secondaryInsuranceChoice, setSecondaryInsuranceChoice] = useState('No');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [backgroundFrontImage, setBackgroundFrontImage] = useState(null);
    const [backgroundBackImage, setBackgroundBackImage] = useState(null);
    const [backgroundSecondaryImage, setBackgroundSecondaryImage] = useState(null);
    const [backgroundSecondaryBackImage, setBackgroundSecondaryBackImage] = useState(null);
    const [showPrimaryConfirmation, setShowPrimaryConfirmation] = useState(false);
    const [modalManualPopup, setModalManualPopup] = useState(false);
    const currentPageURL = window.location.href;

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, []);

    // useEffect(() => {
    //     if (insuranceChoice === 'Insured') {
    //         if (backgroundFrontImage && backgroundDivPrimaryFrontRef.current) {
    //             // const url = new URL(currentPageURL);
    //             const url = new URL(window.location.href.split('#')[0]);
    //             if (url.searchParams.has('prId')) {
    //                 url.searchParams.delete('prId'); // Remove the 'prId' query parameter
    //             }
    //             const modifiedURL = url.toString();
    //             backgroundDivPrimaryFrontRef.current.style.backgroundImage = `url(${modifiedURL}/storage/images?path=${backgroundFrontImage})`;
    //             let frontLabel = document.querySelector('.front');
    //             if (frontLabel) {
    //                 frontLabel.innerHTML = '';
    //             }
    //         }
    //     }
    // }, [backgroundFrontImage, showConfirmation, showPrimaryConfirmation, insuranceChoice]);
    // useEffect(() => {
    //     if (insuranceChoice === 'Insured') {
    //         const url = new URL(window.location.href.split('#')[0]);
    //         if (url.searchParams.has('prId')) {
    //             url.searchParams.delete('prId'); // Remove the 'prId' query parameter
    //         }
    //         const modifiedURL = url.toString();
    //         if (backgroundBackImage && backgroundDivPrimaryBackRef.current) {
    //             backgroundDivPrimaryBackRef.current.style.backgroundImage = `url(${modifiedURL}/storage/images?path=${backgroundBackImage})`;
    //             let backLabel = document.querySelector('.back');
    //             if (backLabel) {
    //                 backLabel.innerHTML = '';
    //             }
    //         }
    //     }
    // }, [backgroundBackImage, showConfirmation, showPrimaryConfirmation, insuranceChoice]);
    // useEffect(() => {
    //     if (secondaryInsuranceChoice === 'Yes') {
    //         if (backgroundSecondaryImage && backgroundDivSecondaryFrontRef.current) {
    //             const url = new URL(window.location.href.split('#')[0]);
    //             if (url.searchParams.has('prId')) {
    //                 url.searchParams.delete('prId'); // Remove the 'prId' query parameter
    //             }
    //             const modifiedURL = url.toString();
    //             backgroundDivSecondaryFrontRef.current.style.backgroundImage = `url(${modifiedURL}/storage/images?path=${backgroundSecondaryImage})`;
    //             let frontLabel = document.getElementById("secondaryFront");
    //             if (frontLabel) {
    //                 frontLabel.innerHTML = '';
    //             }
    //         }
    //     }
    // }, [backgroundSecondaryImage, showConfirmation, showPrimaryConfirmation, secondaryInsuranceChoice]);
    // useEffect(() => {
    //     if (secondaryInsuranceChoice === 'Yes') {
    //         if (backgroundSecondaryBackImage && backgroundDivSecondaryBackRef.current) {
    //             const url = new URL(window.location.href.split('#')[0]);
    //             if (url.searchParams.has('prId')) {
    //                 url.searchParams.delete('prId'); // Remove the 'prId' query parameter
    //             }
    //             const modifiedURL = url.toString();
    //             backgroundDivSecondaryBackRef.current.style.backgroundImage = `url(${modifiedURL}/storage/images?path=${backgroundSecondaryBackImage})`;
    //             let backLabel = document.getElementById("secondaryBack");
    //             if (backLabel) {
    //                 backLabel.innerHTML = '';
    //             }
    //         }
    //     }
    // }, [backgroundSecondaryBackImage, showConfirmation, showPrimaryConfirmation, secondaryInsuranceChoice]);
    // useEffect(() => {
    //     if (insuranceChoice === 'Insured') {
    //         if (filePrimaryFront && backgroundDivPrimaryFrontRef.current) {
    //             let imageUrl = URL.createObjectURL(filePrimaryFront);
    //             backgroundDivPrimaryFrontRef.current.style.backgroundImage = `url(${imageUrl})`;
    //             let frontLabel = document.querySelector('.front');
    //             if (frontLabel) {
    //                 frontLabel.innerHTML = '';
    //             }
    //         }
    //     }
    // }, [filePrimaryFront, showConfirmation, showPrimaryConfirmation, insuranceChoice]);
    // useEffect(() => {
    //     if (insuranceChoice === 'Insured') {
    //         if (filePrimaryBack && backgroundDivPrimaryBackRef.current) {
    //             let imageUrl = URL.createObjectURL(filePrimaryBack);
    //             backgroundDivPrimaryBackRef.current.style.backgroundImage = `url(${imageUrl})`;
    //             let frontLabel = document.querySelector('.back');
    //             if (frontLabel) {
    //                 frontLabel.innerHTML = '';
    //             }
    //         }
    //     }
    // }, [filePrimaryBack, showConfirmation, showPrimaryConfirmation, insuranceChoice]);
    // useEffect(() => {
    //     if (secondaryInsuranceChoice === 'Yes') {
    //         if (fileSecondaryBack && backgroundDivSecondaryBackRef.current) {
    //             let imageUrl = URL.createObjectURL(fileSecondaryBack);
    //             backgroundDivSecondaryBackRef.current.style.backgroundImage = `url(${imageUrl})`;
    //             let frontLabel = document.getElementById("secondaryBack")
    //             if (frontLabel) {
    //                 frontLabel.innerHTML = '';
    //             }
    //         }
    //     }
    // }, [fileSecondaryBack, showConfirmation, showPrimaryConfirmation, secondaryInsuranceChoice]);
    // useEffect(() => {
    //     if (secondaryInsuranceChoice === 'Yes') {
    //         if (fileSecondaryFront && backgroundDivSecondaryFrontRef.current) {
    //             let imageUrl = URL.createObjectURL(fileSecondaryFront);
    //             backgroundDivSecondaryFrontRef.current.style.backgroundImage = `url(${imageUrl})`;
    //             let frontLabel = document.getElementById("secondaryFront")
    //             if (frontLabel) {
    //                 frontLabel.innerHTML = '';
    //             }
    //         }
    //     }
    // }, [fileSecondaryFront, showConfirmation, showPrimaryConfirmation, secondaryInsuranceChoice]);
    // useEffect(() => {
    //     if (!(props.patientRegistrationInfo.length === 0)) {
    //         setDataLoaded(true);
    //         const insuranceImageFront = props.patientRegistrationInfo.UploadedFilePath.insuranceImageFront;
    //         const insuranceImageBack = props.patientRegistrationInfo.UploadedFilePath.insuranceImageBack;
    //         const secondaryInsuranceFront = props.patientRegistrationInfo.UploadedFilePath.secondaryInsuranceFront;
    //         const secondaryInsuranceBack = props.patientRegistrationInfo.UploadedFilePath.secondaryInsuranceBack;

    //         if (insuranceImageFront != null) {
    //             setBackgroundFrontImage(insuranceImageFront.path)
    //             const insurance_type = props.patientRegistrationInfo.patientRegistrationInfo.insurance_type
    //             setInsuranceChoice(insurance_type)
    //             setShowConfirmation(true);
    //         }
    //         if (insuranceImageBack != null) {
    //             setBackgroundBackImage(insuranceImageBack.path);
    //             setShowConfirmation(true);
    //         }
    //         if (secondaryInsuranceFront != null) {
    //             const secondary_insurance_type = props.patientRegistrationInfo.patientRegistrationInfo.secondary_insurance_type
    //             setSecondaryInsuranceChoice(secondary_insurance_type)
    //             setBackgroundSecondaryImage(secondaryInsuranceFront.path);
    //             setShowConfirmation(true);
    //         }
    //         if (secondaryInsuranceBack != null) {
    //             setBackgroundSecondaryBackImage(secondaryInsuranceBack.path);
    //             setShowConfirmation(true);
    //         }
    //     }
    // }, [props]);
    const handleDivPrimaryFrontClick = () => {
        fileInputPrimaryFrontRef.current.click();
    };
    const handleDivPrimaryBackClick = () => {
        fileInputPrimaryBackRef.current.click();
    };
    const handleDivSecondaryFrontClick = () => {
        fileInputSecondaryFrontRef.current.click();
    };
    const handleDivSecondaryBackClick = () => {
        fileInputSecondaryBackRef.current.click();
    };
    const handlePrimaryInsuranceFrontChange = (e) => {
        if (e.target.files.length > 0) {
            setFilePrimaryFront(e.target.files[0]);
        }
    };
    const handlePrimaryInsuranceBackChange = (e) => {
        if (e.target.files.length > 0) {
            setFilePrimaryBack(e.target.files[0]);
        }
    };
    const handleSecondaryInsuranceFrontChange = (e) => {
        if (e.target.files.length > 0) {
            setFileSecondaryFront(e.target.files[0]);
        }
    };
    const handleSecondaryInsuranceBackChange = (e) => {
        if (e.target.files.length > 0) {
            setFileSecondaryBack(e.target.files[0]);
        }
    };
    const ManualVerifyPopup = () => {
        setModalManualPopup(true);
    };
    const handleFormSubmit = async () => {
        setIsSubmitting(true);
        try {
            await new Promise(resolve => setTimeout(resolve, 2000));
            if (insuranceChoice === 'Insured') {
                if ((filePrimaryFront && filePrimaryBack) || (backgroundFrontImage && backgroundBackImage)) {
                    setPrimaryErrorMessage(false);
                    setShowConfirmation(true);

                    if (secondaryInsuranceChoice === 'Yes') {
                        if ((fileSecondaryFront && fileSecondaryBack) || (backgroundSecondaryImage && backgroundSecondaryBackImage)) {
                            setSecondaryErrorMessage(false);
                            setShowConfirmation(true);
                        } else {
                            setSecondaryErrorMessage(true);
                            setShowConfirmation(false);
                        }
                    }
                } else {
                    setPrimaryErrorMessage(true);
                    setIsSubmitting(false); // Stop loading
                    return;
                }
            } else {
                const formDatas = new FormData();
                formDatas.append('uniqueHash', props.uniqueHash);
                formDatas.append('Radio_Insurance_Type', insuranceChoice);
                formDatas.append('Radio_Secondary_Insurance_Type', secondaryInsuranceChoice);
                const url = new URL(currentPageURL);
                if (url.searchParams.has('prId')) {
                    formDatas.append('prId', true);
                }
                // Your actual API call here
                const response = await saveInsuranceUploadForm(formDatas);

                if (response.message === 'Success') {
                    props.handleNextClick(props);
                }
                // else {
                //     const url = new URL(currentPageURL);
                //     if (url.searchParams.has('prId')) {
                //         // Modal.info({
                //         //     content: 'We were unable to process your Insurance information. To ensure a smooth visit, please bring your Insurance card to the front desk during your appointment.',
                //         // });
                //         props.handleNextClick(props);
                //     }
                // }
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsSubmitting(false); // Stop loading
        }
    };
    const onInsuranceUploadInfoNextClick = async () => {
        setIsSubmitting(true);
        try {
            if (insuranceChoice === 'Insured' && secondaryInsuranceChoice === 'Yes' && showConfirmation === true) {
                setShowPrimaryConfirmation(true);
                setShowConfirmation(false);
            }
            else {
                if ((filePrimaryFront && filePrimaryBack) || (fileSecondaryFront && fileSecondaryBack) ||
                    backgroundFrontImage && backgroundBackImage || (backgroundSecondaryImage && backgroundSecondaryBackImage)) {
                    const formDatas = new FormData();
                    formDatas.append('uniqueHash', props.uniqueHash);
                    formDatas.append('Radio_Insurance_Type', insuranceChoice);
                    formDatas.append('Radio_Secondary_Insurance_Type', secondaryInsuranceChoice);
                    if (filePrimaryFront || filePrimaryBack) {
                        if ((filePrimaryFront && filePrimaryBack) || (backgroundFrontImage && backgroundBackImage)) {
                            setPrimaryErrorMessage(false);
                            if (filePrimaryFront) {
                                formDatas.append('insuranceImageFrontSide', filePrimaryFront);
                            }
                            if (filePrimaryBack) {
                                formDatas.append('insuranceImageBackSide', filePrimaryBack);
                            }
                        }
                    }
                    if (secondaryInsuranceChoice === 'Yes' && (fileSecondaryFront || fileSecondaryBack)) {
                        if ((fileSecondaryFront && fileSecondaryBack) || (backgroundSecondaryImage && backgroundSecondaryBackImage)) {
                            setSecondaryErrorMessage(false);
                            if (fileSecondaryFront) {
                                formDatas.append('insuranceSecondaryImageFrontSide', fileSecondaryFront);
                            }
                            if (fileSecondaryBack) {
                                formDatas.append('insuranceSecondaryImageBackSide', fileSecondaryBack);
                            }
                        }
                    }
                    try {
                        const url = new URL(currentPageURL);
                        if (url.searchParams.has('prId')) {
                            formDatas.append('prId', true);
                        }
                        let response = await saveInsuranceUploadForm(formDatas);
                        if (response.message === 'Success') {
                            props.handleNextClick(props);
                        }
                        // else {
                        //     const url = new URL(currentPageURL);
                        //     if (url.searchParams.has('prId')) {
                        //         Modal.info({
                        //             content: 'We were unable to process your Insurance information. To ensure a smooth visit, please bring your Insurance card to the front desk during your appointment.',
                        //         });
                        //         props.handleNextClick(props);
                        //     }
                        // }
                    } catch (error) {
                        console.error('File upload failed', error);
                    }
                }
            }
        } finally {
            setIsSubmitting(false);
        }
    };
    const onInsuranceInfoPreviousClick = () => {
        setShowConfirmation(false);
        setShowPrimaryConfirmation(false);
    };

    const onInsurancePreviousClick = () => {
        props.handlePreviousClick(props);
    };

    const handleInsuranceChoice = (value) => {
        setInsuranceChoice(value);
    };
    const handleSecondaryInsuranceChoice = (value) => {
        setSecondaryInsuranceChoice(value);
        if (value === 'No') {
            setSecondaryErrorMessage(false);
        }
    };
    const onCancelManualVerify = () => {
        setModalManualPopup(true);
    };
    const handleManualVerifySubmit = async (values) => {
        const modalFormValues = await manualRefInsuranceForm.validateFields();
        if (modalFormValues) {
            let response = await verifyInsurancePassword(props.uniqueHash, modalFormValues);
            if (response.message === 'Success') {
                props.handleNextClick(props);
            }
            else {
                Modal.error({
                    title: 'This is an error message',
                    content: 'Invalid Pin',
                });
            }
        }
    };

    // if (!dataLoaded) {
    //     return (
    //         <div className="spinner-overlay">
    //             <div className="spinner">
    //                 <Spin size="large" tip="Loading..." />
    //             </div>
    //         </div>
    //     );
    // }
    return (
        <div>
            <Form form={form} layout="vertical" size="large">
                {showConfirmation && !showPrimaryConfirmation ? <h6>Do You See Your Primary Insurance Card ? </h6> : null}
                {!showConfirmation && !showPrimaryConfirmation ?
                    <div>
                        <h2>Insurance Information</h2>
                        <label style={{ fontSize: 16 }}>Do you have insurance?</label>
                        <Form.Item style={{ marginTop: 16 }}>
                            <Radio.Group value={insuranceChoice}
                                onChange={(e) => handleInsuranceChoice(e.target.value)}>
                                <Space>
                                    <Radio.Button value="Insured" className="btn btn-success">Yes</Radio.Button>
                                    <Radio.Button value="selfpay" className="btn btn-secondary">No</Radio.Button>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        {primaryErrorMessage && <Typography.Title level={3} type="danger">Both image fields are required.</Typography.Title>}
                    </div> : null
                }
                {insuranceChoice === 'Insured' && (
                    <div>
                        {!showConfirmation && !showPrimaryConfirmation ?
                            <p>
                                <b>Please take a picture of your insurance card (Camera is at the back of your phone or
                                    iPad).</b>
                            </p> : null
                        }
                        {!showPrimaryConfirmation &&
                            <div>
                                <Form.Item style={{ marginTop: 20 }}>
                                    <div className="insuranceFrontImageDiv" ref={backgroundDivPrimaryFrontRef}
                                        onClick={!showConfirmation ? handleDivPrimaryFrontClick : null}>
                                        <label className="text-secondary front">Front side</label>
                                    </div>
                                    <input type="file" id="insurancePrimaryFrontFile" name="insurancePrimaryImageFrontSide" style={{ display: 'none' }} accept="image/*"
                                        onChange={handlePrimaryInsuranceFrontChange} ref={fileInputPrimaryFrontRef} />
                                    {!showConfirmation ? <button className="btn btn-primary" id="takePhoto" style={{ width: ' 100%', maxWidth: '380px', borderRadius: '7px', marginTop: '10px' }}
                                        onClick={handleDivPrimaryFrontClick}>Take photo</button>
                                        : null}
                                </Form.Item>
                                <Form.Item style={{ marginTop: 20 }}>
                                    <div ref={backgroundDivPrimaryBackRef} className="insuranceBackImageDiv" onClick={!showConfirmation ? handleDivPrimaryBackClick : null}>
                                        <label className="text-secondary back">Back side</label>
                                    </div>
                                    <input type="file" id="insurancePrimaryBackFile" name="insurancePrimaryImageBackSide" style={{ display: 'none' }} accept="image/*"
                                        onChange={handlePrimaryInsuranceBackChange} ref={fileInputPrimaryBackRef} />
                                    {!showConfirmation ? <button className="btn btn-primary" style={{ width: ' 100%', maxWidth: '380px', borderRadius: '7px', marginTop: '10px' }}
                                        onClick={handleDivPrimaryBackClick}>Take photo</button>
                                        : null}
                                </Form.Item>
                            </div>
                        }
                    </div>
                )}
            </Form>

            {!showConfirmation && insuranceChoice === 'Insured' && (
                <Form form={form} layout="vertical" size="large">
                    {showPrimaryConfirmation ? <h6>Do You See Your Secondary Insurance Card ? </h6> : (
                        <div>
                            <label style={{ fontSize: 16 }}>Do you have a Secondary insurance?</label>
                            <Form.Item style={{ marginTop: 16 }}>
                                <Radio.Group value={secondaryInsuranceChoice}
                                    onChange={(e) => handleSecondaryInsuranceChoice(e.target.value)}>
                                    <Space>
                                        <Radio.Button value="Yes" className="btn btn-success">Yes</Radio.Button>
                                        <Radio.Button value="No" className="btn btn-secondary">No</Radio.Button>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                            {secondaryErrorMessage && <Typography.Title level={3} type="danger">Both image fields are required.</Typography.Title>}
                        </div>
                    )}
                    {secondaryInsuranceChoice === 'Yes' && (
                        <div>
                            {!showConfirmation && !showPrimaryConfirmation ?
                                <p>
                                    <b>Please take a picture of your insurance card (Camera is at the back of your phone or
                                        iPad).</b>
                                </p> : null}
                            <Form.Item style={{ marginTop: 20 }}>
                                <div ref={backgroundDivSecondaryFrontRef} className="secondaryInsuranceFrontImageDiv" onClick={!showPrimaryConfirmation ? handleDivSecondaryFrontClick : null}>
                                    <label id="secondaryFront" className="text-secondary">Front side</label>
                                </div>
                                <input type="file" id="insuranceSecondaryFrontFile" name="insuranceSecondaryImageFrontSide" style={{ display: 'none' }} accept="image/*"
                                    onChange={handleSecondaryInsuranceFrontChange} ref={fileInputSecondaryFrontRef} />
                                {!showPrimaryConfirmation ? <button className="btn btn-primary" id="takePhoto" style={{ width: ' 100%', maxWidth: '380px', borderRadius: '7px', marginTop: '10px' }}
                                    onClick={handleDivSecondaryFrontClick}>Take photo</button>
                                    : null}
                            </Form.Item>
                            <Form.Item style={{ marginTop: 20 }}>
                                <div ref={backgroundDivSecondaryBackRef} className="secondaryInsuranceBackImageDiv" onClick={!showPrimaryConfirmation ? handleDivSecondaryBackClick : null}>
                                    <label id="secondaryBack" className="text-secondary">Back side</label>
                                </div>
                                <input type="file" id="insuranceSecondaryBackFile" name="insuranceSecondaryImageBackSide" style={{ display: 'none' }} accept="image/*"
                                    onChange={handleSecondaryInsuranceBackChange} ref={fileInputSecondaryBackRef} />
                                {!showPrimaryConfirmation ? <button className="btn btn-primary" style={{ width: ' 100%', maxWidth: '380px', borderRadius: '7px', marginTop: '10px' }}
                                    onClick={handleDivSecondaryBackClick}>Take photo</button>
                                    : null}
                            </Form.Item>
                        </div>
                    )}
                </Form>
            )}
            {!showConfirmation && !showPrimaryConfirmation ?
                <Form.Item className="form-footer-button">
                    <Row gutter={12}>
                        <Col>
                            <Button type="primary" onClick={onInsurancePreviousClick}>Previous</Button>
                        </Col>
                        <Col>
                            <Button type="primary" htmlType="submit" onClick={handleFormSubmit}>Next</Button>
                        </Col>
                    </Row>
                </Form.Item>
                :
                <div className="form-footer-button">
                    {isSubmitting ? (
                        <div className="spinner-overlay">
                            <div className="spinner">
                                <Spin size="large" tip="Loading..." />
                            </div>
                        </div>
                    ) : (
                        <Radio.Group value="InsuranceConfirmationButton">
                            <Space>
                                <Radio.Button value="yes" className="btn btn-success" onClick={onInsuranceUploadInfoNextClick}>Yes</Radio.Button>
                                <Radio.Button value="no" className="btn btn-secondary" onClick={onInsuranceInfoPreviousClick}>No</Radio.Button>
                            </Space>
                        </Radio.Group>
                    )}
                </div>
            }
            <Modal className="manual-validate-modal"
                open={modalManualPopup}
                onOk={() => {
                    if (!manualRefInsuranceForm.getFieldValue('manualCheck') && !manualRefInsuranceForm.getFieldValue('pin')) {
                        manualRefInsuranceForm.setFields([
                            { name: 'manualCheck', errors: ['Please check the checkbox.'] },
                            { name: 'pin', errors: ['This field is required.'] },
                        ]);
                    }
                    else if (!manualRefInsuranceForm.getFieldValue('pin')) {
                        manualRefInsuranceForm.setFields([
                            {
                                name: 'pin',
                                errors: ['This field is required.'],
                            },
                        ]);
                    }
                    else if (!manualRefInsuranceForm.getFieldValue('manualCheck')) {
                        manualRefInsuranceForm.setFields([{ name: 'manualCheck', errors: ['Please check the checkbox.'] },]);
                    }
                    else {
                        manualRefInsuranceForm.submit();
                    }
                }}
                maskClosable={false}
                cancelText={null}
                onCancel={onCancelManualVerify} width={500}
            >
                <Form form={manualRefInsuranceForm} layout="vertical" onFinish={handleManualVerifySubmit}>
                    <Form.Item label="Enter your pin" name="pin"
                        rules={[{ required: true, message: 'This field is required.' }]}>
                        <Input.Password placeholder="Enter your pin" />
                    </Form.Item>
                    <Form.Item name="manualCheck" valuePropName="checked" rules={[{ required: true, message: 'Please check the checkbox.' }]}>
                        <Checkbox>Manually Verified</Checkbox>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default InsuranceUpload;
