import axiosInterceptor from "../../middleware/axiosInterceptor";


//Time Zone dropdown
// Function to fetch user details
export const fetchRegisterDetails = async () => {
    const userId = sessionStorage.getItem('userId');
    const url = "/api/user/create-form-details?userid=" + userId;
    try {
        const response = await axiosInterceptor.get(url);
        return response;
    } catch (error) {
        console.error(error);
    }
};


// Function to post user details
export const registerUserDetails = async (userData) => {
    const url = "/api/register";
    try {
        const response = await axiosInterceptor.post(url, userData);

        return response;
    } catch (error) {
        console.error("Error posting user details:", error);
    }
};


//Update method - edit profile & edit user same api
export const updateUser = async (userData) => {
    const url = "/api/user/update";
    try {
        const response = await axiosInterceptor.put(url, userData);

        return response;
    } catch (error) {
        console.error(error);
    }
};
