import DefaultModule from "../../utils/defaultModule";

function Scheduler(props) {
  return (
    <DefaultModule moduleCode={props} />
  );
}


export default Scheduler;
