//#region Local Imports
import { message } from 'antd';
import {setPasswordService} from '../../../services/setPasswordService';
import fetchSecurityQuestions from '../../../services/securityQuestionService';

//#endregion

export async function setPasswordHandler(tempUserId, values, selectedQuestion, navigate, form, setTerms, setError) {
    try {
        setError("");
        const phone = values.phoneNumber ? values.phoneNumber.replace(/\D/g, '') : '';

        const formData = {
            userid: tempUserId,
            password: values.password,
            question_id: selectedQuestion,
            answer: values.answer,
            phone: phone,
            terms: values.terms
        };        

        const response = await setPasswordService(formData);
        if (response && response.data && response.data.success) {
            message.success("Submitted successfully");
            // codeVerify(navigate, setError);
            navigate("/userNotifyVerification");
            //navigate("/dashboard");

        } else {
            if (response && response.data && response.data.businessRules && response.data.businessRules.length > 0) {
                const firstError = response.data.businessRules[0].description;
                setError(firstError);
            }
            else {
                setError("Error updating new Password");
            }
        }
    } catch (error) {
        console.error("Error:", error);
        setError("Error: Failed to submit");
    }
};

export const handleQuestionChange = (value, setSelectedQuestion) => {
    setSelectedQuestion(value);
};


export const fetchSecurityQuestionsHandler = async (setSecurityQuestions) => {
    try {
        const response = await fetchSecurityQuestions();
        setSecurityQuestions(response.data.securityQuestions);
    }
    catch (error) {
        console.error("Error fetching security questions:", error.message);
    }
};


