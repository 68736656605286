import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Table, DatePicker, Col, Row, Radio, Space, Tooltip, Divider, Flex, Layout } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import "../../assets/styles/CRM.css";

const CMSTest = () => {
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const [data, setData] = useState([1]);

    const handleSubmit = () => {
        setData(prevData => [...prevData, {}]);
    };

    const handleChange = (e, index, field) => {
        const newData = [...data];
        newData[index] = {
            ...newData[index],
            [field]: e.target.value,
        };
        setData(newData);
    };

    const headerStyle = {
        textAlign: 'center',
        color: '#555050',
        height: 64,
        paddingInline: 48,
        lineHeight: '64px',
        backgroundColor: '#838fd821',
        fontWeight: 'bolder',
        fontSize: '20px',
    };

    const contentStyle = {
        textAlign: 'center',
        color: '#555050',
        backgroundColor: '#ffffff',
        padding: '25px 25px',
        paddingLeft: '55px',
        paddingRight: '55px'
    };

    const borderedColStyle = {
        border: '1px solid #f0f0f0',
        padding: '0px',
        color: '#2f2c2d',

    };


    return (
        <>
            <Layout>
                <Layout.Header style={headerStyle}>CMS-1500 FORM</Layout.Header>
                <Layout.Content style={contentStyle}>
                    <Form form={form} layout="vertical" onFinish={handleSubmit}>
                        <Row gutter={16} style={{ marginBottom: '16px' }}>
                            <Col span={15} style={borderedColStyle}>
                                <Form.Item label={<span className="custom-table-header">1. Insurance Type</span>} name="insuranceType">
                                    <Radio.Group>
                                        <Space direction="horizontal">
                                            <Radio value="medicare">MEDICARE</Radio>
                                            <Radio value="medicaid">MEDICAID</Radio>
                                            <Radio value="tricare">TRICARE</Radio>
                                            <Radio value="champva">CHAMPVA</Radio>
                                            <Radio value="groupHealth">GROUP HEALTH PLAN</Radio>
                                            <Radio value="feca">FECA BLK LUNG</Radio>
                                            <Radio value="other">OTHER</Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={9} style={borderedColStyle}>
                                <Form.Item label={<span className="custom-table-header">1a. Insured's I.D. Number</span>} name="insuredID">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16} style={{ marginBottom: '16px' }}>
                            <Col span={9} style={borderedColStyle}>
                                <Form.Item label={<span className="custom-table-header">2. Patient's Name</span>} name="patientName" style={{ padding: '10px' }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={6} style={borderedColStyle}>
                                <Form.Item label={<span className="custom-table-header">3. Patient's Birth Date</span>} name="dob" style={{ padding: '10px' }}>
                                    <DatePicker style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={9} style={borderedColStyle}>
                                <Form.Item label={<span className="custom-table-header">4. Insured's Name</span>} name="insuredName" style={{ padding: '10px' }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16} style={{ marginBottom: '16px' }}>
                            <Col span={9} style={borderedColStyle}>
                                <Row span={24}>
                                    <Col span={24}>
                                        <Form.Item label={<span className="custom-table-header">5. Patient Address (No., Street)</span>} name="address">
                                            <TextArea rows={1} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row span={24}>
                                    <Col span={18}>
                                        <Form.Item label={<span className="custom-table-sideheader">City</span>} name="insuredState">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} style={{ paddingLeft: '16px' }}>
                                        <Form.Item label={<span className="custom-table-sideheader">State</span>} name="insuredState">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row span={24}>
                                    <Col span={12}>
                                        <Form.Item label={<span className="custom-table-sideheader">Zip Code</span>} name="insuredZipcode">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} style={{ paddingLeft: '16px' }}>
                                        <Form.Item label={<span className="custom-table-sideheader">Telephone (Include Area Code)</span>} name="insuredTelephone">
                                            <Input addonBefore="+91" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                            </Col>
                            <Col span={6} style={borderedColStyle}>
                                <Row span={24}>
                                    <Col span={24}>
                                        <Form.Item label={<span className="custom-table-header">6. Patient Relationship To Insured</span>} name="patientRelationship">
                                            <Radio.Group >
                                                <Space direction="horizontal">
                                                    <Radio value="self">Self</Radio>
                                                    <Radio value="spouse">Spouse</Radio>
                                                    <Radio value="child">Child</Radio>
                                                    <Radio value="other">Other</Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row span={24}>
                                    <Col span={24}>
                                        <Form.Item label={<span className="custom-table-header">8. Reserved For Nucc Use</span>} name="NUCC">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>

                            </Col>



                            <Col span={9} style={borderedColStyle}>
                                <Row span={24}>
                                    <Col span={24}>
                                        <Form.Item label={<span className="custom-table-header">7. Insured's Address (No., Street)</span>} name="insuredAddress">
                                            <TextArea rows={1} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row span={24}>
                                    <Col span={18}>
                                        <Form.Item label={<span className="custom-table-sideheader">City</span>} name="insuredState">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} style={{ paddingLeft: '16px' }}>
                                        <Form.Item label={<span className="custom-table-sideheader">State</span>} name="insuredState">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row span={24}>
                                    <Col span={12}>
                                        <Form.Item label={<span className="custom-table-sideheader">Zip Code</span>} name="insuredZipcode">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} style={{ paddingLeft: '16px' }}>
                                        <Form.Item label={<span className="custom-table-sideheader">Telephone (Include Area Code)</span>} name="insuredTelephone">
                                            <Input addonBefore="+91" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row gutter={16} style={{ marginBottom: '16px' }}>
                            <Col span={9} style={borderedColStyle}>
                                <Form.Item label={<span className="custom-table-header">9. Other Insured's Name (Last Name, First Name, Middle Initial)</span>} name="otherInsuredName">
                                    <Input />
                                </Form.Item>
                                <Form.Item label={<span className="custom-table-sideheader">a. Other Insured's Policy Or Group Number</span>} name="otherInsuredPolicyNumber">
                                    <Input />
                                </Form.Item>
                                <Form.Item label={<span className="custom-table-sideheader">b. Reserved For Nucc Use</span>} name="reservedForNUCCUseB">
                                    <Input />
                                </Form.Item>
                                <Form.Item label={<span className="custom-table-sideheader">c. Reserved For Nucc Use</span>} name="reservedForNUCCUseC">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={6} style={borderedColStyle}>
                                <Form.Item label={<span className="custom-table-header">10. Is Patient's Condition Related To</span>}>


                                    {/* <Col span={24} > */}
                                    <Form.Item label={<span className="custom-table-sideheader">A. Employment? (Current Or Previous)</span>} name="employment" style={{ marginBottom: 0 }}>
                                        <Radio.Group>
                                            <Radio value="yes">Yes</Radio>
                                            <Radio value="no">No</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    {/* </Col>
                                    <Col span={24}  > */}
                                    <Form.Item label={<span className="custom-table-sideheader">B. Auto Accident?</span>} name="autoAccident" style={{ marginBottom: 0 }}>
                                        <Radio.Group>
                                            <Radio value="yes">Yes</Radio>
                                            <Radio value="no">No</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    {/* </Col>
                                    <Col span={24}  > */}
                                    <Form.Item label={<span className="custom-table-sideheader">C. Other Accident?</span>} name="otherAccident">
                                        <Radio.Group>
                                            <Radio value="yes">Yes</Radio>
                                            <Radio value="no">No</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    {/* </Col>
                                    <Col span={24} > */}
                                    <Form.Item label={<span className="custom-table-sideheader">d.Claim Codes (Designated by NUCC)</span>} name="additionalClaimInfo">
                                        <Input style={{ width: '100%' }} className="input" />
                                    </Form.Item>
                                    {/* </Col> */}
                                </Form.Item>
                            </Col>
                            <Col span={9} style={borderedColStyle}>

                                <Form.Item label={<span className="custom-table-header">11. Insured's Policy Group Or Feca Number</span>} name="insuredPolicyNumber">
                                    <Input />
                                </Form.Item>

                                <Form.Item label={<span className="custom-table-sideheader">a. Insured's Date Of Birth</span>} name="insuredDOB">
                                    <DatePicker style={{ width: '100%' }} />
                                </Form.Item>

                                <Form.Item label={<span className="custom-table-sideheader">b. Other Claim Id (Designated By Nucc)</span>} name="otherClaimID">
                                    <Input />
                                </Form.Item>
                                <Form.Item label={<span className="custom-table-sideheader">c. Insurance Plan Name Or Program Name</span>} name="insurancePlanNameOrProgram">
                                    <Input />
                                </Form.Item>
                                <Form.Item label={<span className="custom-table-sideheader">d. Is There Another Health Benefit Plan?</span>} name="anotherHealthBenefitPlan">
                                    <Radio.Group>
                                        <Radio value="yes">Yes</Radio>
                                        <Radio value="no">No</Radio>
                                    </Radio.Group>
                                    <label>( <b>If yes,</b> complete items 9, 9a and 9d )</label>
                                </Form.Item>

                            </Col>
                        </Row>

                        <Row gutter={16} style={{ marginBottom: '16px' }}>
                            <Col span={15} style={borderedColStyle}>
                                <Form.Item label={<span className="custom-table-header">12.PATIENT'S OR AUTHORIZED PERSON'S SIGNATURE. I authorize the release of any medical or other information necessary to process this claim.
                                    I also request payment of government benefits either to myself or to the party who accepts assignment below</span>} name="form" style={{ marginBottom: 0 }}>
                                </Form.Item>
                                <Row gutter={16}>
                                    <Col xs={24} md={12} className='form-group'>
                                        <Form.Item label="Signed" name="signed" style={{ marginBottom: 0 }}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12} className='form-group'>
                                        <Form.Item label="Date" name="date" style={{ marginBottom: 0 }}>
                                            <DatePicker style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={9} style={borderedColStyle}>
                                <Form.Item label={<span className="custom-table-header">13.INSURED'S OR AUTHORIZED PERSON'S SIGNATURE. I authorize payment
                                    of medical benefits to the undersigned physician or supplier for services
                                    described below.</span>}	>
                                </Form.Item>
                                <Form.Item label="Signed" name="insuredSignature" style={{ marginTop: 20 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16} style={{ marginBottom: '16px' }}>
                            <Col span={9} style={borderedColStyle}>
                                <Form.Item
                                    label={<span className="custom-table-header">14. Date Of Current Illness, Injury, Or Pregnancy (LMP)</span>}
                                >
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item name="currentIllnessDate">
                                                <DatePicker format="MM/DD/YY" style={{ width: '100%' }} className="input" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name="currentIllnessQual">
                                                <Input style={{ width: '100%' }} className="input" placeholder="QUAL" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col span={6} style={borderedColStyle}>
                                <Form.Item label={<span className="custom-table-header">15. Other Date</span>}>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item name="otherDateQual">
                                                <Input style={{ width: '100%' }} className="input" placeholder="QUAL" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name="otherDate">
                                                <DatePicker format="MM/DD/YY" style={{ width: '100%' }} className="input" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col span={9} style={borderedColStyle}>
                                <Form.Item label={<span className="custom-table-header">16. Dates Patient Unable To Work In Current Occupation</span>}>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item name="unableToWorkFrom">
                                                <DatePicker format="MM/DD/YY" style={{ width: '100%' }} className="input" placeholder="From Date" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name="unableToWorkTo">
                                                <DatePicker format="MM/DD/YY" style={{ width: '100%' }} className="input" placeholder="To Date" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16} style={{ marginBottom: '16px' }}>
                            <Col span={9} style={borderedColStyle}>
                                <Form.Item label={<span className="custom-table-header">17. Name Of Referring Provider Or Other Source</span>} name="referringProvider">
                                    <Input style={{ width: '100%' }} className="input" />
                                </Form.Item>
                            </Col>
                            <Col span={6} style={borderedColStyle}>
                                <Form.Item label={<span className="custom-table-header">17a.</span>} name="providerQual">
                                    <Input style={{ width: '100%' }} className="input" />
                                </Form.Item>
                                <Form.Item label={<span className="custom-table-header">17b. NPI</span>} name="providerNPI">
                                    <Input style={{ width: '100%' }} className="input" />
                                </Form.Item>
                            </Col>
                            <Col span={9} style={borderedColStyle}>
                                <Form.Item label={<span className="custom-table-header">18. Hospitalization Dates Related To Current Services</span>}>
                                    <Row gutter={16}>
                                        <Col xs={12} sm={12} className="form-group">
                                            <Form.Item name="hospitalizationFrom">
                                                <DatePicker format="MM/DD/YY" style={{ width: '100%' }} className="input" placeholder="From Date" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} sm={12} className="form-group">
                                            <Form.Item name="hospitalizationTo">
                                                <DatePicker format="MM/DD/YY" style={{ width: '100%' }} className="input" placeholder="To Date" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>

                        {/* <Row className="bordered-row">
                            <Col span={9} className="bordered-col">
                                <Form.Item label={<span className="custom-table-header">17. Name Of Referring Provider Or Other Source</span>} name="referringProvider">
                                    <Input style={{ width: '100%' }} className="input" />
                                </Form.Item>
                            </Col>
                            <Col span={6} className="bordered-col">
                                <Form.Item label={<span className="custom-table-header">17a.</span>} name="providerQual">
                                    <Input style={{ width: '100%' }} className="input" />
                                </Form.Item>
                                <Form.Item label={<span className="custom-table-header">17b. NPI</span>} name="providerNPI">
                                    <Input style={{ width: '100%' }} className="input" />
                                </Form.Item>
                            </Col>
                            <Col span={9} className="bordered-col">
                                <Form.Item label={<span className="custom-table-header">18. Hospitalization Dates Related To Current Services</span>}>
                                    <Row gutter={16}>
                                        <Col xs={12} sm={12} className="form-group">
                                            <Form.Item name="hospitalizationFrom">
                                                <DatePicker format="MM/DD/YY" style={{ width: '100%' }} className="input" placeholder="From Date" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} sm={12} className="form-group">
                                            <Form.Item name="hospitalizationTo">
                                                <DatePicker format="MM/DD/YY" style={{ width: '100%' }} className="input" placeholder="To Date" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row> */}

                        <Row gutter={16} style={{ marginBottom: '16px' }}>
                            <Col span={15} style={borderedColStyle}>
                                <Form.Item label={<span className="custom-table-header">19. Additional Claim Information (Designated by NUCC)</span>} name="additionalClaimInfo">
                                    <Input style={{ width: '100%' }} className="input" />
                                </Form.Item>
                            </Col>
                            <Col span={9} style={borderedColStyle}>
                                <Form.Item label={<span className="custom-table-header">20. Outside Lab?</span>} name="outsideLab">
                                    <Radio.Group>
                                        <Radio value="yes">YES</Radio>
                                        <Radio value="no">NO</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16} style={{ marginBottom: '16px' }}>
                            <Col span={15} style={borderedColStyle}>
                                <Form.Item label={<span className="custom-table-header">21. Diagnosis Or Nature Of Illness Or Injury Relate A-L to service line below (24E)</span>}>
                                    <Row gutter={[16, 16]}>
                                        {['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'].map((placeholder, index) => (
                                            <Col xs={12} sm={8} md={6} lg={4} className="form-group" key={index}>
                                                <Input placeholder={placeholder} />
                                            </Col>
                                        ))}
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col span={9} style={borderedColStyle}>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item label={<span className="custom-table-header">22. Resubmission Code</span>}>
                                            <Input style={{ width: '100%' }} className="input" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label={<span className="custom-table-header">Original Ref. No.</span>}>
                                            <Input style={{ width: '100%' }} className="input" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row span={24}>
                                    <Col span={24}>
                                        <Form.Item label={<span className="custom-table-header">23. Prior Authorization Number</span>}>
                                            <Input style={{ width: '100%' }} className="input" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>


                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form>
                </Layout.Content>
            </Layout>
        </>
    );
}

export default CMSTest;
