export const AppointmentTypeGridColumns = {
  columns: [
    {
      title: "Action",
      key: "action",
      render: ["edit", "delete"],
      width: 100,
      hidden: false,
    },
    {
      title: "Appointment Type Id",
      dataIndex: "appointmentTypeId",
      key: "appointmentTypeId",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: true,
    },
    {
      title: "Appointment Name",
      dataIndex: "appointmentName",
      key: "appointmentName",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: false,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: false,
    },
    // {
    //   title: "Identification",
    //   dataIndex: "identification",
    //   key: "identification",
    //   feature: ["sorter", "filter"],
    //   filters: [],
    //   width: 150,
    //   hidden: false,
    // },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: false,
    },
    {
      title: "Is Blocked",
      dataIndex: "isBlocked",
      key: "isBlocked",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: false,
    },
    {
      title: "Allow Make",
      dataIndex: "allowMake",
      key: "allowMake",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: false,
    },
    {
      title: "Allow Confirm",
      dataIndex: "allowConfirm",
      key: "allowConfirm",
      feature: ["sorter", "filter"],
      filters: [],
      width: 150,
      hidden: false,
    },
    {
      title: " Allow Cancel",
      dataIndex: "allowCancel",
      key: "allowCancel",
      feature: ["sorter", "filter"],
      filters: [],
      width: 100,
      hidden: false,
    },
    {
      title: "Allow Reschedule",
      dataIndex: "allowReschedule",
      key: "allowReschedule",
      feature: ["sorter", "filter"],
      filters: [],
      width: 100,
      hidden: false,
    },
    {
      title: " Allow 48hour",
      dataIndex: "allow48Hour",
      key: "allow48Hour",
      feature: ["sorter", "filter"],
      filters: [],
      width: 100,
      hidden: false,
    },
  ],
};


