
import axiosInterceptor from "../../middleware/axiosInterceptor";

export const fetchAppointmentStatus = async () => {
  const url = "/api/appointments/status";
  try {
    const response = await axiosInterceptor.get(url);
    return response.data;
  }
  catch (error) {
    console.error(error);
  }
};

export const fetchPatients = async (patientName) => {
  const url = `/api/patients/${patientName}`;
  try {
    const response = await axiosInterceptor.get(url);
    return response.data;
  }
  catch (error) {
    console.error('Error fetching patients:', error);
    throw error;
  }
};


export const fetchAppointmentCRM = async (appointmentID) => {
  const url = "/api/appointment/" + appointmentID;
  try {
    const response = await axiosInterceptor.get(url);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getAppointmentData = async (appointment) => {
  const url = "/api/appointments";
  try {
    const response = await axiosInterceptor.post(url, appointment);
    return response.data;
  } catch (error) {
    console.error(error);
    return { error: error.message };
  }
};


export const getAppointmentSecurityPin = async (securityPinData) => {
  const url = "/api/appointments/securityPin";
  try {
    const response = await axiosInterceptor.post(url, securityPinData);
    return response.data;
  } catch (error) {
    console.error(error);
    return { error: error.message };
  }
};

export const getAppointmentConfirm = async () => {
  const url = "/api/appointment/confirm";
  try {
    const response = await axiosInterceptor.post(url);
    return response.data;
  } catch (error) {
    console.error(error);
    return { error: error.message };
  }
};

export const getAppointmentCancel = async () => {
  const url = "/api/appointment/cancel";
  try {
    const response = await axiosInterceptor.post(url);
    return response.data;
  } catch (error) {
    console.error(error);
    return { error: error.message };
  }
};

