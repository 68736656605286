

import axiosInterceptor from "../../middleware/axiosInterceptor";

export async function locationList() {
  const url = "/api/locations";

  try {
    const response = await axiosInterceptor.get(url);
    return response.data.practiceLocations;
  } catch (error) {
    console.error("Error in locationList service:", error);
    throw error;
  }
}

export const practiceLocationById = async (locationid) => {
  const url = "/api/location/" + locationid;
  try {
    const response = await axiosInterceptor.get(url);
    return response.data.practiceLocations;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchFormDetails = async () => {
  const url = "/api/user/fetch-location-initial-data";
  try {
    const response = await axiosInterceptor.get(url);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const deleteLocationDetail = async (deleteData) => {
  const url = "/api/location/delete";
  try {
    const response = await axiosInterceptor.delete(url, { data: deleteData });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};



export const providerLocation = async (locationid) => {
  const url = `/api/location/${locationid}/providers`;
  try {
    const response = await axiosInterceptor.get(url);
    return response.data.provider;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const saveLocationDetail = async (formData) => {
  const url = "/api/addlocation";
  try {
    const response = await axiosInterceptor.post(url, formData);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getLocationDetail = async (locationid) => {
  const url = "/api/pratice-location/" + locationid;
  try {
    const response = await axiosInterceptor.get(url);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateLocationDetail = async (formData) => {
  const url = "/api/update-location";
  try {
    const response = await axiosInterceptor.post(url, formData);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};