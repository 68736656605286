
import { cloneDeep } from 'lodash';
import dayjs from 'dayjs';
import {
  deleteLocationDetail, fetchFormDetails, getLocationDetail, locationList,
  saveLocationDetail, updateLocationDetail
} from '../../services/PracticeLocationService';
import { practiceLocationById, providerLocation } from '../../services/PracticeLocationService';
import { Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { removeFormatting } from '../utilities/utility';

const { confirm } = Modal;

export const fetchData = async (setLocationData, setLoading) => {
  setLoading(true);
  try {
    const locations = await locationList();
    setLocationData(locations);
  } catch (error) {
    console.error('Error fetching location data:', error);
  } finally {
    setLoading(false);
  }
};

export const getGridData = (locationData) => {
  const data = cloneDeep(locationData);
  return data.map(location => ({
    practiceId: location.practiceId,
    identification: location.identification,
    timezoneid: location.timezoneid,
    phoneticname: location.phoneticname,
    locationId: location.locationId,
    practiceName: location.practiceName,
  }));
};

const setformdatetails = (form, formData, setDaysOfOperation) => {
  if (formData != null) {
    form.setFieldsValue({
      "streetAddress": formData.streetAddress,
      "locationId": formData.locationId,
      "locationName": formData.locationName,
      "city": formData.city,
      "state": formData.state,
      "zip": formData.zip,
      "phone": formData.phone,
      "country": formData.country,
      "timeZone": formData.timeZone,
      "holiday": formData.holiday,
      "phoneticName": formData.phoneticName,
      "pbxPhone": formData.pbxPhone,
      "alternatepbxPhone": formData.alternatepbxPhone,
      "workingHoursOptNumber": formData.workingHoursOptNumber,
      "afterHoursOptNumber": formData.afterHoursOptNumber,
      // "startTime": formData.startTime,
      // "endTime": formData.endTime
    });

    if (formData.dayOfOperation != null) {
      setDaysOfOperation(formData.dayOfOperation);
    }
  }
};

export const handleEdit = async (locationid, handleEditClick) => {
  try {
    const response = await practiceLocationById(locationid);
    const locationData = response[0];
    handleEditClick(locationData);
  } catch (error) {
    console.error("Error fetching provider details:", error);
  }
};

export const handleLinkEdit = async (locationid, handleLinkClick) => {
  try {
    const response = await providerLocation(locationid);
    const locationProvider = response[0];
    handleLinkClick(locationProvider); // Set the selected provider in the state
  } catch (error) {
    console.error("Error fetching provider details:", error);
  }
};

export const handleCancel = (setSelectedLocation, setSelectedProvider, setVisible) => {
  setSelectedLocation(null);
  setSelectedProvider(null);
  setVisible(false);
};

export const onBack = (setSelectedProvider, setVisible) => {
  setSelectedProvider(null);
  setVisible(false);
};

export async function fetchPopulateData(setTimeZone, setholiday, setcountry, setLoading) {
  try {
    setLoading(true);
    const response = await fetchFormDetails();
    setTimeZone(response.data.timeZones);
    setholiday(response.data.holiday);
    setcountry(response.data.country);
    // return response;
  } catch (error) {
    console.error('Error fetching location data:', error);
  } finally {
    setLoading(false);
  }
};


// export const handleDeleteDOP = (record, daysOfOperation, setDaysOfOperation) => {
  
//   confirm({
//     title: 'Are you sure you want to delete this practice location?',
//     icon: <ExclamationCircleOutlined />,
//     onOk() {
      
//       const updatedTableData = daysOfOperation.filter(item =>
//         !(item.day === record.day && item.fromTime === record.fromTime && item.toTime === record.toTime)
//       );
//       setDaysOfOperation(updatedTableData);
//     },
//     onCancel() {
      
//     },
//   });
// };

export const handleDeleteDOP = (index, daysOfOperation, setDaysOfOperation) => {
  confirm({
    title: 'Are you sure you want to delete this practice location?',
    icon: <ExclamationCircleOutlined />,
    onOk() {
  
      const updatedTableData = daysOfOperation.filter((item, idx) => idx !== index);
      setDaysOfOperation(updatedTableData);
    },
    onCancel() {
    },
  });
};


export async function handleModalConfirm(modalData, fetchData, setLocationData, setLoading, setModalData) {
  if (modalData.type === 'delete') {
    try {
      const deleteData = {
        locationid: modalData.record.practiceLocationid,
        updatedby: sessionStorage.getItem('userId')
      };

      if (!deleteData.locationid) {
        throw new Error('Practice Location id cannot be empty');
      }
      const response = await deleteLocationDetail(deleteData);

      if (response && response.data && response.data.success) {
        Modal.success({ title: "Success", content: "Practice Location deleted successfully." });
        fetchData(setLocationData, setLoading);
      } else {
        message.error("Failed to delete practice location");
      }
    } catch (error) {
      message.error(error.message || "Failed to delete practice location");
    }
    finally {
      setModalData({ ...modalData, visible: false });
    }
  }
}

export const handleDeletelocation = async (location) => {
  confirm({
    title: 'Are you sure you want to delete this location?',
    icon: <ExclamationCircleOutlined />,
    onOk() {
      deleteLocation(location);
    },
    onCancel() {
    },
  });
};


export async function deleteLocation(location) {
  try {
    const loggedInUserId = sessionStorage.getItem('userId');
    const deleteData = {
      locationid: location.practiceLocationid,
      updatedby: loggedInUserId
    };

    const response = await deleteLocationDetail(deleteData);
    if (response && response.data && response.data.success) {
      Modal.success({ title: "Success", content: "User deleted  successfully." });
    }
    return response;
  } catch (error) {
    console.error("Error delete Location exception:", error.message);
    return null;
  }
};

export const onSavePraticelocation = (form, formData, daysOfOperation, requiredState, initialState, setFormData,
  setDaysOfOperation, setOperations, setErrors) => {
  try {
    const newErrors = {};
    formData.createdBy = sessionStorage.getItem('userId');

    formData.pbxPhone = removeFormatting(formData.pbxPhone);
    formData.alternatepbxPhone = removeFormatting(formData.alternatepbxPhone);

    formData.workingHoursOptNumber = removeFormatting(formData.workingHoursOptNumber);
    formData.afterHoursOptNumber = removeFormatting(formData.afterHoursOptNumber);

    formData.phone = removeFormatting(formData.phone);
    formData.dayOfOperation = daysOfOperation;

    Object.keys(requiredState).forEach((fieldName) => {
      if (!formData[fieldName]) {
        newErrors[fieldName] = `Please input your ${fieldName}!`;
      }
    });

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      if (saveLocation(formData)) {
        setFormData(initialState);
        setDaysOfOperation([]);
        setOperations();
        setformdatetails(form, initialState, setDaysOfOperation);
        Modal.success({ title: "Success", content: "Practice location save successfully." });
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  } catch (error) {
    console.error("Error save practice location exception:", error.message);
    return false;
  }
}

export async function saveLocation(formData) {
  try {
    const response = await saveLocationDetail(formData);
    if (response && response.data && response.data.success) {
      return true;
    }
    else {
      if (response && response.data && response.data.businessRules && response.data.businessRules.length > 0) {
        const Errormessage = response.data.businessRules[0].description;
        Modal.error({ title: "Fail", content: Errormessage });
        return false;
      }
    }
  } catch (error) {
    console.error("Error save practice location exception:", error.message);
    return false;
  }
};

export async function getPraticeLocationbyId(form, locationid, setFormData, setDaysOfOperation, setLoading) {
    try {
    setLoading(true);
    const response = await getLocationDetail(locationid);
    if (response && response.data && response.data.success) {
      setFormData(response.data.practiceLocations[0]);
      setformdatetails(form, response.data.practiceLocations[0], setDaysOfOperation);
    }
    setLoading(false);
    return response;
  } catch (error) {
    console.error("Error delete practice location exception:", error);
    return null;
  }
};

// function removeFormatting(phoneNumber) {
//   //return phoneNumber.replace(/[()\s-]/g, '');
//   return phoneNumber;
// }

export const onUpdatePraticelocation = (form, practiceLocationid, formData, daysOfOperation, requiredState, initialState, setFormData,
  setDaysOfOperation, setOperations, setErrors) => {
    try {
    const newErrors = {};
    formData.createdBy = sessionStorage.getItem('userId');
    formData.practiceLocationid = practiceLocationid;

    formData.pbxPhone = removeFormatting(formData.pbxPhone);
    formData.alternatepbxPhone = removeFormatting(formData.alternatepbxPhone);

    formData.workingHoursOptNumber = removeFormatting(formData.workingHoursOptNumber);
    formData.afterHoursOptNumber = removeFormatting(formData.afterHoursOptNumber);

    formData.startTime = formData.startTime ? dayjs(formData.startTime).format('HH:mm') : '';
    formData.endTime = formData.endTime ? dayjs(formData.endTime).format('HH:mm') : '';

    formData.phone = removeFormatting(formData.phone);
    formData.dayOfOperation = daysOfOperation;

    Object.keys(requiredState).forEach((fieldName) => {
      if (!formData[fieldName]) {
        newErrors[fieldName] = `Please input your ${fieldName}!`;
      }
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      if (updateLocation(formData)) {
        setFormData(initialState);
        setDaysOfOperation([]);
        setOperations();
        setformdatetails(form, initialState, setDaysOfOperation);

        Modal.success({ title: "Success", content: "Practice location updated successfully." });
        return true;
      } else {
        return false;
      }
    }
    else {
      return false;
    }
  } catch (error) {
    console.error("Error updated practice location exception:", error.message);
    return false;
  }
}

export async function updateLocation(formData) {
  try {
    const response = await updateLocationDetail(formData);
    if (response && response.data && response.data.success) {
      return true;
    }
    else {
      if (response && response.data && response.data.businessRules && response.data.businessRules.length > 0) {
        const Errormessage = response.data.businessRules[0].description;
        Modal.error({ title: "Fail", content: Errormessage });
        return false;
      }
    }
  } catch (error) {
    console.error("Error updated practice location exception:", error.message);
    return false;
  }
};


